import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import "../CSS/cycle-vs-ecycle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "react-router-dom";
import "../CSS/form.css";
import bluelogo from "../images/EM-LOGO_Blue.png";
import FormTestRideKerala from "../Components/FormTestRideKerala";
import FormPopUpModalKerala from "../Components/FormPopUpModalKerala";

const CycleVsEcycleKerala = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  SwiperCore.use([Navigation, Pagination]);

  return (
    <>
      <div
        className="position-sticky navigation w-100"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div className=" desktop-nav m-0 p-0 p-3 ps-lg-5 pe-lg-5 ">
          <div className="d-flex justify-content-center">
            <img
              src={bluelogo}
              className="img-fluid"
              style={{ width: "170px" }}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="cycle-vs-ecycle">
        {/*hero banner */}
        <div className="hero-banner position-relative">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/MOBILE+VIEW+BANNER.png"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/DESKTOP+VIEW+BANNER.png"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/DESKTOP+VIEW+BANNER.png"
              alt="t-Rex+banner"
              className="d-block w-100"
            />
          </picture>
          <div className="position-absolute banner-cta-text d-none d-lg-block">
            <h1
              className="text-uppercase text-em"
              style={{ fontSize: "1.5em", fontWeight: "bolder" }}
            >
              <span style={{ fontFamily: "Metropolis-bold" }}>പെഡൽ</span>
              &nbsp;അല്ലെങ്കിൽ&nbsp;
              <span style={{ fontFamily: "Metropolis-bold" }}>
                ത്വരിതപ്പെടുത്തുക
              </span>
            </h1>
            <div className="d-flex justify-content-center align-items-center ">
              <FormTestRideKerala />
            </div>
          </div>
          <div className="position-absolute w-100 banner-cta-text-mb d-lg-none">
            <div className="d-flex justify-content-center">
              <h1
                className="text-uppercase text-em"
                style={{ fontSize: "0.9em", fontWeight: "bolder" }}
              >
                <span style={{ fontFamily: "Metropolis-bold" }}>പെഡൽ</span>
                &nbsp;അല്ലെങ്കിൽ&nbsp;
                <span style={{ fontFamily: "Metropolis-bold" }}>
                  ത്വരിതപ്പെടുത്തുക
                </span>
              </h1>
            </div>
          </div>

          <div
            className="position-absolute banner-div-curved d-flex justify-content-center align-items-center"
            onClick={() => setIsModalOpen(true)}
            style={{ cursor: "pointer" }}
          >
            <div className="background-em text-light px-4 py-3 px-lg-5 text-center">
              <h1 className="m-0 banner-text">₹999 മുതൽ ഇ-സൈക്കിൾ നേടൂ</h1>
            </div>
          </div>
        </div>
        <div className="mt-5 d-flex justify-content-between align-items-center flex-column w-100 banner-cta-btn-mb d-lg-none">
          <FormTestRideKerala />
        </div>

        {/* usp */}
        <div className="px-lg-5 px-3 mt-5 pt-lg-4 usp-comparison ">
          <h2
            className="text-uppercase mb-3"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            എന്തുകൊണ്ടാണ് ഇ-സൈക്കിൾ തിരഞ്ഞെടുക്കുന്നത്?
          </h2>
          <div className="row">
            <div className="col-6 col-lg-3 mb-3">
              <div className="d-flex p-3 usp-comparison-sub-div background-em rounded-4 align-items-center">
                <div className="usp-comparison-img">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/SWEAT+FREE+COMMUTE+ICON.png"
                    className="img-fluid"
                    alt="Sweat Free Commute"
                  />
                </div>
                <div>
                  <h3 className="text-uppercase text-light mb-3 mb-lg-0">
                    വിയർപ്പ് രഹിത യാത്ര
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 mb-3">
              <div className="d-flex p-3 usp-comparison-sub-div background-em rounded-4 align-items-center">
                <div className="usp-comparison-img">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/80%2B+KM+RANGE+ICON.png"
                    className="img-fluid"
                    alt="80+ KM Range"
                  />
                </div>
                <div>
                  <h3 className="text-uppercase text-light mb-0 d-lg-none">
                    80+ കി.മീ
                    <br />
                    25 കിലോമീറ്റർ വേഗത
                  </h3>
                  <h3 className="d-none d-lg-block text-uppercase text-light mb-0">
                    80+ കി.മീ 25 കിലോമീറ്റർ വേഗത
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 mb-3">
              <div className="d-flex p-3 usp-comparison-sub-div background-em rounded-4 align-items-center">
                <div className="usp-comparison-img">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/NO+COST+EMI+ICON+.png"
                    className="img-fluid"
                    alt="No-Cost EMI"
                  />
                </div>
                <div>
                  <h3 className="text-uppercase text-light mb-3 mb-lg-0">
                    നോ-കോസ്റ്റ് ഇഎംഐ
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 mb-3">
              <div className="d-flex p-3 usp-comparison-sub-div background-em rounded-4 align-items-center">
                <div className="usp-comparison-img">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/PEDAL+OR+ACCELERATE+ICON.png"
                    className="img-fluid"
                    alt="Pedal or Accelerate"
                  />
                </div>
                <div>
                  <h3 className="text-uppercase text-light mb-0">
                    പെഡൽ അല്ലെങ്കിൽ ത്വരിതപ്പെടുത്തുക
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* cycle vs ecycle */}
        <div className="comparison-landing px-lg-5 mt-4 mt-lg-5">
          <h2
            className="px-3 px-lg-0 mb-3"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            സൈക്കിളും സൈക്കിളും തമ്മിലുള്ള വ്യത്യാസം
          </h2>
          <div className="d-flex comparison-div">
            <div className="col col-md-6 first-div pe-lg-3 ">
              <picture className="main-banner-img">
                <source
                  media="(max-width:650px)"
                  srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/E-CYCLE+COMPARISON+MOBILE+KERALA.png"
                />
                <source
                  media="(min-width:651px)"
                  srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/E-CYCLE+COMPARISON+DESKTOP+KERALA.png"
                />
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/E-CYCLE+COMPARISON+DESKTOP+KERALA.png"
                  alt="t-Rex+banner"
                  className="d-block w-100"
                />
              </picture>
              <div className="comparison-points px-3 px-lg-0 my-lg-3 mt-3">
                <ul>
                  <li>
                    <p className="mb-1">ദൈർഘ്യമേറിയ യാത്രകൾക്ക് മികച്ചത്</p>
                  </li>
                  <li>
                    <p className="mb-1">
                      ശാരീരികക്ഷമതയ്‌ക്കുള്ള പെഡൽ അല്ലെങ്കിൽ വിയർപ്പില്ലാത്ത
                      യാത്രയ്‌ക്കായി ത്രോട്ടിൽ
                    </p>
                  </li>
                  <li>
                    <p className="mb-1">
                      വാഹനങ്ങൾ മാറ്റിസ്ഥാപിക്കാം, ഇന്ധനച്ചെലവിൽ ഗണ്യമായി
                      ലാഭിക്കാം
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <hr
              className="d-lg-none"
              style={{ borderTop: "2px solid", opacity: "0.7", margin: "0" }}
            ></hr>
            <div className="col col-md-6 ps-lg-3">
              <picture className="main-banner-img mt-4 mt-lg-0">
                <source
                  media="(max-width:650px)"
                  srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/CYCLE+COMPARISON+MOBILE+KERALA.png"
                />
                <source
                  media="(min-width:651px)"
                  srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/CYCLE+COMPARISON+DESKTOP+KERALA.png"
                />
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/CYCLE+COMPARISON+DESKTOP+KERALA.png"
                  alt="t-Rex+banner"
                  className="d-block w-100"
                />
              </picture>
              <div className="comparison-points px-3 px-lg-0 my-3">
                <ul>
                  <li>
                    <p className="mb-1">
                      ദൈർഘ്യമേറിയ യാത്രകൾക്കായി രൂപകൽപ്പന ചെയ്തിട്ടില്ല
                    </p>
                  </li>
                  <li>
                    <p className="mb-1">കൂടുതൽ ശാരീരിക പരിശ്രമം ആവശ്യമാണ്</p>
                  </li>
                  <li>
                    <p className="mb-1">
                      ഇന്ധനം ഉപയോഗിക്കുന്ന വാഹനങ്ങൾ മാറ്റിസ്ഥാപിക്കാൻ കഴിയില്ല
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={() => setIsModalOpen(true)}
          className=" text-light mx-auto bg-gradient w-100 d-flex justify-content-center align-items-center w-fit  mt-4"
          style={{ fontFamily: "Metropolis-medium", cursor: "pointer" }}
        >
          <div className="background-em  w-fit d-inline-block px-4 py-2 text-uppercase rounded-3">
            ഒരു ടെസ്റ്റ് റൈഡ് ബുക്ക് ചെയ്യുക
          </div>
        </div>
        {/* feature */}
        <div className="px-lg-5 px-3 mt-5 features-comparison">
          <h2
            className=" text-uppercase mb-3"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            കുറഞ്ഞ പരിശ്രമം, ദൈർഘ്യമേറിയ യാത്രകൾ
          </h2>

          <div className="d-lg-none">
            <video
              autoPlay
              muted
              loop
              disableRemotePlayback
              playsInline
              className="w-100"
            >
              <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/Trex+air+yellow_1.mp4" />
              നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല. നിങ്ങളുടെ
              ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
            </video>
          </div>

          <Swiper
            navigation={{ clickable: true }}
            spaceBetween={15}
            slidesPerView={1.3}
            initialSlide={1}
            centeredSlides={false}
            loop={true}
            breakpoints={{
              1024: {
                slidesPerView: 5,
                spaceBetween: 20,
                centeredSlides: false,
                navigation: { clickable: false },
                arrows: false,
              },
            }}
            className="inside-div"
          >
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/pedal+assist.mp4" />
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="" style={{ height: "100px" }}>
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    പെഡൽ അസിസ്റ്റ് ടെക്നോളജി
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    ആയാസരഹിതമായ റൈഡുകൾ
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#pedalAssistModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/charging.mp4" />
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="" style={{ height: "100px" }}>
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    നീക്കം ചെയ്യാവുന്ന ബാറ്ററി
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    എവിടെയും ചാർജ് ചെയ്യുക
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#batteryModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/display.mp4" />
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="" style={{ height: "100px" }}>
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    മൾട്ടിഫങ്ഷണൽ ഡിസ്പ്ലേ
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    നിയന്ത്രണത്തിൽ തുടരുക
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#displayModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/suspension.mp4" />
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="" style={{ height: "100px" }}>
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    ഡ്യുവൽ സസ്പെൻഷൻ
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    ആശ്വാസം പുനർനിർവചിച്ചു
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#suspensionModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/range.mp4" />
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="" style={{ height: "100px" }}>
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    80+ കി.മീ
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    യാത്രയ്‌ക്കായി നിർമ്മിച്ചത്
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#rangeModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/frame.mp4" />
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="" style={{ height: "100px" }}>
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    മടക്കാവുന്ന ഫ്രെയിം
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    കോംപാക്റ്റ് ഒപ്പം പോർട്ടബിൾ
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#frameModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/warranty.mp4" />
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="" style={{ height: "100px" }}>
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    വിശ്വസനീയമായ വാറൻ്റി
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>മനസ്സമാധാനം</h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#warrantyModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/fat+tyre.mp4" />
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                    നിങ്ങളുടെ ബ്രൗസർ വീഡിയോ ടാഗിനെ പിന്തുണയ്ക്കുന്നില്ല.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="" style={{ height: "100px" }}>
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    ഫാറ്റ് ടയർ ഓപ്ഷൻ
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    റോഡുകൾ കീഴടക്കുക
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#fatTyreModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* display modal */}
        <div
          class="modal fade"
          id="displayModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  ഒറ്റനോട്ടത്തിൽ സമഗ്രമായ തത്സമയ വിവരങ്ങളുമായി മുന്നോട്ട് പോകുക.
                  വേഗത, യാത്ര ചെയ്ത ദൂരം, ബാറ്ററി ലെവൽ, അസിസ്റ്റ് മോഡ്
                  തിരഞ്ഞെടുക്കൽ തുടങ്ങിയ അവശ്യ ഡാറ്റ ഇത് വാഗ്ദാനം ചെയ്യുന്നു,
                  സുരക്ഷിതവും കൂടുതൽ അറിവുള്ളതുമായ സൈക്ലിംഗ് അനുഭവം
                  ഉറപ്പാക്കിക്കൊണ്ട് റൈഡറുടെ നിയന്ത്രണവും സൗകര്യവും
                  വർദ്ധിപ്പിക്കുന്നു.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* pedal assist modal */}
        <div
          class="modal fade"
          id="pedalAssistModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  പെഡലിംഗ് പ്രയത്നത്തെ അടിസ്ഥാനമാക്കി സ്വയമേവ സഹായം നൽകിക്കൊണ്ട്
                  റൈഡിംഗ് അനുഭവം മെച്ചപ്പെടുത്തുക. ഈ ഫീച്ചർ അനായാസമായ സൈക്ലിംഗ്
                  പ്രോത്സാഹിപ്പിക്കുന്നു, പ്രത്യേകിച്ച് ചരിവുകളിലോ ദീർഘദൂര
                  യാത്രകളിലോ, സുഗമവും കൂടുതൽ ആസ്വാദ്യകരവുമായ സവാരിക്ക്
                  മനുഷ്യശക്തിയുടെയും വൈദ്യുത സഹായത്തിൻ്റെയും തടസ്സമില്ലാത്ത
                  മിശ്രിതം വാഗ്ദാനം ചെയ്യുന്നു.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* warranty modal */}
        <div
          class="modal fade"
          id="warrantyModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  ഫ്രെയിമുകൾ മുതൽ ബാറ്ററികൾ, ഇലക്ട്രിക്കൽ ഘടകങ്ങൾ വരെയുള്ള
                  സമഗ്രമായ കവറേജ്, ഞങ്ങളുടെ വാറൻ്റി നിങ്ങൾ വഴിയുടെ ഓരോ ഘട്ടവും
                  കവർ ചെയ്തിട്ടുണ്ട്. എല്ലാ പെഡലിലും ആശങ്കയില്ലാതെ യാത്ര
                  ചെയ്യുക!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* frame modal */}
        <div
          class="modal fade"
          id="frameModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  മടക്കാവുന്ന ഫ്രെയിമും വരുന്നു, നിങ്ങൾക്ക് ആവശ്യമുള്ളിടത്തേക്ക്
                  കൊണ്ടുപോകുന്നത് എളുപ്പമാക്കുന്നു. അനായാസം ട്രാഫിക്കിലൂടെ
                  തുറക്കുകയും സിപ്പ് ചെയ്യുകയും ചെയ്യുക. ഇത് സ്റ്റൈലിഷ് പോലെ
                  ഭാരം കുറഞ്ഞതാണ്. നിങ്ങൾ യാത്ര ചെയ്യുകയാണെങ്കിലും അല്ലെങ്കിൽ
                  വിനോദ സവാരികൾക്കായി ഇത് ഉപയോഗിക്കുന്നുണ്ടെങ്കിലും, നിങ്ങൾ
                  എവിടെ പോയാലും അതിൻ്റെ കരുത്തുറ്റ ബിൽഡ് നിങ്ങളെ തിരികെ
                  കൊണ്ടുവരും!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* fat tyre modal */}
        <div
          class="modal fade"
          id="fatTyreModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  ഫാറ്റ് ടയർ ഇലക്ട്രിക് സൈക്കിൾ സ്വന്തമാക്കാൻ
                  ആഗ്രഹിക്കുന്നുണ്ടോ? ഞങ്ങൾക്ക് അതും ഉണ്ട്! എടുക്കാത്ത റോഡുകൾ
                  എടുക്കുക, പുതിയ സാഹസികതകളും സമാനതകളില്ലാത്ത വിനോദവും അൺലോക്ക്
                  ചെയ്യുക!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* range modal */}
        <div
          class="modal fade"
          id="rangeModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  ഒറ്റ ചാർജിൽ ദീർഘദൂര യാത്രാ ദൂരങ്ങൾ നൽകുന്നു. ഈ കഴിവ് റൈഡർമാരെ
                  കൂടുതൽ ദൂരം അനായാസം മറികടക്കാൻ പ്രാപ്തരാക്കുന്നു, പരമ്പരാഗത
                  സൈക്കിളുകൾക്ക് ആവശ്യമായ ശാരീരിക അദ്ധ്വാനമില്ലാതെ യാത്ര
                  ചെയ്യുന്നതിനും വിനോദ യാത്രകൾക്കും ഞങ്ങളുടെ ഇലക്ട്രിക്
                  സൈക്കിളുകൾ അനുയോജ്യമാക്കുന്നു.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* suspension modal */}
        <div
          class="modal fade"
          id="suspensionModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  അസമമായ ഭൂപ്രദേശങ്ങളിൽ നിന്നുള്ള ബമ്പുകളും വൈബ്രേഷനുകളും ആഗിരണം
                  ചെയ്യുന്നു, സുഖവും സ്ഥിരതയും വർദ്ധിപ്പിക്കുന്നു. ഇത്
                  റൈഡറിലുള്ള ആഘാതം കുറയ്ക്കുന്നതിലൂടെ റൈഡ് നിലവാരം
                  മെച്ചപ്പെടുത്തുന്നു, വ്യത്യസ്ത റോഡ് അവസ്ഥകൾക്ക്
                  അനുയോജ്യമാക്കുകയും മൊത്തത്തിൽ സുഗമവും കൂടുതൽ ആസ്വാദ്യകരവുമായ
                  സൈക്ലിംഗ് അനുഭവം ഉറപ്പാക്കുകയും ചെയ്യുന്നു.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* battery modal */}
        <div
          class="modal fade"
          id="batteryModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  നീക്കം ചെയ്യാവുന്ന ബാറ്ററി ഫീച്ചർ സൗകര്യപ്രദമായ ചാർജിംഗും
                  സംഭരണവും അനുവദിക്കുന്നു. എളുപ്പത്തിൽ വേർപെടുത്താവുന്ന, ഇത്
                  വീട്ടിലോ ഓഫീസിലോ തടസ്സങ്ങളില്ലാതെ റീചാർജ് ചെയ്യാൻ
                  സഹായിക്കുന്നു, സങ്കീർണ്ണമായ ഇൻഫ്രാസ്ട്രക്ചറിൻ്റെ ആവശ്യമില്ലാതെ
                  തടസ്സമില്ലാത്ത റൈഡുകൾ ഉറപ്പാക്കുന്നു, ഇത് നഗര ചലനത്തിന്
                  അനുയോജ്യമാക്കുന്നു. ഒരു സ്മാർട്ട്ഫോൺ പോലെ ചാർജ് ചെയ്യുക,
                  അക്ഷരാർത്ഥത്തിൽ!
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* reviews */}
        <div className="px-lg-5 px-3 my-5">
          <h2
            className="text-uppercase mb-3"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            ഗോത്രത്തിൽ നിന്ന് കേൾക്കുക
          </h2>
          <Swiper
            navigation={{ clickable: true }}
            spaceBetween={15}
            slidesPerView={1.3}
            initialSlide={2}
            centeredSlides={false}
            loop={true}
            breakpoints={{
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
                centeredSlides: false,
                navigation: { clickable: false },
                arrows: false,
                initialSlide: 4,
                allowTouchMove: false,
                loop: false,
                slide: false,
                noSwiping: true,
              },
            }}
            slide
          >
            <SwiperSlide className="">
              <div className="">
                <div>
                  <img
                    className="w-100"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    alt="reviews"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-1.jpg"
                  ></img>
                </div>
                <div
                  className="p-3 review-1-background"
                  style={{
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <h4
                    style={{ fontFamily: "Metropolis-bold" }}
                    className="mb-1 text-light"
                  >
                    കരൺ ജോഷി
                  </h4>
                  <div>
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Icon
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#review1Modal"
                      icon="uiw:plus-circle"
                      width="1.7em"
                      height="1.7em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="">
              <div className="">
                <div>
                  <img
                    className="w-100"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    alt="reviews"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-2.jpg"
                  ></img>
                </div>
                <div
                  className="p-3 review-2-background"
                  style={{
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <h4
                    style={{ fontFamily: "Metropolis-bold" }}
                    className="mb-1 text-light"
                  >
                    കൽപന ജെയിൻ
                  </h4>
                  <div>
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Icon
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#review2Modal"
                      icon="uiw:plus-circle"
                      width="1.7em"
                      height="1.7em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="">
              <div className="">
                <div>
                  <img
                    className="w-100"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    alt="reviews"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-3.jpg"
                  ></img>
                </div>
                <div
                  className="p-3 review-3-background"
                  style={{
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <h4
                    style={{ fontFamily: "Metropolis-bold" }}
                    className="mb-1 text-light"
                  >
                    സുഖ്വീർ സിംഗ്
                  </h4>
                  <div>
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Icon
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#review3Modal"
                      icon="uiw:plus-circle"
                      width="1.7em"
                      height="1.7em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="">
              <div className="">
                <div>
                  <img
                    className="w-100"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    alt="reviews"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-4.jpg"
                  ></img>
                </div>
                <div
                  className="p-3 review-4-background"
                  style={{
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <h4
                    style={{ fontFamily: "Metropolis-bold" }}
                    className="mb-1 text-light"
                  >
                    സംസ്കർ മാരൂ
                  </h4>
                  <div>
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Icon
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#review4Modal"
                      icon="uiw:plus-circle"
                      width="1.7em"
                      height="1.7em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          {/* review 1 modal */}
          <div
            class="modal fade"
            id="review1Modal"
            tabindex="-1"
            aria-labelledby="displayModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div
                class="modal-content text-center text-light"
                style={{ backgroundColor: "#424242" }}
              >
                <div class="modal-body p-3">
                  <div className="d-flex justify-content-end mb-3">
                    <Icon
                      icon="zondicons:minus-solid"
                      width="1.2em"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      height="1.2em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                  <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                    ഞാൻ അടുത്തിടെ Emotorad X1 ഇലക്ട്രിക് സൈക്കിൾ വാങ്ങി, അത്
                    എൻ്റെ പ്രതീക്ഷകളെ പൂർണ്ണമായും മറികടന്നു. സുഗമമായ രൂപകൽപ്പന
                    മുതൽ ശക്തമായ പ്രകടനം വരെ, ഈ ഇ-ബൈക്ക് ഒരു ഗെയിം ചേഞ്ചറാണ്.
                    ബിൽഡ് ക്വാളിറ്റി അസാധാരണമാണ്, സവാരി ചെയ്യുമ്പോൾ ദൃഢവും
                    വിശ്വസനീയവുമായ അനുഭവം നൽകുന്നു. ബാറ്ററി ലൈഫ് ശ്രദ്ധേയമാണ്,
                    പവർ തീരുമെന്ന ആശങ്കയില്ലാതെ ദീർഘദൂര യാത്രകൾ സാധ്യമാക്കുന്നു.
                    പെഡൽ-അസിസ്റ്റ് ഫീച്ചർ തടസ്സമില്ലാത്തതാണ്, കയറ്റം കയറുന്നത്
                    ഒരു കാറ്റ് ഉണ്ടാക്കുന്നു. ഇലക്ട്രിക് മോട്ടോർ സുഗമവും
                    പ്രതികരിക്കുന്നതുമായ യാത്ര നൽകുന്നു, മൊത്തത്തിലുള്ള കൈകാര്യം
                    ചെയ്യൽ മികച്ചതാണ്. നിങ്ങൾ പരിചയസമ്പന്നനായ സൈക്കിൾ യാത്രികനോ
                    പുതിയ ഇലക്ട്രിക് ബൈക്കുകളോ ആകട്ടെ, Emotorad X1 എല്ലാവർക്കും
                    മികച്ച റൈഡിംഗ് അനുഭവം പ്രദാനം ചെയ്യുന്നു. ഉപസംഹാരമായി,
                    താങ്ങാനാവുന്ന വിലയിൽ ഉയർന്ന നിലവാരമുള്ള ഇലക്ട്രിക് സൈക്കിൾ
                    തിരയുന്ന ഏതൊരാൾക്കും ഞാൻ Emotorad X1 വളരെ ശുപാർശ ചെയ്യുന്നു.
                    ഇത് ശൈലി, പ്രകടനം, പുതുമ എന്നിവ തടസ്സങ്ങളില്ലാതെ
                    സംയോജിപ്പിക്കുന്നു, ഇത് വിപണിയിൽ മികച്ച തിരഞ്ഞെടുപ്പായി
                    മാറുന്നു.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* review 2 modal */}
          <div
            class="modal fade"
            id="review2Modal"
            tabindex="-1"
            aria-labelledby="displayModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div
                class="modal-content text-center text-light"
                style={{ backgroundColor: "#424242" }}
              >
                <div class="modal-body p-3">
                  <div className="d-flex justify-content-end mb-3">
                    <Icon
                      icon="zondicons:minus-solid"
                      width="1.2em"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      height="1.2em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                  <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                    ഞാൻ അടുത്തിടെ EMotorad Doodle V2 ഇ-സൈക്കിൾ വാങ്ങി, എൻ്റെ
                    അനുഭവത്തിൽ ഞാൻ പുളകിതനാണ്! ബൈക്കിൻ്റെ സുഗമമായ ഡിസൈൻ, അതിൻ്റെ
                    ശക്തമായ ഇലക്ട്രിക് മോട്ടോറിനൊപ്പം, സുഗമവും ആസ്വാദ്യകരവുമായ
                    യാത്ര പ്രദാനം ചെയ്യുന്നു. ബാറ്ററി ലൈഫ് എൻ്റെ പ്രതീക്ഷകളെ
                    കവിയുന്നു, പവർ തീർന്നുപോകുമെന്ന ആശങ്കയില്ലാതെ ദീർഘദൂര
                    യാത്രകൾ അനുവദിച്ചു. അവബോധജന്യമായ നിയന്ത്രണങ്ങളും സുഖപ്രദമായ
                    ഇരിപ്പിടങ്ങളും വിവിധ ഭൂപ്രദേശങ്ങളിലൂടെ നാവിഗേറ്റ്
                    ചെയ്യുന്നത് സന്തോഷകരമാക്കുന്നു. ശൈലി, പ്രവർത്തനക്ഷമത,
                    കാര്യക്ഷമത എന്നിവ സമന്വയിപ്പിക്കുന്ന ഒരു മികച്ച വൈദ്യുത
                    ചക്രം EMotorad യഥാർത്ഥത്തിൽ സൃഷ്ടിച്ചിരിക്കുന്നു.
                    വിശ്വസനീയവും രസകരവുമായ ഇ-സൈക്കിൾ തിരയുന്ന ആർക്കും ഞാൻ ഡൂഡിൽ
                    V2 വളരെ ശുപാർശ ചെയ്യുന്നു.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* review3 modal */}
          <div
            class="modal fade"
            id="review3Modal"
            tabindex="-1"
            aria-labelledby="displayModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div
                class="modal-content text-center text-light"
                style={{ backgroundColor: "#424242" }}
              >
                <div class="modal-body p-3">
                  <div className="d-flex justify-content-end mb-3">
                    <Icon
                      icon="zondicons:minus-solid"
                      width="1.2em"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      height="1.2em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                  <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                    E MOTORAD X2 ശ്രദ്ധേയമായ ഒരു ഇലക്ട്രിക് ബൈക്കാണ്, അത്
                    അസാധാരണമായ സൗകര്യവും സൗകര്യവും കൊണ്ട് ശ്രദ്ധേയമായ
                    പ്രകടനമാണ്. ശക്തമായ മോട്ടോറും ദീർഘകാല ബാറ്ററിയും ഉപയോഗിച്ച്,
                    X2 സുഗമവും കാര്യക്ഷമവുമായ യാത്ര നൽകുന്നു, ഇത് ദൈനംദിന
                    യാത്രകൾക്കും വാരാന്ത്യ സാഹസിക യാത്രകൾക്കും
                    അനുയോജ്യമാക്കുന്നു. ബൈക്കിൻ്റെ ദൃഢമായ ഫ്രെയിമും ഉയർന്ന
                    നിലവാരമുള്ള ഘടകങ്ങളും ഈടുനിൽക്കുന്നതും വിശ്വാസ്യതയും
                    ഉറപ്പാക്കുന്നു, അതേസമയം അതിൻ്റെ എർഗണോമിക് ഡിസൈൻ ദീർഘദൂര
                    യാത്രകളിൽ പോലും സുഖപ്രദമായ റൈഡിംഗ് അനുഭവം നൽകുന്നു. X2 ൻ്റെ
                    ഒതുക്കമുള്ള വലിപ്പവും ഭാരം കുറഞ്ഞ നിർമ്മാണവും നഗര
                    പരിതസ്ഥിതികളിൽ കൈകാര്യം ചെയ്യുന്നത് എളുപ്പമാക്കുന്നു,
                    അതേസമയം മടക്കാവുന്ന രൂപകൽപ്പന സൗകര്യപ്രദമായ സംഭരണവും
                    ഗതാഗതവും അനുവദിക്കുന്നു. മൊത്തത്തിൽ, E MOTORAD X2 ഒരു മികച്ച
                    ഇലക്ട്രിക് ബൈക്കാണ്, അത് പണത്തിന് മികച്ച മൂല്യം വാഗ്ദാനം
                    ചെയ്യുന്നു, ഇത് വിശ്വസനീയവും ആസ്വാദ്യകരവുമായ ഗതാഗത മാർഗ്ഗം
                    തേടുന്ന ഏതൊരാൾക്കും മികച്ച തിരഞ്ഞെടുപ്പായി മാറുന്നു.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* review 4 modal */}
          <div
            class="modal fade"
            id="review4Modal"
            tabindex="-1"
            aria-labelledby="displayModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div
                class="modal-content text-center text-light"
                style={{ backgroundColor: "#424242" }}
              >
                <div class="modal-body p-3">
                  <div className="d-flex justify-content-end mb-3">
                    <Icon
                      icon="zondicons:minus-solid"
                      width="1.2em"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      height="1.2em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                  <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                    ഞാൻ അടുത്തിടെ ഒരു ഇ-സൈക്കിൾ വാങ്ങി, അത് എൻ്റെ യാത്രാനുഭവത്തെ
                    പൂർണ്ണമായും മാറ്റിമറിച്ചുവെന്ന് ഞാൻ പറയണം. ഈ ഇലക്ട്രിക്
                    ബൈക്ക് പരമ്പരാഗത സൈക്കിളിൻ്റെ സൗകര്യവും ഒരു ഇലക്ട്രിക്
                    മോട്ടോറിൻ്റെ കാര്യക്ഷമതയും സമന്വയിപ്പിക്കുന്നു, യഥാർത്ഥത്തിൽ
                    വൈവിധ്യമാർന്ന ഗതാഗത മാർഗ്ഗം വാഗ്ദാനം ചെയ്യുന്നു. ഒന്നാമതായി,
                    ഇ-സൈക്കിൾ അവിശ്വസനീയമാംവിധം പരിസ്ഥിതി സൗഹൃദമാണ്. ഒരു
                    പരമ്പരാഗത വാഹനത്തിന് പകരം ഒരു ഇലക്ട്രിക് ബൈക്ക്
                    തിരഞ്ഞെടുക്കുന്നതിലൂടെ, ഞാൻ എൻ്റെ കാർബൺ കാൽപ്പാടുകൾ ഗണ്യമായി
                    കുറയ്ക്കുകയും വൃത്തിയുള്ള അന്തരീക്ഷത്തിലേക്ക് സംഭാവന
                    നൽകുകയും ചെയ്യുന്നു. എൻ്റെ ദൈനംദിന യാത്ര ഇപ്പോൾ എമിഷൻ
                    രഹിതമാണെന്ന് അറിയാൻ ഇത് ശക്തമാക്കുന്നു. ഇ-സൈക്കിളിൻ്റെ
                    ഏറ്റവും മികച്ച സവിശേഷതകളിലൊന്ന് അതിൻ്റെ ഇലക്ട്രിക്
                    മോട്ടോറാണ്. ഒരു ബട്ടൺ അമർത്തിയാൽ, എനിക്ക് അനായാസമായി
                    കുന്നുകൾ കയറാനും വിയർക്കാതെ ദീർഘദൂരം സഞ്ചരിക്കാനും കഴിയും.
                    മോട്ടോർ സ്വാഗതാർഹമായ ഉത്തേജനം പ്രദാനം ചെയ്യുന്നു,
                    പ്രത്യേകിച്ചും ഞാൻ ഇതുവരെ ഉണർന്നിട്ടില്ലാത്ത പ്രഭാത
                    യാത്രകളിൽ. മാത്രമല്ല, ഇ-സൈക്കിൾ അതിശയകരമാംവിധം
                    വൈവിധ്യമാർന്നതാണ്. ഞാൻ തിരക്കേറിയ നഗര തെരുവുകളിൽ നാവിഗേറ്റ്
                    ചെയ്യുകയാണെങ്കിലും അല്ലെങ്കിൽ മനോഹരമായ ബൈക്ക് പാതകൾ
                    പര്യവേക്ഷണം ചെയ്യുകയാണെങ്കിലും, അത് എല്ലാ പരിതസ്ഥിതികളിലും
                    മനോഹരമായി കൈകാര്യം ചെയ്യുന്നു. ദൃഢമായ ഫ്രെയിമും
                    പ്രതികരിക്കുന്ന ബ്രേക്കുകളും ആത്മവിശ്വാസം പകരുന്നു, അതേസമയം
                    ക്രമീകരിക്കാവുന്ന ക്രമീകരണങ്ങൾ എൻ്റെ റൈഡിംഗ് അനുഭവം എൻ്റെ
                    മുൻഗണനകൾക്ക് അനുസൃതമായി ക്രമീകരിക്കാൻ എന്നെ അനുവദിക്കുന്നു.
                    സൗകര്യത്തിൻ്റെ കാര്യത്തിൽ, ഇ-സൈക്കിൾ ശരിക്കും തിളങ്ങുന്നു.
                    വീട്ടിലോ ഓഫീസിലോ എളുപ്പത്തിൽ ചാർജ് ചെയ്യാൻ കഴിയുന്ന ഒരു
                    നീക്കം ചെയ്യാവുന്ന ബാറ്ററിയാണ് ഇതിൽ സജ്ജീകരിച്ചിരിക്കുന്നത്,
                    എൻ്റെ യാത്രകൾക്ക് ആവശ്യമായ പവർ എപ്പോഴും ഉണ്ടെന്ന്
                    ഉറപ്പാക്കുന്നു. കൂടാതെ, മിനുസമാർന്ന രൂപകൽപ്പനയും ഒതുക്കമുള്ള
                    വലുപ്പവും ഇടുങ്ങിയ സ്ഥലങ്ങളിൽ സംഭരിക്കുന്നത്
                    എളുപ്പമാക്കുന്നു, അത് എൻ്റെ അപ്പാർട്ട്‌മെൻ്റിലോ തിരക്കേറിയ
                    ബൈക്ക് റാക്കിലോ ആകട്ടെ. മൊത്തത്തിൽ, എൻ്റെ ഇ-സൈക്കിൾ വാങ്ങലിൽ
                    എനിക്ക് സന്തോഷിക്കാൻ കഴിയില്ല. ഇത് എൻ്റെ ദൈനംദിന യാത്രയിൽ
                    വിപ്ലവം സൃഷ്ടിച്ചുവെന്ന് മാത്രമല്ല, കൂടുതൽ സുസ്ഥിരമായ ഒരു
                    ജീവിതശൈലി നയിക്കാൻ എന്നെ സഹായിക്കുകയും ചെയ്തു. കാര്യക്ഷമവും
                    പരിസ്ഥിതി സൗഹാർദ്ദപരവുമായ ഗതാഗത മാർഗ്ഗമാണ് നിങ്ങളുടേതെങ്കിൽ,
                    ഒരു ഇ-സൈക്കിൾ പരിഗണിക്കാൻ ഞാൻ വളരെ ശുപാർശ ചെയ്യുന്നു. ഇത്
                    ശരിക്കും ഒരു ഗെയിം ചേഞ്ചറാണ്.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormPopUpModalKerala
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default CycleVsEcycleKerala;
