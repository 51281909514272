import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import { contactUsCreate, partnerWithUsCreate } from "../Actions/formActions";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Components/Footer";
// import WhatsAppBot from "../Components/WhatsAppBot"
import "../CSS/contact.css";
import { useNavigate } from "react-router-dom";
import Meta from "../Components/Meta";
// import CallBtn from "../Components/CallBtn"
import { Icon } from "@iconify/react";

const ContactUsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [purpose, setPurpose] = useState("");
  const [message, setMessage] = useState("");

  const contactUs = useSelector((state) => state.contactUs);
  const { contact } = contactUs;
  const [partnerName, setPartnerName] = useState("");
  const [partnerEmail, setPartnerEmail] = useState("");
  const [partnerPhoneNumber, setPartnerPhoneNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [partnerMessage, setPartnerMessage] = useState("");

  const partnerWithUs = useSelector((state) => state.partnerWithUs);
  const { partnerStatus } = partnerWithUs;

  useEffect(() => {
    if (partnerStatus) {
      navigate("/submitted");
    }
  }, [partnerStatus]);

  const partnerSubmitForm = (e) => {
    e.preventDefault();
    dispatch(
      partnerWithUsCreate(
        partnerName,
        partnerEmail,
        partnerPhoneNumber,
        businessName,
        partnerMessage
      )
    );
  };
  const submitForm = (e) => {
    e.preventDefault();
    dispatch(contactUsCreate(name, email, phoneNumber, purpose, message));
  };

  useEffect(() => {
    if (contact) {
      navigate("/submitted");
    }
  }, [contact]);

  return (
    <>
      {" "}
      <Meta
        title={"Contact Us | EMotorad Customer Care Number"}
        description={
          "Have questions about EMotorad e-bikes? Our Contact Us page provides all the information you need to get in touch with our team."
        }
        cannonicalTag={"https://www.emotorad.com/contact-us"}
      />
      <Navbar />
      <div className="container-fluid">
        <div
          className="row"
          style={{
            background:
              'url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/contact/world-map-displays-continents-representing-major-landmasses-geographic-regions-across-globe-generative-ai+1.jpg")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="col-8 d-none d-lg-block p-4">
            <div className="mt-4">
              <h2 className="fw-bold" style={{ fontFamily: "Metropolis-bold" }}>
                CONTACT US
              </h2>
              <p className="text-em">
                Slide into our DMs because we’d love to hear from you. Be it
                dealership enquiries, bulk orders, or after-sales, we are all
                ears. We believe in business, but even more so in building
                meaningful relationships and making good choices for people,
                profit, and our planet.
              </p>
            </div>

            <div className="mt-5">
              <h3 className="fw-bold" style={{ fontFamily: "Metropolis-bold" }}>
                FIND OUR OFFICES HERE
              </h3>
              <p className="text-em">
                Where we craft your e-bike with love and passion
              </p>
              <div className="mt-3">
                <div className="row">
                  <div className="col-6" style={{ height: "100%" }}>
                    <div
                      className="p-4 h-100 bg-light rounded-3 "
                      style={{ boxShadow: "2px 2px 5px lightgrey" }}
                    >
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.411144196927!2d73.71301741538053!3d18.645537470205618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bb110ff854c5%3A0xf2d5e1b3254bb20a!2sEMotorad!5e0!3m2!1sen!2sin!4v1679755058688!5m2!1sen!2sin"
                        className="office-location"
                        allowFullScreen=""
                        loading="lazy"
                        title="office"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                      <p className="mt-2" style={{ fontSize: "0.85em" }}>
                        <b>Pune Office: </b>
                        <br />
                        EMotorad, At post Jambe, taluka Mulshi, 169/2 Sangawade,
                        Road, Pune, Maharashtra
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-6 " style={{ height: "100%" }}>
                    <div
                      className="p-4 bg-light rounded-3 "
                      style={{ boxShadow: "2px 2px 5px lightgrey" }}
                    >
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15555.333298677662!2d77.6709148!3d12.9184319!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1335e62f2321%3A0x3b2348d96c2886e8!2sSmartworks%20Vaishnavi%20Tech%20Park!5e0!3m2!1sen!2sin!4v1699421254076!5m2!1sen!2sin"
                        className="office-location"
                        allowFullScreen=""
                        loading="lazy"
                        title="office"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                      <p className="mt-2" style={{ fontSize: "0.85em" }}>
                        <b>Bengaluru Office: </b> <br />
                        EMotorad, Ground Floor, North Block, Smartworks,
                        Vaishnavi Tech Park, Sy. No. 16/1 and 17/2, Bellandur
                        Gate, Sarjapur Main Road, Ambalipura, Bengaluru,
                        Karnataka, 560102.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-12  p-4">
            <div className="mt-2 d-lg-none">
              <h2
                className="fw-bold text-center"
                style={{ fontFamily: "Metropolis-bold" }}
              >
                CONTACT US
              </h2>
              <p className="text-em text-center">
                Slide into our DMs because we’d love to hear from you. Be it
                dealership enquiries, bulk orders, or after-sales, we are all
                ears. We believe in business, but even more so in building
                meaningful relationships and making good choices for people,
                profit, and our planet.
              </p>
            </div>
            <div
              className="p-3 bg-light rounded-3"
              style={{ border: "1px solid #004dac" }}
            >
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/contact/contact+us+img+Desktop.jpg"
                alt=""
                className="img-fluid w-100"
              />
              <form className="mt-4 contact-us-form" onSubmit={submitForm}>
                <div class=" mb-2 ">
                  <input
                    required
                    value={name}
                    pattern="[A-Za-z\s]{2,}"
                    onChange={(e) => {
                      setName(e.target.value);
                      e.target.setCustomValidity("");
                    }}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        "Name should have at least 2 characters and contain only alphabets."
                      )
                    }
                    type="text"
                    style={{ padding: "0.7em" }}
                    className="form-control "
                    id="exampleFormControlInput1"
                    placeholder="Name"
                  />
                </div>

                <div class=" mb-2">
                  <input
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    style={{ padding: "0.7em" }}
                    id="exampleFormControlInput1"
                    placeholder="Email"
                  />
                </div>

                <div class="mb-2">
                  <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value.slice(0, 15));
                      e.target.setCustomValidity("");
                    }}
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Enter a valid phone number")
                    }
                    className="form-control w-100"
                    required
                    style={{ padding: "0.7em" }}
                    id="exampleFormControlInput1"
                    placeholder="Phone Number"
                  />
                </div>

                <div class="mb-2">
                  <select
                    onChange={(e) => {
                      setPurpose(e.target.value);
                    }}
                    className="form-select w-100"
                    required
                    style={{ padding: "0.7em" }}
                    id="exampleFormControlInput1"
                    placeholder="Select query type"
                  >
                    <option selected style={{ color: "grey" }}>
                      Select query type
                    </option>
                    <option value="Dealership in India">
                      Dealership in India
                    </option>
                    <option value="Sales Enquiry">Sales Enquiry</option>
                    <option value="Product Queries">Product Queries</option>
                    <option value="Bulk Order(B2B)">Bulk Order(B2B)</option>
                    <option value="After-Sales">After-Sales</option>
                    <option value="International Business Opportunities">
                      International Business Opportunities
                    </option>
                    <option value="Partnership/Collaboration with EMotorad">
                      Partnership/Collaboration with EMotorad
                    </option>
                    <option value="Others">Others</option>
                  </select>
                </div>

                <div class=" mb-4">
                  <textarea
                    value={message}
                    style={{ padding: "0.7em" }}
                    onChange={(e) => setMessage(e.target.value)}
                    class="form-control"
                    placeholder="Message"
                    id="floatingTextarea2"
                    required
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="background-em border-0 rounded-3 text-light p-2 w-100"
                >
                  Submit <Icon icon="ph:arrow-right-light" width={30} />
                </button>
              </form>
            </div>
          </div>

          <div className="col-12 d-none mb-4 d-lg-block">
            <div className="container-fluid mt-4 col-12 mt-lg-5  ">
              <h3
                className=" fw-bold mb-1"
                style={{ fontFamily: "Metropolis-bold" }}
              >
                CROSSING BORDERS
              </h3>
              <p className="text-em">
                Drop in to geek on e-cycles or a cup of chai
              </p>

              <div
                className="d-flex  justify-content-between p-lg-0 "
                style={{}}
              >
                <div className=" mt-2  p-2  ">
                  <div
                    className=" p-3 rounded-2 "
                    style={{ backgroundColor: "#E9E9E9" }}
                  >
                    <p className="mb-1">India</p>
                    <span style={{ fontSize: "0.8em" }}>
                      At post Jambe, taluka Mulshi, 169/2 Sangawade, Road, Pune,
                      Maharashtra 411033
                    </span>
                  </div>
                </div>

                <div className=" mt-2 p-2  ">
                  <div
                    className=" p-3 rounded-2 "
                    style={{ backgroundColor: "#E9E9E9" }}
                  >
                    <p className="mb-1">Spain</p>
                    <span style={{ fontSize: "0.8em" }}>
                      Lexidy Law Boutique, Avinguda Diagonal, 442, 3° 1a,
                      Barcelona, 08037
                    </span>
                  </div>
                </div>

                <div className=" mt-2   p-2  ">
                  <div
                    className=" p-3 rounded-2 "
                    style={{ backgroundColor: "#E9E9E9" }}
                  >
                    <p className="mb-1">Japan</p>
                    <span style={{ fontSize: "0.8em" }}>
                      Navi Shibuya V 3F, 5-5 Maruyama-cho, Shibuya-ku, Tokyo,
                      Japan
                    </span>
                  </div>
                </div>

                <div className=" mt-2 p-2  ">
                  <div
                    className=" p-3 rounded-2 "
                    style={{ backgroundColor: "#E9E9E9" }}
                  >
                    <p className="mb-1">UAE</p>
                    <span style={{ fontSize: "0.8em" }}>
                      Business Center, Al Shmookh Building, UAO Free Trade Zone,
                      UmmAI Quwain, UAE
                    </span>
                  </div>
                </div>

                <div className=" mt-2  p-2  ">
                  <div
                    className=" p-3 rounded-2 "
                    style={{ backgroundColor: "#E9E9E9" }}
                  >
                    <p className="mb-1">Australia</p>
                    <span style={{ fontSize: "0.8em" }}>
                      Ausstech Holdings Pty Ltd Suite 246/4,16-18
                      Beenleigh-Redland Bay Road, Loganholme QLD 4129, Australia
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mb-4 d-lg-none">
            <div className="container-fluid mt-4 col-12 mt-lg-5  ">
              <h3 className=" fw-bold mb-1">FIND OUR OFFICES HERE</h3>
              <p className="text-em">
                Where we craft your e-bike with love and passion
              </p>

              <div
                className="d-flex justify-content-between p-lg-0 "
                style={{
                  overflowX: "scroll",
                  gap: "1em",
                  alignItems: "stretch",
                }}
              >
                <div className="col-10 mb-4   " style={{}}>
                  <div
                    className="p-2  bg-light rounded-3 "
                    style={{
                      boxShadow: "2px 2px 5px lightgrey",
                      height: "100%",
                    }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.411144196927!2d73.71301741538053!3d18.645537470205618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bb110ff854c5%3A0xf2d5e1b3254bb20a!2sEMotorad!5e0!3m2!1sen!2sin!4v1679755058688!5m2!1sen!2sin"
                      className="office-location"
                      allowFullScreen=""
                      loading="lazy"
                      title="office"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <p className="mt-2" style={{ fontSize: "0.85em" }}>
                      <b>Pune Office: </b>
                      <br />
                      EMotorad, At post Jambe, taluka Mulshi, 169/2 Sangawade,
                      Road, Pune, Maharashtra
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-10 mb-4" style={{ height: "100%" }}>
                  <div
                    className="p-2 bg-light rounded-3 "
                    style={{ boxShadow: "2px 2px 5px lightgrey" }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15555.333298677662!2d77.6709148!3d12.9184319!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1335e62f2321%3A0x3b2348d96c2886e8!2sSmartworks%20Vaishnavi%20Tech%20Park!5e0!3m2!1sen!2sin!4v1699421254076!5m2!1sen!2sin"
                      className="office-location"
                      allowFullScreen=""
                      loading="lazy"
                      title="office"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <p className="mt-2" style={{ fontSize: "0.85em" }}>
                      <b>Bengaluru Office: </b> <br />
                      EMotorad, Ground Floor, North Block, Smartworks, Vaishnavi
                      Tech Park, Sy. No. 16/1 and 17/2, Bellandur Gate, Sarjapur
                      Main Road, Ambalipura, Bengaluru, Karnataka, 560102.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mb-4 d-lg-none">
            <div className="container-fluid mt-4 col-12 mt-lg-5  ">
              <h3
                className=" fw-bold mb-1"
                style={{ fontFamily: "Metropolis-bold" }}
              >
                CROSSING BORDERS
              </h3>
              <p className="text-em">
                Drop in to geek on e-cycles or a cup of chai
              </p>

              <div
                className="d-flex  justify-content-between p-lg-0 "
                style={{ overflowX: "scroll" }}
              >
                <div className=" mt-2  p-2 col-9  ">
                  <div
                    className=" p-3 rounded-2  "
                    style={{ backgroundColor: "#E9E9E9" }}
                  >
                    <p className="mb-1">India</p>
                    <span style={{ fontSize: "0.8em" }}>
                      At post Jambe, taluka Mulshi, 169/2 Sangawade, Road, Pune,
                      Maharashtra 411033
                    </span>
                  </div>
                </div>

                <div className=" mt-2 p-2 col-9   ">
                  <div
                    className=" p-3 rounded-2 "
                    style={{ backgroundColor: "#E9E9E9" }}
                  >
                    <p className="mb-1">Spain</p>
                    <span style={{ fontSize: "0.8em" }}>
                      Lexidy Law Boutique, Avinguda Diagonal, 442, 3° 1a,
                      Barcelona, 08037
                    </span>
                  </div>
                </div>

                <div className=" mt-2   p-2  col-9 ">
                  <div
                    className=" p-3 rounded-2  "
                    style={{ backgroundColor: "#E9E9E9" }}
                  >
                    <p className="mb-1">Japan</p>
                    <span style={{ fontSize: "0.8em" }}>
                      Navi Shibuya V 3F, 5-5 Maruyama-cho, Shibuya-ku, Tokyo,
                      Japan
                    </span>
                  </div>
                </div>

                <div className=" mt-2 p-2  col-9">
                  <div
                    className=" p-3 rounded-2  "
                    style={{ backgroundColor: "#E9E9E9" }}
                  >
                    <p className="mb-1">UAE</p>
                    <span style={{ fontSize: "0.8em" }}>
                      Business Center, Al Shmookh Building, UAO Free Trade Zone,
                      UmmAI Quwain, UAE
                    </span>
                  </div>
                </div>

                <div className=" mt-2  p-2 col-9  ">
                  <div
                    className=" p-3 rounded-2  "
                    style={{ backgroundColor: "#E9E9E9" }}
                  >
                    <p className="mb-1">Australia</p>
                    <span style={{ fontSize: "0.8em" }}>
                      Ausstech Holdings Pty Ltd Suite 246/4,16-18
                      Beenleigh-Redland Bay Road, Loganholme QLD 4129, Australia
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <div className="position-relative">
          <div
            className="position-absolute"
            style={{
              width: "100%",
              height: "100%",
              zIndex: "1",
              background: "rgba(0,0,0,0.5)",
            }}
          ></div>
          <picture>
            <source
              media="(max-width:650px)"
              srcSet={
                "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/contact/contact+us+breaker+Mobile.jpg"
              }
            />
            <source
              media="(min-width:651px)"
              srcSet={
                "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/contact/contact+us+breaker+desktop.jpg"
              }
            />
            <img
              src={
                "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/contact/contact+us+breaker+desktop.jpg"
              }
              alt="Banner1"
              className="img-fluid"
            />
          </picture>
          <div className="position-absolute partner-img-text">
            <h2 className="text-light fw-bold">LET'S JOIN FORCES</h2>
            <p className="text-light">
              We’re always looking for great partners
            </p>
          </div>
          <div
            className=" d-none d-lg-block p-3 bg-light rounded-3 partner-form-div"
            style={{ border: "1px solid #004dac" }}
          >
            <form className="" onSubmit={partnerSubmitForm}>
              <div class=" mb-2">
                <input
                  required
                  value={partnerName}
                  pattern="[A-Za-z\s]{2,}"
                  onChange={(e) => {
                    setPartnerName(e.target.value);
                    e.target.setCustomValidity("");
                  }}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Name should have at least 2 characters and contain only alphabets."
                    )
                  }
                  type="text"
                  className="form-control "
                  id="exampleFormControlInput1"
                  placeholder="Name"
                />
              </div>

              <div class="mb-2">
                <input
                  type="email"
                  required
                  value={partnerEmail}
                  onChange={(e) => setPartnerEmail(e.target.value)}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Email"
                />
              </div>

              <div class=" mb-2">
                <input
                  type="tel"
                  value={partnerPhoneNumber}
                  onChange={(e) => {
                    setPartnerPhoneNumber(e.target.value.slice(0, 11));
                    e.target.setCustomValidity("");
                  }}
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Enter a valid phone number")
                  }
                  className="form-control w-100"
                  required
                  id="exampleFormControlInput1"
                  placeholder="Phone Number"
                />
              </div>

              <div class=" mb-2">
                <input
                  required
                  value={businessName}
                  pattern="[A-Za-z\s]{2,}"
                  onChange={(e) => {
                    setBusinessName(e.target.value);
                    e.target.setCustomValidity("");
                  }}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Name should have at least 2 characters and contain only alphabets."
                    )
                  }
                  type="text"
                  className="form-control "
                  id="exampleFormControlInput1"
                  placeholder="Company Name"
                />
              </div>

              <div class=" mb-4">
                <textarea
                  value={partnerMessage}
                  onChange={(e) => setPartnerMessage(e.target.value)}
                  class="form-control"
                  placeholder="Message"
                  id="floatingTextarea2"
                  rows="6"
                ></textarea>
              </div>

              <button
                type="submit"
                className="background-em border-0 rounded-3 text-light p-2 w-100"
              >
                Send Message <Icon icon="ph:arrow-right-light" width={30} />
              </button>
            </form>
          </div>
        </div>
        <div className="p-4 row">
          <div className="col-lg-7 col-12">
            <h4 className="fw-bold">PARTNER WITH US!</h4>
            <p>
              If you haven’t guessed already, we love e-cycles. If you do too,
              then let’s chat. We aim to change the face of the e-cycle industry
              and can do it only with strong communities of people behind them.
              We believe in business, but even more so in building meaningful
              relationships, and making good choices for people, profit, and our
              planet.
            </p>
          </div>
        </div>
        <div className="p-4 pt-0 d-lg-none">
          <div
            className=" d-lg-none p-3 bg-light rounded-3 col-12"
            style={{ border: "1px solid #004dac" }}
          >
            <form className="" onSubmit={partnerSubmitForm}>
              <div class=" mb-2">
                <input
                  required
                  value={partnerName}
                  pattern="[A-Za-z\s]{2,}"
                  style={{ padding: "0.7em" }}
                  onChange={(e) => {
                    setPartnerName(e.target.value);
                    e.target.setCustomValidity("");
                  }}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Name should have at least 2 characters and contain only alphabets."
                    )
                  }
                  type="text"
                  className="form-control "
                  id="exampleFormControlInput1"
                  placeholder="Name"
                />
              </div>

              <div class="mb-2">
                <input
                  type="email"
                  required
                  value={partnerEmail}
                  style={{ padding: "0.7em" }}
                  onChange={(e) => setPartnerEmail(e.target.value)}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Email"
                />
              </div>

              <div class=" mb-2">
                <input
                  type="tel"
                  value={partnerPhoneNumber}
                  style={{ padding: "0.7em" }}
                  onChange={(e) => {
                    setPartnerPhoneNumber(e.target.value.slice(0, 11));
                    e.target.setCustomValidity("");
                  }}
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Enter a valid phone number")
                  }
                  className="form-control w-100"
                  required
                  id="exampleFormControlInput1"
                  placeholder="Phone Number"
                />
              </div>

              <div class=" mb-2">
                <input
                  required
                  value={businessName}
                  style={{ padding: "0.7em" }}
                  pattern="[A-Za-z\s]{2,}"
                  onChange={(e) => {
                    setBusinessName(e.target.value);
                    e.target.setCustomValidity("");
                  }}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Name should have at least 2 characters and contain only alphabets."
                    )
                  }
                  type="text"
                  className="form-control "
                  id="exampleFormControlInput1"
                  placeholder="Company Name"
                />
              </div>

              <div class=" mb-4">
                <textarea
                  value={partnerMessage}
                  style={{ padding: "0.7em" }}
                  onChange={(e) => setPartnerMessage(e.target.value)}
                  class="form-control"
                  placeholder="Message"
                  id="floatingTextarea2"
                  rows="4"
                ></textarea>
              </div>

              <button
                type="submit"
                className="background-em border-0 rounded-3 text-light p-2 w-100"
              >
                Send Message <Icon icon="ph:arrow-right-light" width={30} />
              </button>
            </form>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default ContactUsScreen;
