import {
  GET_STORES_FAIL,
  GET_STORES_REQUEST,
  GET_STORES_SUCCESS,
} from "../Constants/storeConstants.js";

export const getStoresReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STORES_REQUEST:
      return { loading: true };
    case GET_STORES_SUCCESS:
      return { loading: false, stores: action.payload };
    case GET_STORES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
