import React, { useState, useEffect } from "react";
import Navbar from "../Components/QuizNavbar";
import Questions from "../questions.json";
import Question from "../Components/Question";
import QuizResult from "../Components/QuizResult";

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function selectRandomObjects(arr) {
  const selectedIndices = new Set();
  while (selectedIndices.size < 2) {
    selectedIndices.add(getRandomInt(0, arr.length - 1));
  }
  return Array.from(selectedIndices).map((index) => arr[index]);
}

function QuizScreen() {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [won, setWon] = useState("");
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const start = () => {
    const firstTwo = selectRandomObjects(Questions.slice(0, 12));
    const secondTwo = selectRandomObjects(Questions.slice(12, 20));
    const lastTwo = selectRandomObjects(Questions.slice(20));
    const newQuestionList = [...firstTwo, ...secondTwo, ...lastTwo];
    setQuestionList(newQuestionList);
    setIsQuizStarted(true);
  };

  const retry = () => {
    setIsQuizStarted(false);
    setCurrentQuestionIndex(0);
    setMarkedAnswers([]);
    setName("");
    setPhoneNumber("");
    setEmail("");
    setWon("");
  };
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [markedAnswers, setMarkedAnswers] = useState([]);

  const isQuestionEnd = currentQuestionIndex === questionList.length;

  const calculateResult = () => {
    let correct = 0;
    questionList.forEach((question, index) => {
      if (parseInt(question.correctOptionIndex) == markedAnswers[index] + 1) {
        correct++;
      }
    });
    return {
      total: questionList.length,
      correct: correct,
      percentage: Math.trunc((correct / questionList.length) * 100),
    };
  };
  useEffect(() => {
    const fetchData = async () => {
      const isLastQuestion = currentQuestionIndex === questionList.length;
      if (isLastQuestion && isQuizStarted) {
        try {
          const res = await fetch(
            "https://sheet.best/api/sheets/a5ecff49-d53c-4a5f-a1d1-4becdd97fe52",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                Name: name,
                PhoneNumber: phoneNumber,
                Email: email,
                Won: won,
              }),
            }
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    if (won !== "") {
      fetchData();
    }
  }, [
    currentQuestionIndex,
    isQuizStarted,
    questionList.length,
    name,
    phoneNumber,
    email,
    won,
  ]);

  return (
    <>
      <Navbar />
      <div
        className="quiz-screen"
        style={{
          height: "100vh",
          background: "#f5f5f5",
        }}
      >
        <div className="quiz-container">
          {isQuizStarted ? (
            isQuestionEnd ? (
              <QuizResult
                result={calculateResult()}
                retry={retry}
                setWin={(res) => {
                  setWon(res);
                }}
                name
                phoneNumber
                email
              />
            ) : (
              <Question
                question={questionList[currentQuestionIndex]}
                totalQuestions={questionList.length}
                currentQuestion={currentQuestionIndex + 1}
                setAnswer={(index) => {
                  if (!markedAnswers[currentQuestionIndex]) {
                    setMarkedAnswers([...markedAnswers, index]);
                    setCurrentQuestionIndex(currentQuestionIndex + 1);
                  }
                }}
              />
            )
          ) : (
            <div className="join-screen">
              <h2>
                <b>Join Quiz</b>
              </h2>
              <ul>
                <li>Hey there, ready to ride your way? 🚀</li>
                <li>
                  Answer 4 out of 6 questions and win EMotorad vouchers! 🏆
                </li>
                <li>We've got Sports, Business, and EMotorad in the mix! 📚</li>
                <li>Just 10 seconds per question, so keep on your toes! ⏱️</li>
                <li>No time for snoozing, hit START and roll right in! 🎉</li>
                <li>Test your skills with some MCQ madness! 🤓</li>
                <li>Just share some basic info and get started! 📝</li>
              </ul>
              <form
                // method="POST"
                // action="https://script.google.com/a/macros/emotorad.com/s/AKfycbycvGg01XKpednhuD9ObXedCahqjazTUY3GCnwCBWT3wL889XWfNH_QIsw0oN5YGc7h/exec"
                onSubmit={start}
              >
                <input
                  type="text"
                  name="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value.trimStart())}
                  placeholder="Name"
                  required
                  className="quiz-form-input"
                />{" "}
                <input
                  type="tel"
                  name="Phone Number"
                  placeholder="Phone Number"
                  required
                  className="quiz-form-input"
                  value={phoneNumber}
                  onChange={(e) => {
                    const phoneNumber = e.target.value.slice(0, 12);
                    setPhoneNumber(phoneNumber);

                    if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                      e.target.setCustomValidity("");
                    } else {
                      e.target.setCustomValidity(
                        "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                      );
                    }
                  }}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                    )
                  }
                />
                <input
                  type="email"
                  name="Email"
                  placeholder="Email ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.trimStart())}
                  className="quiz-form-input"
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button type="submit" className="quiz-button">
                    START
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default QuizScreen;
