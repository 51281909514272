import React, { useEffect, useRef } from "react";

const VideoCard = ({
  videoSrcMobile,
  videoSrcDesktop,
  heading,
  description,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null, // relative to the viewport
      threshold: [0.5, 1.0], // Track when the video is 50% and 100% visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const video = entry.target;

        // Play video only if it's 100% visible, pause otherwise
        if (entry.intersectionRatio === 1) {
          if (video.paused) {
            video.play().catch((error) => {
              console.error("Video playback failed:", error);
            });
          }
        } else {
          if (!video.paused) {
            video.pause();
          }
        }
      });
    }, observerOptions);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div className="video-card-body position-relative">
      <video
        ref={videoRef}
        className="main-banner-video w-100 rounded-4"
        autoPlay
        loop
        muted
        playsInline
      >
        <source
          media="(max-width:650px)"
          src={videoSrcMobile}
          type="video/mp4"
        />
        <source
          media="(min-width:651px)"
          src={videoSrcDesktop}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      {/* Conditional gradient and text overlay */}
      {heading && description && (
        <div className="overlay position-absolute top-0 start-0 w-100 h-100 d-flex align-items-end rounded-4 px-lg-4">
          <div className="text-overlay text-white px-3 py-2 py-lg-3 w-100">
            <h3 style={{ fontFamily: "Metropolis-bold" }}>{heading}</h3>
            <p style={{ fontSize: "0.9em" }}>{description}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoCard;
