import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../CSS/papScreen.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Icon } from "@iconify/react";
import ReactPlayer from "react-player";
import Modal from "react-modal";
import Meta from "../Components/Meta";

export default function PapScreen() {
  const [activeItem, setActiveItem] = useState(1);
  const [activeSwiper, setActiveSwiper] = useState(4);
  const rangeData = [
    {
      id: 1,
      url1: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/papDhoni/01.jpg",
    },
    {
      id: 2,
      url1: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/papDhoni/02.jpg",
    },
    {
      id: 3,
      url1: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/papDhoni/03.jpg",
    },
  ];
  const handleHover = (id) => {
    if (activeItem === id) {
      return;
    }
    setActiveItem(id);
  };
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <Navbar />
      <Meta
        title={
          "Mahendra Singh Dhoni special connection with Electric Cycles - MSD seen riding EMotorad Ecycle"
        }
        description={
          "Mahendra Singh Dhoni rides Doodle V3 an electric cycle by Emotorad electric cycles. Explore a diverse range of premium electric bicycles and bikes. Shop MSD’s E-Cycle picks now!"
        }
        cannonicalTag={
          "https://www.emotorad.com/mahendra-singh-dhoni-electric-cycles"
        }
      />
      <div className="position-relative w-100" style={{ margin: "auto" }}>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modal-video-mahi"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              display: "flex",
              zIndex: "100",
              alignItems: "center",
              justifyContent: "center",
            },
            content: {
              position: "static",
              margin: "auto",
              border: "none",
              padding: "0px",
              outline: "none",
              overflow: "hidden",
            },
          }}
          contentLabel="Video Modal"
        >
          <ReactPlayer
            url="https://youtube.com/shorts/P0Fq763ShSY?feature=shared"
            controls={true}
            playing={true}
            width="100%"
            height="100%"
          />
        </Modal>
      </div>
      {/* hero banner */}
      <div
        className="position-relative p-3 p-lg-5"
        style={{ backgroundColor: "#004dac" }}
      >
        <div className="row">
          <div className="col-12 col-lg-8">
            <h1
              className="text-light text-uppercase"
              style={{ fontFamily: "Metropolis-bolder", fontSize: "" }}
            >
              League of Legends: <br className="d-lg-none"></br>
              <span className="text-light" style={{ fontFamily: "Metropolis" }}>
                MS Dhoni chooses our e-cycle Doodle
              </span>
            </h1>
            <p className="text-light">
              Mahi is having a blast riding our favourite foldable e-cycle,
              Doodle
            </p>
          </div>
          <div className="position-relative col-12 col-lg-4 ps-5 pe-5 pb-5 dhoni-banner pb-lg-0">
            <picture className="main-banner-img">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/papDhoni/07.jpg"
                alt="Banner1"
                className="d-block img-fluid"
                style={{ borderRadius: "10px" }}
              />
            </picture>

            <div className="play-icon-dhoni" onClick={openModal}>
              {window.innerWidth > 700 ? (
                <Icon icon="logos:youtube-icon" width={80} />
              ) : (
                <Icon icon="logos:youtube-icon" width={60} />
              )}
            </div>
          </div>
          <div className="p-lg-4 p-3 position-absolute ms-text d-lg-block d-none">
            <p>
              BEHOLD! We cannot keep calm because the legendary G.O.A.T Mahendra
              Singh Dhoni was papped with our beloved Doodle V3 electric cycle!
              Amidst his love for bikes and supercars, his nod to our creation
              means the world to us. Watching him cruise through urban streets
              with his trademark poise has our hearts swelling with pride.
              Inspiring millions of fans, our Captain has put Cool into mobility
              and we couldn’t be happier!
            </p>
          </div>
        </div>
        <div className="p-lg-4 p-3 position-relative ms-text-mobile d-lg-none">
          <p>
            BEHOLD! We cannot keep calm because the legendary G.O.A.T Mahendra
            Singh Dhoni was papped with our beloved Doodle V3 electric cycle!
            Amidst his love for bikes and supercars, his nod to our creation
            means the world to us. Watching him cruise through urban streets
            with his trademark poise has our hearts swelling with pride.
            Inspiring millions of fans, our Captain has put Cool into mobility
            and we couldn’t be happier!
          </p>
        </div>
      </div>
      {/* doodle breaker */}
      <div className="position-relative mt-3 mb-3 mb-lg-0">
        {window.innerWidth <= 650 ? (
          <video
            autoPlay
            muted
            loop
            disableRemotePlayback
            playsInline
            className="w-100"
          >
            <source
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/papDhoni/Comp+2.webm"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        ) : (
          <video
            autoPlay
            muted
            loop
            disableRemotePlayback
            playsInline
            className="w-100"
          >
            <source
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/papDhoni/Comp+1.webm"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
        <div className="doodle-banner-btn mt-4 mt-lg-0">
          <Link
            to={"/bikes/doodle-v3"}
            className="em-btn-1 text-decoration-none "
          >
            Buy Now
          </Link>
        </div>
      </div>
      {/* doodle slider */}
      <div className="range-section p-3 p-lg-5">
        <div className="">
          <div className="range-card-sub-div row pb-4">
            <div
              className=" range-card-sub-div-content col-12 col-lg-5"
              style={{
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div>
                <h1
                  className="text-uppercase text-em mb-3"
                  style={{
                    fontFamily: "Metropolis-bold",
                  }}
                >
                  Know more about Dhoni’s e-cycle!{" "}
                </h1>
                <p>
                  Mahendra Singh Dhoni chose our Doodle V3 to ride across city
                  streets. Doodle V3 is a unique foldable e-cycle that’s your
                  perfect companion for city rides and mountain biking. Its
                  powerful removable 12.75ah battery can power over 60 km on a
                  single charge, speeding up to 25 kmph. With lifetime warranty,
                  zip across any terrain blissfully—we have your back! Priced at
                  INR 52,999, this e-cycle is a beast on two wheels.
                </p>
              </div>

              <div className="d-none d-lg-block">
                <Link
                  to={"/bikes"}
                  className="em-btn-1 text-uppercase text-decoration-none"
                >
                  Know More
                </Link>
              </div>
            </div>
            <div className=" col-12 col-lg-7">
              <div className="d-flex" style={{ gap: "1em" }}>
                {rangeData.map((item) => (
                  <div
                    className=" card-doodle"
                    onMouseOver={() => handleHover(item.id)}
                    key={item.id}
                    style={{
                      maxWidth: activeItem === item.id ? "70%" : "15%",
                      flexGrow: activeItem === item.id ? 4 : 1,
                      backgroundImage: `url(${item.url1})`,
                      boxShadow:
                        activeItem === item.id
                          ? "0px 0px 10px rgba(0, 77, 172)"
                          : "0px 0px 20px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    {activeItem !== item.id && (
                      <div
                        style={{
                          position: "absolute",
                          top: "0%",
                          left: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          backgroundColor: "black",
                          background:
                            "linear-gradient(to bottom, rgba(255,255,255,0), black)",
                        }}
                      />
                    )}
                    {activeItem !== item.id && (
                      <div className="w-100">
                        <p
                          className="position-absolute text-uppercase text-light d-none d-lg-block"
                          style={{
                            bottom: "10%",
                            right: "5%",
                            transform: "rotate(270deg)",
                          }}
                        >
                          Drag here
                        </p>
                        <p
                          className="position-absolute text-uppercase text-light d-lg-none"
                          style={{
                            bottom: "10%",
                            right: "5%",
                            transform: "rotate(270deg)",
                          }}
                        >
                          Click here
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* {rangeData.map((item) => (
            <div
              className="ps-4 "
              onMouseOver={() => handleHover(item.id)}
              // onMouseLeave={() => handleHoverLeave()}
              key={item.id}
              style={{
                position: "relative",
                minWidth: "10%",
                cursor: "pointer",
                borderRadius: "20px",
                flexGrow: activeItem === item.id ? 4 : 1,
                transition: "all 0.6s ease",
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${item.url1})`,
                  boxShadow:
                    activeItem === item.id
                      ? "0px 0px 10px rgba(0, 77, 172)"
                      : "0px 0px 20px rgba(0, 0, 0, 0.2)",
                  borderRadius: "20px",
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  transition: "all 0.6s linear",
                }}
                isActive={item.id === activeItem}
              ></div>
            </div>
          ))} */}
        </div>
      </div>
      {/* BUY NOW FIX */}
      <div
        className="background-em d-flex d-lg-none position-sticky align-items-center justify-content-center p-2"
        style={{
          gap: "",
          bottom: "0%",
          border: "0px",
          width: "100%",
          zIndex: "2",
        }}
      >
        <div className="col-12">
          <Link
            to={"/bikes/doodle-v3"}
            className="btn p-2 fw-bold w-100 btn-light d-block ms-auto text-em border-0"
          >
            Buy Now
          </Link>
        </div>
      </div>
      {/* news breaker */}
      {/* <div className="news-carousel p-3 p-lg-5">
        <Splide
          className="p-1 pb-3 pt-4 flex align-items-center"
          options={{
            type: "loop",
            drag: false,
            arrows: false,
            rewind: "false",
            pagination: false,
            autoplay: false,
            perMove: 1,
            gap: "2em",
            padding: "0em",
            pauseOnHover: false,
            resetProgress: false,
            interval: 4000,
            perPage: 4,
            speed: 1000,
            breakpoints: {
              640: {
                type: "loop",
                drag: true,
                perPage: 1,
                arrows: false,
                pagination: true,
                autoplay: true,
                perMove: 1,
                gap: "1em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                interval: 3000,
                speed: 1000,
              },
            },
          }}
        >
          <SplideSlide key="slide1" className="mb-2">
            <div className="">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/papDhoni/times_of_india.jpg"
                alt=""
                className="img-fluid d-block mx-auto"
              />
              <div className="news-desc">
                <p className="mt-4 mb-4 text-light">
                  Mahendra Singh Dhoni chose our Doodle V3 to ride across city
                  streets. Doodle V3 is a unique foldable e-cycle that’s your
                  perfect companion for city rides and mountain biking. Its
                  powerful removable 12.75ah battery can power over 60.
                </p>
                <a
                  target="_blank"
                  href="https://timesofindia.indiatimes.com/blogs/voices/how-augmenting-ev-adoption-plugs-in-the-gap-of-existing-mobility/"
                  className="mx-auto em-btn-2 text-decoration-none "
                >
                  READ MORE
                </a>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide key="slide2" className="mb-2">
            <div>
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/papDhoni/times_of_india.jpg"
                alt=""
                className="img-fluid d-block mx-auto"
              />

              <div className="news-desc">
                <p className="mt-4 mb-4 text-light">
                  Mahendra Singh Dhoni chose our Doodle V3 to ride across city
                  streets. Doodle V3 is a unique foldable e-cycle that’s your
                  perfect companion for city rides and mountain biking. Its
                  powerful removable 12.75ah battery can power over 60.
                </p>
                <a
                  target="_blank"
                  href="https://timesofindia.indiatimes.com/blogs/voices/how-augmenting-ev-adoption-plugs-in-the-gap-of-existing-mobility/"
                  className="mx-auto em-btn-2 text-decoration-none "
                >
                  READ MORE
                </a>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide key="slide3" className="mb-2">
            <div>
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/papDhoni/times_of_india.jpg"
                alt=""
                className="img-fluid d-block mx-auto"
              />

              <div className="news-desc">
                <p className="mt-4 mb-4 text-light">
                  Mahendra Singh Dhoni chose our Doodle V3 to ride across city
                  streets. Doodle V3 is a unique foldable e-cycle that’s your
                  perfect companion for city rides and mountain biking. Its
                  powerful removable 12.75ah battery can power over 60.
                </p>
                <a
                  target="_blank"
                  href="https://timesofindia.indiatimes.com/blogs/voices/how-augmenting-ev-adoption-plugs-in-the-gap-of-existing-mobility/"
                  className="mx-auto em-btn-2 text-decoration-none "
                >
                  READ MORE
                </a>
              </div>
            </div>
          </SplideSlide>{" "}
          <SplideSlide key="slide4" className="mb-2">
            <div>
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/papDhoni/times_of_india.jpg"
                className="img-fluid d-block mx-auto"
                alt=""
              />

              <div className="news-desc">
                <p className="mt-4 mb-4 text-light">
                  Mahendra Singh Dhoni chose our Doodle V3 to ride across city
                  streets. Doodle V3 is a unique foldable e-cycle that’s your
                  perfect companion for city rides and mountain biking. Its
                  powerful removable 12.75ah battery can power over 60.
                </p>
                <a
                  target="_blank"
                  href="https://timesofindia.indiatimes.com/blogs/voices/how-augmenting-ev-adoption-plugs-in-the-gap-of-existing-mobility/"
                  className="mx-auto em-btn-2 text-decoration-none "
                >
                  READ MORE
                </a>
              </div>
            </div>
          </SplideSlide>
        </Splide>
      </div> */}
      <Footer />
    </>
  );
}
