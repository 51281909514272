import React, { useEffect } from "react"

const WhatsAppBot = ({ bottom }) => {
	return (
		<>
			<a
				href='https://api.whatsapp.com/send/?phone=918686050590&text=hi&app_absent=0'
				className='wa-chat-btn-fixed-wrap d-none d-lg-block'
				target='_blank'>
				<img
					className='wa-chat-btn-icon-image '
					alt='Whatsapp Chat Button'
					src='https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/test/whatsapp+logo.png'
				/>{" "}
			</a>
			<a
				href='https://api.whatsapp.com/send/?phone=918686050590&text=hi&app_absent=0'
				style={{ bottom }}
				className='wa-chat-btn-fixed-wrap-mb  d-lg-none'
				target='_blank'>
				<img
					className='wa-chat-btn-icon-image '
					alt='Whatsapp Chat Button'
					src='https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/test/whatsapp+logo.png'
				/>{" "}
			</a>
		</>
	)
}

WhatsAppBot.defaultProps = {
	bottom: "5%",
}

export default WhatsAppBot
