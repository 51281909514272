import React, { useState, useEffect } from "react";
import "../CSS/landing-page3.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { getAllBikes } from "../Actions/bikeActions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const LandingPage3Screen = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bike, setBike] = useState("");

  const [city, setCity] = useState("");

  useEffect(() => {
    dispatch(getAllBikes());
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/form/landing",
      { name, email, phoneNumber, bike, city },
      config
    );
    if (data) {
      navigate("/book-submitted");
    }
  };
  const dispatch = useDispatch();

  const currencyFormat = (num) => {
    return "₹" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const allAccessories = useSelector((state) => state.allAccessories);
  const { accessories } = allAccessories;

  useEffect(() => {
    dispatch(getAllBikes());
  }, []);

  return (
    <div className="container-fluid p-0">
      <div className="landingpage-main-div bg-dark position-relative">
        <div
          className="starting-at  position-absolute pt-2 ps-3 pe-5"
          style={{}}
        >
          <p className="mb-0">Starting from</p>
          <h3 className="fw-bold">₹24,999/-</h3>
        </div>
        <img
          src="https://ar-euro.s3.ap-south-1.amazonaws.com/logo/black+1.png"
          className="img-fluid landingpage-main-logo-sm d-block d-lg-none mx-auto"
          alt=""
        />
        <div className="landingpage-form-div d-none d-lg-block">
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/logo/black+1.png"
            className="img-fluid landingpage-main-logo d-block mx-auto"
            alt=""
          />

          <form
            onSubmit={submit}
            className="p-lg-4  landingpage-form-div-main mt-lg-4 "
          >
            <h6
              className=""
              style={{
                fontWeight: "600",
                fontSize: "1.1em",
              }}
            >
              Get upto 20% off on EMotorad electric cycles*
            </h6>
            <div className="mt-lg-4" id="landingform">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value.trimStart())}
                placeholder="Name"
                className="mb-3 form-input"
              />{" "}
              <input
                type="number"
                placeholder="Phone Number"
                className=" mb-3 form-input"
                value={phoneNumber}
                onChange={(e) =>
                  setPhoneNumber(e.target.value.trimStart().slice(0, 10))
                }
              />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value.trimStart())}
                className="mb-3 form-input"
              />{" "}
            </div>
            <input
              type="text"
              placeholder="City"
              className=" mb-3 form-input"
            />

            <select
              name=""
              required
              className="w-100"
              onChange={(e) => setBike(e.target.value)}
              id=""
            >
              <option selected disabled>
                Select Bike
              </option>
              <option value="X1">X1</option>
              <option value="X2">X2</option>
              <option value="X3">X3</option>
              <option value="Doodle">Doodle</option>
              <option value="EMX">EMX</option>
              <option value="T-Rex+">T-Rex+</option>
              {/* <option value='Desert Eagle'>
								Desert Eagle
							</option>
							<option value='Night Hawk'>
								Night Hawk
							</option> */}
            </select>
            <button
              type="submit"
              className="btn submit-btn-lg mt-lg-4 mb-lg-3 btn-block w-100 "
            >
              Book Your Test Ride Now
            </button>
            <small className="text-center">
              By choosing to confirm, you consent to share your personal
              information with EMotorad. Terms and Conditions applied.
            </small>
          </form>
        </div>
      </div>
      {/* mobile form */}
      <div className=" p-3 d-md-none">
        <form
          onSubmit={submit}
          className="p-3  landingpage-form-div-main mt-4 "
        >
          <h6
            className=""
            style={{
              fontWeight: "600",
              fontSize: "1.1em",
            }}
          >
            Get upto 20% off on EMotorad electric cycles*
          </h6>
          <div className="mt-4" id="landingform">
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value.trimStart())}
              className="mb-3 form-input"
            />{" "}
            <input
              type="number"
              placeholder="Phone Number"
              className=" mb-3 form-input"
              value={phoneNumber}
              onChange={(e) =>
                setPhoneNumber(e.target.value.trimStart().slice(0, 10))
              }
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value.trimStart())}
              className="mb-3 form-input"
            />{" "}
          </div>
          <input
            type="text"
            placeholder="City"
            value={city}
            onChange={(e) => setCity(e.target.value.trimStart())}
            className=" mb-3 form-input"
          />
          <select
            name=""
            required
            className="w-100"
            onChange={(e) => setBike(e.target.value)}
            id=""
          >
            <option selected disabled>
              Select Bike
            </option>
            <option value="X1">X1</option>
            <option value="X2">X2</option>
            <option value="X3">X3</option>
            <option value="Doodle">Doodle</option>
            <option value="EMX">EMX</option>
            <option value="T-Rex+">T-Rex+</option>
            {/* <option value='Desert Eagle'>
							Desert Eagle
						</option>
						<option value='Night Hawk'>
							Night Hawk
						</option> */}
          </select>
          <button
            type="submit"
            className="btn submit-btn-lg mt-lg-4 mt-3 mb-lg-3 btn-block w-100 "
          >
            Book Your Test Ride Now
          </button>
          <small className="text-center mt-4">
            By choosing to confirm, you consent to share your personal
            information with EMotorad. Terms and Conditions applied.
          </small>
        </form>
      </div>
      <div className="p-4 landing-page-marketplaces-main">
        <h5 className="text-center mt-lg-4 ">
          Buy Best-selling electric cycles now at your favorite platforms
        </h5>
        <div className="row landing-page-marketplaces mt-lg-4 pt-lg-4 mb-lg-5">
          <div className="col-3 ">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/test/Flipkart.png"
              className="img-fluid d-block mx-auto "
              alt=""
            />
            <p className="text-center mt-lg-3 mt-2">Flipkart</p>
          </div>
          <div className="col-3 ">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/test/Amazon.png"
              className="img-fluid d-block mx-auto "
              alt=""
            />
            <p className="text-center mt-lg-3 mt-2">Amazon</p>
          </div>
          <div className="col-3 ">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/test/Croma.png"
              className="img-fluid d-block mx-auto "
              alt=""
            />
            <p className="text-center mt-lg-3 mt-2">Croma</p>
          </div>
          <div className="col-3">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/test/Hamleys.png"
              className="img-fluid d-block mx-auto "
              alt=""
            />
            <p className="text-center mt-lg-3 mt-2">Hamleys</p>
          </div>
        </div>
      </div>
      <div className="p-4  " style={{ background: "#000" }}>
        <div className="container">
          <div className="conatiner-fluid mt-4 mb-4">
            <div className="row riding-cost align-items-center">
              <div className="col-lg-6 ps-lg-5 text-light">
                <h2>
                  Rising costs? <br /> No sweat
                </h2>
                <h4 className="mt-lg-5 mt-4">Save ₹3,710/mo on fuel cost</h4>
                <p>Spend only 7 paise per/km with EMotorad electric cycles. </p>
                <h4 className="mt-lg-5 mt-4">No cost EMI</h4>
                <p>Pay just Rs.1200 per month on EMotorad electric cycles</p>
                <h4 className="mt-lg-5 mt-4">0 maintenance cost</h4>
                <p>Zero cost on repairs, replacements, or upkeep.</p>
              </div>
              <div className="col-lg-6 mt-lg-0 mt-3">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/landingpage-3/587x604.jpg"
                  alt=""
                  style={{ borderRadius: "5px" }}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 landing-service">
        <h5 className="text-center   mt-lg-4 ">
          100% Customer satisfaction guaranteed
        </h5>
        <div className="row  mt-4 pt-2 mt-lg-5 pt-lg-4 mb-lg-5">
          <div className="col-3 ">
            <img
              src="https://cdn-icons-png.flaticon.com/128/950/950299.png"
              className="img-fluid d-block mx-auto "
              alt=""
            />
            <p className="text-center mt-lg-3 mt-2">24x7 After-sales Support</p>
          </div>
          <div className="col-3 ">
            <img
              src="https://cdn-icons-png.flaticon.com/128/1239/1239608.png"
              className="img-fluid d-block mx-auto "
              alt=""
            />
            <p className="text-center mt-lg-3 mt-2">170+ Dealer Network</p>
          </div>
          <div className="col-3 ">
            <img
              src="https://cdn-icons-png.flaticon.com/128/4553/4553809.png"
              className="img-fluid d-block mx-auto "
              alt=""
            />
            <p className="text-center mt-lg-3 mt-2">At Home Assembly</p>
          </div>
          <div className="col-3">
            <img
              src="https://cdn-icons-png.flaticon.com/128/1067/1067566.png"
              className="img-fluid d-block mx-auto "
              alt=""
            />
            <p className="text-center mt-lg-3 mt-2">
              72hrs Guaranteed Resolution
            </p>
          </div>
        </div>
      </div>
      <div className="x-factor-landind-section p-lg-5 p-2">
        <h1 className="mt-4 mb-2">X-Factor Range</h1>
        <p className="text-light d-none d-lg-block text-center">
          Premium Economical ecycles starting at ₹24,999
        </p>
        <div className=" w-100">
          <Splide
            className="ps-0 pe-0 pt-0 mb-0 w-100"
            options={{
              arrows: true,
              drag: false,
              type: "slide",
              pagination: true,
              autoplay: false,
              perMove: 1,
              gap: "1em",
              pauseOnHover: false,
              resetProgress: false,
              interval: 5000,
              perPage: 3,
              speed: 1000,
              breakpoints: {
                640: {
                  perPage: 1,
                  arrows: true,
                  drag: true,
                },
              },
            }}
          >
            {accessories ? (
              accessories.map(
                (product, i) =>
                  product.subCategory === "x-factor" && (
                    <SplideSlide className="p-1">
                      <div
                        className="bg-light mb-3 landing-bike-card mt-3"
                        style={{
                          borderRadius: "14px",
                        }}
                      >
                        <div className="">
                          <img
                            src={product.cartImages[0]}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="p-3 mt-1 d-flex   justify-content-between">
                          <div>
                            <h6 className="mb-0 fw-bold ">{product.name}</h6>
                            <p
                              style={{
                                fontSize: "1em",
                              }}
                              className="card-price text-dark mb-0 d-flex"
                            >
                              {currencyFormat(product.price)}
                              {product.slashPrice > 0 && (
                                <span className="">
                                  {" "}
                                  {currencyFormat(product.slashPrice)}
                                </span>
                              )}
                            </p>
                          </div>

                          <a
                            href="#landingform"
                            style={{
                              background: "#10B068",
                            }}
                            className="btn text-light"
                          >
                            Book Now
                          </a>
                        </div>
                      </div>{" "}
                    </SplideSlide>
                  )
              )
            ) : (
              <div
                className="spinner-border mx-auto mt-5 mb-5 text-light"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </Splide>
        </div>
        <div className="mt-lg-5 mt-3 container pt-lg-4">
          <div className="row ">
            <div className="col-lg-5 d-none d-lg-block">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/landingpage-3/304x406b.jpg"
                alt=""
                className="img-fluid w-75"
              />
            </div>
            <div className="col-lg-7 Specifications">
              <h2>Best-in-class Specifications</h2>
              <div className="row mt-lg-5 mt-5  ">
                <div className="col-lg-6   p-lg-3 col-6">
                  <div className="row  align-items-center">
                    <div className="col-12">
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/4435/4435880.png"
                        className="img-fluid "
                        alt=""
                      />
                    </div>
                    <div className="ps-3 col-12 mt-3">
                      <h6 className="fw-bold">Front fork Suspension</h6>
                      <p className="mb-0  ">
                        A ride that's well damped no matter where you're camped
                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-6 col-6">
                  <div className="row  align-items-center">
                    <div className="col-12">
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/3642/3642592.png"
                        className="img-fluid "
                        alt=""
                      />
                    </div>
                    <div className="ps-3 col-12 mt-3">
                      <h6 className="fw-bold">Brilliant LCD Display</h6>
                      <p className="mb-0  ">
                        Easy to read and bright even under direct sunlight
                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-6  mt-5 col-6">
                  <div className="row  align-items-center">
                    <div className="col-12">
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/2479/2479510.png"
                        className="img-fluid "
                        alt=""
                      />
                    </div>
                    <div className="ps-3 col-12 mt-3">
                      <h6 className="fw-bold">250W Rear Hub Motor</h6>
                      <p className="mb-0 ">
                        Effort that's gentle permormance that's mental
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6  mt-5 col-6">
                  <div className="row  align-items-center">
                    <div className="col-12">
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/3103/3103277.png"
                        className="img-fluid "
                        alt=""
                      />
                    </div>
                    <div className="ps-3 col-12 mt-3">
                      <h6 className="fw-bold">Long lasting Battery</h6>
                      <p className="mb-0 ">
                        Batteries that detach built for versatility, no catch
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a
            href="#landingform"
            className="btn submit-btn-lg  mb-lg-3 btn-block  mx-auto d-block mt-5 "
          >
            Book Your Test Ride Now
          </a>
        </div>
      </div>{" "}
      <div className="x-factor-landind-section p-lg-5 mt-5 p-2">
        <h1 className="mt-4 mb-2">Desire Range</h1>
        <p className="text-light d-none d-lg-block text-center">
          India’s best Premium Electric cycles starting at ₹39,999
        </p>
        <div className=" w-100">
          <Splide
            className="ps-0 pe-0 pt-0 mb-0 w-100"
            options={{
              arrows: true,
              drag: false,
              type: "slide",
              pagination: true,
              autoplay: false,
              perMove: 1,
              gap: "1em",
              pauseOnHover: false,
              resetProgress: false,
              interval: 5000,
              perPage: 3,
              speed: 1000,
              breakpoints: {
                640: {
                  perPage: 1,
                  arrows: true,
                  drag: true,
                },
              },
            }}
          >
            {accessories ? (
              accessories.map(
                (product, i) =>
                  product.subCategory === "desire" &&
                  product.name != "Lil E" &&
                  product.name != "EMX Aqua" && (
                    <SplideSlide className="p-1">
                      <div
                        className="bg-light mb-3 landing-bike-card mt-3"
                        style={{
                          borderRadius: "14px",
                        }}
                      >
                        <div className="">
                          <img
                            src={product.cartImages[0]}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="p-3 mt-1 d-flex   justify-content-between">
                          <div>
                            <h6 className="mb-0 fw-bold ">{product.name}</h6>
                            <p
                              style={{
                                fontSize: "1em",
                              }}
                              className="card-price text-dark mb-0 d-flex"
                            >
                              {currencyFormat(product.price)}
                              {product.slashPrice > 0 && (
                                <span className="">
                                  {" "}
                                  {currencyFormat(product.slashPrice)}
                                </span>
                              )}
                            </p>
                          </div>

                          <a
                            href="#landingform"
                            style={{
                              background: "#10B068",
                            }}
                            className="btn text-light"
                          >
                            Book Now
                          </a>
                        </div>
                      </div>{" "}
                    </SplideSlide>
                  )
              )
            ) : (
              <div
                className="spinner-border mx-auto mt-5 mb-5 text-light"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </Splide>
        </div>
        <div className="mt-lg-5 mt-3 container pt-lg-4">
          <div className="row ">
            <div className="col-lg-5 d-none d-lg-block">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/landingpage-3/304x406a.jpg"
                alt=""
                className="img-fluid w-75"
              />
            </div>
            <div className="col-lg-7 Specifications">
              <h2>Best-in-class Specifications</h2>
              <div className="row mt-lg-5 mt-5  ">
                <div className="col-lg-6   p-lg-3 col-6">
                  <div className="row  align-items-center">
                    <div className="col-12 col-lg-4">
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/4435/4435880.png"
                        className="img-fluid "
                        alt=""
                      />
                    </div>
                    <div className="ps-3 col-12 col-lg-8 mt-3">
                      <h6 className="fw-bold">Front fork Suspension</h6>
                      <p className="mb-0  ">
                        A ride that's well damped no matter where you're camped
                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-6 col-6">
                  <div className="row  align-items-center">
                    <div className="col-12 col-lg-4">
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/3642/3642592.png"
                        className="img-fluid "
                        alt=""
                      />
                    </div>
                    <div className="ps-3 col-12 mt-3 col-lg-8">
                      <h6 className="fw-bold">Multifunctional LCD Display</h6>
                      <p className="mb-0  ">
                        Easy to read and bright even under direct sunlight
                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-6  mt-5 col-6">
                  <div className="row  align-items-center">
                    <div className="col-12 col-lg-4">
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/2479/2479510.png"
                        className="img-fluid "
                        alt=""
                      />
                    </div>
                    <div className="ps-3 col-12 col-lg-8 mt-3">
                      <h6 className="fw-bold">250W Rear Hub Motor</h6>
                      <p className="mb-0 ">
                        Effort that's gentle permormance that's mental
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6  mt-5 col-6 ">
                  <div className="row  align-items-center">
                    <div className="col-12 col-lg-4">
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/3103/3103277.png"
                        className="img-fluid "
                        alt=""
                      />
                    </div>
                    <div className="ps-3 col-12 col-lg-8 mt-3">
                      <h6 className="fw-bold">Removable Battery</h6>
                      <p className="mb-0 ">
                        Batteries that detach built for versatility, no catch
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a
            href="#landingform"
            className="btn submit-btn-lg  mb-lg-3 btn-block  mx-auto d-block mt-5 "
          >
            Book Your Test Ride Now
          </a>
        </div>
      </div>{" "}
      <div
        className="p-3 mt-4  "
        style={{
          backgroundColor: "black",
        }}
      >
        <h3 className="text-center landing-black-stripe text-light">
          <span className="pt-2">Over 500+ </span>
          <span style={{ color: "#10B068" }} className="ps-2 pe-2">
            <i className="fa-solid fa-star me-1"></i>
            <i className="fa-solid fa-star me-1"></i>
            <i className="fa-solid fa-star me-1"></i>
            <i className="fa-solid fa-star me-1"></i>
            <i className="fa-solid fa-star"></i>
          </span>
          <span>User Reviews</span>
        </h3>
      </div>
      <div className=" ">
        <Splide
          className="ps-1 ps-lg-5 pe-lg-5 pt-0 pb-4 pe-1"
          options={{
            arrows: true,
            drag: false,
            type: "slide",
            pagination: true,
            autoplay: false,
            perMove: 1,
            gap: "1em",
            pauseOnHover: false,
            resetProgress: false,
            interval: 5000,
            perPage: 3,
            speed: 1000,
            breakpoints: {
              640: {
                perPage: 1,
                arrows: true,
                drag: true,
              },
            },
          }}
        >
          <SplideSlide>
            <div
              className="bg-light p-3 mb-3  align-items-center  d-flex landing-bike-card mt-3"
              style={{
                borderRadius: "14px",
              }}
            >
              <div className="col-4">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/reviews/EMX/4+PARAG+M.jpg"
                  className="img-fluid "
                  alt=""
                />
              </div>
              <div className="p-3">
                <h5>Mark Dcosta</h5>
                <p style={{ fontSize: "0.8em" }}>
                  The EMotrad doodle electric cycle is very comfortable and has
                  a good ergonomic design. The PAS function is very helpful.
                  Highly recommend this electric cycle.
                </p>
              </div>
            </div>{" "}
          </SplideSlide>{" "}
          <SplideSlide>
            <div
              className="bg-light align-items-center  p-3 mb-3 d-flex landing-bike-card mt-3"
              style={{
                borderRadius: "14px",
              }}
            >
              <div className=" col-4">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/reviews/X2/Ratan+sharma.jpg"
                  className="img-fluid "
                  alt=""
                />
              </div>
              <div className="p-3">
                <h5>Ratan sharma</h5>
                <p style={{ fontSize: "0.8em" }}>
                  This Bike is a great choice for those looking for an electric
                  bike that is reliable, efficient, and easy to use. The bike is
                  well-built.
                </p>
              </div>
            </div>{" "}
          </SplideSlide>{" "}
          <SplideSlide>
            <div
              className="bg-light align-items-center p-3 mb-3 d-flex landing-bike-card mt-3"
              style={{
                borderRadius: "14px",
              }}
            >
              <div className="col-4">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/reviews/X1/Arjun+Rastogi.jpg"
                  className="img-fluid "
                  alt=""
                />
              </div>
              <div className="p-3">
                <h5>Arjun Rastogi</h5>
                <p style={{ fontSize: "0.8em" }}>
                  Went to look for options in cycles and chose the best
                  affordable ebike ever!! X1 you are beautiful!!
                </p>
              </div>
            </div>{" "}
          </SplideSlide>
        </Splide>
      </div>
      <div className="container landing-contact  mt-5">
        <div
          className="p-lg-5 p-3 rounded-3 text-light"
          style={{ background: "black" }}
        >
          <h3>
            Still having any confusion about our electric cycles? <br /> Talk to
            the EM experts now!
          </h3>

          <h4 className="mt-4">
            <i className="fa-solid fa-envelope me-2"></i> contactus@emotorad.com
          </h4>
          <h4 className="mt-3">
            <i className="fa-solid fa-phone me-3"></i>
            +91 8686050590
          </h4>
        </div>
      </div>
    </div>
  );
};

export default LandingPage3Screen;
