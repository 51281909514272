import React, { Suspense, useState } from "react";
import "../CSS/trexFamily.css";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { bikeHighlights } from "../Components/tRexFamily";
import { Link } from "react-router-dom";
import VideoCard from "../Components/VideoCard.js";

const TRexFamilyScreen = () => {
  const [activeItem, setActiveItem] = useState(1);
  const rangeData = [
    {
      id: 1,
      url1: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/usp/Jumpstart+Your+Fun.png",
      text: "Jumpstart Your Fun",
    },
    {
      id: 2,
      url1: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/usp/Swerve+in+Style.png",
      text: "Swerve in Style",
    },
    {
      id: 3,
      url1: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/usp/Ride+the+Moment.png",
      text: "Ride the Moment",
    },
  ];
  const handleHover = (id) => {
    if (activeItem === id) {
      return;
    }
    setActiveItem(id);
  };
  const [proColor, setProColor] = useState(0);
  const [airColor, setAirColor] = useState(0);
  const [v3Color, setV3Color] = useState(0);
  const handleClick = (itemName, index) => {
    if (itemName === "T-Rex Pro") {
      setProColor(index);
    } else if (itemName === "T-Rex Air") {
      setAirColor(index);
    } else {
      setV3Color(index);
    }
  };
  return (
    <div className="tRexFamily-div tRexFamily-primary-bg">
      <Navbar />
      <>
        <div className="position-relative mb-4">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/main/Artboard+3+copy+3.jpg"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/main/Artboard+2+copy+9.jpg"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/main/Artboard+2+copy+9.jpg"
              alt="Banner1"
              className="d-block w-100"
            />
          </picture>
        </div>
        <div className="position-relative mb-4">
          <Link to={"/t-rex-family/t-rex-pro"} className="text-decoration-none">
            <picture className="main-banner-img">
              <source
                media="(max-width:650px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/pro/T-Rex+Pro+Mobile.jpg"
              />
              <source
                media="(min-width:651px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/pro/T-Rex+Pro+Desktop.jpg"
              />
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/pro/T-Rex+Pro+Desktop.jpg"
                alt="Banner1"
                className="d-block w-100"
              />
            </picture>
          </Link>
          <div className="position-absolute t-rexfamily-banner-text w-100">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ flexDirection: "column" }}
            >
              <h5 className="text-light mb-3 helvetica-neue-bold">
                From ₹52,999
              </h5>
              <div
                className="d-flex justify-content-center"
                style={{ gap: "1em" }}
              >
                <div className="btn-know-more text-uppercase rounded-5">
                  <Link
                    to={"/t-rex-family/t-rex-pro"}
                    style={{ color: "inherit" }}
                    className="my-2 text-decoration-none"
                  >
                    Know More
                  </Link>
                </div>
                <div className="btn-buy-now text-uppercase rounded-5">
                  <Link
                    to="/bikes/t-rex-pro"
                    style={{ color: "inherit" }}
                    className="my-2 text-decoration-none"
                  >
                    Buy Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-3 px-lg-5 pt-4 py-lg-5 mt-4">
          <h2
            className="mb-3 mb-lg-4"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            Join the T-Rex Family
          </h2>
          <div className="d-lg-none">
            <ul id="video-cards">
              {rangeData.map((item, index) => (
                <li className="video-card" id={`card${index + 1}`}>
                  <div className="video-card-body position-relative rounded-4">
                    <img src={item.url1} className="img-fluid rounded-4" />
                    <div className="overlay position-absolute start-0 w-100 top-0 h-100 d-flex align-items-end rounded-4 px-lg-4">
                      <div className="text-overlay text-center text-white px-3 py-2 py-lg-3 w-100 fw-bold">
                        <p style={{ fontSize: "1em" }}>{item.text}</p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="d-lg-block d-none">
            <div className="d-flex" style={{ gap: "3em" }}>
              {rangeData.map((item) => (
                <div
                  className="card-t-rex-family position-relative rounded-4 addition-card"
                  key={item.id}
                  style={{
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <img src={item.url1} className="img-fluid rounded-4"></img>
                  <div
                    className="rounded-4"
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                      backgroundColor: "black",
                      background:
                        "linear-gradient(to bottom,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.5) 100%",
                    }}
                  />
                  <div className="w-100">
                    <h5
                      className="position-absolute text-light w-100 fw-bold px-4"
                      style={{
                        bottom: "5%",
                      }}
                    >
                      {item.text}
                    </h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="position-relative mb-4">
          <Link to={"/t-rex-family/t-rex-v3"} className="text-decoration-none">
            <picture className="main-banner-img">
              <source
                media="(max-width:650px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/v3/Artboard+3+copy+2.jpg"
              />
              <source
                media="(min-width:651px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/v3/Artboard+2+copy+8.jpg"
              />
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/v3/Artboard+2+copy+8.jpg"
                alt="Banner1"
                className="d-block w-100"
              />
            </picture>
          </Link>
          <div className="position-absolute t-rexfamily-banner-text w-100">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ flexDirection: "column" }}
            >
              <h5 className="text-light mb-3 helvetica-neue-bold">
                From ₹44,999
              </h5>
              <div
                className="d-flex justify-content-center"
                style={{ gap: "1em" }}
              >
                <div className="btn-know-more text-uppercase rounded-5">
                  <Link
                    to={"/t-rex-family/t-rex-v3"}
                    style={{ color: "inherit" }}
                    className="my-2 text-decoration-none"
                  >
                    Know More
                  </Link>
                </div>
                <div className="btn-buy-now text-uppercase rounded-5">
                  <Link
                    to="/bikes/t-rex+"
                    style={{ color: "inherit" }}
                    className="my-2 text-decoration-none"
                  >
                    Buy Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-relative mb-4">
          <Link to={"/t-rex-family/t-rex-air"} className="text-decoration-none">
            <picture className="main-banner-img">
              <source
                media="(max-width:650px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/air/Artboard+2+(1).png"
              />
              <source
                media="(min-width:651px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/air/Artboard+1.png"
              />
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/air/Artboard+1.png"
                alt="Banner1"
                className="d-block w-100"
              />
            </picture>
          </Link>
          <div className="position-absolute t-rexfamily-banner-text w-100">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ flexDirection: "column" }}
            >
              <h5 className="text-light mb-3 helvetica-neue-bold">
                From ₹34,999
              </h5>
              <div
                className="d-flex justify-content-center"
                style={{ gap: "1em" }}
              >
                <div className="btn-know-more text-uppercase rounded-5">
                  <Link
                    to={"/t-rex-family/t-rex-air"}
                    style={{ color: "inherit" }}
                    className="my-2 text-decoration-none"
                  >
                    Know More
                  </Link>{" "}
                </div>
                <div className="btn-buy-now text-uppercase rounded-5">
                  <Link
                    to="/bikes/t-rex-pro"
                    style={{ color: "inherit" }}
                    className="my-2 text-decoration-none"
                  >
                    Buy Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="comparison-div-tRex px-lg-5 px-3 py-5 mt-3 mt-lg-5">
          <h2 className="mb-3 mb-lg-4 helvetica-neue-bold">
            Find Your Perfect Ride
          </h2>
          <div className="d-flex comparison-flex-div">
            {bikeHighlights.map((item, index) => (
              <div
                className="tRexFamily-secondary-bg text-center rounded-3"
                key={index}
              >
                <div className="p-3 py-4">
                  <div>
                    <img
                      src={
                        item.images[
                          item.name === "T-Rex Pro"
                            ? proColor
                            : item.name === "T-Rex Air"
                            ? airColor
                            : v3Color
                        ]
                      }
                      alt={item.name}
                      className="img-fluid addition-card"
                    />
                  </div>
                  <div className="d-flex my-5 justify-content-center">
                    {item.models.map((i, index) => (
                      <div
                        className="me-2 rounded-circle"
                        onClick={() => handleClick(item.name, index)}
                        style={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                          background: `${i.color}`,
                        }}
                      ></div>
                    ))}
                  </div>
                  <div className="mt-4">
                    <h4 className="mb-4 helvetica-neue-bold">{item.name}</h4>
                    <div
                      className="mb-3"
                      style={{ fontFamily: "Metropolis-light", height: "55px" }}
                    >
                      <p>{item.description}</p>
                    </div>
                    <div className="mb-4 helvetica-neue-bold">
                      <h5>{item.price}</h5>
                    </div>
                    <div
                      className="d-flex justify-content-center"
                      style={{ gap: "1em" }}
                    >
                      <div className="btn-know-more text-uppercase rounded-5">
                        <Link
                          to={item.knowMoreUrl}
                          style={{ color: "inherit" }}
                          className="my-2 text-decoration-none"
                        >
                          Know More{" "}
                        </Link>
                      </div>
                      <div className="btn-buy-now text-uppercase rounded-5">
                        <Link
                          to={item.url}
                          style={{ color: "inherit" }}
                          className="my-2 text-decoration-none"
                        >
                          Buy Now
                        </Link>
                      </div>
                    </div>
                    {item.specs.map((item, index) => (
                      <div className="my-5 spec-comparison-section">
                        <img
                          style={{ width: "40px" }}
                          className="mb-3"
                          src={item.icon}
                        ></img>
                        <div className="w-100 d-flex justify-content-center">
                          <div className="w-75">
                            <p
                              style={{
                                fontSize: "0.8em",
                              }}
                              className="text-uppercase mb-1 helvetica-neue-bold"
                            >
                              {item.name}
                            </p>
                            <p
                              style={{
                                fontFamily: "Metropolis-light",
                                fontSize: "0.8em",
                              }}
                              className=" mb-0"
                            >
                              {item.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="faq-div-tRex px-lg-5 px-3 py-5">
          <h2 className="mb-0 helvetica-neue-bold">FAQs</h2>
          <p
            className="mb-4"
            style={{ fontSize: "1.1em", fontFamily: "Metropolis-medium" }}
          >
            Your questions, answered.
          </p>
          <div
            class="accordion"
            id="accordionExample"
            style={{ fontFamily: "Metropolis-Light" }}
          >
            <div class="accordion-item mb-2 mb-lg-3">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button px-0 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  Which is the best e-cycle in the T-Rex family?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body px-0">
                  Each of the e-cycles in the T-Rex family has their speciality.
                  T-Rex Pro is built for all-terrain performance, with advanced
                  features and durability. The T-Rex+ offers extended range and
                  power for longer rides, while the T-Rex Air is lightweight and
                  designed for easy, efficient riding.
                </div>
              </div>
            </div>
            <div className="cycle-vs-ecycle-line"></div>
            <div class="accordion-item my-2 my-lg-3">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button px-0 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  How long does the battery last on the T-Rex models?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body px-0">
                  On a full charge, the T-Rex Pro can reach up to 70 km on pedal
                  assist; the T-Rex+ and T-Rex Air offer a 50+ range on pedal
                  assist with great efficiency.
                </div>
              </div>
            </div>
            <div className="cycle-vs-ecycle-line"></div>
            <div class="accordion-item my-2 my-lg-3">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button px-0 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Can I remove the battery to charge it?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body px-0">
                  Yes, all T-Rex models come with removable batteries for easy
                  charging wherever and wherever you need them.
                </div>
              </div>
            </div>
            <div className="cycle-vs-ecycle-line "></div>
            <div class="accordion-item my-2 my-lg-3">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button px-0 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Are the T-Rex bikes water-resistant?
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body px-0">
                  Yes, all T-Rex bikes are designed to be water-resistant,
                  making them safe to ride in light rain. However, avoid
                  submerging the electrical components in deep water.
                </div>
              </div>
            </div>
            <div className="cycle-vs-ecycle-line "></div>
            <div class="accordion-item my-2 my-lg-3">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button px-0 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Do the bikes come assembled?
                </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body px-0">
                  The T-Rex bikes are shipped 90% assembled. You’ll only need to
                  attach a few components, like the pedals, front wheel,
                  handlebar, and you’re ready to ride!
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="px-3 px-lg-5 py-5">
          <h2
            className="mb-3 mb-lg-4"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            Get the Highlights
          </h2>
          <ul id="video-cards">
            <li className="video-card" id="card1">
              <VideoCard
                videoSrcMobile="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trex+pro+mobile.mp4"
                videoSrcDesktop="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trex+pro+desktop.mp4"
              />
            </li>
            <li className="video-card" id="card2">
              <VideoCard
                videoSrcMobile="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trex+pro+mobile.mp4"
                videoSrcDesktop="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trex+pro+desktop.mp4"
              />
            </li>
            <li className="video-card" id="card3">
              <VideoCard
                videoSrcMobile="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trex+pro+mobile.mp4"
                videoSrcDesktop="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trex+pro+desktop.mp4"
              />
            </li>
            <li className="video-card" id="card4">
              <VideoCard
                videoSrcMobile="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trex+pro+mobile.mp4"
                videoSrcDesktop="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trex+pro+desktop.mp4"
              />
            </li>
          </ul>
        </div> */}
      </>

      {/* <Canvas style={{ height: "100vh" }} className="">
          <ambientLight intensity={0.5} />
          <directionalLight position={[10, 10, 5]} intensity={1} />
          <OrbitControls enableZoom={false} />
          <Suspense fallback={null}>
            <Trex_pro_blue>
              <meshStandardMaterial color={"blue"} />
            </Trex_pro_blue>
          </Suspense>
          <Environment preset="sunset" />
          <ContactShadows
            opacity={1}
            scale={10}
            blur={1}
            far={10}
            resolution={256}
            color="#000000"
          />
        </Canvas> */}
      <Footer />
    </div>
  );
};

export default TRexFamilyScreen;
