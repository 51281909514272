export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST'
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS'
export const CONTACT_US_FAIL = 'CONTACT_US_FAIL'
export const CONTACT_US_RESET = 'CONTACT_US_RESET'

export const EMI_REQUEST = 'EMI_REQUEST'
export const EMI_SUCCESS = 'EMI_SUCCESS'
export const EMI_FAIL = 'EMI_FAIL'
export const EMI_RESET = 'EMI_RESET'

export const PARTNER_WITH_US_REQUEST = 'PARTNER_WITH_US_REQUEST'
export const PARTNER_WITH_US_SUCCESS = 'PARTNER_WITH_US_SUCCESS'
export const PARTNER_WITH_US_FAIL = 'PARTNER_WITH_US_FAIL'
export const PARTNER_WITH_US_RESET = 'PARTNER_WITH_US_RESET'

export const WARRANTY_REQUEST = 'WARRANTY_REQUEST'
export const WARRANTY_SUCCESS = 'WARRANTY_SUCCESS'
export const WARRANTY_FAIL = 'WARRANTY_FAIL'
export const WARRANTY_RESET = 'WARRANTY_RESET'

export const INSURANCE_REQUEST = 'INSURANCE_REQUEST'
export const INSURANCE_SUCCESS = 'INSURANCE_SUCCESS'
export const INSURANCE_FAIL = 'INSURANCE_FAIL'
export const INSURANCE_RESET = 'INSURANCE_RESET'