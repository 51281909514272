import React, { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import "../CSS/loder.css";
const Loder = () => {
  const boltRef = useRef(null);
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    const bolt = boltRef.current;
    const div = bolt.querySelector("div");

    gsap.set(div, { rotation: 360 });

    const tween = gsap
      .timeline({
        onComplete: () => {
          bolt.classList.remove("animate");
          repeat();
        },
      })
      .to(div, 0.7, { y: 80, rotation: 370 })
      .to(div, 0.6, { y: -140, rotation: 20 })
      .to(div, 0.1, { rotation: -24, y: 80 })
      .to(div, 0.8, { ease: "back.out(1.6)", rotation: 0, y: 0 });

    function repeat() {
      setTimeout(() => {
        bolt.classList.add("animate");
        tween.restart();
      }, 400);
    }
  }, []);

  //   useEffect(() => {
  //     const onPageLoad = () => {
  //       setTimeout(() => {
  //         setPageLoaded(true);
  //       }, 0);
  //     };

  //     if (document.readyState === "complete") {
  //       setPageLoaded(true);
  //     } else {
  //       window.addEventListener("load", onPageLoad, false);

  //       return () => window.removeEventListener("load", onPageLoad);
  //     }
  //   }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoaded(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div
        className={` position-fixed ${pageLoaded && "preloder"}  `}
        style={{
          height: window.screen.height,
          width: "100%",
          zIndex: "999",
          background: "#004DAC",
        }}
      >
        <div className="loder-main-div">
          <div class="bolt animate" ref={boltRef}>
            <svg viewBox="0 0 170 57" class="white left">
              <path d="M36.2701759,17.9733192 C-0.981139498,45.4810755 -7.86361824,57.6618438 15.6227397,54.5156241 C50.8522766,49.7962945 201.109341,31.1461782 161.361488,2"></path>
            </svg>
            <svg viewBox="0 0 170 57" class="white right">
              <path d="M36.2701759,17.9733192 C-0.981139498,45.4810755 -7.86361824,57.6618438 15.6227397,54.5156241 C50.8522766,49.7962945 201.109341,31.1461782 161.361488,2"></path>
            </svg>
            <div>
              <span></span>
            </div>
            <svg viewBox="0 0 112 44" class="circle">
              <path d="M96.9355003,2 C109.46067,13.4022454 131.614152,42 56.9906735,42 C-17.6328048,42 1.51790702,13.5493875 13.0513641,2"></path>
            </svg>
            <svg viewBox="0 0 70 3" class="line left">
              <path
                transform="translate(-2.000000, 0.000000)"
                d="M2,1.5 L70,1.5"
              ></path>
            </svg>
            <svg viewBox="0 0 70 3" class="line right">
              <path
                transform="translate(-2.000000, 0.000000)"
                d="M2,1.5 L70,1.5"
              ></path>
            </svg>
          </div>
        </div>
        <section>
          <div class="typewriter">
            <h1 className="text-light loder-text text-center">
              Do More Than Just Move
            </h1>
          </div>
        </section>
      </div>
      {/* <p>Do More Than Just Move </p> */}
    </>
  );
};

export default Loder;
