import React, { useState, useRef, useEffect } from "react";
import "../CSS/movements.css";
import { getAllBikes } from "../Actions/bikeActions";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import logo from "../images/mainlogo.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ReactPixel from "react-facebook-pixel";
function MovementsLandingPage() {
  const ytRef = useRef();
  const ytThumbnailRef = useRef();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bike, setBike] = useState("");
  const reviews = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 32,
  ];

  useEffect(() => {
    ReactPixel.init("1034452493992700");
    ReactPixel.pageView();
    dispatch(getAllBikes());
  }, []);

  const scrollToRegister = () => {
    const registerSection = document.getElementById("register");
    if (registerSection) {
      const targetPosition =
        registerSection.getBoundingClientRect().height * -0.5;
      window.scrollTo({
        top: registerSection.offsetTop + targetPosition,
        behavior: "smooth",
      });
    } else {
      console.error('Element with ID "register" not found.');
    }
  };

  const scrollToCtalinks = () => {
    const ctaSection = document.getElementById("cta-links");
    if (ctaSection) {
      const targetPosition = ctaSection.getBoundingClientRect().height * -2.5;
      window.scrollTo({
        top: ctaSection.offsetTop + targetPosition,
        behavior: "smooth",
      });
      // window.scrollBy(0, targetPosition);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(
        "/api/form/movementsLanding",
        { name, email, phoneNumber, bike },
        config
      );

      if (data) {
        ReactPixel.track("SubmitForm");
        navigate("/registration-confirmation");
      }
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };
  const dispatch = useDispatch();
  if (
    ytRef.current &&
    ytThumbnailRef.current &&
    ytThumbnailRef.current.splide
  ) {
    ytRef.current.sync(ytThumbnailRef.current.splide);
    ytThumbnailRef.current.sync(ytRef.current.splide);
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBIaH1D753G5bOfcm7kExBfP7uwOQoqHYo&libraries=places&callback=initMap&solution_channel=GMP_codelabs_simplestorelocator_v1_a";
    script.async = true;
    script.onload = () => {
      initializeMap();
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const initializeMap = () => {
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer();

    const newMap = new window.google.maps.Map(
      document.getElementById("map-marathon"),
      {
        zoom: 12,
        center: { lat: 12.972082, lng: 77.603735 },
      }
    );
    directionsRenderer.setMap(newMap);

    const request = {
      origin: { lat: 18.563900492116634, lng: 73.78820398902656 },
      destination: { lat: 18.56388232332301, lng: 73.78814280747963 },
      waypoints: [
        {
          location: { lat: 18.562251406781797, lng: 73.77661332500361 },
          stopover: true,
        },
        {
          location: { lat: 18.551427881501592, lng: 73.77717805383831 },
          stopover: true,
        },
        {
          location: { lat: 18.536937671419743, lng: 73.78855759616823 },
          stopover: true,
        },
        {
          location: { lat: 18.54347852620794, lng: 73.81747032500327 },
          stopover: true,
        },
        {
          location: { lat: 18.565413128918106, lng: 73.80678514524658 },
          stopover: true,
        },
      ],
      travelMode: "DRIVING",
    };

    directionsService.route(request, (result, status) => {
      if (status === "OK") {
        directionsRenderer.setDirections(result);
      } else {
        console.error(`Directions request failed due to ${status}`);
      }
    });
  };

  // Initialize the map when the window loads
  window.onload = initializeMap;

  const countDownDate = new Date("June 02, 2024 06:30").getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const distance = countDownDate - now;

    if (distance < 0) {
      clearInterval();
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const scrollHandler = () => {
      const scrollTrigger = document.getElementById(
        "landing-banner-movements"
      ).offsetTop;
      const isButtonVisible = window.scrollY > scrollTrigger;
      setIsVisible(isButtonVisible);
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", scrollHandler);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <>
      <div className="landingpagemovementsbody">
        {/* navabar */}
        <div
          className="navbar-banner p-2 position-sticky navigation w-100"
          style={{ zIndex: "99", top: "0%" }}
        >
          <div class="row">
            <div class="col-md-5">
              <div class="row">
                <div class="col-md-12 text-center">
                  <img
                    src={logo}
                    className="img-fluid"
                    style={{ width: "140px" }}
                    alt=""
                  />
                </div>
                <div class="col-md-12 navbar-banner-mainheading text-center">
                  E-CYCLOTHON{" "}
                  <span className="navbar-submainheading">2024</span>
                </div>
              </div>
            </div>
            <div className="mt-2 d-lg-none">
              <div className="row">
                <div className="col-4 navbar-banner-heading text-center">
                  VENUE
                </div>
                <div className="col-4 navbar-banner-heading text-center">
                  DATE
                </div>
                <div class="col-4 navbar-banner-heading text-center">TIME</div>
              </div>
              <div className="row">
                <div className="col-4 navbar-banner-subheading text-center">
                  PUNE
                </div>
                <div className="col-4 navbar-banner-subheading text-center">
                  02-06-2024
                </div>
                <div class="col-4 navbar-banner-subheading text-center">
                  06:30 AM
                </div>
              </div>
            </div>
            <div class="col-md-7 desktop-event-navbar">
              <div class="row d-flex justify-content-center">
                <div class="col-md-4 navbar-banner-heading text-center">
                  VENUE
                </div>
                <div class="col-md-4 navbar-banner-heading text-center">
                  DATE
                </div>
                <div class="col-md-4 navbar-banner-heading text-center">
                  TIME
                </div>
                <div class="col-md-4 navbar-banner-subheading text-center">
                  PUNE
                </div>
                <div class="col-md-4 navbar-banner-subheading text-center">
                  02-06-2024
                </div>
                <div class="col-md-4 navbar-banner-subheading text-center">
                  06:30 AM
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* main-banner */}
        <div className="position-relative landing-banner-movements">
          <h1 className="position-absolute heading-one">
            <em>
              MOV<b className="heading-one-sub">EM</b>
              ENTS OF FUN
            </em>
          </h1>
          <h6 className="position-absolute heading-two">
            <em>E-CYCLOTHON 2.0</em>
          </h6>
          <h6 className="position-absolute heading-three">
            PUNE'S FIRST E-CYCLOTHON
          </h6>
          <picture>
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/movements/Event_Mobile+(4).png"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/movements/Event+(4).jpg"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/movements/Event+(4).jpg"
              className="img-fluid w-100"
              alt=""
              srcSet=""
            />
          </picture>
          <div className="btn-container-movements">
            <form
              onSubmit={submit}
              className="p-4 movements-form mt-4 form-container-main-banner"
            >
              <div className="timer-desktop">
                <p>
                  REGISTRATION ENDS IN:{" "}
                  <span className="timer-passion-movements">
                    {timeRemaining.days}d {timeRemaining.hours}h{" "}
                    {timeRemaining.minutes}m {timeRemaining.seconds}s
                    {timeRemaining.days === 0 &&
                      timeRemaining.hours === 0 &&
                      timeRemaining.minutes === 0 &&
                      timeRemaining.seconds === 0 &&
                      "EXPIRED"}
                  </span>
                </p>
              </div>
              <h4
                className="mb-3"
                style={{ textAlign: "center", color: "#004dac" }}
              >
                <b>
                  FILL THE FORM <br></br> TO JOIN THE MOVEMENT
                </b>
              </h4>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value.trimStart())}
                placeholder="Name"
                required
                className="movements-main-banner-form-input"
              />{" "}
              <input
                type="tel"
                placeholder="Phone Number"
                required
                className="movements-main-banner-form-input"
                value={phoneNumber}
                onChange={(e) => {
                  const phoneNumber = e.target.value.slice(0, 12);
                  setPhoneNumber(phoneNumber);

                  if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                    e.target.setCustomValidity("");
                  } else {
                    e.target.setCustomValidity(
                      "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                    );
                  }
                }}
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                  )
                }
              />
              <input
                type="email"
                required
                placeholder="Email ID"
                value={email}
                onChange={(e) => setEmail(e.target.value.trimStart())}
                className="movements-main-banner-form-input"
              />{" "}
              <select
                onChange={(e) => setBike(e.target.value)}
                className="movements-main-banner-form-input"
              >
                <option disabled selected>
                  <p style={{ color: "#cdcdce" }}>Which e-cycle you ride?</p>
                </option>
                <option style={{ color: "black" }} value="X1">
                  X1
                </option>
                <option style={{ color: "black" }} value="X2">
                  X2
                </option>
                <option style={{ color: "black" }} value="X3">
                  X3
                </option>
                <option style={{ color: "black" }} value="T-Rex+">
                  T-Rex+
                </option>
                <option style={{ color: "black" }} value="T-Rex Air">
                  T-Rex Air
                </option>
                <option style={{ color: "black" }} value="EMX">
                  EMX
                </option>
                <option style={{ color: "black" }} value="EMX+">
                  EMX+
                </option>
                <option style={{ color: "black" }} value="Doodle">
                  Doodle
                </option>
                <option style={{ color: "black" }} value="Other Brands">
                  Other Brands
                </option>
                <option style={{ color: "black" }} value="No E-cycle">
                  No E-cycle
                </option>
              </select>
              <button
                type="submit"
                className="mt-3 btn btn-lg movements-register-button-main-banner"
              >
                Register Here
              </button>
            </form>
          </div>
        </div>
        {/* form-section */}
        <div className="d-lg-none mt-3">
          <div className="form-container" id="register">
            <h1 className="form-heading">
              FILL THE FORM <br></br> TO JOIN THE MOVEMENT
            </h1>
            <div className="form-sub-container display-flex align-items-center justify-content-center">
              <form onSubmit={submit} className="p-4 movements-form mt-4 ">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value.trimStart())}
                  placeholder="Name"
                  required
                  className="movements-form-input"
                />{" "}
                <input
                  type="tel"
                  placeholder="Phone Number"
                  required
                  className=" movements-form-input"
                  value={phoneNumber}
                  onChange={(e) => {
                    const phoneNumber = e.target.value.slice(0, 12);
                    setPhoneNumber(phoneNumber);

                    if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                      e.target.setCustomValidity("");
                    } else {
                      e.target.setCustomValidity(
                        "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                      );
                    }
                  }}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                    )
                  }
                />
                <input
                  type="email"
                  required
                  placeholder="Email ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.trimStart())}
                  className="movements-form-input"
                />{" "}
                <select
                  onChange={(e) => setBike(e.target.value)}
                  className="movements-form-input"
                >
                  <option selected disabled>
                    Which e-cycle you ride?
                  </option>
                  <option style={{ color: "black" }} value="X1">
                    X1
                  </option>
                  <option style={{ color: "black" }} value="X2">
                    X2
                  </option>
                  <option style={{ color: "black" }} value="X3">
                    X3
                  </option>
                  <option style={{ color: "black" }} value="T-Rex+">
                    T-Rex+
                  </option>
                  <option style={{ color: "black" }} value="T-Rex Air">
                    T-Rex Air
                  </option>
                  <option style={{ color: "black" }} value="EMX">
                    EMX
                  </option>
                  <option style={{ color: "black" }} value="EMX+">
                    EMX+
                  </option>
                  <option style={{ color: "black" }} value="Doodle">
                    Doodle
                  </option>
                  <option style={{ color: "black" }} value="Other Brands">
                    Other Brands
                  </option>
                  <option style={{ color: "black" }} value="No E-cycle">
                    No E-cycle
                  </option>
                </select>
                <button
                  type="submit"
                  className="mt-3 btn btn-lg movements-register-button p-2"
                >
                  Register Here
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* journey-div */}
        <div className="journey-div">
          <div
            style={{ display: "flex", height: "100%", marginTop: "2em" }}
            className="journey-div-sub-desktop"
          >
            <div style={{ flex: "20%", textAlign: "center" }}>
              <img
                className="icon-image-event"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/movements/cycling.png"
              ></img>
            </div>
            <div style={{ flex: "80%" }}>
              <h6
                className=" blue-heading"
                style={{ fontFamily: "Metropolis-medium" }}
              >
                <b>CELEBRATE WORLD BICYCLE DAY </b>
              </h6>
              <h3 className="black-small-heading mb-3">
                AND ENVIRONMENT DAY IN STYLE
              </h3>
              <div className="row">
                <div className="col-5">
                  <img
                    className="banner-event-image-2 mb-3"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/ecyclothon/1.jpg"
                  ></img>
                </div>
                <div className="col-2"></div>
                <div className="col-5">
                  <img
                    className="banner-event-image-2 mb-3"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/ecyclothon/2.jpg"
                  ></img>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <p className="p-tags mb-3">
                    EM Rads, lead the way to revolution! Presenting MovEMents of
                    fun 2.0, where we raise awareness for sustainability, make
                    way to a healthier lifestyle and a cleaner planet.
                  </p>
                  <h3
                    className="blue-heading mb-3"
                    style={{ fontSize: "1.2em" }}
                  >
                    #jointhemovement
                  </h3>
                </div>
                <div className="col-3" style={{ textAlign: "center" }}>
                  <img
                    className="prize-image-2"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/movements/Prize.png"
                  ></img>
                </div>
                <div className="col-5">
                  <p className="p-tags">
                    Celebrate bicycle day and environment day with a thrilling
                    20 km e-cycle only ride through Pune's beautiful scenery
                    with other enthusiastic e-cyclists.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", height: "100%", marginTop: "2em" }}
            className="d-lg-none"
          >
            <div style={{ flex: "20%", textAlign: "center" }}>
              <img
                className="icon-image-event"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/movements/cycling.png"
              ></img>
            </div>
            <div style={{ flex: "80%" }}>
              <h6
                className=" blue-heading"
                style={{ fontFamily: "Metropolis-medium" }}
              >
                <b>CELEBRATE WORLD BICYCLE DAY</b>
              </h6>
              <h3 className="black-small-heading mb-3 fw-bold">
                AND ENVIRONMENT DAY IN STYLE
              </h3>
              <p className="p-tags mb-3">
                EM Rads, lead the way to revolution! Presenting MovEMents of fun
                2.0, where we raise awareness for sustainability, make way to a
                healthier lifestyle and a cleaner planet.
              </p>
              <h3 className="blue-heading mb-3" style={{ fontSize: "1.2em" }}>
                #jointhemovement
              </h3>
              <img
                className="banner-event-image mb-3"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/ecyclothon/1.jpg"
              ></img>
              <div style={{ textAlign: "center" }}>
                <img
                  className="prize-image mb-3"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/movements/Prize.png"
                ></img>
              </div>
              <img
                className="banner-event-image mb-3"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/ecyclothon/2.jpg"
              ></img>
              <p className="p-tags">
                Celebrate bicycle day and environment day with a thrilling 20 km
                e-cycle only ride through Pune's beautiful scenery with other
                enthusiastic e-cyclists.
              </p>
            </div>
          </div>
          <div style={{ display: "flex", height: "100%", marginTop: "2em" }}>
            <div style={{ flex: "20%", textAlign: "center" }}>
              <img
                className="icon-image-event"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/movements/cycling.png"
                alt="Prize Image"
              ></img>
            </div>
            <div style={{ flex: "80%" }}>
              <h3
                className="blue-heading mb-3 "
                style={{ fontFamily: "Metropolis-medium" }}
              >
                THERE'S MORE IN STORE
              </h3>
              <p className="p-tags">
                No e-cycle? No worries! We have over 50 e-cycles available for
                rent, so you don't miss out on the fun. Experience a day filled
                with activities, including a complementary breakfast, Zumba
                session, and much more!
              </p>
              <img
                className="banner-event-image-3"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/ecyclothon/3.jpg"
              ></img>
            </div>
          </div>
          <div style={{ display: "flex", height: "100%", marginTop: "2em" }}>
            <div style={{ flex: "20%", textAlign: "center" }}>
              <img
                className="icon-image-event-2"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/movements/location.png"
              ></img>
            </div>
            <div style={{ flex: "80%" }}>
              <h6 className="black-small-heading">
                <b>WHERE</b>
              </h6>
              <h3
                className="blue-heading mb-3"
                style={{ fontFamily: "Metropolis-medium" }}
              >
                PUNE
              </h3>
              <p className="p-tags fw-bold">
                <a
                  href="https://www.google.com/maps?cid=7177088143503697256"
                  className="text-decoration-none"
                  style={{ color: "inherit" }}
                >
                  Mayfield Ivy Garden Dhankude Farms, Aundh-Baner Link Rd, Pune,
                  Maharashtra 411045
                </a>
              </p>
            </div>
          </div>
          <div style={{ display: "flex", height: "100%", marginTop: "2em" }}>
            <div style={{ flex: "20%", textAlign: "center" }}></div>
            <div style={{ flex: "80%" }}>
              <h3
                className="blue-heading mb-3"
                style={{ fontFamily: "Metropolis-medium" }}
              >
                EXCLUSIVE CARE PACKAGE FOR THE STARS
              </h3>
              <p className="p-tags">
                Get to know your electric cycle with our experts on the ground
                and enjoy a checkup and service for your star electric cycle!
                Join 'MovEMents by EMotorad,' where the road symbolizes life's
                challenges. This initiative isn't just about riding—it's a
                movement where every pedal signifies overcoming obstacles,
                self-discovery, and empowerment. Be part of building a strong
                electric cycle community and embrace a vibrant, connected, and
                energized lifestyle!
              </p>
              <p className="p-tags">*T&C Apply</p>
            </div>
          </div>
          <div style={{ display: "flex", height: "100%", marginTop: "2em" }}>
            <div style={{ flex: "20%", textAlign: "center" }}>
              <img
                className="icon-image-event-3"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/movements/distance-icon.png"
              ></img>
            </div>
            <div style={{ flex: "80%" }}>
              <h6 className="black-small-heading">
                <b>E-CYCLOTHON 2.0</b>
              </h6>
              <h3
                className="blue-heading mb-3"
                style={{ fontFamily: "Metropolis-medium" }}
              >
                ROUTE
              </h3>
              <div id="map-marathon"></div>
              <div className="distance-div">
                <p>
                  DISTANCE: <b>20Km</b>
                </p>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", height: "100%", marginTop: "2em" }}>
            <div style={{ flex: "20%", textAlign: "center" }}>
              <img
                className="icon-image-event-2"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/movements/Info-icon.png"
              ></img>
            </div>
            <div style={{ flex: "80%" }}>
              <h3 className="blue-heading">CHARGED WITH EXCITEMENT!</h3>
              <h3 className="blue-heading mb-3">
                GET READY TO ROLL THE ELECTRIC WAY WITH US!
              </h3>
              <p className="p-tags">
                Explore Pune's scenic routes, breeze through traffic, and ride
                freely under the open skies. Rest assured, our dedicated
                volunteers will be there to support you every step of the way,
                ensuring a smooth and enjoyable journey from start to finish!
              </p>
            </div>
          </div>
        </div>
        {/* user Reviews */}
        <div className="mt-lg-5 mb-5">
          <h2 className="container-fluid text-center text-lg-start text-passion mb-3 ps-lg-5">
            LEAGUE OF LEGENDS
          </h2>
          <div className="position-relative ">
            <Splide
              className=" p-0  flex align-items-center"
              options={{
                arrows: false,
                drag: false,
                type: "loop",
                pagination: false,
                autoplay: true,
                autoScroll: {
                  speed: 1,
                },
                perMove: 1,
                gap: "3em",
                padding: "1em",
                pauseOnHover: false,
                resetProgress: false,
                interval: 1000,
                direction: "rtl",
                perPage: 6,

                speed: 1000,
                breakpoints: {
                  640: {
                    arrows: false,
                    drag: false,
                    type: "loop",
                    perPage: 1,

                    pagination: false,
                    autoplay: true,
                    perMove: 1,
                    gap: "1em",

                    pauseOnHover: false,
                    resetProgress: false,
                    interval: 1000,
                    focus: 0,
                    perPage: 2,

                    speed: 500,
                  },
                },
              }}
            >
              {reviews
                .filter((element) => element < 15)
                .map((element, index) => {
                  return (
                    <SplideSlide key={index} className=" h-100">
                      <div>
                        <img
                          src={`https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/reviews/${element}.jpg`}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </SplideSlide>
                  );
                })}
            </Splide>
            <h1 className="text-center mt-3 mb-3">
              JOIN THE
              <span className="fw-bold">&nbsp;85K</span> CLUB
            </h1>
            <Splide
              className=" flex p-0 align-items-center"
              options={{
                arrows: false,
                drag: false,
                type: "loop",
                pagination: false,
                autoplay: true,
                autoScroll: {
                  speed: 1,
                },
                perMove: 1,
                gap: "3em",
                padding: "1em",
                pauseOnHover: true,
                resetProgress: false,
                interval: 1000,
                direction: "ltr",
                perPage: 6,

                speed: 1000,
                breakpoints: {
                  640: {
                    arrows: false,
                    drag: false,
                    type: "loop",
                    perPage: 1,

                    pagination: false,
                    autoplay: true,
                    perMove: 1,
                    gap: "1em",

                    pauseOnHover: false,
                    resetProgress: false,
                    interval: 1000,
                    focus: 0,
                    perPage: 2,

                    speed: 500,
                  },
                },
              }}
            >
              {reviews
                .filter((element) => element > 14)
                .map((element, index) => {
                  return (
                    <SplideSlide key={index} className=" h-100">
                      <div>
                        <img
                          src={`https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/reviews/${element}.jpg`}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </SplideSlide>
                  );
                })}
            </Splide>
          </div>
        </div>
        {/* faq-section */}
        <div className="faq-container mb-5">
          <h1 className="form-heading mb-5">FAQS</h1>
          <div className="accordion" id="accordionExample">
            <div className="row">
              <div className="col-md-6 mb-3 ">
                <div className="accordion-item">
                  <h2 className="accordion-header faq-heading" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How do I sign up for this event?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      To register for this exciting event, simply click on the
                      "Register Now" button above or use the provided Referral
                      link.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="accordion-item">
                  <h2 className="accordion-header faq-heading" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Is there parking available at the venue?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Absolutely! We offer ample parking space at the event site
                      for your electric cycle.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="accordion-item">
                  <h2
                    className="accordion-header faq-heading"
                    id="headingThree"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      I don't own an electric cycle, can I still participate?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Yes, you can! We have a limited number of electric cycles
                      available for the event, and you can avail one at a cost
                      of 1299 Rupees. For more details and bookings, please
                      reach out to us at +91-9424648894
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="accordion-item">
                  <h2 className="accordion-header faq-heading" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      What do I get at the end of the ride?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      The conclusion of the event promises a fantastic
                      experience, including medal and certificate distribution,
                      a healthy breakfast, and memorable photos to capture the
                      moment.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="accordion-item">
                  <h2
                    className="accordion-header faq-heading"
                    id="headingFifth"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFifth"
                      aria-expanded="false"
                      aria-controls="collapseFifth"
                    >
                      How long is the ride?
                    </button>
                  </h2>
                  <div
                    id="collapseFifth"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFifth"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Brace yourself for a thrilling 20 km ride! Let's embark on
                      this electrifying journey together.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="accordion-item">
                  <h2
                    className="accordion-header faq-heading"
                    id="headingSixth"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixth"
                      aria-expanded="false"
                      aria-controls="collapseSixth"
                    >
                      Are First Aid facilities available at the venue?
                    </button>
                  </h2>
                  <div
                    id="collapseSixth"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSixth"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Absolutely! Our team is equipped to provide first aid
                      assistance throughout the ride, ensuring the safety of our
                      participants.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="accordion-item">
                  <h2
                    className="accordion-header faq-heading"
                    id="headingFifth"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      Can I register one day before the event?
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      We have 300 slots available for registration, and it
                      operates on a first-come, first-serve basis. To ensure you
                      secure your spot and to avoid any last-minute rush, we
                      strongly encourage you to register as early as possible.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="accordion-item">
                  <h2
                    className="accordion-header faq-heading"
                    id="headingEight"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      Is this a paid or free event?
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Participation in E-Cyclothon is free for the riders who
                      own any E-Cycle. For other participants, there is a
                      nominal fee of Rs. 1299 to join the event. Fully
                      redeemable on our exciting range of electric cycles.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* countdown-div-mobile */}
        <div className="floating-timer d-lg-none">
          <p>
            REGISTRATION ENDS IN:{" "}
            <span className="timer-passion-movements">
              {timeRemaining.days}d {timeRemaining.hours}h{" "}
              {timeRemaining.minutes}m {timeRemaining.seconds}s
              {timeRemaining.days === 0 &&
                timeRemaining.hours === 0 &&
                timeRemaining.minutes === 0 &&
                timeRemaining.seconds === 0 &&
                "EXPIRED"}
            </span>
          </p>
        </div>
        {/* floating-rgister-mobile */}
        <button
          className={`floating-cta-event w-100 text-decoration-none d-lg-none text-center${
            isVisible ? "visible" : ""
          }`}
          onClick={scrollToRegister}
        >
          REGISTER
        </button>
      </div>
    </>
  );
}

export default MovementsLandingPage;
