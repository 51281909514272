import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "../CSS/footer.css";
import { Link } from "react-router-dom";
import logo from "../images/EM-LOGO_Blue.png";
import { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";

const Footer = () => {
  const [open, openNav] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const hygraph = new GraphQLClient(
    "https://api-ap-south-1.hygraph.com/v2/clcrn35rt0ct401ur6hv90b6q/master"
  );

  const QUERY = gql`
    {
      posts(orderBy: publishedAt_DESC, first: 2) {
        title
        slug
      }
    }
  `;
  useEffect(() => {
    const getBlogs = async () => {
      const { posts } = await hygraph.request(QUERY);
      setBlogPosts(posts);
    };
    getBlogs();
  }, []);

  const closeNavforDeadLink = () => {
    openNav(false);
  };
  return (
    <>
      <footer
        className="pt-4  pe-5 pe-3 ps-3 pe-lg-5 ps-lg-5 d-none d-lg-block"
        style={{ fontFamily: "Metropolis-medium" }}
      >
        <div className="">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={logo}
                style={{ width: "150px" }}
                alt=""
                className="mb-3"
              />
              <p className=" mt-lg-3   footer-desc">
                EMotorad (EM) is an electric vehicle company that strives to
                bring futuristic ebikes at an affordable price for adventure
                seekers, daily commuters, or casual riders.
              </p>
              <p className=" footer-desc">
                For General Enquires:{" "}
                <a href="tel:+918686050590" className="footer-links">
                  +91 8686050590
                </a>{" "}
                |{" "}
                <a
                  href="mailto:contactus@emotorad.com"
                  className="footer-links"
                >
                  contactus@emotorad.com
                </a>{" "}
                <br />
                <br />
                For Service Related Queries:{" "}
                <a href="tel:+918956248161" className="footer-links">
                  +91 8956248161
                </a>{" "}
                |{" "}
                <a href="mailto:service@emotorad.com" className="footer-links">
                  service@emotorad.com
                </a>{" "}
                <br />
                <br />
                For Partnership Queries:{" "}
                <a
                  href="mailto:enterprise@emotorad.in"
                  className="footer-links"
                >
                  enterprise@emotorad.in
                </a>{" "}
              </p>
              <div className="d-flex mt-3 footer-desc" style={{ gap: "1em" }}>
                <div>Address:</div>
                <div>
                  <a
                    href="https://maps.app.goo.gl/111GV3hCUtBgdxbdA"
                    className="footer-links"
                  >
                    At post Jambe, Taluka Mulshi, 159/2 Sangawade, Road, Pune,
                    Maharashtra 411033
                  </a>
                  <div className="mb-2" />
                  <a
                    href="https://maps.app.goo.gl/JM5ACkiXhWqYofku9"
                    className="footer-links"
                  >
                    EMotorad, Ground Floor, North Block, Smartworks, Vaishnavi
                    Tech Park, Sy. No. 16/1 and 17/2, Bellandur Gate, Sarjapur
                    Main Road, Ambalipura, Bengaluru, Karnataka, 560102
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 footer-links p-4 ps-5 pe-5">
              <div className="row">
                <div className="col-3">
                  <h5 className="color-dark mb-3 mt-3">About</h5>
                  <Link to="/ourstory" className="">
                    Our Story
                  </Link>

                  <Link to="/contact-us" className="">
                    Contact Us
                  </Link>

                  <Link to="/blogs" className="">
                    Blogs
                  </Link>
                  <Link to="/news" className="">
                    News
                  </Link>
                  <Link to="/mahendra-singh-dhoni-electric-cycles" className="">
                    Celebrity X EM
                  </Link>
                  <Link to="/partner-with-us" className="">
                    Partner with us
                  </Link>
                  <a
                    href="https://www.dynem.in/"
                    className="text-decoration-none"
                  >
                    Manufacturing
                  </a>
                </div>
                <div className="col-2">
                  <h5 className="color-dark mb-3 mt-3">Range</h5>{" "}
                  <Link to="/bikes#x-factor" className="txt-regular">
                    X-Factor
                  </Link>
                  <Link to="/bikes#desire" className="txt-regular">
                    Desire
                  </Link>
                  {/* <Link
									to='/bikes/range/elite'
									className='txt-regular'>
									Elite
								</Link> */}
                </div>

                <div className="col-4">
                  <h5 className="color-dark mb-3 mt-3">Popular Articles</h5>{" "}
                  <Link
                    to="/blogs/demystifying-fat-tyre-ebikes"
                    className="txt-regular"
                  >
                    Decoding Fat Tyre E-cycle
                  </Link>
                  <Link
                    to="/blogs/best-electric-cycles-under-2000"
                    className="txt-regular"
                  >
                    Best electric cycle under 20000
                  </Link>
                  <Link
                    to="/blogs/common-misconceptions-about-electric-eikes"
                    className="txt-regular"
                  >
                    Common e-Bike Misconceptions
                  </Link>
                  <h5 className="color-dark mb-3 mt-3">Recent Articles</h5>{" "}
                  {blogPosts &&
                    blogPosts.map((blog) => (
                      <Link
                        to={`/blogs/${blog.slug}`}
                        className="txt-regular"
                        key={blog.id}
                      >
                        {blog.title}
                      </Link>
                    ))}
                </div>

                <div className="col-3">
                  <h5 className="color-dark mb-3 mt-3 ">Assurance</h5>{" "}
                  <Link to="/warranty" className="txt-regular">
                    Warranty
                  </Link>
                  <Link to="/e-bicycle-insurance" className="txt-regular">
                    Insurance
                  </Link>
                  <Link to="/emi" className="txt-regular">
                    EMI
                  </Link>
                  <Link to="/know-your-ecycle" className="txt-regular">
                    Know Your E-Cycle
                  </Link>
                  <a
                    href="/find-e-bicycle-store"
                    className="text-decoration-none "
                  >
                    Find Store
                  </a>
                  <Link to="/service" className="txt-regular">
                    Service
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-3">
              <div className=" ">
                <div
                  className="d-flex justify-content-around justify-content-md-start  mt-3  "
                  style={{ gap: "3em" }}
                >
                  <div className="">
                    <a
                      href="https://www.facebook.com/EMotorad"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa-brands  social fa-facebook-f"></i>
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://twitter.com/e_motorad"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <Icon
                        icon="fa6-brands:x-twitter"
                        width="1.7em"
                        height="1.7em"
                        style={{ color: "#004dac" }}
                      />
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://www.instagram.com/e_motorad/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i className="fa-brands social fa-instagram"></i>
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://www.linkedin.com/company/emotorad/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa-brands social fa-linkedin-in"></i>
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://www.youtube.com/channel/UCBaDv3uoPiBPSVDZJHGCCWg"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa-brands social fa-youtube"></i>
                    </a>
                  </div>
                </div>
                <div className="row footer-bottom mt-4">
                  <div className="col-6">
                    <p className="">
                      Copyright © INKODOP TECHNOLOGIES PRIVATE LIMITED 2023 All
                      rights reserved.
                    </p>
                  </div>
                  <div className="col-6 ps-5 pe-5">
                    <div className="d-md-flex d-none" style={{ gap: "1.5em" }}>
                      <Link to="/privacy-policy" className="txt-regular">
                        Privacy Policy
                      </Link>{" "}
                      <Link to="/terms&condition" className="txt-regular">
                        Terms & Conditions
                      </Link>{" "}
                      <Link
                        to="/refund-and-cancellation-policy"
                        className="txt-regular"
                      >
                        Policy Refund & Cancellation Policy
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer
        className="pt-4 pe-3 ps-3 pe-lg-5 ps-lg-5  d-lg-none"
        style={{ fontFamily: "Metropolis-medium" }}
      >
        <div className="">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <img
                src={logo}
                style={{ width: "150px" }}
                alt=""
                className="mb-3"
              />
              <p className=" mt-lg-3   footer-desc">
                EMotorad (EM) is an electric vehicle company that strives to
                bring futuristic ebikes at an affordable price for adventure
                seekers, daily commuters, or casual riders.
              </p>
              <p className=" footer-desc">
                For General Enquires:{" "}
                <a href="tel:+918686050590" className="footer-links">
                  +91 8686050590
                </a>{" "}
                |{" "}
                <a
                  href="mailto:contactus@emotorad.com"
                  className="footer-links"
                >
                  contactus@emotorad.com
                </a>{" "}
                <br />
                <br />
                For Service Related Queries:{" "}
                <a href="tel:+918956248161" className="footer-links">
                  +91 8956248161
                </a>{" "}
                |{" "}
                <a href="mailto:service@emotorad.com" className="footer-links">
                  service@emotorad.com
                </a>{" "}
                <br />
                <br />
                For Partnership Queries:{" "}
                <a
                  href="mailto:enterprise@emotorad.in"
                  className="footer-links"
                >
                  enterprise@emotorad.in
                </a>{" "}
              </p>
              <div className="d-flex mt-3 footer-desc" style={{ gap: "1em" }}>
                <div>Address:</div>
                <div>
                  <a
                    href="https://maps.app.goo.gl/111GV3hCUtBgdxbdA"
                    className="footer-links"
                  >
                    At post Jambe, Taluka Mulshi, 159/2 Sangawade, Road, Pune,
                    Maharashtra 411033
                  </a>
                  <div className="mb-2" />
                  <a
                    href="https://maps.app.goo.gl/JM5ACkiXhWqYofku9"
                    className="footer-links"
                  >
                    EMotorad, Ground Floor, North Block, Smartworks, Vaishnavi
                    Tech Park, Sy. No. 16/1 and 17/2, Bellandur Gate, Sarjapur
                    Main Road, Ambalipura, Bengaluru, Karnataka, 560102
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-6 footer-links">
              <div className="col-lg-6 footer-links">
                <div
                  className="d-flex justify-content-between align-items-center "
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample2"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <h5 className="mt-3 dropdown-footer-heading">Range</h5>
                  <span
                    className="iconify"
                    data-icon="ep:arrow-down"
                    style={{ color: "black" }}
                    data-width="17"
                    data-height="17"
                  ></span>
                </div>
                <div
                  className="collapse rounded-3 border-r-5 dropdown-footer-options"
                  id="collapseExample2"
                >
                  <Link to="/bikes#x-factor" className="txt-regular">
                    X-Factor
                  </Link>
                  <Link to="/bikes#desire" className="txt-regular">
                    Desire
                  </Link>
                </div>
                <hr className="mb-1" />
              </div>
              <div
                className="d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample1"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <h5 className="mt-3 dropdown-heading">About</h5>
                <span
                  className="iconify"
                  data-icon="ep:arrow-down"
                  style={{ color: "black" }}
                  data-width="17"
                  data-height="17"
                ></span>
              </div>
              <div
                className="collapse rounded-3 border-r-5 dropdown-footer-options"
                id="collapseExample1"
              >
                <Link to="/ourstory" className="">
                  Our Story
                </Link>
                <Link to="/contact-us" className="">
                  Contact Us
                </Link>
                <Link to="/blogs" className="">
                  Blogs
                </Link>
                <Link to="/news" className="">
                  News
                </Link>
                <Link to="/mahendra-singh-dhoni-electric-cycles" className="">
                  Celebrity X EM
                </Link>
                <Link to="/emi" className="txt-regular">
                  Find Store
                </Link>
                <a
                  href="https://www.dynem.in/"
                  className="text-decoration-none"
                >
                  Manufacturing
                </a>
              </div>

              <hr className="mb-1" />
              <div
                className="d-flex justify-content-between align-items-center "
                data-bs-toggle="collapse"
                data-bs-target="#collapsepopulararticles"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <h5 className="mt-3 dropdown-footer-heading">
                  Popular Articles
                </h5>
                <span
                  className="iconify"
                  data-icon="ep:arrow-down"
                  style={{ color: "black" }}
                  data-width="17"
                  data-height="17"
                ></span>
              </div>
              <div
                className="collapse rounded-3 border-r-5 dropdown-footer-options"
                id="collapsepopulararticles"
              >
                <Link
                  to="/blogs/demystifying-fat-tyre-ebikes"
                  className="txt-regular"
                >
                  Decoding Fat Tyre E-cycle
                </Link>
                <Link
                  to="/blogs/best-electric-cycles-under-2000"
                  className="txt-regular"
                >
                  Best electric cycle under 20000
                </Link>
                <Link
                  to="/blogs/common-misconceptions-about-electric-eikes"
                  className="txt-regular"
                >
                  Common e-Bike Misconceptions
                </Link>
                <h5 className="color-dark mb-3 mt-3">Recent Articles</h5>{" "}
                {blogPosts &&
                  blogPosts.map((blog) => (
                    <Link
                      to={`/blogs/${blog.slug}`}
                      className="txt-regular"
                      key={blog.id}
                    >
                      {blog.title}
                    </Link>
                  ))}
              </div>
              <hr className="mb-1" />
            </div>

            <div className="col-lg-6 footer-links">
              <div
                className="d-flex justify-content-between align-items-center "
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample3"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <h5 className="mt-3 dropdown-footer-heading">Assurance</h5>
                <span
                  className="iconify"
                  data-icon="ep:arrow-down"
                  style={{ color: "black" }}
                  data-width="17"
                  data-height="17"
                ></span>
              </div>
              <div
                className="collapse rounded-3 border-r-5 dropdown-footer-options"
                id="collapseExample3"
              >
                <Link to="/warranty" className="txt-regular">
                  Warranty
                </Link>
                <Link to="/e-bicycle-insurance" className="txt-regular">
                  Insurance
                </Link>
                <Link to="/emi" className="txt-regular">
                  EMI
                </Link>
                <Link to="/know-your-ecycle" className="txt-regular">
                  Know Your E-Cycle
                </Link>
                <Link to="/service" className="txt-regular">
                  Service
                </Link>
              </div>
              <hr className="mb-1" />
            </div>

            <div className="col-lg-6 footer-links">
              <div
                className="d-flex justify-content-between align-items-center "
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample4"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <h5 className="mt-3 dropdown-footer-heading">Legal</h5>
                <span
                  className="iconify"
                  data-icon="ep:arrow-down"
                  style={{ color: "black" }}
                  data-width="17"
                  data-height="17"
                ></span>
              </div>
              <div
                className="collapse rounded-3 border-r-5 dropdown-footer-options"
                id="collapseExample4"
              >
                <Link to="/privacy-policy" className="txt-regular">
                  Privacy Policy
                </Link>{" "}
                <Link to="/terms&condition" className="txt-regular">
                  Terms & Conditions
                </Link>{" "}
                <Link
                  to="/refund-and-cancellation-policy"
                  className="txt-regular"
                >
                  Policy Refund & Cancellation Policy
                </Link>{" "}
              </div>
              <hr className="mb-1" />
            </div>
            <div className="col-lg-12 mt-3">
              <div className=" ">
                <div
                  className="d-flex justify-content-around justify-content-md-start  mt-3  "
                  style={{ gap: "3em" }}
                >
                  <div className="">
                    <a
                      href="https://www.facebook.com/EMotorad"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa-brands  social fa-facebook-f"></i>
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://twitter.com/e_motorad"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa-brands social fa-twitter"></i>
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://www.instagram.com/e_motorad/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i className="fa-brands social fa-instagram"></i>
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://www.linkedin.com/company/emotorad/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa-brands social fa-linkedin-in"></i>
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://www.youtube.com/channel/UCBaDv3uoPiBPSVDZJHGCCWg"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa-brands social fa-youtube"></i>
                    </a>
                  </div>
                </div>

                <div className="footer-bottom mt-4">
                  <div className="text-center">
                    <p className="">
                      Copyright © INKODOP TECHNOLOGIES PRIVATE LIMITED 2023 All
                      rights reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
