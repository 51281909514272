import React, { useState, useEffect } from "react";
import "../CSS/offerPopup.css";
import axios from "axios";

const NotifyMe = ({ setShowModel, url, bike }) => {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const submit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/form/notify",
        {
          email,
          phoneNumber,
          bike,
        },
        config
      );
      if (data) {
        setShowModel(false);
      }
    } catch (error) {
      setShowModel(false);
    }
  };

  return (
    <div
      className=" position-fixed"
      style={{
        height: "100vh",
        width: "100%",
        zIndex: "100",
        top: "0%",
        backgroundColor: "rgba(255,255,255,0.4)",
      }}
    >
      <div className="container p-0   notify-me">
        <i
          className="fa-solid fa-xmark notify-me-close-btn  "
          style={{}}
          onClick={() => {
            setShowModel(false);
          }}
        ></i>
        <div className="row ">
          <div className="col-lg-6 d-none    p-0 d-lg-block ">
            <img src={url} className="img-fluid h-100 w-100" alt="" />
          </div>
          <div
            className="col-lg-6 p-4 ps-3 pe-3 p-lg-0 pt-5  align-items-center d-flex"
            style={{
              backgroundColor: "#1d1d1d",
            }}
          >
            <form onSubmit={submit} className=" mx-auto notify-me-content">
              <h3 className=" text-lg-center ">
                <span>Good things</span> come to those who wait!
              </h3>
              <p className="mt-3">
                We'll notify you as soon as the bikes are back in stock.
              </p>
              <div className="mt-lg-5 ps-3 pe-3 mt-5" style={{}}>
                <input
                  type="email"
                  name=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mx-auto"
                  placeholder="Email"
                  id=""
                />
                <p
                  className="mt-4 mb-4 text-center"
                  style={{
                    fontSize: "0.9em",
                    color: "lightgrey",
                  }}
                >
                  or
                </p>
                <input
                  type="number"
                  name=""
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value.slice(0, 10))}
                  className="mx-auto"
                  placeholder="Phone / WhatsApp Number"
                  style={{}}
                  id=""
                />
              </div>
              <div className="mt-lg-5 ps-3 pe-3 mt-5">
                <button
                  disabled={email || phoneNumber ? false : true}
                  type="submit"
                  className="btn rounded-0 w-100 mx-auto d-block btn-primary p-2"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotifyMe;
