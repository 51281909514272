import React, {
  useState,
  // useCallback,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductBySubCategory } from "../Actions/bikeActions.js";
import { useParams } from "react-router-dom";
// import logo from "../images/logo1.png"
// import { addToCart } from "../Actions/cartActions"
import Navbar from "../Components/Navbar.js";
import { useNavigate, Link } from "react-router-dom";
// import { getloginUserInfo } from "../Actions/userAction"
import Footer from "../Components/Footer.js";
import "../CSS/product.css";
import "../CSS/accessories.css";
import WhatsAppBot from "../Components/WhatsAppBot.js";
// import Fade from "react-reveal/Fade"
import Meta from "../Components/Meta.js";
import CallBtn from "../Components/CallBtn.js";
const AccessoriesCategoryScreen = () => {
  // const navigate = useNavigate()
  const dispatch = useDispatch();
  const { name } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productBySubCategory = useSelector(
    (state) => state.productBySubCategory
  );
  const { products } = productBySubCategory;

  // const [model, setModel] = useState(0)

  useEffect(() => {
    if (name) {
      dispatch(getProductBySubCategory(name));
    }
  }, [dispatch, name, userInfo]);

  const currencyFormat = (num) => {
    return (
      "Rs" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  };

  // const addCart = () => {
  // 	dispatch(addToCart(productInfo._id, model, 1))
  // 	navigate("/cart")
  // }
  const meta = {
    helmet: {
      title: "Bicycle Helmet | Buy E Helmet For Your Safety",
      description:
        "Ensure your safety on every ride with our premium Bicycle Helmets. Shop now for top-quality helmets designed for maximum protection and comfort.",
      canonicalTag: "https://www.emotorad.com/accessories/category/combo",
    },
    headlight: {
      title: "Buy Bicycle Headlight | EMotorad Headlights",
      description:
        "Enhance your night cycling safety with EMotorad's premium bicycle headlights. Buy now for brighter and clearer visibility on your rides.",
      canonicalTag: "https://www.emotorad.com/accessories/category/combo",
    },
    lock: {
      title: "Buy Bicycle Lock at Best Price | EMotorad Locks",
      description:
        "Get the best price on EMotorad bicycle locks! Strong, secure, and easy-to-use locks to protect your bike",
      canonicalTag: "https://www.emotorad.com/accessories/category/combo",
    },
    pump: {
      title: "Cycle Pump | Buy EMotorad Bicycle Air Pump at Best Prices",
      description:
        "Looking for a dependable cycle pump? Choose EMotorad for a top-notch air pump that won't let you down.",
      canonicalTag: "https://www.emotorad.com/accessories/category/combo",
    },
    bag: {
      title: "Buy Bicycle Bag at EMotorad",
      description:
        "Find the ideal bicycle bag that suits your style and needs at EMotorad. Our collection promises quality, durability, and sleek designs",
      canonicalTag: "https://www.emotorad.com/accessories/category/combo",
    },
    combo: {
      title: "Buy Bicycle Accessories Combo at Best Prices",
      description:
        "Find your perfect cycling companion with our exclusive bicycle accessories combo deals at fantastic prices",
      canonicalTag: "https://www.emotorad.com/accessories/category/combo",
    },
  };

  const metaData = {};

  for (const key in meta) {
    if (meta.hasOwnProperty(key) && key == name) {
      metaData["range"] = meta[key];
    }
  }
  return (
    <>
      {products && (
        <>
          {" "}
          <Meta
            title={metaData.range.title}
            description={metaData.range.description}
            cannonicalTag={metaData.range.canonicalTag}
          />
          {/* <WhatsAppBot /> */}
          <CallBtn />
          <Navbar />
          <div className="container-fluid  mt-0 p-lg-4">
            <div className="row align-items-center">
              <div className=" col-md-3 mt-2 col-12">
                <h4 className="mx-auto category-heading-1">
                  {name.toUpperCase()}
                </h4>{" "}
              </div>

              {products &&
                products.map((product, i) => (
                  <div key={i} className="  col-md-3 mt-2  p-md-3  col-6">
                    <Link
                      to={`/accessories/${product.urlName}`}
                      className="text-decoration-none text-dark"
                    >
                      <div className=" p-lg-3 p-2  acc-card">
                        <img
                          src={product.DisplayImg || product.cartImages[0]}
                          alt=""
                          className="img-fluid w-75 d-block mx-auto "
                        />
                      </div>
                      <div className="d-md-flex justify-content-between">
                        <p className="acc-name">{product.name}</p>
                        <div
                          className="d-flex"
                          style={{
                            gap: "1em",
                          }}
                        >
                          {product.slashPrice > 0 && (
                            <p
                              className="acc-price"
                              style={{
                                textDecoration: "line-through",
                              }}
                            >
                              {currencyFormat(product.slashPrice)}
                            </p>
                          )}

                          <p className="acc-price">
                            {currencyFormat(product.price)}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
          <Footer />{" "}
        </>
      )}
    </>
  );
};

export default AccessoriesCategoryScreen;
