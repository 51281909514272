import React, { useEffect } from "react"
import {
	useNavigate,
	Link,
} from "react-router-dom"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import { getloginUserInfo } from "../Actions/userAction"
import "../CSS/profile.css"

const ProfileScreen = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const userLogin = useSelector(
		(state) => state.userLogin,
	)
	const { loginInfo } = userLogin

	const getUserInfo = useSelector(
		(state) => state.getUserInfo,
	)
	const { userInfo: userDetails } = getUserInfo

	useEffect(() => {
		if (!loginInfo._id) {
			navigate("/login")
		}
	}, [loginInfo])

	useEffect(() => {
		if (loginInfo) {
			dispatch(getloginUserInfo(loginInfo._id))
		}
	}, [])

	return (
		<div className='container '>
			<div className='mt-lg-5 mt-3 align-items-center d-flex'>
				<i
					style={{ cursor: "pointer" }}
					className='profile-back fa-solid fa-arrow-left me-2 '
					onClick={() => navigate(-1)}></i>
				<p className=' font-mon profile-border-heading '>
					PROFILE
				</p>
			</div>
			<div
				className='col-12 mt-lg-5 col-lg-4 dropdown-content-list'
				style={{ borderRadius: "5px" }}>
				<div className='p-2 mt-4 '>
					<div className='bg  p-3 rounded-2 d-flex align-items-center'>
						<div className='me-3'>
							<img
								className='rounded-circle'
								src={
									userDetails &&
									userDetails.profilePicture
								}
								width={50}
								referrerPolicy='no-referrer'
								alt=''
							/>
						</div>
						<div>
							<p className='m-0 font-bold'>
								{userDetails && userDetails.name}
							</p>
						</div>
					</div>
					<div className=' mt-3 '>
						<Link
							className='text-decoration-none'
							to='/profile/orders'>
							<div className='bg  d-flex p-3 text-dark align-items-center rounded-2'>
								<i
									className='fa-solid mt-1 fa-box-open '
									style={{
										fontSize: "1.1em",
									}}></i>
								<p className='m-0 ms-4'>
									My Orders
								</p>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProfileScreen
