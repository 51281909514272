export const bikeHighlights = [
  {
    name: "T-Rex Pro",
    url: "/bikes/t-rex-pro",
    knowMoreUrl: "/t-rex-family/t-rex-pro",
    description: "Powerful, smart, and ready for any terrain at all times.",
    price: "From ₹52,999",
    images: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/bikes/trex+pro+blue.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/bikes/trex+pro+green.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/bikes/trex+pro+purple.png",
    ],
    videos: [
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+PRO/TREX+PRO+BLUE+(mobile).mp4",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+PRO/TREX+PRO+BLUE+(website).mp4",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+PRO/TREX+PRO+Green+(Mobile).mp4",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+PRO/TREX+PRO+Green+(website).mp4",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+PRO/TREX+PRO+PURPLE+(mobile).mp4",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+PRO/TREX+PRO+PURPLE+(website).mp4",
      ],
    ],
    teaser: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trailer/Mobile+view++(1).mp4",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trailer/Trex+pro+desktop+d.mp4",
    ],
    variants: ['27.5"', '29"'],
    models: [
      { color: "blue", colorName: "Ocean blue" },
      { color: "green", colorName: "Spring green" },
      { color: "purple", colorName: "Deep purple" },
    ],
    specs: [
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/frame+(1).png",
        name: "FRAME",
        description: "Aluminium Alloy 6061",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/range.png",
        name: "RANGE",
        description: "70 KM on PAS | 45 KM on Throttle",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Display.png",
        name: "DISPLAY",
        description: "Cluster i13 Handlebar Integrated Display",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Frontlight.png",
        name: "Front Light",
        description: "Handlebar integrated lights with high/low beam options",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/taillight.png",
        name: "taillight",
        description: "Comes with inbuilt indicator and brake light option",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Tyre.png",
        name: "TYRE",
        description: '27.5" | 29" x  2.25" Nylon Tyres',
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/battery+(1).png",
        name: "BATTERY",
        description: "13Ah",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Riders+weight.png",
        name: "Rider Weight",
        description: "Up to 110 KG",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Frame+warrenty.png",
        name: "Frame Warranty",
        description: "Lifetime",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/charging+time.png",
        name: "Charging Time",
        description: "5-7 hours",
      },
    ],
  },
  {
    name: "T-Rex+",
    url: "/bikes/t-rex+",
    knowMoreUrl: "/t-rex-family/t-rex-v3",
    description: "With power and versatility, built for the adventurer in you.",
    price: "From ₹44,999",
    images: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/bikes/trex+plus+red+and+black.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/bikes/trex+plus+green.png",
    ],
    teaser: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trailer/Mobile+view++(1).mp4",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trailer/Trex+pro+desktop+d.mp4",
    ],
    videos: [
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+PLUS/TREX+PLUS+(Mobile).mp4",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+PLUS/TREX+PLUS+(website).mp4",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+PLUS/TREX+PLUS+Green+(Mobile).mp4",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+PLUS/TREX+PLUS+Green+(website).mp4",
      ],
    ],
    variants: ['26"', '27.5"', '29"'],
    models: [
      {
        color: "linear-gradient(to bottom left, #df1f26  50%, #262729 50%)",
        colorName: "Crimson Black",
      },
      { color: "#AEAE65", colorName: "Olive Fade" },
    ],
    specs: [
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/frame+(1).png",
        name: "FRAME",
        description: "Aluminium Alloy 6061",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/range.png",
        name: "RANGE",
        description: "50 KM on PAS | 40 Km on Throttle",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Display.png",
        name: "DISPLAY",
        description: "Cluster i8 Stem Integrated Display",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Frontlight.png",
        name: "Front Light",
        description: "Front light with integrated horn",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/taillight.png",
        name: "taillight",
        description: "Yes",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Tyre.png",
        name: "TYRE",
        description: '26" | 27.5" | 29" x  2.25" Nylon Tyres',
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/battery+(1).png",
        name: "BATTERY",
        description: "10.2AH",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Riders+weight.png",
        name: "Rider Weight",
        description: "Up to 110 KG",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Frame+warrenty.png",
        name: "Frame Warranty",
        description: "Lifetime",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/charging+time.png",
        name: "Charging Time",
        description: "4-5 hours",
      },
    ],
  },
  {
    name: "T-Rex Air",
    url: "/bikes/t-rex-air",
    knowMoreUrl: "/t-rex-family/t-rex-air",
    description: "Lightweight freedom, designed for effortless rides.",
    price: "From ₹34,999",
    images: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/bikes/trex+air+red.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/bikes/trex+air+yellow.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/bikes/trex+air+bottle+green.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/bikes/trex+air+orange.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/bikes/trex+air+green_.png",
    ],
    teaser: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trailer/Mobile+view++(1).mp4",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Trailer/Trex+pro+desktop+d.mp4",
    ],
    videos: [
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+AIR/TREX+AIR+RED+(Mobile).mp4",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+AIR/TREX+AIR+RED+(Website).mp4",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+AIR/TREX+AIR+Yellow+(Mobile).mp4",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+AIR/TREX+AIR+Yellow+(Website).mp4",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+AIR/TREX+AIR+DARK+GREEN+(Mobile).mp4",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+AIR/TREX+AIR+DARK+GREEN+(Website).mp4",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+AIR/TREX+AIR+ORAGE+(Mobile).mp4",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+AIR/TREX+AIR+ORAGE+(Website).mp4",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+AIR/TREX+AIR+GREEN+(Mobile).mp4",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/360+TURNTABLE/TREX+AIR/TREX+AIR+GREEN+(Website).mp4",
      ],
    ],
    variants: ['27.5"', '29"'],
    models: [
      { color: "#CC001B", colorName: "Coral Cruise" },
      { color: "#FFC100", colorName: "Sundown Yellow" },
      { color: "#69796F", colorName: "Forest Fury" },
      { color: "#F58815", colorName: "Orange Blaze" },
      { color: "#42B32A", colorName: "Tropical Green" },
    ],
    specs: [
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/frame+(1).png",
        name: "FRAME",
        description: "High Tensile Steel Frame",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/range.png",
        name: "RANGE",
        description: "50 KM on PAS | 40 Km on Throttle",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Display.png",
        name: "DISPLAY",
        description: "Cluster C5 Display",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Frontlight.png",
        name: "Front Light",
        description: "Front light with integrated horn",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/taillight.png",
        name: "taillight",
        description: "No",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Tyre.png",
        name: "TYRE",
        description: '27.5" | 29" x  2.4" Nylon Tyres',
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/battery+(1).png",
        name: "BATTERY",
        description: "10.2AH",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Riders+weight.png",
        name: "Rider Weight",
        description: "Up to 110 KG",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/Frame+warrenty.png",
        name: "Frame Warranty",
        description: "5 Years",
      },
      {
        icon: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/icons/charging+time.png",
        name: "Charging Time",
        description: "4-5 hours",
      },
    ],
  },
];
