import React from "react";
import logo from "../images/EM-LOGO_Blue.png";
function QuizNavbar() {
  return (
    <>
      {/* desktop navbar */}
      <div
        className="position-sticky navigation w-100"
        style={{
          zIndex: "99",
          top: "0%",
          borderBlockEnd: "1px solid #939393",
        }}
      >
        <div
          className="m-0 p-0 quiz-nav p-3 "
          style={{ backgroundColor: "#ffffff" }}
        >
          <div className="d-flex container  justify-content-between align-items-center">
            <div className="col-8" style={{ marginLeft: "1.3em" }}>
                <img
                  src={logo}
                  className="img-fluid"
                  style={{ width: "200px" }}
                  alt=""
                />
            </div>
            <div className="d-flex col-4 nav-items justify-content-center">
              {" "}
              <div className="">
                <h2 className="m-0 quiz-heading" style={{ cursor: "pointer", }}>
                  THE VISIONARIES
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuizNavbar;
