import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  CONTACT_US_RESET,
  EMI_RESET,
  INSURANCE_RESET,
  PARTNER_WITH_US_RESET,
  WARRANTY_RESET,
} from "../Constants/formConstants";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import "../CSS/thankyou.css";

const CheckInSuccessScreen = () => {
  const location = useLocation();

  const { bibNumber } = location.state || {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: CONTACT_US_RESET,
    });
    dispatch({ type: EMI_RESET });
    dispatch({ type: PARTNER_WITH_US_RESET });
    dispatch({ type: WARRANTY_RESET });

    dispatch({
      type: INSURANCE_RESET,
    });
  }, []);

  return (
    <div className="container d-flex justify-content-center align-items-center">
      <div className="thankyou-div">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Icon
          icon="ion:bicycle"
          width="5em"
          height="5em"
          style={{ color: "#14b317" }}
        />
        <p className="thankyou font-mon ">You're checked in successfully!</p>
        <br></br>
        {bibNumber && (
          <p style={{ color: "#004dac", fontSize: "1.1em" }}>
            Your BIB number is: {bibNumber}
          </p>
        )}{" "}
      </div>
    </div>
  );
};

export default CheckInSuccessScreen;
