import React from 'react';
import '../CSS/appPrivacyPolicy.css'; // Assuming this is where your CSS file is located
import logo from '../images/logo.png'; // Assuming this is your logo import

const AppPrivacyPolicy = () => {
  return (
    <div className="app-container">
      <header className="app-header">
        <img src={logo} alt="Company Logo" className="app-logo" />
        <h3 className="app-title">INKODOP TECHNOLOGIES PRIVATE LIMITED</h3>
      </header>

      <div className="policy-content">
        <div className="policy-meta">
          <span className="bold">Last Updated:</span> 12th June, 2024
        </div>

        <p className="policy-section">
          About Inkodop Technologies Private Limited ( “We” and/or “Us” and/or “Company” ) is engaged in the business of providing services across the electric vehicles segment, including manufacture and sales of electric vehicles. The Company has its registered office at 159/2, Grampanchayat Milkat no 2896, Village Jambe, Taluka Munshi, Pune, Maharastra 411033. The Company has a presence in foreign countries as well.
        </p>

        <p className="policy-section">
          The Company is concerned about the privacy of its users (“User(s)” and/or “You” and/or “Your”) while using the Emotorad eBikes and accessing the ‘Emotorad’ application (“Application”) whether through a mobile phone.
        </p>

        <p className="policy-section">
          Please read this Privacy Policy (“Policy”) carefully before using the Application. Your consent to use of the Application or the services provided by the Application shall signify Your acceptance of this Policy and Your agreement to be legally bound by the same.
        </p>

        <p className="policy-section">
          If You do not agree with the terms of this Policy, do not use the Application or avail any of the services offered through the Application.
        </p>

        <section className="policy-section">
          <h4>1. About the Policy</h4>
          <p>
            When You register on the Application, We may seek and collect certain personal information for Your use of the Application. You consent to the collection, use, and disclosure of the information in accordance with this Policy. Therefore, We request You to read this Policy carefully before using the Application.
          </p>
          <p>
            This privacy policy is applicable to the Emotorad app (hereinafter referred to as "Application") for mobile devices, which was developed by INKODOP TECHNOLOGIES PRIVATE LIMITED as a Freemium service. This service is provided "AS IS".
          </p>
          <p>
            Please note that this Policy will give You a general understanding of:
          </p>
          <ul>
            <li>The circumstances under which We collect and process Your Personal Data;</li>
            <li>The types of Personal Data We collect;</li>
            <li>The reasons for collecting Your Personal Data;</li>
            <li>How We handle Your Personal Data;</li>
            <li>Distribution of responsibility for the Processing of Personal Data between various legal entities of the Company, and</li>
            <li>Contact details for Us so You can receive information about and claim Your rights in relation to our processing of Your Personal Data.</li>
          </ul>
        </section>

        <section className="policy-section">
          <h4>2. Collection, processing and use of personal data</h4>
          <h5>2.1 Principles</h5>
          <p>
            Personal data means any information relating to an identified or identifiable natural person, such as name, telephone number, e-mail address that expresses the identity of a person. We collect, process and use personal data only if there is a legal basis for doing so.
          </p>
          <h5>2.2 Categories of data processed</h5>
          <p>
            The following categories of data are processed:
          </p>
          <ul>
            <li>user-related data (e.g. name, telephone, e-mail)</li>
            <li>device-related data (e.g. model of the eBike, frame number, eBike settings, statistics and activity data)</li>
            <li>service-related data (e.g. GPS data, activity)</li>
            <li>device type (e.g. IOS, Android, operating system)</li>
            <li>Log files (e.g. IP address)</li>
          </ul>

          <h5>2.3 Processing purposes and legal bases</h5>


          <h6>2.3.1 Provision of services</h6>
          <p>
            Via our Application, information about your eBike is presented and visualised for you in a way that is individual and tailored to your needs. Among other things, you will receive an overview of the components used as well as further technical information about your eBike.
          </p>
          <p>
            To make use of our services, your user account must be linked to an eBike. The link is established by connecting to your user account via Bluetooth. To establish such a Bluetooth connection to devices with the Android operating systems, Android requires access to your position data (e.g. GPS data) from us.
          </p>
          <p>
            For this purpose, we process your position data to establish a Bluetooth connection with an Android device.
          </p>
          <p>
            When linking to your user account, the following information of the hardware and software component of your eBike ("device-related data") is processed, among others:
          </p>
          <ul>
            <li>Model of the eBike</li>
            <li>Date of manufacture and purchase</li>
            <li>Battery status</li>
            <li>eBike settings (e.g., language, time, time zone)</li>
            <li>Statistics (e.g. DTE, SOC)</li>
            <li>Activity data (e.g., total trip time, completed and upcoming service intervals)</li>
          </ul>

          <h6>2.3.1.1 Home screen</h6>
          <p>
            The home screen gives you a quick overview of all the important information such as battery status, remaining range, connection state of your eBike. Your eBike model is also displayed on the Home Screen. For the display of various information about your eBike on the home screen, the following device-related data, for example, is processed: eBike model, colour, battery status
          </p>

          <h6>2.3.1.2 Route planning, navigation and activity tracking</h6>
          <p>
            You can use our location-based services for calculating and displaying routes. The trip duration of the route, if available, is calculated based on the riding behaviour of the preceding eBike rides. In addition, every activity with your eBike is recorded and visualised in the app (Ride History). view of how far you can get with the current engine support.
          </p>
          <p>
            The following data is processed when you use the services ("Service-related data"):
          </p>
          <ul>
            <li>Position data (e.g. GPS data)</li>
            <li>Start and destination address, search history.</li>
            <li>Rides completed</li>
            <li>Activities (e.g. max. speed, total trip time, elevation, avg. speed, calories burnt, rider effort distribution)</li>
          </ul>
          <p>
            As part of the activity tracking, you have the option to allow the activity recording to run in the background. To do this, you must set the location access in the background to "Always allow".
          </p>
          <p>
            You can change the location sharing in the background at any time in the settings.
          </p>
          <p>
            Regarding the tracking of mobile devices (GSM tracking), we refer to the privacy policy of your mobile provider as well as the app store operators for devices with the Android and iOS operating systems. Please note that most mobile devices allow you to enable or disable the use of location services in the device's settings menu.
          </p>

          <h6> 2.3.1.3 Activating an already registered eBike for a new user account</h6>
          <p>
            To make full use of our services, your user account must be linked to an eBike. Each eBike can be assigned to just one user account at a time.
          </p>
          <p>
            If your eBike is already assigned to another user account (e.g. if you have purchased your eBike privately because the previous owner has forgotten to remove the eBike from his user account after selling it), you will receive an error message when you try to link the eBike to your user account. The current link between your eBike and another user account must first be reset so that you can link the eBike with your own user account.
          </p>
          <p>
            For this purpose, you must contact the user currently linked to your eBike in order to have the eBike removed from the currently linked user account.
          </p>

          <h6>2.3.2 eBike software updates (updates over-the-air)</h6>
          <p>
            To ensure your eBike software is up-to-date and functional, the eBike must be linked to your user account to install updates.
          </p>
          <p>
            This is the only way we can guarantee that the updates are assigned to the correct eBike model and that you receive all the necessary information and instructions about the update process (e.g. updating the batteries and the software), as well as any specific features and benefits that come with the update.
          </p>

          <h6><span className='point'>2.3.2.1</span> Application updates</h6>
          <p>
            For updating the Application, we collect data on the respective update. You can install these updates individually. In doing so, we also show you the innovations of the respective update. Some updates are not important for you. To install, you have the option to decline. The app updates that are necessary for the use of the service are not visible.
          </p>

          <h6>2.3.3 Customer service (support)</h6>
          <p>
            If you contact us by e-mail, we will process the personal data provided by you in order to answer your questions and requests.
          </p>

          <h5>2.4 Duration of storage, time limits</h5>
          <p>
            After the purpose of processing has been achieved, we delete the personal data. Data is also deleted automatically by when you uninstall the app.
          </p>
        </section>

        <section className="policy-section">
          <h4>3. Children</h4>
          <p>
            The Company does not use the Application to knowingly solicit data from or market to children under the age of 10.
          </p>
        </section>

        <section className="policy-section">
          <h4>4. Third Party Access</h4>
          <p>
            Only aggregated, anonymized data is periodically transmitted to external services to aid the Company in improving the Application and their service. The Company may share your information with third parties in the ways that are described in this privacy statement.
          </p>
          <p>
            Please note that the Application utilizes third-party services that have their own Privacy Policy about handling data. Below are the links to the Privacy Policy of the third-party service providers used by the Application:
          </p>
          <ul>
            <li><a href="https://policies.google.com/terms">Google Play Services</a></li>
            <li><a href="https://amplitude.com/privacy">Amplitude</a></li>
          </ul>
          <p>
            We may disclose User Provided and Automatically Collected Information: as required by law, such as to comply with a subpoena, or similar legal process; when they believe in good faith that disclosure is necessary to protect their rights, protect your safety or the safety of others, investigate fraud, or respond to a government request; with their trusted services providers who work on their behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.
          </p>
        </section>

        <section className="policy-section">
          <h4>5. Sharing/Transfer of Personal Data</h4>
          <p>
            We shall exercise reasonable care to keep Your information confidential to the maximum possible extent. We may limit disclosure of the user information to our entities, joint ventures, agents, sellers or third parties for the purposes of the services provided herein or for any other marketing related activity undertaken by or on behalf of our entities and/or its joint ventures on a need-to-know basis.
          </p>
          <p>
            As You are aware, We might share Your Personal Information with our partners/third party service providers in order to improve our service. You explicitly provide your consent for such disclosure of Your Personal Information to third parties, including without limitation for the following purposes:
          </p>
          <p>
            We make sure that they are as committed as We are to protecting Your Data and Information.
          </p>
        </section>

        <section className="policy-section">
          <h4>5.1 Corporate Affiliates</h4>
          <p>
            We may share information with other group companies and transfer it to a third party in case of a merger or reorganization or related companies providing services for enhancement of customer experience.
          </p>
        </section>

        <section className="policy-section">
          <h4>5.2 Compliance and Security</h4>
          <p>
            It may be necessary for Us to disclose Your Personal Data to governmental and public authorities for reasons of national security, law enforcement or other issues of public importance. Where legally permitted We will inform You ahead of such transfer.
          </p>
        </section>

        <section className="policy-section">
          <h4>6. International transfers</h4>
          <p>
            We may transfer your Personal Data to recipients in countries outside India that may have differing data protection laws. In those instances, We will ensure that appropriate safeguards are in place for that transfer and storage as required by applicable law. We will ensure there is a legal ground for the transfer in accordance with applicable data protection legislation.
          </p>
        </section>

        <section className="policy-section">
          <h4>7. Retention</h4>
          <p>
            We will only retain your Personal Data for as long as it is necessary to fulfill the purposes outlined in this Policy or the purposes of which You have otherwise been informed. This means that when You have consented to our processing of Your Personal Data, We will retain the data for as long as the customer relationship lasts (and, where applicable, until the expiry of the warranty period) or until You withdraw Your consent. If You have revoked Your consent, We may nevertheless retain certain Personal Data for the period required in order for Us to meet our legal obligations and defend ourselves in legal disputes. We will redact or delete Your Sensitive Personal Data if you withdraw your consent, unless required to be retained by applicable law. If We have not received Your consent for Processing, the Personal Data will only be retained to the extent We are permitted to do so by law.
          </p>
        </section>

        <section className="policy-section">
          <h4>8. Deletion of your information</h4>
          <p>
            If You wish to have the Information that You have provided to us deleted, You can do so by writing to Us at <a href="mailto:developer@emotorad.com">developer@emotorad.com</a>. Please note that deletion of certain Information may lead to deletion of Your account with us and the use of the Application and/or the services/features provided therein. You understand that under certain circumstances, the request for the deletion of Information can be denied.
          </p>
        </section>

        <section className="policy-section">
          <h4>9. Phishing</h4>
          <p>
            Identity theft and the fraudulent practice currently known as "phishing" are of great concern to Us. Phishing is an attempt to acquire personal/sensitive information which the legitimate organization already has. Safeguarding information to help protect You from identity theft is a top priority. We do not and will not, at any time, request Your credit card information or national identification numbers in a non-secure or unsolicited e-mail or telephone communication. Please exercise caution while providing any such information.
          </p>
        </section>

        <section className="policy-section">
          <h4>10. Security</h4>
          <p>
            We use all necessary technical and organizational measures to ensure an adequate level of protection and to protect your data under our management, in particular against the risks of accidental or unlawful destruction, manipulation, loss, alteration or unauthorised disclosure or access. Our security measures are constantly being improved in line with technological developments.
          </p>
        </section>

        <section className="policy-section">
          <h4>11. Your Consent</h4>
          <p>
            By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by us.
          </p>
        </section>

        <section className="policy-section">
          <h4>12. Changes in Policy</h4>
          <p>
            We reserve the right to change this Policy at any time, so please visit the Application regularly to review our privacy statement. Please therefore note the current version of our privacy policy.
          </p>
        </section>

        <section className="policy-section">
          <h4>13. Contact Us</h4>
          <p>
            If you have any questions regarding privacy while using the Application, or have questions about the practices, please contact the Service Provider via email at <a href="mailto:developer@emotorad.com">developer@emotorad.com</a>
          </p>
        </section>
      </div>
    </div>
  );
}

export default AppPrivacyPolicy;
