import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import { emiCreate } from "../Actions/formActions";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../Components/Meta";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import "../CSS/emi.css";
import { getAllBikes } from "../Actions/bikeActions";
const EMIScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bike, setBike] = useState("X1");
  const EMI = useSelector((state) => state.EMI);
  const { emiStatus } = EMI;

  useEffect(() => {
    dispatch(getAllBikes());
  }, []);
  const allBikes = useSelector((state) => state.allBikes);
  const { bikes } = allBikes;

  useEffect(() => {
    const data = bikes?.[0]?.name;
    if (data && !bike) {
      setBike(data);
    }
  }, [bikes, bike]);

  const submitForm = (e) => {
    e.preventDefault();
    dispatch(emiCreate(name, email, phoneNumber, bike));
  };

  useEffect(() => {
    if (emiStatus) {
      navigate("/submitted");
    }
  }, [emiStatus]);

  return (
    <>
      <Meta
        title={" EMotorad | EMI"}
        description={
          "EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
        }
      />
      <Navbar />
      <div
        className="container-fluid  m-0 pb-0 "
        style={{
          background: "black",
        }}
      >
        <div className="row p-0 ">
          <div className="col-lg-5 p-0">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/etc-pages-banners/EMI.jpg"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-7 mt-lg-5 pt-lg-4 contactus-main">
            <div className=" mx-auto">
              <h1>EMI</h1>
              <p className="subline">
                Buying an EM e-bike is easier than ever. A simple message to our
                team and you will get customised quotes and No Cost EMIs.
              </p>
              <form action="" onSubmit={submitForm}>
                <div className="mt-4">
                  <div className="mb-3">
                    <label
                      htmlhtmlfor="exampleFormControlInput1"
                      className="form-label text-light "
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      value={name}
                      pattern="[A-Za-z\s]{2,}"
                      required
                      onChange={(e) => {
                        setName(e.target.value);
                        e.target.setCustomValidity("");
                      }}
                      style={{
                        textTransform: " capitalize",
                        padding: "0.7em",
                      }}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Name should have at least 3 characters and contain only alphabets."
                        )
                      }
                      id="exampleFormControlInput1"
                      placeholder=""
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlhtmlfor="exampleFormControlInput1"
                      className="form-label text-light "
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      style={{ padding: "0.7em" }}
                      id="exampleFormControlInput1"
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="row">
                    <div className="mb-lg-5 col-lg-6 col-12 mb-3">
                      <label
                        htmlhtmlfor="exampleFormControlInput1"
                        className="form-label text-light "
                      >
                        Select Bike
                      </label>
                      <select
                        onChange={(e) => setBike(e.target.value)}
                        className="form-select "
                        style={{
                          padding: "0.78em",
                        }}
                        aria-label="Default select example"
                      >
                          {bikes?.map((bike) => (
                            (bike.name !== 'Nighthawk' && bike.name !== 'Desert Eagle') &&
                            <option key={bike.name} value={bike.name}>
                              {bike.name}
                            </option>
                          ))}
                        {/* <option value='Desert Eagle'>
													Desert Eagle
												</option>
												<option value='NightHawk'>
													NightHawk
												</option> */}
                      </select>
                    </div>
                    <div className="mb-lg-5 col-lg-6 col-12 mb-3">

                    <label
                      htmlhtmlfor="exampleFormControlInput1"
                      className="form-label text-light "
                    >
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      style={{ padding: "0.7em" }}
                      id="exampleFormControlInput1"
                      placeholder=""
                      value={phoneNumber}
                      required
                      onChange={(e) => {
                        setPhoneNumber(e.target.value.slice(0, 11));
                        e.target.setCustomValidity("");
                      }}
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Enter a valid phone number")
                      }
                    />
                  </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary mx-auto d-block fw-bold mt-lg-4 "
                  >
                    Send Message
                  </button>
                </div>{" "}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="pt-5 d-lg-none"></div>
        <div className="margin-top-5">
          <h1 className=" fw-bold mt-4 pt-lg-3 down-border pb-lg-5 pb-3 ">
            EM's EMI PARTNERS
          </h1>
        </div>

        <div className="row mt-2 mb-3 d-none d-lg-block align-items-center">
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/EarlyS.png"
            className="col-2 "
            alt=""
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/AXI.png"
            className="col-2 "
            alt=""
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/HDFC-BA.png"
            className="col-2 "
            alt=""
          />
          <img
            src="	https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/bajaj-f.png"
            className="col-2 "
            alt=""
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/LIQ.png"
            className="col-2 "
            alt=""
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/ZES.png"
            className="col-2 "
            alt=""
          />
        </div>

        <div className=" d-flex d-lg-none">
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/HDFC-BA.png"
            className="col-6 "
            alt=""
          />
          <img
            src="	https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/bajaj-f.png"
            className="col-6 "
            alt=""
          />
        </div>

        <div className=" d-flex d-lg-none">
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/LIQ.png"
            className="col-6 "
            alt=""
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/ZES.png"
            className="col-6 "
            alt=""
          />
        </div>
        <div className=" d-flex d-lg-none">
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/EarlyS.png"
            className=" col-6"
            alt=""
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/emi-partner/AXI.png"
            className=" col-6 "
            alt=""
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EMIScreen;
