import axios from "axios"
import {
	ORDER_BY_ID_FAIL,
	ORDER_BY_ID_REQUEST,
	ORDER_BY_ID_SUCCESS,
	ORDER_CREATE_FAIL,
	ORDER_CREATE_REQUEST,
	ORDER_CREATE_SUCCESS,
} from "../Constants/orderConstants"

export const orderCreate =
	(
		userId,
		name,
		email,
		phoneNumber,
		addressLine1,
		addressLine2,
		city,
		state,
		pincode,
		amount,
		items,
		promoCode,
		gstin,
	) =>
	async (dispatch) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			}

			dispatch({ type: ORDER_CREATE_REQUEST })
			const { data } = await axios.post(
				"/api/order/create",
				{
					userId,
					name,
					email,
					phoneNumber,
					address: {
						addressLine1,
						addressLine2,
						city,
						state,
						pincode,
					},
					amount,
					items,
					promoCode,
					gstin,
				},
				config,
			)

			dispatch({
				type: ORDER_CREATE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: ORDER_CREATE_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getOrderDetailsById =
	(id) => async (dispatch) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			}

			dispatch({ type: ORDER_CREATE_REQUEST })
			const { data } = await axios.get(
				`/api/order/orders/${id}`,
				config,
			)

			dispatch({
				type: ORDER_CREATE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: ORDER_CREATE_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
