import React from "react";
import ordersuccess from "../images/order-confirm.png";
import { Link } from "react-router-dom";

const PreOrderThankyouScreen = () => {
  return (
    <div style={{ height: "90vh" }} className="container">
      <div className="row  h-100 align-items-center position-relative">
        <div className="col-12 col-lg-6 offset-lg-3">
          <h6
            className="text-center"
            style={{
              lineHeight: "1.5em",
              fontSize: "1.5em",
              fontWeight: "700",
            }}
          >
            {" "}
            We’re happy to let you know that we’ve received your pre-booking.{" "}
          </h6>

          <p
            className="mt-lg-4 mt-4 text-center"
            style={{
              lineHeight: "1.5em",
              fontSize: "0.9em",
              fontWeight: "200",
            }}
          >
            You will be receving a confirmation email with the order details.
          </p>
        </div>

        <Link className="d-block mx-auto text-center" to="/">
          Go back
        </Link>
      </div>
    </div>
  );
};

export default PreOrderThankyouScreen;
