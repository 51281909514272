import { PREBOOK_ADD_ITEM } from "../Constants/preBookConstants";

export const preBookReducer = (
  state = { preBookItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case PREBOOK_ADD_ITEM:
      const item = action.payload;
      return {
        ...state,
        preBookItems: [{ ...item }],
      };
    default:
      return state;
  }
};
