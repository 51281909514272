import React from "react"

const CallBtn = ({ bottom }) => {
	return (
		<>
			<a
				href='tel:+918686050590 '
				className='call-wa-chat-btn-fixed-wrap d-none d-lg-block'
				target='_blank'>
				<i
					className='fa-solid fa-phone text-light'
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						translate: "-50% -50%",
					}}></i>
			</a>
			<a
				href='tel:+918686050590 '
				style={{ bottom }}
				className='call-wa-chat-btn-fixed-wrap-mb  d-lg-none'
				target='_blank'>
				<i
					className='fa-solid fa-phone text-light fa-xs'
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						translate: "-50% -50%",
					}}></i>
			</a>
		</>
	)
}
CallBtn.defaultProps = {
	bottom: "15%",
}
export default CallBtn
