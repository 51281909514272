import {
	ORDER_CREATE_FAIL,
	ORDER_CREATE_REQUEST,
	ORDER_CREATE_SUCCESS,
	ORDER_CONFIRM_SUCCESS,
	ORDER_CONFIRM_FAIL,
	ORDER_BY_ID_REQUEST,
	ORDER_BY_ID_SUCCESS,
	ORDER_BY_ID_FAIL,
	ORDER_CONFIRM_RESET,
	ORDER_CREATE_RESET,
} from "../Constants/orderConstants"

export const orderCreateReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case ORDER_CREATE_REQUEST:
			return { loading: true }
		case ORDER_CREATE_SUCCESS:
			return {
				loading: false,
				orderResult: action.payload,
			}
		case ORDER_CREATE_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		case ORDER_CREATE_RESET:
			return {}
		default:
			return state
	}
}

export const orderConfirmReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case ORDER_CONFIRM_SUCCESS:
			return { loading: false, orderStatus: true }
		case ORDER_CONFIRM_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		case ORDER_CONFIRM_RESET:
			return {}
		default:
			return state
	}
}

export const orderByIdReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case ORDER_BY_ID_REQUEST:
			return { loading: true }
		case ORDER_BY_ID_SUCCESS:
			return {
				loading: false,
				order: action.payload,
			}
		case ORDER_BY_ID_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}
