import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import VideoCard from "../Components/VideoCard";
import { bikeHighlights } from "../Components/tRexFamily";
import { Swiper, SwiperSlide } from "swiper/react";

export const TRexAirScreen = () => {
  const [activeItem, setActiveItem] = useState(1);
  const rangeData = [
    {
      id: 1,
      url1: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/usp/Feel+the+Wind%2C+Not+the+Traffic.jpg",
      text: "Feel the Wind, Not the Traffic",
    },
    {
      id: 2,
      url1: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/usp/All-day+Power%2C+All-day+Fun.jpg",
      text: "All-day Power, All-day Fun",
    },
    {
      id: 3,
      url1: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/usp/Always+Keep+Moving.jpg",
      text: "Always Keep Moving",
    },
  ];
  const handleHover = (id) => {
    if (activeItem === id) {
      return;
    }
    setActiveItem(id);
  };
  const [proColor, setProColor] = useState(0);
  const [videoKey, setVideoKey] = useState(0);
  const reloadVideo = () => {
    setVideoKey((prevKey) => prevKey + 1); // Change key to force re-render
  };
  const [airColor, setAirColor] = useState(0);
  const [v3Color, setV3Color] = useState(0);
  const [activeColor, setActiveColor] = useState(0);
  const [activeVariant, setActiveVariant] = useState(0);
  const [activeVideo, setActiveVideo] = useState(0);
  const handleClick = (itemName, index) => {
    if (itemName === "T-Rex Pro") {
      setProColor(index);
    } else if (itemName === "T-Rex Air") {
      setAirColor(index);
    } else {
      setV3Color(index);
    }
  };
  const handleActiveColor = (index) => {
    setActiveColor(index);
    setActiveVideo(index);
  };
  const handleActiveVariant = (index) => {
    setActiveVariant(index);
  };
  const bikeHighlightsData = bikeHighlights.find((b) => b.name === "T-Rex Air");

  const teaserRef = useRef(null);
  const viewRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null, // relative to the viewport
      threshold: [0.5, 1.0], // Track when the video is 50% and 100% visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const video = entry.target;

        // Play video only if it's 100% visible, pause otherwise
        if (entry.intersectionRatio === 1) {
          if (video.paused) {
            video.play().catch((error) => {
              console.error("Video playback failed:", error);
            });
          }
        } else {
          if (!video.paused) {
            video.pause();
          }
        }
      });
    }, observerOptions);

    if (teaserRef.current) {
      observer.observe(teaserRef.current);
    }

    return () => {
      if (teaserRef.current) {
        observer.unobserve(teaserRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null, // relative to the viewport
      threshold: [0.5, 1.0], // Track when the video is 50% and 100% visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const video = entry.target;

        // Play video only if it's 100% visible, pause otherwise
        if (entry.intersectionRatio === 1) {
          if (video.paused) {
            video.play().catch((error) => {
              console.error("Video playback failed:", error);
            });
          }
        } else {
          if (!video.paused) {
            video.pause();
          }
        }
      });
    }, observerOptions);

    if (viewRef.current) {
      observer.observe(viewRef.current);
    }

    return () => {
      if (viewRef.current) {
        observer.unobserve(viewRef.current);
      }
    };
  }, []);

  return (
    <div className="tRexFamily-div tRexFamily-primary-bg">
      <Navbar
        tRexNavbar={true}
        name={"T-REX AIR"}
        buylink={"/bikes/t-rex-air"}
      />
      <div className="position-relative">
        <Link to={"/bikes/t-rex-air"} className="text-decoration-none">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/air/Artboard+2+(1).png"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/air/Artboard+1.png"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/banners/air/Artboard+1.png"
              alt="Banner1"
              className="d-block w-100"
            />
          </picture>
        </Link>
        <div className="position-absolute t-rexfamily-banner-text w-100">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ flexDirection: "column" }}
          >
            <h5
              style={{ fontFamily: "Metropolis-bold" }}
              className="text-light mb-3"
            >
              From ₹34,999
            </h5>
            <div className="d-flex justify-content-center">
              <div className="btn-buy-now text-uppercase rounded-5">
                <Link
                  to="/bikes/t-rex-pro"
                  style={{ color: "inherit" }}
                  className="my-2 text-decoration-none"
                >
                  Buy Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-3 px-lg-5 pt-4 py-lg-5 mt-5">
        <h2 className="mb-3 mb-lg-4" style={{ fontFamily: "Metropolis-bold" }}>
          Why T-Rex Air?
        </h2>
        <div className="d-lg-none">
          <ul id="video-cards">
            {rangeData.map((item, index) => (
              <li className="video-card" id={`card${index + 1}`}>
                <div className="video-card-body position-relative rounded-4">
                  <img src={item.url1} className="img-fluid rounded-4" />
                  <div className="overlay position-absolute start-0 w-100 top-0 h-100 d-flex align-items-end rounded-4 px-lg-4">
                    <div className="text-overlay text-white px-3 py-1 py-lg-3 w-100">
                      <p style={{ fontSize: "1em" }} className="fw-bold">
                        {item.text}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="d-lg-block d-none">
          <div className="d-flex" style={{ gap: "3em" }}>
            {rangeData.map((item) => (
              <div
                className="card-t-rex-family position-relative rounded-4 addition-card"
                key={item.id}
                style={{
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
                }}
              >
                <img src={item.url1} className="img-fluid rounded-4"></img>
                <div
                  className="rounded-4"
                  style={{
                    position: "absolute",
                    top: "0%",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    backgroundColor: "black",
                    background:
                      "linear-gradient(to bottom,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.5) 100%",
                  }}
                />
                <div className="w-100">
                  <h5
                    className="position-absolute text-light w-100 fw-bold px-4"
                    style={{
                      bottom: "5%",
                    }}
                  >
                    {item.text}
                  </h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className="px-3 px-lg-5 pt-5 pb-3 mt-0">
        <h2 className="mb-3 mb-lg-4" style={{ fontFamily: "Metropolis-bold" }}>
          Get the Highlights
        </h2>
        <ul id="video-cards">
          <li className="video-card" id="card1">
            <VideoCard
              videoSrcMobile="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Get+the+highlits/headlight+and+indicator/Headlight+n+indicator+Mobile.mp4"
              videoSrcDesktop="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Get+the+highlits/headlight+and+indicator/Headlight+n+indicator-.mp4"
              heading="Display, Reimagined"
              description="The Cluster C5 Display keeps all your ride stats at your fingertips—clear, intuitive, and easy to use."
            />
          </li>
          <li className="video-card" id="card2">
            <VideoCard
              videoSrcMobile="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Get+the+highlits/Dynamic+handlebar+video/Trex+pro+Dynamic+handle++mobile.mp4"
              videoSrcDesktop="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Get+the+highlits/Dynamic+handlebar+video/Trex+pro+Dynamic+handle+desktop.mp4"
              heading="Illuminate Every Ride"
              description="The front light with an integrated horn ensures visibility and safety, no matter the time of day."
            />
          </li>
          <li className="video-card" id="card3">
            <VideoCard
              videoSrcMobile="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Get+the+highlits/tail+light+and+indiactor/Tail+light+rendr+mobile.mp4"
              videoSrcDesktop="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/videos/pro/Get+the+highlits/tail+light+and+indiactor/Tail+light+rendr+e.mp4"
              heading="Shift Into Gear"
              description="With a Shimano TY300 7 speed gearing system, you’ll glide over every incline, trail, or city street with precision."
            />
          </li>
        </ul>
      </div> */}
      {/* <div className="py-5 py-lg-5">
        <h2
          className="mb-3 mb-lg-4 px-3 px-lg-5"
          style={{ fontFamily: "Metropolis-bold" }}
        >
          Bold Colours. Perfect Fit.
        </h2>
        <p className="col-md-6 col-12 px-3 px-lg-5">
          Available in vibrant shades and sizes, the T-Rex Air doesn’t just
          perform—it stands out.
        </p>
        <div className="view-div-bike">
          <div className="d-lg-block d-none cycle-selection-option-desktop position-relative">
            <div
              className="cycle-background"
              style={{
                width: "100%",
                backgroundColor: "#D5D5d5",
              }}
            >
              <div className="d-flex justify-content-center">
                <video
                  ref={viewRef}
                  key={videoKey}
                  style={{ width: "45%" }}
                  className="main-banner-video"
                  autoPlay
                  loop
                  muted
                  src={bikeHighlightsData.videos[activeVideo][1]}
                  playsInline
                ></video>
              </div>
              <div
                className="d-flex justify-content-center align-items-center pb-3"
                style={{ gap: "2em" }}
              >
                <div
                  className="d-flex justify-content-center py-2 px-1 rounded-5"
                  style={{ backgroundColor: "#D9D9D9" }}
                >
                  {bikeHighlightsData.models.map((i, index) => (
                    <div
                      className="rounded-circle m-1 "
                      onClick={() => handleActiveColor(index)}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        border:
                          index === activeColor ? "0.7px solid white" : ``,
                        background: i.color,
                      }}
                    ></div>
                  ))}
                </div>
                <div
                  className="d-flex justify-content-center py-2 px-2 rounded-5"
                  style={{ backgroundColor: "#9A9A9A", gap: "0.5em" }}
                >
                  {bikeHighlightsData.variants.map((i, index) => (
                    <div
                      className="rounded-5 px-3 py-1"
                      onClick={() => {
                        handleActiveVariant(index);
                        reloadVideo();
                      }}
                      style={{
                        cursor: "pointer",
                        fontFamily: "Metropolis-medium",
                        backgroundColor:
                          index === activeVariant ? "white" : "transparent",
                        color: index === activeVariant ? "black" : "white",
                        boxShadow:
                          index === activeVariant
                            ? "0px 4px 10px rgba(0, 0, 0, 0.1)"
                            : "",
                      }}
                    >
                      {i} inch
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="d-lg-none cycle-selection-option-desktop mt-4">
            <video
              ref={viewRef}
              key={videoKey}
              className="main-banner-video img-fluid"
              autoPlay
              loop
              muted
              src={bikeHighlightsData.videos[activeVideo][0]}
              playsInline
            ></video>
            <div
              className="d-flex justify-content-between align-items-center px-3 px-lg-5"
              style={{ gap: "2em" }}
            >
              <div
                className="d-flex justify-content-center py-2 px-2 rounded-5"
                style={{ backgroundColor: "#D9D9D9", gap: "0.5em" }}
              >
                {bikeHighlightsData.models.map((i, index) => (
                  <div
                    className="rounded-circle my-1"
                    onClick={() => handleActiveColor(index)}
                    style={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                      border: index === activeColor ? "0.7px solid white" : ``,
                      background: i.color,
                    }}
                  ></div>
                ))}
              </div>
              <div
                className="d-flex justify-content-center py-2 px-2 rounded-5"
                style={{ backgroundColor: "#9A9A9A", gap: "0.5em" }}
              >
                {bikeHighlightsData.variants.map((i, index) => (
                  <div
                    className="rounded-5 px-3 py-1 text-center"
                    onClick={() => {
                      handleActiveVariant(index);
                      reloadVideo();
                    }}
                    style={{
                      cursor: "pointer",
                      fontSize: "0.9em",
                      fontFamily: "Metropolis-medium",
                      backgroundColor:
                        index === activeVariant ? "white" : "transparent",
                      color: index === activeVariant ? "black" : "white",
                      boxShadow:
                        index === activeVariant
                          ? "0px 4px 10px rgba(0, 0, 0, 0.1)"
                          : "",
                    }}
                  >
                    {i}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="px-3 px-lg-5 py-4 pt-5 py-lg-5">
        <h2 className="mb-3 mb-lg-4" style={{ fontFamily: "Metropolis-bold" }}>
          Features
        </h2>
        <div className="d-lg-block d-none">
          <div className="d-flex" style={{ gap: "3em" }}>
            <div className="position-relative addition-card">
              <img
                className="img-fluid addition-card rounded-4"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/additional+features/t-rex+air/Battery+desktop.png"
              ></img>
              <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-end rounded-4 px-lg-3">
                <div className=" text-white w-100">
                  <h3
                    style={{ fontFamily: "Metropolis-bold", fontSize: "1.2em" }}
                    className="mb-1"
                  >
                    All-Day Fun.
                  </h3>
                  <p className="mb-3" style={{ fontSize: "0.8em" }}>
                    The 10.2Ah battery offers up to 50 km on pedal assist,
                    giving you the freedom to explore longer and further.
                  </p>
                </div>
              </div>
            </div>
            <div className="position-relative addition-card">
              <img
                className="img-fluid rounded-4"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/additional+features/t-rex+air/Motor+and+derailers+esktop.png"
              ></img>
              <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-end rounded-4 px-lg-3">
                <div className="text-white w-100">
                  <h3
                    style={{ fontFamily: "Metropolis-bold", fontSize: "1.2em" }}
                    className="mb-1"
                  >
                    Smooth, Reliable, Powerful.
                  </h3>
                  <p className="mb-3" style={{ fontSize: "0.8em" }}>
                    With its 250W rear hub motor, the T-Rex Air transforms every
                    pedal stroke into a burst of smooth, efficient power.
                  </p>
                </div>
              </div>
            </div>
            <div className="position-relative addition-card">
              <img
                className="img-fluid rounded-4"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/additional+features/t-rex+air/Frame+desktop.png"
              ></img>
              <div className=" position-absolute top-0 start-0 w-100 h-100 d-flex align-items-end rounded-4 px-lg-3">
                <div className="text-white w-100">
                  <h3
                    style={{ fontFamily: "Metropolis-bold", fontSize: "1.2em" }}
                    className="mb-1"
                  >
                    Durable, Lightweight Performance.
                  </h3>
                  <p className="mb-3" style={{ fontSize: "0.8em" }}>
                    Crafted with a high-tensile steel frame, it is built tough
                    for any terrain, while still being agile and easy to handle.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-none">
          <Swiper
            spaceBetween={15}
            slidesPerView={1.1}
            centeredSlides={false}
            loop={true}
            slide
          >
            <SwiperSlide>
              <div className="position-relative">
                <img
                  className="img-fluid rounded-4"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/additional+features/t-rex+air/Battery+desktop.png"
                ></img>
                <div
                  className="rounded-4"
                  style={{
                    position: "absolute",
                    top: "0%",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    backgroundColor: "black",
                    background:
                      "linear-gradient(to bottom,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.6) 100%",
                  }}
                />
                <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-end rounded-4 px-3">
                  <div className=" text-white w-100">
                    <h3
                      style={{
                        fontFamily: "Metropolis-bold",
                        fontSize: "1em",
                      }}
                      className="mb-1"
                    >
                      All-Day Fun.
                    </h3>
                    <p className="mb-3" style={{ fontSize: "0.75em" }}>
                      The 10.2Ah battery offers up to 50 km on pedal assist,
                      giving you the freedom to explore longer and further.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="position-relative">
                <img
                  className="img-fluid addition-card rounded-4"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/additional+features/t-rex+air/Motor+and+derailers+esktop.png"
                ></img>
                <div
                  className="rounded-4"
                  style={{
                    position: "absolute",
                    top: "0%",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    backgroundColor: "black",
                    background:
                      "linear-gradient(to bottom,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.6) 100%",
                  }}
                />
                <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-end rounded-4 px-3">
                  <div className=" text-white w-100">
                    <h3
                      style={{
                        fontFamily: "Metropolis-bold",
                        fontSize: "1em",
                      }}
                      className="mb-1"
                    >
                      Smooth, Reliable, Powerful.
                    </h3>
                    <p className="mb-3" style={{ fontSize: "0.75em" }}>
                      With its 250W rear hub motor, the T-Rex Air transforms
                      every pedal stroke into a burst of smooth, efficient
                      power.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="position-relative">
                <img
                  className="img-fluid addition-card rounded-4"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+family/additional+features/t-rex+air/Frame+desktop.png"
                ></img>
                <div
                  className="rounded-4"
                  style={{
                    position: "absolute",
                    top: "0%",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    backgroundColor: "black",
                    background:
                      "linear-gradient(to bottom,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.6) 100%",
                  }}
                />
                <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-end rounded-4 px-3">
                  <div className=" text-white w-100">
                    <h3
                      style={{
                        fontFamily: "Metropolis-bold",
                        fontSize: "1em",
                      }}
                      className="mb-1"
                    >
                      Durable, Lightweight Performance.
                    </h3>
                    <p className="mb-3" style={{ fontSize: "0.75em" }}>
                      Crafted with a high-tensile steel frame, it is built tough
                      for any terrain, while still being agile and easy to
                      handle.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="comparison-div-tRex px-lg-5 px-3 py-5 mt-3 mt-lg-5">
        <h2 className="mb-3 mb-lg-4" style={{ fontFamily: "Metropolis-bold" }}>
          Find Your Perfect Ride
        </h2>
        <div className="d-flex comparison-flex-div">
          {bikeHighlights.map((item, index) => (
            <div
              className="tRexFamily-secondary-bg text-center rounded-3"
              key={index}
            >
              <div className="p-3 py-4">
                <div>
                  <img
                    src={
                      item.images[
                        item.name === "T-Rex Pro"
                          ? proColor
                          : item.name === "T-Rex Air"
                          ? airColor
                          : v3Color
                      ]
                    }
                    alt={item.name}
                    className="img-fluid addition-card"
                  />
                </div>
                <div className="d-flex my-5 justify-content-center">
                  {item.models.map((i, index) => (
                    <div
                      className="me-2 rounded-circle"
                      onClick={() => handleClick(item.name, index)}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        background: `${i.color}`,
                      }}
                    ></div>
                  ))}
                </div>
                <div className="mt-4">
                  <h4
                    className="mb-4"
                    style={{ fontFamily: "Metropolis-Bold" }}
                  >
                    {item.name}
                  </h4>
                  <div
                    className="mb-3"
                    style={{ fontFamily: "Metropolis-light", height: "55px" }}
                  >
                    <p>{item.description}</p>
                  </div>
                  <div
                    className="mb-4"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    <h5>{item.price}</h5>
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    style={{ gap: "1em" }}
                  >
                    <div className="btn-know-more text-uppercase rounded-5">
                      <Link
                        to={item.knowMoreUrl}
                        style={{ color: "inherit" }}
                        className="my-2 text-decoration-none"
                      >
                        Know More{" "}
                      </Link>
                    </div>
                    <div className="btn-buy-now text-uppercase rounded-5">
                      <Link
                        to={item.url}
                        style={{ color: "inherit" }}
                        className="my-2 text-decoration-none"
                      >
                        Buy Now
                      </Link>
                    </div>
                  </div>
                  {item.specs.map((item, index) => (
                    <div className="my-5 spec-comparison-section">
                      <img
                        style={{ width: "40px" }}
                        className="mb-3"
                        src={item.icon}
                      ></img>
                      <div className="w-100 d-flex justify-content-center">
                        <div className="w-75">
                          <p
                            style={{
                              fontFamily: "Metropolis-bold",
                              fontSize: "0.8em",
                            }}
                            className="text-uppercase mb-1"
                          >
                            {item.name}
                          </p>
                          <p
                            style={{
                              fontFamily: "Metropolis-light",
                              fontSize: "0.8em",
                            }}
                            className=" mb-0"
                          >
                            {item.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="faq-div-tRex px-lg-5 px-3 py-4 py-lg-5">
        <h2 className="mb-0" style={{ fontFamily: "Metropolis-bold" }}>
          FAQs
        </h2>
        <p
          className="mb-4"
          style={{ fontSize: "1.1em", fontFamily: "Metropolis-medium" }}
        >
          Your questions, answered.
        </p>
        <div
          class="accordion"
          id="accordionExample"
          style={{ fontFamily: "Metropolis-Light" }}
        >
          <div class="accordion-item mb-2 mb-lg-3">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button px-0 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Can I use the T-Rex Air for my office commute?
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body px-0">
                Yes, the T-Rex Air’s high-performance motor and pedal assist
                make daily commuting both easy and enjoyable.
              </div>
            </div>
          </div>
          <div className="cycle-vs-ecycle-line"></div>
          <div class="accordion-item my-2 my-lg-3">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button px-0 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Is the display easy to use?
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body px-0">
                Absolutely. The Cluster C5 Display keeps all essential stats
                within easy view, so you stay in control of every ride.
              </div>
            </div>
          </div>
          <div className="cycle-vs-ecycle-line"></div>
          <div class="accordion-item my-2 my-lg-3">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button px-0 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Can I take the T-Rex Air off-road?
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body px-0">
                Definitely. With a 7-speed Shimano gear system and robust build,
                the T-Rex Air is ready for off-road challenges.
              </div>
            </div>
          </div>
          <div className="cycle-vs-ecycle-line "></div>
          <div class="accordion-item my-2 my-lg-3">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button px-0 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                How long does the battery last?
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body px-0">
                You can get up to 50 km on pedal assist mode and 40 km on
                throttle mode, depending on your riding conditions.
              </div>
            </div>
          </div>
          <div className="cycle-vs-ecycle-line "></div>
          <div class="accordion-item my-2 my-lg-3">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button px-0 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Is the T-Rex Air water-resistant?
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body px-0">
                Yes, the T-Rex Air is built to handle light rain and damp
                conditions. Just avoid submerging it in water for maximum
                protection.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
