import React, { useEffect } from "react"
import ordersuccess from "../images/test-ride-confirm.png"
import party from "party-js"
import {
	Link,
	useNavigate,
} from "react-router-dom"
import {
	useSelector,
	useDispatch,
} from "react-redux"

const TestRideConfirmScreen = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const bookTestride = useSelector(
		(state) => state.bookTestride,
	)
	const { testride } = bookTestride

	return (
		<div
			style={{ height: "90vh" }}
			className='container'>
			<div className='row  h-100 align-items-center position-relative'>
				<div className='col-12 col-lg-6 offset-lg-3'>
					<img
						src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/test/Book+a+test+ride+illustration.jpg"
						className='img-fluid d-block w-75 mx-auto'
						alt=''
					/>
					<h6
						className='text-center'
						style={{
							lineHeight: "1.5em",
							fontSize: "1.1em",
							fontWeight: "700",
						}}>
						{" "}
						Congratulations, your test ride has been booked!{" "}
					</h6>

					<p
						className='mt-lg-4 mt-4 text-center'
						style={{
							lineHeight: "1.5em",
							fontSize: "0.9em",
							fontWeight: "200",
						}}>
						Our team of experts will contact you shortly.
					</p>
				</div>

				<Link
					className='d-block mx-auto text-center text-decoration-none' style={{fontWeight:900, fontSize:"1.4em", color:"#242424"}}
					to='/'>
					Go back
				</Link>
			</div>
		</div>
	)
}

export default TestRideConfirmScreen
