import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  CONTACT_US_RESET,
  EMI_RESET,
  INSURANCE_RESET,
  PARTNER_WITH_US_RESET,
  WARRANTY_RESET,
} from "../Constants/formConstants";
import { useDispatch } from "react-redux";
import "../CSS/thankyou.css";
import ReactPixel from "react-facebook-pixel";

const LeadSubmitScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: CONTACT_US_RESET,
    });
    dispatch({ type: EMI_RESET });
    dispatch({ type: PARTNER_WITH_US_RESET });
    dispatch({ type: WARRANTY_RESET });

    dispatch({
      type: INSURANCE_RESET,
    });
    ReactPixel.init("1034452493992700");
    ReactPixel.pageView();
  }, []);

  return (
    <div className="container">
      <div className="thankyou-div">
        <p className="thankyou font-mon ">REGISTRATION CONFIRMED!</p>
        <h4>Check your Email for Further Details! </h4>
        <br></br>
        <h6>
          Join the Club:{""} &nbsp;
          <a href="https://bit.ly/4dLg8zs" className="" target="_blank">
            <img
              className="wa-chat-btn-icon-image "
              style={{ width: "35px" }}
              alt="Whatsapp Chat Button"
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/test/whatsapp+logo.png"
            />{" "}
          </a>
        </h6>
        <br></br>
        <h6>
          In the meantime, why not find out more about us and our e-bikes.
          Alternatively, give us a call on{" "}
          <a href="tel:+919424648894" className="text-decoration-none">
            +91-9424648894
          </a>
        </h6>
        <Link to={"/"} className="go-back-link">
          Check our Website
        </Link>
      </div>
    </div>
  );
};

export default LeadSubmitScreen;
