import React from "react";

const DealersScreen = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <iframe
        style={{
          width: "100%",
          height: "100%",
        }}
        src="https://dealers.emotorad.com"
        title="d"
      ></iframe>
    </div>
  );
};

export default DealersScreen;
