import React, { useState } from "react";

const TooltipMobile = ({ bike }) => {
  const [spec, setSpec] = useState(0);

  const specsImages = [
    [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/mobile/Asset+5.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/mobile/Asset+9.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/mobile/Asset+11.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/mobile/Asset+3.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/mobile/Asset+13.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/mobile/Asset+7.png",
    ],
    [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/mobile/Asset+5.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/x-limited-edition/mobile/Artboard+23.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/mobile/Asset+11.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/mobile/Asset+3.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/x-limited-edition/mobile/Artboard+22.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/x-limited-edition/mobile/Artboard+19.png",
    ],
    [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X2/mobile/Asset+5.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X2/mobile/Asset+21.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X2/mobile/Asset+23.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X2/mobile/Asset+3.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X2/mobile/Asset+25.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X2/mobile/Asset+173.png",
    ],
    [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X3/mobile/Asset+29.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X3/mobile/Asset+33.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X3/mobile/Asset+35.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X3/mobile/Asset+27.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X3/mobile/Asset+39.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X3/mobile/Asset+29.png",
    ],
    [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-REX%2B+old/Mobile/Asset+91.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-REX%2B+old/Mobile/Asset+95.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-REX%2B+old/Mobile/Asset+97.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-REX%2B+old/Mobile/Asset+89.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-REX%2B+old/Mobile/Asset+99.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-REX%2B+old/Mobile/Asset+93.png",
    ],
    [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-rex-air/Mobile/specs-06.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-rex-air/Mobile/Artboard+18.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-REX%2B+old/Mobile/Asset+97.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-rex-air/Mobile/Artboard+10.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-rex-air/Mobile/Artboard+16.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-rex-air/Mobile/Artboard+9.png",
    ],
    [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/EMX+old/Mobile/Asset+59.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/EMX+new/Mobile/Asset+79.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/EMX+new/Mobile/Asset+81.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/EMX+new/Mobile/Asset+73.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/EMX+old/Mobile/Asset+67.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/EMX+new/Mobile/Asset+77.png",
    ],
    [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/EMX+new/Mobile/Asset+75.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/EMX+new/Mobile/Asset+79.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/EMX+new/Mobile/Asset+81.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/EMX+new/Mobile/Asset+73.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/EMX+old/Mobile/Asset+67.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/EMX+new/Mobile/Asset+77.png",
    ],
    [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/Doodle/Mobile/Asset+43.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/Doodle/Mobile/Asset+47.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/Doodle/Mobile/Asset+49.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/Doodle/Mobile/Asset+41.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/Doodle/Mobile/Asset+55.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/Doodle/Mobile/Asset+51.png",
    ],
    [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/Doodle+V3/mobile/battery+mobile.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/Doodle+V3/mobile/suspension+mobile.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/Doodle/Mobile/Asset+49.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/Doodle/Mobile/Asset+41.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/Doodle+V3/mobile/display+mobile.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/Doodle+V3/mobile/frame+mobile.png",
    ],
    [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/LIL+E+old/Mobile/Asset+117.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/LIL+E+old/Mobile/Asset+119.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/LIL+E+old/Mobile/Asset+127.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/LIL+E+old/Mobile/Asset+123.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/LIL+E+old/Mobile/Asset+121.png",
    ],
    [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/mobile/Asset+5.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/tooltip/neoSuspension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/mobile/Asset+11.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/mobile/Asset+3.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/mobile/Asset+13.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/tooltip/neoFrame.png",
    ],
  ];

  switch (bike) {
    case "x1":
      return (
        <>
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/tooltip/x1+blue.png"
              className="bike-img img-fluid d-block mx-auto"
              alt=""
            />
            {/* battery */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x1-spec-1  align-items-center"
              onClick={() => setSpec(0)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* suspensions */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x1-spec-2  align-items-center"
              onClick={() => setSpec(1)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* diskbrake */}

            <div
              className="position-absolute d-lg-flex  d-block x1-spec x1-spec-3  align-items-center"
              onClick={() => setSpec(2)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* motor */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x1-spec-4  align-items-center"
              onClick={() => setSpec(3)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* display */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x1-spec-5  align-items-center"
              onClick={() => setSpec(4)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* frame */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x1-spec-6  align-items-center"
              onClick={() => setSpec(5)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
          </div>
          <div className="mt-2">
            <img
              src={specsImages[0][spec]}
              alt=""
              className="img-fluid w-75 d-block mx-auto"
            />
          </div>
        </>
      );

      break;
    case "neo":
      return (
        <>
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/cartBot.png"
              className="bike-img img-fluid d-block mx-auto"
              alt=""
            />
            {/* battery */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x1-spec-1  align-items-center"
              onClick={() => setSpec(0)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* suspensions */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x1-spec-2  align-items-center"
              onClick={() => setSpec(1)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* diskbrake */}

            <div
              className="position-absolute d-lg-flex  d-block x1-spec neo-spec-3  align-items-center"
              onClick={() => setSpec(2)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* motor */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec neo-spec-4  align-items-center"
              onClick={() => setSpec(3)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* display */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec neo-spec-5  align-items-center"
              onClick={() => setSpec(4)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* frame */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x1-spec-6  align-items-center"
              onClick={() => setSpec(5)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
          </div>
          <div className="mt-2">
            <img
              src={specsImages[11][spec]}
              alt=""
              className="img-fluid w-75 d-block mx-auto"
            />
          </div>
        </>
      );

      break;
    // case "x1-limited-edition":
    //   return (
    //     <>
    //       <div className="position-relative">
    //         <img
    //           src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/product-page/X1-Limited-Edition/specification+image/T+rex+air.png"
    //           className="bike-img img-fluid d-block mx-auto"
    //           alt=""
    //         />
    //         {/* battery */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec x1-spec-1  align-items-center"
    //           onClick={() => setSpec(0)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* suspensions */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec x1-spec-2  align-items-center"
    //           onClick={() => setSpec(1)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* diskbrake */}

    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec x1-spec-3  align-items-center"
    //           onClick={() => setSpec(2)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* motor */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec x1-spec-4  align-items-center"
    //           onClick={() => setSpec(3)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* display */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec x1-limited-spec-5  align-items-center"
    //           onClick={() => setSpec(4)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* frame */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec x1-spec-6  align-items-center"
    //           onClick={() => setSpec(5)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //       </div>
    //       <div className="mt-2">
    //         <img
    //           src={specsImages[1][spec]}
    //           alt=""
    //           className="img-fluid w-75 d-block mx-auto"
    //         />
    //       </div>
    //     </>
    //   );

    //   break;
    // case "x2":
    //   return (
    //     <>
    //       <div className="position-relative">
    //         <img
    //           src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/product-page/X2/Desktop/04+Specifications+single+image/Layer+4.png"
    //           className="img-fluid d-block mx-auto"
    //           alt=""
    //         />
    //         {/* battery */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec x1-spec-1  align-items-center"
    //           onClick={() => setSpec(0)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* suspension brake */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec x1-spec-2  align-items-center"
    //           onClick={() => setSpec(1)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* diskbrake */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec x1-spec-3  align-items-center"
    //           onClick={() => setSpec(2)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* motor */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec x1-spec-4  align-items-center"
    //           onClick={() => setSpec(3)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* display */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec x1-spec-5  align-items-center"
    //           onClick={() => setSpec(4)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* frame */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec x1-spec-6  align-items-center"
    //           onClick={() => setSpec(5)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //       </div>
    //       <div className="mt-2">
    //         <img
    //           src={specsImages[2][spec]}
    //           alt=""
    //           className="img-fluid w-75 d-block mx-auto"
    //         />
    //       </div>
    //     </>
    //   );

    //   break;
    case "x3":
      return (
        <>
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/product-page/X3/Desktop/04+Specifications+single+image/Layer+5.png"
              className="img-fluid d-block mx-auto"
              alt=""
            />
            {/* battery */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x3-spec-1  align-items-center"
              onClick={() => setSpec(0)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* suspension brake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x3-spec-2  align-items-center"
              onClick={() => setSpec(1)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* diskbrake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x3-spec-3  align-items-center"
              onClick={() => setSpec(2)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* motor */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x3-spec-4  align-items-center"
              onClick={() => setSpec(3)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* display */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x3-spec-5  align-items-center"
              onClick={() => setSpec(4)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* frame */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec x3-spec-6  align-items-center"
              onClick={() => setSpec(5)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
          </div>
          <div className="mt-2">
            <img
              src={specsImages[3][spec]}
              alt=""
              className="img-fluid w-75 d-block mx-auto"
            />
          </div>
        </>
      );

      break;
    // case "t-rex+":
    //   return (
    //     <>
    //       <div className="position-relative">
    //         <img
    //           src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/product-page/T-REX%2B/Desktop/04+Specifications+single+image/4th+copy.png"
    //           className="img-fluid d-block mx-auto"
    //           alt=""
    //         />
    //         {/* battery */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec t-rex-spec-1  align-items-center"
    //           onClick={() => setSpec(0)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* suspension brake */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec t-rex-spec-2  align-items-center"
    //           onClick={() => setSpec(1)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* diskbrake */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec t-rex-spec-3  align-items-center"
    //           onClick={() => setSpec(2)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* motor */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec t-rex-spec-4  align-items-center"
    //           onClick={() => setSpec(3)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* display */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec emx-spec-5  align-items-center"
    //           onClick={() => setSpec(4)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //         {/* frame */}
    //         <div
    //           className="position-absolute d-lg-flex  d-block x1-spec t-rex-spec-6  align-items-center"
    //           onClick={() => setSpec(5)}
    //         >
    //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
    //         </div>
    //       </div>
    //       <div className="mt-2">
    //         <img
    //           src={specsImages[4][spec]}
    //           alt=""
    //           className="img-fluid w-75 d-block mx-auto"
    //         />
    //       </div>
    //     </>
    //   );

    //   break;
    case "t-rex-air":
      return (
        <>
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex-air/tooltip/T-rex%2B+Air+Red.png"
              className="img-fluid d-block mx-auto"
              alt=""
            />
            {/* battery */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec t-rex-spec-1  align-items-center"
              onClick={() => setSpec(0)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* suspension brake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec t-rex-spec-2  align-items-center"
              onClick={() => setSpec(1)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* diskbrake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec t-rex-spec-3  align-items-center"
              onClick={() => setSpec(2)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* motor */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec t-rex-spec-4  align-items-center"
              onClick={() => setSpec(3)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* display */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec t-rex-spec-5  align-items-center"
              onClick={() => setSpec(4)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* frame */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec t-rex-spec-6  align-items-center"
              onClick={() => setSpec(5)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
          </div>
          <div className="mt-2">
            <img
              src={specsImages[5][spec]}
              alt=""
              className="img-fluid w-75 d-block mx-auto"
            />
          </div>
        </>
      );
      break;
    case "emx+":
      return (
        <>
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/product-page/EMX%2B/Desktop/04+Specifications+single+image/Layer+14.png"
              className="img-fluid d-block mx-auto"
              alt=""
            />
            {/* battery */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec emx-spec-1  align-items-center"
              onClick={() => setSpec(0)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* suspension brake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec emx-spec-2  align-items-center"
              onClick={() => setSpec(1)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* diskbrake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec emx-spec-3  align-items-center"
              onClick={() => setSpec(2)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* motor */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec emx-spec-4  align-items-center"
              onClick={() => setSpec(3)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* display */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec emx-spec-5  align-items-center"
              onClick={() => setSpec(4)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* frame */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec emx-spec-6  align-items-center"
              onClick={() => setSpec(5)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
          </div>
          <div className="mt-2">
            <img
              src={specsImages[6][spec]}
              alt=""
              className="img-fluid w-75 d-block mx-auto"
            />
          </div>
        </>
      );
      break;
    case "emx":
      return (
        <>
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/product-page/EMX/Desktop/04+Specifications+single+image/Layer+8.png"
              className="img-fluid d-block mx-auto"
              alt=""
            />
            {/* battery */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec emx-spec-1  align-items-center"
              onClick={() => setSpec(0)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* suspension brake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec emx-spec-2  align-items-center"
              onClick={() => setSpec(1)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* diskbrake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec emx-spec-3  align-items-center"
              onClick={() => setSpec(2)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* motor */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec emx-spec-4  align-items-center"
              onClick={() => setSpec(3)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* display */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec emx-spec-5  align-items-center"
              onClick={() => setSpec(4)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* frame */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec emx-spec-6  align-items-center"
              onClick={() => setSpec(5)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
          </div>
          <div className="mt-2">
            <img
              src={specsImages[7][spec]}
              alt=""
              className="img-fluid w-75 d-block mx-auto"
            />
          </div>
        </>
      );

      break;

    case "doodle":
      return (
        <>
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/product-page/Doodle/Desktop/04+Specifications+single+image/Layer+10.png"
              className="img-fluid d-block mx-auto"
              alt=""
            />
            {/* battery */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec doodle-spec-1  align-items-center"
              onClick={() => setSpec(0)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* suspension brake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec doodle-spec-2  align-items-center"
              onClick={() => setSpec(1)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* diskbrake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec doodle-spec-3  align-items-center"
              onClick={() => setSpec(2)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* motor */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec doodle-spec-4  align-items-center"
              onClick={() => setSpec(3)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* display */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec doodle-spec-5  align-items-center"
              onClick={() => setSpec(4)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* frame */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec doodle-spec-6  align-items-center"
              onClick={() => setSpec(5)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
          </div>
          <div className="mt-2">
            <img
              src={specsImages[8][spec]}
              alt=""
              className="img-fluid w-75 d-block mx-auto"
            />
          </div>
        </>
      );

      break;

    case "doodlev3":
      return (
        <>
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/product-page/Doodle+v3/specification+image/dd0.png"
              className="img-fluid d-block mx-auto"
              alt=""
            />
            {/* battery */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec doodle-spec-1  align-items-center"
              onClick={() => setSpec(0)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* suspension brake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec doodlev3-spec-2  align-items-center"
              onClick={() => setSpec(1)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* diskbrake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec doodlev3-spec-3  align-items-center"
              onClick={() => setSpec(2)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* motor */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec doodlev3-spec-4  align-items-center"
              onClick={() => setSpec(3)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* display */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec doodlev3-spec-5  align-items-center"
              onClick={() => setSpec(4)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* frame */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec doodlev3-spec-6  align-items-center"
              onClick={() => setSpec(5)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
          </div>
          <div className="mt-2">
            <img
              src={specsImages[9][spec]}
              alt=""
              className="img-fluid w-75 d-block mx-auto"
            />
          </div>
        </>
      );

      break;

    case "lile":
      return (
        <>
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/product-page/lilE+v2/desktop/specifications/Layer+15.png"
              className="img-fluid d-block mx-auto"
              alt=""
            />
            {/* battery */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec lile-spec-1  align-items-center"
              onClick={() => setSpec(0)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* suspension brake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec lile-spec-2  align-items-center"
              onClick={() => setSpec(1)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* diskbrake */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec lile-spec-3  align-items-center"
              onClick={() => setSpec(2)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
            {/* motor */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec lile-spec-4  align-items-center"
              onClick={() => setSpec(3)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>

            {/* frame */}
            <div
              className="position-absolute d-lg-flex  d-block x1-spec lile-spec-6  align-items-center"
              onClick={() => setSpec(4)}
            >
              <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            </div>
          </div>
          <div className="mt-2">
            <img
              src={specsImages[10][spec]}
              alt=""
              className="img-fluid w-75 d-block mx-auto"
            />
          </div>
        </>
      );

      break;

      // case "desert-eagle":
      //   return (
      //     <>
      //       <div className="position-relative">
      //         <img
      //           src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/product-page/Desert+eagle/Desktop/04+Specifications+single+image/Layer+11.png"
      //           className="img-fluid d-block mx-auto"
      //           alt=""
      //         />
      //         {/* battery */}
      //         <div
      //           className="position-absolute d-lg-flex  d-block x1-spec deagle-spec-1  align-items-center"
      //           onClick={() => setSpec(0)}
      //         >
      //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
      //         </div>
      //         {/* suspension brake */}
      //         <div
      //           className="position-absolute d-lg-flex  d-block x1-spec deagle-spec-2  align-items-center"
      //           onClick={() => setSpec(1)}
      //         >
      //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
      //         </div>
      //         {/* diskbrake */}
      //         <div
      //           className="position-absolute d-lg-flex  d-block x1-spec deagle-spec-3  align-items-center"
      //           onClick={() => setSpec(2)}
      //         >
      //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
      //         </div>
      //         {/* motor */}
      //         <div
      //           className="position-absolute d-lg-flex  d-block x1-spec deagle-spec-4  align-items-center"
      //           onClick={() => setSpec(3)}
      //         >
      //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
      //         </div>
      //         {/* display */}
      //         <div
      //           className="position-absolute d-lg-flex  d-block x1-spec deagle-spec-5  align-items-center"
      //           onClick={() => setSpec(4)}
      //         >
      //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
      //         </div>
      //         {/* frame */}
      //         <div
      //           className="position-absolute d-lg-flex  d-block x1-spec deagle-spec-6  align-items-center"
      //           onClick={() => setSpec(5)}
      //         >
      //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
      //         </div>
      //       </div>
      //       <div className="mt-2">
      //         <img
      //           src={specsImages[9][spec]}
      //           alt=""
      //           className="img-fluid w-75 d-block mx-auto"
      //         />
      //       </div>
      //     </>
      //   );

      //   break;
      // case "nighthawk":
      //   return (
      //     <>
      //       <div className="position-relative">
      //         <img
      //           src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/product-page/Nighthawk/Desktop/04+Specifications+single+image/Layer+12.png"
      //           className="img-fluid d-block mx-auto"
      //           alt=""
      //         />
      //         {/* battery */}
      //         <div
      //           className="position-absolute d-lg-flex  d-block x1-spec deagle-spec-1  align-items-center"
      //           onClick={() => setSpec(0)}
      //         >
      //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
      //         </div>
      //         {/* suspension brake */}
      //         <div
      //           className="position-absolute d-lg-flex  d-block x1-spec deagle-spec-2  align-items-center"
      //           onClick={() => setSpec(1)}
      //         >
      //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
      //         </div>
      //         {/* diskbrake */}
      //         <div
      //           className="position-absolute d-lg-flex  d-block x1-spec deagle-spec-3  align-items-center"
      //           onClick={() => setSpec(2)}
      //         >
      //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
      //         </div>
      //         {/* motor */}
      //         <div
      //           className="position-absolute d-lg-flex  d-block x1-spec deagle-spec-4  align-items-center"
      //           onClick={() => setSpec(3)}
      //         >
      //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
      //         </div>
      //         {/* display */}
      //         <div
      //           className="position-absolute d-lg-flex  d-block x1-spec deagle-spec-5  align-items-center"
      //           onClick={() => setSpec(4)}
      //         >
      //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
      //         </div>
      //         {/* frame */}
      //         <div
      //           className="position-absolute d-lg-flex  d-block x1-spec deagle-spec-6  align-items-center"
      //           onClick={() => setSpec(5)}
      //         >
      //           <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
      //         </div>
      //       </div>
      //       <div className="mt-2">
      //         <img
      //           src={specsImages[10][spec]}
      //           alt=""
      //           className="img-fluid w-75 d-block mx-auto"
      //         />
      //       </div>
      //     </>
      //   );

      break;
    default:
      break;
  }
};

export default TooltipMobile;
