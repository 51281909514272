import React from "react";
import { Link } from "react-router-dom";
import "../CSS/thankyou.css";

function LandingSubmit() {
  const resetModal = () => {
    const modal = document.getElementById("exampleModal");
    modal.style.display = "none";

    // Remove the modal backdrop
    const modalBackdrops = document.getElementsByClassName("modal-backdrop");
    Array.from(modalBackdrops).forEach((backdrop) => {
      backdrop.parentNode.removeChild(backdrop);
    });
  };
  return (
    <div className="container">
      <div className="thankyou-div">
        <p className="thankyou font-mon ">
          Thank you for getting in touch with us!
        </p>
        <h4>We'll speak soon!</h4>
        <h6>
          In the meantime, why not find out more about us and our e-bikes.
          Alternatively, give us a call on +91 8686050590
        </h6>
        <Link to={"/student"} className="go-back-link" onClick={resetModal}>
          Go Back
        </Link>
      </div>
    </div>
  );
}

export default LandingSubmit;
