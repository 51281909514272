import React, { useEffect, useState, useRef } from "react";
import logo from "../images/EM-LOGO_Blue.png";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllBikes } from "../Actions/bikeActions";
import "../CSS/corporateLanding.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { Navigation, Pagination } from "swiper";
import Meta from "../Components/Meta";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Footer from "../Components/Footer";

export const CorporateGiftingLandingPage = (props) => {
  const navigate = useNavigate();
  const submit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/form/landingCorporateGifting",
      {
        name,
        email,
        phoneNumber,
        companyName,
      },
      config
    );
    if (data) {
      navigate("/book-submitted");
    }
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [orderSize, setOrderSize] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [model, setModel] = useState("");
  const [message, setMessage] = useState("");

  SwiperCore.use([Navigation, Pagination]);

  const dispatch = useDispatch();

  const allBikes = useSelector((state) => state.allBikes);
  const { bikes } = allBikes;
  const [modelStates, setModelStates] = useState(
    bikes ? Array(bikes.length).fill(0) : []
  );
  const handleColorClick = (bikeIndex, colorIndex) => {
    setModelStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[bikeIndex] = colorIndex;
      return newStates;
    });
  };

  useEffect(() => {
    if (!bikes) {
      dispatch(getAllBikes());
    }
  }, []);

  useEffect(() => {
    if (bikes) {
      setModelStates(Array(bikes.length).fill(0));
    }
  }, [bikes]);

  const currencyFormat = (num) => {
    return "₹" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  return (
    <>
      {/* header */}
      {/* <Meta
          title={"Book Your Test Ride Now | EMotorad"}
          description={
            "EMotorad brings a range of the best electric bikes that are affordable &amp; futuristic. Crafted with the state of art components, at the best price. Book your test ride now!"
          }
        /> */}
      <div
        className="position-sticky navigation w-100"
        style={{
          zIndex: "99",
          top: "0%",
          borderBottom: "2px solid rgb(197, 197, 197)",
        }}
      >
        <div
          className="m-0 p-0 quiz-nav pe-3 ps-3 ps-lg-5 ps-lg-5 p-3"
          style={{
            backgroundColor: "#ffffff",
          }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <Link to={"/"}>
              <img
                src={logo}
                className="img-fluid"
                style={{ width: "170px" }}
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>
      {/* desktop form */}
      <div className=" d-none d-lg-block position-relative">
        <div className="position-absolute gift-banner-text">
          <h2 className=" text-white giftingSubHeading">
            Dial Up Your<br></br>
            <div className="giftingSubHeading1">Corporate Gifting Game</div>
          </h2>
          <br></br>
          <p className="text-white heading-text-gifting">
            Say goodbye to boring gifts and<br></br>let the good times roll!
          </p>
        </div>
        <div className="position-absolute gift-banner-form">
          <form
            className="d-flex justify-content-between align-content-center flex-column giftingForm p-4"
            onSubmit={submit}
          >
            <h5 className="mb-1 text-center giftingformHeading font-weight-bold">
              Get In Touch
            </h5>
            <p className="m-0 mb-2 text-center giftingtext-d">
              Connect with our team for exclusive deals
            </p>
            <input
              type="text"
              className="form-control input-gifting mb-3 w-100"
              required
              value={name}
              autoComplete="off"
              style={{ background: "none" }}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
            <input
              type="tel"
              className="form-control input-gifting mb-3 w-100"
              required
              value={phoneNumber}
              style={{ background: "none" }}
              onChange={(e) => {
                const phoneNumber = e.target.value.slice(0, 12);
                setPhoneNumber(phoneNumber);

                if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                  e.target.setCustomValidity("");
                } else {
                  e.target.setCustomValidity(
                    "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                  );
                }
              }}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                )
              }
              placeholder="Phone Number"
            />
            <input
              type="email"
              placeholder="Email"
              required
              className="form-control input-gifting mb-3 w-100"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Company Name"
              required
              className="form-control input-gifting mb-3 w-100"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <button
              type="submit"
              className="giftBtn w-100 home-cta-2 input-gifting"
            >
              Submit
            </button>
            <small
              className="text-center text-secondary mt-4"
              style={{ fontSize: "0.7em" }}
            >
              By choosing to confirm, you consent to share your personal
              information with EMotorad. Terms and Conditions applied.
            </small>
          </form>
        </div>
        <img
          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/WEB+1.jpg"
          className="img-fluid"
        ></img>
        {/* <div className="d-flex justify-content-between">
          <div>
            <h1 className=" text-white giftingHeading">
              A Gift That Inspires Greatness
            </h1>
            <br></br>
            <br></br>
            <p className="text-white heading-text-gifting">
              Show your team you value their lifestyle beyond work. <br></br>
              Gift them electric mobility for a sustainable lifestyle <br></br>
              and a healthier future.
            </p>
          </div>
          <div>
            <form
              className="d-flex justify-content-between align-content-center flex-column giftingForm p-4"
              onSubmit={submit}
            >
              <h5 className="mb-3 text-center giftingformHeading font-weight-bold">
                Movements of Fun
              </h5>
              <p className="m-0 mb-3 text-center giftingtext">
                Fill in your details for the best deals
              </p>
              <input
                type="text"
                className="form-control input-sm mb-3 w-100"
                required
                value={name}
                autoComplete="off"
                style={{ background: "none" }}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
              <input
                type="tel"
                className="form-control input-sm mb-3 w-100"
                required
                value={phoneNumber}
                style={{ background: "none" }}
                onChange={(e) => {
                  const phoneNumber = e.target.value.slice(0, 12);
                  setPhoneNumber(phoneNumber);

                  if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                    e.target.setCustomValidity("");
                  } else {
                    e.target.setCustomValidity(
                      "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                    );
                  }
                }}
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                  )
                }
                placeholder="Phone Number"
              />
              <input
                type="email"
                placeholder="Email"
                required
                className="form-control input-sm mb-3 w-100"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="text"
                placeholder="Company Name"
                required
                className="form-control input-sm mb-3 w-100"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <select
                required
                className="form-select input-sm mb-3 w-100"
                onChange={(e) => setOrderSize(e.target.value)}
              >
                <option selected disabled style={{ color: "grey" }}>
                  Order Size
                </option>
                <option value="1-10">1-10</option>
                <option value="10-20">10-20</option>
                <option value="20-50">20-50</option>
                <option value="50-100">50-100</option>
                <option value="100+">100+</option>
              </select>
              <select
                required
                className="form-select input-sm mb-3 w-100"
                onChange={(e) => setModel(e.target.value)}
              >
                <option selected disabled style={{ color: "grey" }}>
                  SKU / Model
                </option>
                {bikes &&
                  bikes
                    .filter(
                      (item) =>
                        item.name !== "Legend 07 MSD Edition" &&
                        item.name !== "EMX Aqua"
                    )
                    .map((item, index) => (
                      <option value={item.name}>{item.name}</option>
                    ))}
              </select>
              <textarea
                type="text"
                textarea
                placeholder="Message"
                required
                className="form-control input-sm mb-3 w-100"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button type="submit" className="giftBtn w-100 home-cta-2">
                Submit
              </button>
            </form>
          </div>
        </div> */}
      </div>
      {/* mobile form */}
      <div className="d-lg-none">
        <img
          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/WEB+2.jpg"
          className="img-fluid "
        ></img>
      </div>
      <div className="ps-3 pe-3 mt-2 d-lg-none">
        <form onSubmit={submit} className="p-3  gifting-form-div-main mt-4 ">
          <h5 className="text-center giftingformHeading font-weight-bold">
            Get In Touch
          </h5>
          <p
            className="m-0 text-center giftingtext"
            style={{ fontSize: "0.95em" }}
          >
            Connect with our team for exclusive deals
          </p>

          <div className="mt-3" id="landingform">
            <input
              type="text"
              placeholder="Name"
              required
              className=" mb-3 form-input"
              value={name}
              pattern="[A-Za-z\s]{2,}"
              onChange={(e) => {
                setName(e.target.value);
                e.target.setCustomValidity("");
              }}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Name should have at least 2 characters and contain only alphabets."
                )
              }
            />
            <input
              type="number"
              placeholder="Phone / WhatsApp number"
              required
              className=" mb-3 form-input"
              value={phoneNumber}
              onChange={(e) => {
                const phoneNumber = e.target.value.slice(0, 12);
                setPhoneNumber(phoneNumber);

                if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                  e.target.setCustomValidity("");
                } else {
                  e.target.setCustomValidity(
                    "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                  );
                }
              }}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                )
              }
            />
            <input
              type="email"
              placeholder="Email"
              required
              className=" mb-3 form-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Company Name"
              required
              className=" mb-3 form-input"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="btn submit-btn-gift btn-block w-100 "
          >
            Submit
          </button>
          <small className="text-center mt-4 " style={{ fontSize: "0.7em" }}>
            By choosing to confirm, you consent to share your personal
            information with EMotorad. Terms and Conditions applied.
          </small>
        </form>
      </div>
      {/* image grid */}
      <div className="mt-5 mb-5 pt-4" style={{ backgroundColor: "#F0F0F0" }}>
        <h1
          className=" mb-3 text-uppercase text-em heading-giftpage ps-3 pe-3 ps-lg-5 pe-lg-5"
          style={{
            fontFamily: "Metropolis-black",
          }}
        >
          WHAT MAKES OUR E-CYCLES THE PERFECT GIFT?
        </h1>
        <div className="p-lg-5 p-3 pb-0 pt-0 pb-lg-0 pt-lg-0">
          <Splide
            className="p-1 pb-3 pt-4 flex align-items-center"
            options={{
              type: "loop",
              drag: false,
              arrows: false,
              rewind: "false",
              pagination: false,
              autoplay: false,
              perMove: 1,
              gap: "2em",
              padding: "0em",
              pauseOnHover: false,
              resetProgress: false,
              interval: 4000,
              perPage: 3,
              speed: 1000,
              breakpoints: {
                640: {
                  type: "loop",
                  drag: true,
                  perPage: 1,
                  arrows: false,
                  pagination: true,
                  autoplay: true,
                  perMove: 1,
                  gap: "1em",
                  padding: "0em",
                  pauseOnHover: true,
                  resetProgress: false,
                  interval: 4000,
                  speed: 1000,
                },
              },
            }}
          >
            <SplideSlide key="slide1" className="mb-2">
              <div className="usecase">
                <div className="usecase-img ">
                  <img
                    className="img-fluid rounded-4"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/Layer+4.jpg"
                  ></img>
                </div>
                <div className=" text-center d-flex justify-content-center">
                  <p className="usecase-text">
                    Perfect for commutes, health & wellness
                  </p>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide key="slide1" className="mb-2">
              <div className="usecase">
                <div className="usecase-img">
                  <img
                    className="img-fluid rounded-4"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/Layer+5.jpg"
                  ></img>
                </div>
                <div className=" text-center d-flex justify-content-center">
                  <p className="usecase-text">
                    Ditch Monday blues with sustainable hues
                  </p>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide key="slide1" className="mb-2">
              <div className="usecase">
                <div className="usecase-img">
                  <img
                    className="img-fluid rounded-4"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/Layer+6.jpg"
                  ></img>
                </div>
                <div className=" text-center d-flex justify-content-center">
                  <p className="usecase-text">
                    Glide through traffic effortlessly at every turn
                  </p>
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>

        {/* <div className="row">
          <div className="col-12 col-lg-6 mb-4">
            <div className="position-relative">
              <div className="gradient-grey"></div>
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/Artboard+1.jpg"
                className="img-fluid"
              ></img>
              <div className="position-absolute desk-gif-1">
                <h4 className="text-light m-0 mb-3 gift-grid-text ps-2 pe-2">
                  Keep fitness in check and add movements of fun
                </h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-4">
            <div className="position-relative">
              <div className="gradient-grey"></div>
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/Artboard+3.jpg"
                className="img-fluid"
              ></img>
              <div className="position-absolute desk-gif-1">
                <h4 className="text-light m-0 mb-3 gift-grid-text ps-2 pe-2">
                  Juniors to seasoned pros, it's feasible for everyone
                </h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-4 mb-lg-0">
            <div className="position-relative">
              <div className="gradient-grey"></div>
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/Artboard+2.jpg"
                className="img-fluid"
              ></img>
              <div className="position-absolute desk-gif-1">
                <h4 className="text-light m-0 mb-3 gift-grid-text ps-2 pe-2">
                  Skip traffic and reach on time
                </h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="position-relative">
              <div className="gradient-grey"></div>
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/Artboard+4.jpg"
                className="img-fluid"
              ></img>
              <div className="position-absolute desk-gif-1">
                <h4 className="text-light m-0 mb-3 gift-grid-text ps-2 pe-2">
                  Say goodbye to fuel; this gift keeps giving
                </h4>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* products */}
      <div className="mt-5 mb-5">
        <h1
          className="mb-3 ps-3 pe-3 ps-lg-5 ps-lg-5 text-uppercase text-em heading-giftpage"
          style={{
            fontFamily: "Metropolis-black",
          }}
        >
          hop on and ride the vibe!
        </h1>
        <div className="gift-products ps-3 pe-3 p-lg-0">
          <Swiper
            navigation={{ clickable: true }}
            spaceBetween={15}
            slidesPerView={1.1}
            initialSlide={2}
            centeredSlides={false}
            loop={true}
            breakpoints={{
              1024: {
                slidesPerView: 3.8,
                spaceBetween: 20,
                centeredSlides: true,
              },
              1724: {
                slidesPerView: 4.3,
                spaceBetween: 20,
                centeredSlides: true,
                loop: true,
                initialSlide: 2,
              },
            }}
            slide
          >
            {bikes &&
              bikes
                .filter(
                  (item) =>
                    item.name !== "Legend 07 MSD Edition" &&
                    item.name !== "EMX Aqua"
                )
                .map((item, index) => (
                  <SwiperSlide key={index} className="p-1">
                    <div
                      className="border position-relative rounded-3 p-2"
                      style={{
                        boxShadow:
                          "0px 0px 5px 5px rgba(0, 96, 214, 0.3), -0px -0px 1px 1px rgba(0, 96, 214, 0.3)",
                        overflow: "hidden",
                      }}
                    >
                      <div>
                        <img
                          src={item.cartImages[modelStates[index]]}
                          alt=""
                          className="img-fluid "
                        />
                      </div>
                      <div className="ps-2  pe-2">
                        <div
                          className="d-flex mb-2 justify-content-center"
                          style={{ gap: "0.5em" }}
                        >
                          {item.models.map((model, colorIndex) => (
                            <button
                              key={colorIndex}
                              className={`rounded-circle home-bike-card-color border-0  ${
                                modelStates[index] === colorIndex
                                  ? "active"
                                  : ""
                              }`}
                              style={{
                                pointer: "cursor",
                                width: "30px",
                                minHeight: "30px",
                                background: model.colorCode,
                              }}
                              onClick={() =>
                                handleColorClick(index, colorIndex)
                              }
                            ></button>
                          ))}
                        </div>
                        <div className="mt-4">
                          <h3
                            className=" m-0 mb-3"
                            style={{
                              fontSize: "1.6rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontWeight: "600",
                              fontFamily: "Metropolis-bold",
                            }}
                          >
                            {item.name === "X1 Limited Edition" ||
                            item.name === "Legend 07 MSD Edition" ? (
                              <>
                                {item.name === "X1 Limited Edition" ? (
                                  <>
                                    X1{" "}
                                    <span
                                      style={{
                                        fontSize: "0.5em",
                                        fontFamily: "Metropolis-medium",
                                      }}
                                    >
                                      Limited Edition
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    Legend 07{" "}
                                    <span
                                      style={{
                                        fontSize: "0.5em",
                                        fontFamily: "Metropolis-medium",
                                      }}
                                    >
                                      MSD Edition
                                    </span>
                                  </>
                                )}
                              </>
                            ) : (
                              item.name
                            )}
                          </h3>
                        </div>

                        {item.highLights?.map((highlight, i) => (
                          <p
                            key={i}
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              width: "100%",
                              fontSize: "0.8em",
                            }}
                          >
                            <i className="fa-solid fa-circle-stop text-em "></i>{" "}
                            {highlight}
                          </p>
                        ))}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>
      </div>
      {/* testimonials */}
      <div className="testimonials-gift pt-4">
        <h1
          className="heading-giftpage text-uppercase text-light ps-3 pe-3 ps-lg-5 pe-lg-5 "
          style={{
            fontFamily: "Metropolis-black",
          }}
        >
          OUR HAPPY CUSTOMERS
        </h1>
        <div className="ps-3 pe-3 ps-lg-5 pe-lg-5 p-lg-5 pt-lg-4 p-4">
          <div className="testimonials-format" style={{ gap: "1.5em" }}>
            <div className="testimonials-card text-light me-4 me-lg-0">
              <div className="p-3 p-lg-4">
                <div className="reviews-desc mb-4">
                  <p className="mb-2">
                    My team only deserves the best. After an amazing 2023, I
                    wanted to gift something special to my team for putting in
                    their best effort. I wanted it to have a special place in
                    their heart, and a gift that they would cherish forever, and
                    this was just the ticket!
                  </p>
                </div>
                <div className="creds">
                  <p className="m-0" style={{ fontFamily: "Metropolis-bold" }}>
                    Riddhi Bhog{" "}
                  </p>
                  <p className="m-0">CEO and Founder</p>
                  <p className="m-0">Hedonist</p>
                </div>
              </div>
            </div>
            <div className="testimonials-card text-light ms-4 me-lg-0 ms-lg-0">
              <div className="p-3 p-lg-4">
                <div className="reviews-desc mb-4">
                  <p className="mb-2">
                    I received this gift from my employer as an award for “Best
                    Team Player” and I didn’t see this coming at all. It would
                    take me hours to commute to work, leaving me exhausted most
                    of the time. I now cut through traffic, save money, and look
                    better than ever.
                  </p>
                </div>
                <div className="creds">
                  <p className="m-0" style={{ fontFamily: "Metropolis-bold" }}>
                    Mihir Rajurkar
                  </p>
                  <p className="m-0">Sr. Client Success Manager</p>
                  <p className="m-0">Phreesia</p>
                </div>
              </div>
            </div>
            <div className="testimonials-card text-light me-4 me-lg-0">
              <div className="p-3 p-lg-4">
                <div className="reviews-desc mb-4">
                  <p className="mb-2">
                    Finding the ideal gift for VIPs and top tier clients has
                    always been a daunting task. This year, we combined health
                    and functionality to create the perfect solution: an
                    electric cycle, seamlessly blended luxury with utility
                    making it the perfect gifting solution.
                  </p>
                </div>
                <div className="creds">
                  <p className="m-0" style={{ fontFamily: "Metropolis-bold" }}>
                    Shilpa Nair
                  </p>
                  <p className="m-0">HRBP</p>
                  <p className="m-0">NICE Solutions</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* icons */}
      <div className="mt-5 ps-3 pe-3 ps-lg-5 pe-lg-5">
        <h1
          className=" mb-3 heading-giftpage text-uppercase text-em "
          style={{
            fontFamily: "Metropolis-black",
          }}
        >
          WHY CHOOSE EMOTORAD?
        </h1>
        <div className="d-lg-flex d-none justify-content-between  align-items-center p-5 pt-4">
          <div className="support-div">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/growth.png"
              className="support-img"
            ></img>
            <p className="text-center mt-3">India’s Leading E-cycle Brand</p>
          </div>
          <div className="support-div">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/save-the-world.png"
              className="support-img"
            ></img>
            <p className="text-center mt-3">Unique Sustainable Gifts</p>
          </div>
          <div className="support-div">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/corporation.png"
              className="support-img"
            ></img>
            <p className="text-center mt-3">Exclusive Corporate Offers</p>
          </div>
          <div className="support-div">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/free-delivery.png"
              className="support-img"
            ></img>
            <p className="text-center mt-3">Free Delivery Pan India</p>
          </div>
          <div className="support-div">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/support.png"
              className="support-img"
            ></img>
            <p className="text-center mt-3">Quick Resolution Under 72 Hours</p>
          </div>
        </div>
        <div className="d-flex justify-content-between  align-items-center d-lg-none mt-4">
          <div className="support-div">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/growth.png"
              className="support-img"
            ></img>
            <p className="text-center mt-3 support-text">
              India’s Leading E-cycle Brand
            </p>
          </div>
          <div className="support-div">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/save-the-world.png"
              className="support-img"
            ></img>
            <p className="text-center mt-3 support-text">
              Unique Sustainable Gifts
            </p>
          </div>
          <div className="support-div">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/corporation.png"
              className="support-img"
            ></img>
            <p className="text-center mt-3 support-text">
              Exclusive Corporate Offers
            </p>
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center d-lg-none mt-3"
          style={{ gap: "3em" }}
        >
          <div className="support-div">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/free-delivery.png"
              className="support-img"
            ></img>
            <p className="text-center mt-3 support-text">
              Free Delivery Pan India
            </p>
          </div>
          <div className="support-div">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gifting/support.png"
              className="support-img"
            ></img>
            <p className="text-center mt-3 support-text">
              Quick Resolution Under 72 Hours
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
