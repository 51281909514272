import React from "react"
import Footer from "../Components/Footer"
import Navbar from "../Components/Navbar"
const TermsAndCondition = () => {
	return (
		<div className='container-fluid p-0'>
			<div className=''>
				<Navbar />
			</div>
			<div className='container mb-lg-5 pb-lg-5'>
				<div className='mt-4'>
					<p className=' font-mon warranty-border-heading  d-lg-none mt-4 pt-lg-3'>
						Terms & Conditions
					</p>
					<hr className='d-lg-none' />
					<div className='margin-top-5 d-none d-lg-block'>
						<hr className='d-lg-none' />
						<div className='d-flex down-border pb-4 '>
							<div className='col-8'>
								<p className=' font-mon mb-lg-4 border-heading pt-lg-3'>
									Terms & Conditions
								</p>
							</div>
						</div>
					</div>
					<p className='mt-2 text-justify'>
						Welcome to www.emotorad.com (the
						“Website”). There are a few rules,
						which our visitors must follow when
						using our Website, so we ask all our
						visitors to read our Terms and
						Conditions of Use carefully. This
						Website is brought to you by Inkodop
						Technologies Private Limited and its
						group entities, doing business as
						EMotorad (“EM” or “we”, or “us”) and
						all rights, including copyright, in
						the content of this Website is owned
						or controlled by EM. EM and its
						affiliates provide access to the
						Website subject to the Terms and
						Conditions of Use set out on this
						page. If you have any comments or
						queries relating to this Website,
						please contact us via email at
						contactus@emotorad.com or by post at:
						At post Jambe, Taluka Mulshi, 169/2
						Sangawade, Road, Pune, Maharashtra
						411033. This Document is an electronic
						record in terms with the Information
						Technology Act, 2000 and the
						Information Technology (Intermediary
						Guidelines) Rules, 2011, and any other
						applicable law(s) in force, in India,
						regarding the access and use of the
						Website ‘EMotorad .’ The acceptance to
						the Terms and Conditions of Use by the
						visitor shall be considered as a valid
						contract under the Indian Contract
						Act, 1872. All notices under this
						agreement will be considered as
						written and have been duly signed once
						the same is electronically confirmed.
						For purposes of ease of understanding,
						terms such as “You” and “User” and
						“Visitor”, shall be used to refer to
						the customer. Please read this
						document carefully before partaking in
						the use of the Platform and any of the
						services so offered by it. By using
						this site, you affirm that you are
						able and legally competent to accept
						and agree to these terms and our
						privacy policy and cookie policy. If
						you are using this Website, you are
						deemed to accept the terms and
						conditions mentioned under this policy
					</p>

					<ol className='mt-5'>
						<li className='h5 mt-4 fw-bold '>
							DEFINITIONS:
						</li>
						<ul>
							<li className='mt-3'>
								<h6 className='fw-bold'>
									{" "}
									CONFIDENTIAL INFORMATION:{" "}
								</h6>
								shall mean any personal
								information of the User,
								including, without limitation,
								full name, email address, contact
								number, postal address, company
								name, date of birth, etc. of the
								User that is required to provide
								in the submission form in lieu of
								any services that the User wishes
								to receive from EM. Further, the
								receipt of such Personal
								Information from the User is
								mandatory to provide the requested
								services.
							</li>

							<li className='mt-3'>
								<h6 className='fw-bold'>
									USER:{" "}
								</h6>{" "}
								shall include any person who has
								attained 16 years of age and is
								legally competent to enter into a
								valid contract, irrespective of
								any nationality, and accesses the
								Website of EM for any legitimate
								interest only
							</li>
							<li className=' mt-3'>
								<h6 className='fw-bold'>
									INTELLECTUAL PROPERTY{" "}
								</h6>
								shall mean and include Trademarks,
								Copyrights, Website content,
								Logos, Words, terminologies,
								business concept etc. belonging to
								Website.
							</li>
							<li className=' mt-3'>
								<h6 className='fw-bold'>
									CYBER CRIME
								</h6>
								shall mean any act or omission
								committed by the User or any third
								party for any illegal or unlawful
								purposes(s) by utilizing the
								services provided by the Website
								of EM.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							SERVICES:
						</li>
						<ul>
							<li>
								‘EMOTORAD’, creates electric
								bicycles, electric scooters,
								electric bikes and their parts
							</li>
							<li>
								These bikes, bicycles, scooters
								are carefully created and
								manufactured by EMOTORAD for
								creating an environment friendly
								alternative for the usage of the
								bikes, scooters and other
								vehicles.
							</li>
							<li>
								The bikes, scooters, bicycles, etc
								have been manufactured by EMOTORAD
								for making it user friendly and
								more efficient in respect to
								nature. This is purely based on
								the rechargeable batteries and
								electricity.
							</li>
							<li>
								Additionally, we also intend to
								identify the present need for
								saving the environment with
								effective measures. Therefore, we
								have created and manufactured
								electric bikes and cycles.
							</li>
							<li>
								The User shall, for the purposes
								of using the services of the
								Platform, create an account and
								shall be responsible for
								maintaining the confidentiality of
								the login details, display name
								and purchase history. The User
								shall also be responsible for all
								activities taking place under his
								login ID and password. Details
								such as location, name, and so on
								as provided by the users shall be
								accessible by the Website for the
								purpose of providing the services
								of the Website as well as to other
								users contacting them.
							</li>
							<li>
								The Platform has the sole and
								exclusive right to suspend, block,
								impede, deny access and/or
								terminate the account of the User.
								In the event of there being any
								unauthorised breach of the account
								of the User, the User shall
								immediately notify the Grievance
								Officer of the same who shall take
								necessary Action in an expeditious
								manner.
							</li>
							<li>
								Should the User choose any paid
								activity or feature of the
								Website, they shall confirm the
								purchase(s) and then be directed
								to the payment processor gateway
								(Payment Gateway) which shall be a
								third party. The User must go
								through their privacy policy and
								terms of use before proceeding to
								make a payment using the Payment
								Gateway. The Website shall in no
								manner be responsible for any and
								all issues arising from and in
								relation to the Payment Gateway.
							</li>
							<li>
								The User(s) agrees that all
								features will be used entirely at
								their own risk and EM accepts no
								responsibility for any direct,
								indirect, incidental,
								consequential, special, exemplary
								and/or pecuniary losses suffered
								by the User or a third party as a
								result of User’s use of the
								Website
							</li>
							<li>
								We do not seek or accept
								unsolicited ideas. By submitting
								any material to us through any of
								the Site, the User expressly
								grants us and our affiliates a
								royalty-free, non-exclusive, fully
								transferable, assignable and
								sublicensable right and license to
								use, reproduce, modify, display,
								transmit, adapt, publish,
								translate, create derivative works
								from and distribute such material,
								throughout the world and in
								perpetuity, in all media now known
								or hereafter devised and for all
								lawful business purposes. The User
								also acknowledge and agree that if
								and to the extent the material
								submitted by the User identifies
								him/her by name, likeness, voice
								or otherwise (“User
								identification”), the foregoing
								license granted by the User will
								automatically be deemed to cover
								and extend to our use of User
								identification in connection with
								our use of the material submitted
								by the User.
							</li>
							<li>
								In the event the User wishes to
								cancel, request refunding or
								terminate any purchase, then the
								User shall proceed in the manner
								as specified under this Agreement.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							CONFIDENTIALITY
						</li>
						<ul>
							<li>
								The information collected from the
								User shall be confidential
								information and it shall not be
								shared with any third party.
							</li>
							<li>
								The information collected from the
								User shall be confidential
								information and it shall not be
								shared with any third party.
							</li>
							<li>
								The Confidential Information may
								also be shared with the
								Affiliates, group companies,
								employees and other business
								partners for the legitimate
								purpose of promotion of the
								business, carrying out order
								processing activities concerning
								the User or any other activity as
								a part of the business.
							</li>
							<li>
								The Confidential Information may
								be shared with the law enforcement
								agencies and governmental
								authorities for legal and
								statutory compliance.
							</li>
							<li>
								The Confidential Information shall
								be shared if it is a requirement
								to launch on other platforms such
								as IOS, Android, Google, Microsoft
								etc.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							USER CONDUCT
						</li>
						<p>
							That the User must, for the purposes
							of being granted access to the
							Website and services offered
							thereunder, uphold and abide by the
							following:
						</p>
						<ul>
							<li className='mt-2'>
								Engage in any form of activity
								whereby the confidential
								information of other User(s),
								including but not limited to their
								location, password, login details,
								purchase history and other such
								account details, is collected in
								any manner contrary to the law of
								the land.
							</li>
							<li className='mt-2'>
								Partake in any form activity on
								the Website by using the account
								of another User without their
								consent / knowledge.
							</li>
							<li className='mt-2'>
								Publish, put up, conduct business
								in a manner that is wrong,
								harassing, blasphemous, obscene,
								defamatory, libellous, hateful,
								disparaging, promote illegal
								activities such as gambling,
								pornography, money laundering or
								any other acts, material or images
								deemed unlawful by the law of the
								land.
							</li>
							<li className='mt-2'>
								Partake in activities which
								infringe on the Privacy of other
								User(s), Website and any other
								individuals so associated,
								representatives, agents and even
								employees without their explicit
								consent.
							</li>
							<li className='mt-2'>
								Behave in any manner deemed to be
								threatening, defamatory, obscene,
								disparaging and/or hurtful to any
								other individual on the basis of
								their religion, caste, class,
								creed, sex or other such factors
								deemed necessary in the eyes of
								law
							</li>
							<li className='mt-2'>
								Partake in any activity causing
								there to be unsolicited emails,
								messages, calls to other User(s),
								Websites and any other individuals
								so associated, representatives,
								agents and even employees of the
								same.
							</li>
							<li className='mt-2'>
								Partake in any action which
								directly or indirectly infringes
								on the intellectual property
								rights of the users, Website or
								any other individual, association,
								corporate body, company, shop
								and/or commercial entity
							</li>
							<li className='mt-2'>
								Partake in the marketing,
								advertising, providing
								information, instructions and/or
								solicitation of activities,
								objects, products, goods or works
								deemed unlawful in the eyes of the
								law.
							</li>
							<li className='mt-2'>
								Exceed the scope of authorised
								access to the Website in a manner
								so deemed unlawful and/or
								inappropriate by the law of the
								land.
							</li>
							<li className='mt-2'>
								Interfere with any other User(s)
								right to enjoy the services of the
								Website in any manner which is
								held, construed and/or interpreted
								as being unlawful under the law of
								the land and/or as per the
								direction of the Website.
							</li>
							<li className='mt-2'>
								Partake in the filing of
								information solely for fraudulent
								or misinformative purposes which
								are against the law of the land.
							</li>
							<ul className='mt-2'>
								The User(s) shall be deemed to
								conducting themselves in an
								unlawful manner should they:
								<li className='mt-2'>
									Gain access to the Platform
									despite refusing to fill in the
									account details in a proper
									manner;
								</li>
								<li className='mt-2'>
									Harass the user(s) / Website
									owner and any other individuals
									so associated, representatives,
									agents and even employees of the
									same in any manner by using the
									contact details so provided;
								</li>
								<li className='mt-2'>
									Partake in any activities with
									the sole intention of causing
									loss of revenue / business
									issues and/or any other
									financial problems to the
									Website.
								</li>
							</ul>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							INTELLECTUAL PROPERTY RIGHTS:
						</li>
						<ul>
							<li className='mt-2'>
								All information, photographs,
								illustrations, artwork and other
								graphic materials, and names,
								logos and trademarks on the
								Website are protected by copyright
								laws, trademark laws
							</li>
							<li className='mt-2'>
								These works, logos, graphics,
								sounds or images may not be
								copied, reproduced, retransmitted,
								distributed, disseminated, sold,
								published, broadcasted, or
								circulated whether in whole or in
								part, unless expressly permitted
								by us.
							</li>
							<li className='mt-2'>
								The reference image uploaded on
								the Website will be purchased
								before being uploaded. All the
								intellectual property rights in
								the design and concept of the
								Website EMotorad, the name, logo,
								trade secrets will vest in the
								owner
							</li>
							<li className='mt-2'>
								Nothing contained on the Website
								should be construed as granting by
								implication, estoppel, or
								otherwise, any license or right to
								use any trademark displayed on the
								Website without the written
								permission. Misuse of any
								trademarks or any other content
								displayed on the Website is
								prohibited.
							</li>
							<li className='mt-2'>
								We will not hesitate to take legal
								action against any unauthorised
								usage of our trademarks,
								copyrights, name or symbols to
								preserve and protect its rights in
								the matter. All rights not
								expressly granted herein are
								reserved. Other product and
								company names mentioned herein may
								also be the trademarks of their
								respective owners
							</li>
							<li className='mt-2'>
								The User shall also not use the
								services provided by the Website
								to broadcast messages to promote
								any form of business that is of
								similar nature to that of the
								services provided by Website
							</li>
							<li className='mt-2'>
								The concept and working of the
								Website are protected under the
								Laws of India and therefore shall
								not be reverse engineered or
								replicated or re-created by the
								User.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							AMENDMENTS
						</li>
						<ul>
							<li className='mt-2'>
								We may periodically make changes
								to the contents of the Website,
								including to the descriptions of
								the services advertised, at any
								time and without notice. We assume
								no liability or responsibility for
								any errors or omissions in the
								content of the Website.
							</li>
							<li className='mt-2'>
								We reserve the right to amend
								these Terms and Conditions of Use
								from time to time without notice.
								The revised Terms and Conditions
								of Use will be posted on the
								Website and shall take effect from
								the date of such posting.
							</li>
							<li className='mt-2'>
								The user is advised to review
								these terms and conditions
								periodically as they are binding
								upon you.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							DISCLAIMER AND EXCLUSION OF
							LIABILITY:
						</li>
						<ul>
							<li className='mt-2'>
								The Website agrees to protect all
								data provided by the User for the
								purpose of using the Website.
							</li>
							<li className='mt-2'>
								The Website agrees to manage,
								store and delete all the data when
								necessary, belonging to the User
								in light of the circumstances that
								are raised.
							</li>
							<li className='mt-2'>
								In light of a scenario where the
								Laws of India, or the courts of
								appropriate jurisdiction, or any
								other appropriate body, in the
								name of public welfare and cyber
								security request the Website to
								provide the data so collected from
								users, then the Website shall
								provide the data, but only under
								the provisions substantiated by
								the law.
							</li>
							<li className='mt-2'>
								The Website shall take complete
								precaution and make effort to
								protect the data provided by the
								User. But considering situations
								unfathomable or beyond the control
								of reasonability, if there is a
								data breach by the actions of a
								third party against public
								welfare, the Website reserves the
								right to stay indemnified by the
								User
							</li>
							<li className='mt-2'>
								To the fullest extent permitted by
								applicable law, we disclaim all
								representations and warranties
								relating to the Website and its
								contents, including in relation to
								any inaccuracies or omissions in
								the Website, warranties of
								merchantability, quality, fitness
								for a particular purpose,
								accuracy, availability,
								non-infringement or implied
								warranties from course of dealing
								or usage of trade.
							</li>
							<li className='mt-2'>
								The Website may contain errors,
								inaccuracies or omissions related
								to product descriptions, pricing,
								promotions, offers, availability
								and other information. If any
								information is inaccurate,
								incomplete or out of date, we
								reserve the right to correct,
								change or update such information
								or to cancel orders related to
								such information (including after
								you have submitted an order)
								without prior notice and issue a
								refund for amounts paid. We cannot
								guarantee that your computer’s
								display of color or other product
								features is accurate. Please note
								that weights may vary, due to many
								variables, such as frame size,
								components included or excluded as
								well as running changes to
								component specifications. We urge
								you to weigh the bikes or frames
								you are considering, at your
								retailer, with identical or
								comparable equipment. There is no
								industry standard as to how a
								bicycle should be weighed.
							</li>
							<li className='mt-2'>
								The Website will always be
								accessible, uninterrupted, timely,
								secure, error free or free from
								computer virus or other invasive
								or damaging code or that the
								Website will not be affected by
								any acts of God or other force
								majeure events, including
								inability to obtain or shortage of
								necessary materials, equipment
								facilities, power or
								telecommunications, lack of
								telecommunications equipment or
								facilities and failure of
								information technology or
								telecommunications equipment or
								facilities
							</li>
							<li className='mt-2'>
								While the Website may use
								reasonable efforts to include
								accurate and up-to- date
								information on the Website, it
								does not guarantee the accuracy,
								timeliness or completeness of the
								Website.
							</li>
							<li className='mt-2'>
								We shall not be liable for any
								acts or omissions of any third
								parties howsoever caused, and for
								any direct, indirect, incidental,
								special, consequential or punitive
								damages, howsoever caused,
								resulting from or in connection
								with the Website and the services
								offered in the Website, your
								access to, use of or inability to
								use the Website or the services
								offered in the Website, reliance
								on or downloading from the Website
								and/or services, or any delays,
								inaccuracies in the information or
								in its transmission including but
								not limited to damages for loss of
								business or profits, use, data or
								other intangible, even if we have
								been advised of the possibility of
								such damages.
							</li>
							<li className='mt-2'>
								We shall not be liable in
								contract, tort (including
								negligence or breach of statutory
								duty) or otherwise howsoever and
								whatever the cause thereof, for
								any indirect, consequential,
								collateral, special or incidental
								loss or damage suffered or
								incurred by you in connection with
								the Website and these Terms and
								Conditions of Use. For the
								purposes of these Terms and
								Conditions of Use, indirect or
								consequential loss or damage
								includes, without limitation, loss
								of revenue, profits, anticipated
								savings or business, loss of data
								or goodwill, loss of use or value
								of any equipment including
								software, claims of third parties,
								and all associated and incidental
								costs and expenses.
							</li>
							<li className='mt-2'>
								The above exclusions and
								limitations apply only to the
								extent permitted by law. None of
								your statutory rights as a
								consumer that cannot be excluded
								or limited are affected.
							</li>
							<li className='mt-2'>
								Notwithstanding our efforts to
								ensure that our system is secure,
								the User acknowledges that all
								electronic data transfers are
								potentially susceptible to
								interception by others. We cannot,
								and do not, warrant that data
								transfers pursuant to the Website,
								or electronic mail transmitted to
								and from us, will not be monitored
								or read by others.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							JURISDICTION AND LEGAL ACTION:
						</li>
						<ul>
							<li className='mt-2'>
								Any dispute arising with respect
								to the usage, services or any
								other dispute arising from a third
								party shall be referred to
								Arbitration
							</li>
							<li className='mt-2'>
								The User shall intimate the
								Website and initiate a discussion
								leading up to Arbitration if
								needed.
							</li>
							<li className='mt-2'>
								The Website reserves the right to
								take legal countermeasures if the
								User chooses to take any legal
								action. The action so taken by us
								will be on grounds of breach of
								contract and misuse of the law
							</li>
							<li className='mt-2'>
								Considering an action caused by a
								third party, the User agrees to
								not initiate any action or
								proceeding for the damage caused
								by a third party.
							</li>
							<li className='mt-2'>
								If we suspect any misuse of the
								Website by the User, then we
								reserve the right to initiate a
								strict legal action against the
								User.
							</li>
							<li className='mt-2'>
								The User must from time to time
								provide proof which is genuine in
								nature in any Dispute Resolution
								model.
							</li>
							<li className='mt-2'>
								Any action taken without the
								consent of the Website will be
								treated as a violation of this
								User Agreement and if it results
								in any damages sustained by us,
								then the User will be dealt with
								as per the provisions of the laws
								of India.
							</li>
							<li className='mt-2'>
								In the event the Parties choose to
								resolve the dispute via
								litigation, they agree to limit
								themselves to the jurisdiction of
								the courts of Pune.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							TERMINATION
						</li>
						<ul>
							<p className='mt-2'>
								That the Website shall have the
								sole and exclusive right to
								unilaterally terminate the account
								of the User immediately in the
								following events:
							</p>
							<li className='mt-2'>
								Should it be found that the said
								User has been engaging in
								activities that violate the Terms
								of Use, Privacy Policy and other
								such policies, the law of the land
								or any other rules and regulations
								put forward by the governing
								authorities.
							</li>
							<li className='mt-2'>
								Should continue in the engagement
								of such activities despite being
								given due warnings not to do so
								via mail.
							</li>
							<li className='mt-2'>
								Are to manipulate, abuse, twist or
								mis-interpret the Terms of Use,
								Privacy Policy and other such
								policies, the law of the land or
								any other rules and regulations
								put forward by the governing
								authorities for purposes of
								partaking in activities that are
								unlawful.
							</li>
							<li className='mt-2'>
								Refusal to make the requisite
								payments for paid features, if
								applicable
							</li>
							<li className='mt-2'>
								Harass, intimidate, provoke,
								abuse, hurt or partake in any
								unlawful action against the owner
								and the Website, and any other
								individuals so associated,
								representatives, agents and even
								employees of the same. Should the
								User decide to terminate their
								account themselves, they may do so
								only after having:
							</li>
							<p className='mt-2'>
								Completed any and all pending
								payments
							</p>
							<li>
								Should not upload, create or share
								any malicious software, content,
								virus or destructive algorithm
								which may cause harm to the other
								users, Website or any other
								concerned individual / third
								party.
							</li>
							<li className='mt-2'>
								Breach any part of the Terms of
								Use, Privacy Policy and other such
								policies, the law of the land or
								any other rules and regulations
								put forward by the governing
								authorities.
							</li>

							<p className='mt-2'>
								Once the account is terminated,
								then the Website shall not grant
								anyone access to the information
								and the same shall be erased
								within 90 days from any and all
								storage servers and other such
								personalised hardware used for the
								working of the Platform.
							</p>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							INDEMNITY
						</li>
						<ul>
							<li>
								The User shall hold harmless and
								indemnify the owner and the
								Website, and any other individuals
								so associated, representatives,
								agents and even employees of the
								same from any and all claims,
								demands, actions, attorney’s fees
								made by any individual or third
								party due to a breach of the Terms
								of use, Privacy Policy and other
								such policies, the law of the land
								or any other rules and regulations
								put forward by the governing
								authorities caused directly /
								indirectly by the User and/or
								their actions while utilizing the
								Platform.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							GRIEVANCE OFFICER:
						</li>
						<ul>
							<li>
								In the event there is any
								complaint / clarification / doubt/
								issue that the User so seeks to be
								clarified, they must contact the
								Grievance Officer. Following are
								the details of the Grievance
								Officer:
							</li>
						</ul>
						<hr />
						<p
							className='mb-0 text-muted mt-3'
							style={{ fontSize: "0.8em" }}>
							FullName:
						</p>
						<h4 className='fw-bold'>
							Arjun Khatri
						</h4>
						<hr />
						<p
							className='mb-0 text-muted mt-3'
							style={{ fontSize: "0.8em" }}>
							Email ID:
						</p>
						<h4 className='fw-bold'>
							arjun@emotorad.com
						</h4>

						<hr />
						<p
							className='mb-0 text-muted mt-3'
							style={{ fontSize: "0.8em" }}>
							Office Number:
						</p>
						<h4 className='fw-bold'>
							+91 8686050590
						</h4>

						<hr />
						<p>
							The Grievance Officer shall attempt
							to resolve any and all issues so
							brought before him /her in a fair
							and expeditious manner within 28
							business days. After completion of
							the review the Grievance Officer
							shall submit a report to the User
							and the Website.
						</p>

						<li className='h5 mt-4 fw-bold'>
							PRICES OF PRODUCTS:
						</li>
						<ul>
							<li>
								All prices made are applicable
								only to quantities specified and
								change from time to time
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							RETURNS AND CANCELLATIONS
						</li>
						<ul>
							<li>
								For returns and refunds, please
								refer to the Returns and
								Cancellation Policy available on
								the Website.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							DELIVERY AND PASSING OF RISK:
						</li>
						<ul>
							<li>
								Any dates and times quoted for
								delivery are to be treated as an
								estimate and the Company shall not
								be liable whatsoever for failure
								to deliver by such date or time.
								The risk in the goods shall pass
								to the Customer on delivery to the
								Customer's premises or to any
								person or party authorised by the
								Customer to receive goods. We
								shall not be responsible for loss
								arising from delay or failure to
								deliver arising from causes beyond
								our control. These include the Act
								of God, war, civil disturbance,
								riot, industrial action or
								dispute, natural calamity,
								controls, restrictions or
								prohibitions of Government or
								other competent authority, fire,
								flood, sabotage or
								non-availability of materials.
								Once the product(s) is delivered,
								it cannot be returned or cancelled
								and no refund will be provided for
								the same. Product(s) are shipped
								on client/customer risk, we are
								not liable for any damage in
								transport.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							RETENTION OF TITLE:
						</li>
						<ul>
							<li>
								Goods shall remain the property of
								EMotorad until payment in full has
								been received. Until title to the
								goods passes to the Customer he
								shall hold the goods for EMotorad
								and shall store the goods in such
								a manner as they are readily
								identifiable as the property of
								EMotorad. Ownership of all tooling
								and film positives rests with
								EMotorad.
							</li>
						</ul>
						<li className='h5 mt-4 fw-bold'>
							LEGAL STATEMENT
						</li>
						<ul>
							<p>
								All text, images, animation,
								videos, music, sounds, website
								design graphics, text selections,
								arrangements and all software are
								copyright © Inkodop Technologies
								Private Limited, unless stated
								otherwise. <br /> EM and EMotorad
								brand logos are registered
								trademarks of Inkodop Technologies
								Private Limited and are used under
								license by Inkodop Technologies
								Private Limited, unless stated
								otherwise. <br />
								www.emotorad.com is a registered
								domain name of InkoDop
								Technologies Private Limited.{" "}
								<br /> <br />
								Registered Office: <br /> 30C,
								Vivene Valley, Lake Land Country
								Club, Kona Expressway, P.O.: Nibra
								Domjur, Santragachi, Howrah, West
								Bengal 711403. <br /> <br />{" "}
								Corporate Office: <br />
								At post Jambe, Taluka Mulshi,
								169/2 Sangawade, Road, Pune,
								Maharashtra 411033.
							</p>
						</ul>
					</ol>
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default TermsAndCondition
