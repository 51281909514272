import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import "../CSS/waitlistLandingPage.css";
import Meta from "../Components/Meta.js";
import { getBikeByName, getAllBikes } from "../Actions/bikeActions.js";
import { useDispatch, useSelector } from "react-redux";
import ModalMobile from "../Components/ModalMobile.js";
import { Link } from "react-router-dom";

function WaitlistLandigPage() {
  const allBikes = useSelector((state) => state.allBikes);
  const { bikes } = allBikes;
  const bikeByName = useSelector((state) => state.bikeByName);
  const { bike: bikeInfo } = bikeByName;
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getBikeByName("x1-pro-legend-07"));
    };
    fetchData();
  }, [dispatch, allBikes]);
  const [isDimensionOpen, setIsDimensionOpen] = useState(true);
  const toggleDimensionCollapse = () => {
    setIsDimensionOpen(!isDimensionOpen);
  };
  const [Collapse, setCollapse] = useState(false);

  return (
    <>
      <Meta
        title={"Buy Latest EMotorad Electric Bicycle | Latest Collection"}
        description={
          "Get ahead with the latest EMotorad electric bicycles. Our new collection offers enhanced features, superior comfort, and unmatched performance."
        }
      />
      <Navbar />
      <div className="position-relative">
        <picture>
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/msd+blue/Mobile+msd+bleed+blue+edition.jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/msd+blue/desktop+msdbleed+blueedition.jpg"
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/msd+blue/desktop+msdbleed+blueedition.jpg"
            className="img-fluid w-100"
            alt=""
            srcSet=""
          />
        </picture>
        <div className="position-absolute kalki-banner-btn">
          <ModalMobile
            className="kalki-btn py-3 px-4 px-lg-5"
            text="PRE-BOOK NOW @ ₹999"
          />
        </div>
      </div>

      {bikeInfo && (
        <div className="">
          <div
            className="text-white px-3 px-lg-5 py-3 mb-4"
            style={{ backgroundColor: "#004dac" }}
          >
            <p className="m-0 text-center text-main">
              Ride Signed Mahi special ecycle- Legend 07, now in an all-new
              bleed blue vibe.
            </p>
          </div>
          <div className="text-center d-none d-lg-block">
            <h1 className="text-passion text-uppercase">
              Legend 07- Bleed Blue
            </h1>
            <h4 className="fw-bold">Limited Edition Launch</h4>
            <p className="d-none d-lg-block">
              Get the captain cool of all ecycles and ride the electric way,
              mahi way!
            </p>
          </div>

          <div className="row p-lg-5 p-3 mb-3" style={{ margin: "auto" }}>
            <div className="col-lg-6 col-12 text-center">
              <div className="d-lg-none">
                <h1 className="text-passion text-uppercase">
                  Legend 07- Bleed Blue
                </h1>
                <h4 className="fw-bold">Limited Edition Launch</h4>
              </div>
              <div className="">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/msd+blue/Dhoni+blue.jpg"
                  className="img-fluid"
                ></img>
              </div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ gap: "1em" }}
              >
                <h4
                  className="text-center "
                  style={{
                    fontFamily: "Metropolis-medium",
                    textDecoration: "line-through",
                  }}
                >
                  ₹39,999
                </h4>
                <h3
                  className="text-center fw-bold text-em"
                  style={{
                    fontFamily: "Metropolis-medium",
                  }}
                >
                  ₹29,999
                </h3>
              </div>

              <div className="text-center d-none d-lg-block">
                <ModalMobile
                  className="kalki-btn py-3 px-5 py-lg-3 px-lg-5"
                  text="PRE-BOOK NOW @ ₹999"
                />
              </div>
              <p className="text-em fw-bold p-highlighted d-lg-block d-none mt-3">
                Limited stock available.
              </p>
            </div>
            <br></br>
            <div className="col-lg-6 col-12  mb-lg-0 p-font">
              <p className="text-center d-lg-none">
                Get the captain cool of all ecycles and ride the electric way,
                mahi way!
              </p>
              <div className="text-center d-lg-none mt-2 mb-2">
                {/* <a
                  href="https://rzp.io/l/WtgAb3fTkS"
                  className=" text-decoration-none kalki-btn py-3 px-5"
                >
                  PRE-BOOK NOW @ ₹2898
                </a> */}
                <ModalMobile
                  className="kalki-btn py-3 px-5 py-lg-3 px-lg-5"
                  text="PRE-BOOK NOW @ ₹999"
                />
              </div>
              <p className="text-em fw-bold p-highlighted text-center d-lg-none">
                Limited stock available
              </p>

              <h5 className="fw-bold mt-4 mb-3 text-em">Specs</h5>
              <div className="mb-3">
                <div className="collapse show" id="collapseExample1">
                  <div className="">
                    {bikeInfo.specs.map(
                      (item, i) =>
                        i <= 6 && (
                          <div key={i} className="row ">
                            <div className="col-md-6 col-5">
                              <p className="m-0 specs-feature">{item.name}</p>
                            </div>
                            <div className="col-md-6 col-7">
                              <p className="m-0 specs-feature">{item.value}</p>
                            </div>
                            <hr className="p-0" style={{ marginTop: "8px" }} />
                          </div>
                        )
                    )}
                    {bikeInfo.specs.map(
                      (item, i) =>
                        i > 6 && (
                          <div key={i} className="collapse" id="collapseEx">
                            <div className="row">
                              <div className="col-md-6 col-5 ">
                                <p className="m-0 specs-feature">{item.name}</p>
                              </div>
                              <div className="col-md-6 col-7">
                                <p className="m-0 specs-feature">
                                  {item.value}
                                </p>
                              </div>
                              <hr
                                className="p-0"
                                style={{ marginTop: "8px" }}
                              />
                            </div>
                          </div>
                        )
                    )}
                    <button
                      className="spec-btn mt-4 mb-4 mx-auto d-block "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEx"
                      aria-expanded="false"
                      onClick={() => setCollapse(!Collapse)}
                      aria-controls="collapseExample"
                    >
                      {Collapse ? "Collapse" : " See More"}
                    </button>
                  </div>
                </div>
              </div>
              {!isDimensionOpen && (
                <hr className="p-0" style={{ marginTop: "16px" }} />
              )}
            </div>
            {/* <div className="col-6 d-none d-lg-block"></div> */}
          </div>
        </div>
      )}
      <div
        className="background-em d-lg-none position-sticky p-2"
        style={{
          bottom: "0%",
          border: "0px",
          width: "100%",
          zIndex: "2",
        }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            gap: "1.5em",
            bottom: "0%",
            border: "0px",
            width: "100%",
            zIndex: "2",
          }}
        >
          <div className="" style={{ width: "100%" }}>
            <ModalMobile
              className="fix-btn btn p-2 py-3 fw-bold w-100 btn-light d-block ms-auto text-em border-0"
              text="PRE-BOOK NOW"
            />
          </div>
        </div>
      </div>

      {/* <div
        style={{
          backgroundColor: "#960201",
          padding: "0.8em",
        }}
      >
        <h2
          className="text-center bottom-passion mb-0"
          style={{ color: "#f8f4f1" }}
        >
          Waitlist members will get access to exclusive launch offers!
        </h2>
      </div> */}
      <Footer></Footer>
    </>
  );
}

export default WaitlistLandigPage;
