import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import storesData from "../stores.json";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
// import "../CSS/store.css"

const StoreScreen = () => {
  const [map, setMap] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const [originMarker, setOriginMarker] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [storeList, setStoreList] = useState([]);
  const mapStyle = [
    {
      featureType: "landscape.man_made",
      elementType: "geometry",
      stylers: [
        {
          color: "#f7f1df",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry",
      stylers: [
        {
          color: "#d0e3b4",
        },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.business",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.medical",
      elementType: "geometry",
      stylers: [
        {
          color: "#fbd3da",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#bde6ab",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffe15f",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#efd151",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "black",
        },
      ],
    },
    {
      featureType: "transit.station.airport",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#cfb2db",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#a2daf2",
        },
      ],
    },
  ];

  const initializeMap = () => {
    const newMap = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 7,
      center: { lat: 22.694189, lng: 77.817573 },
    });
    setMap(newMap);
    newMap.data.addGeoJson(storesData, { idPropertyName: "storeid" });

    // newMap.data.loadGeoJson("../stores.json", { idPropertyName: 'storeid' });

    newMap.data.setStyle((feature) => {
      return {
        icon: {
          url: `https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/store.png`,
          scaledSize: new window.google.maps.Size(70, 70),
        },
      };
    });

    const newInfoWindow = new window.google.maps.InfoWindow();
    setInfoWindow(newInfoWindow);
    newMap.data.addListener("click", (event) => {
      const category = event.feature.getProperty("category");
      const name = event.feature.getProperty("name");
      const address = event.feature.getProperty("address");
      const phone = event.feature.getProperty("phone");
      const link = event.feature.getProperty("link");
      const position = event.feature.getGeometry().get();
      const content = (
        <div className="d-flex p-lg-3 align-items-center">
          <img
            className="w-25 d-none d-lg-block"
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/Green.png"
            alt="store-icon"
          />
          <div className="ps-lg-3">
            <h4 className="d-none d-lg-block">{name}</h4>
            <h6 className="d-lg-none">{name}</h6>
            <p></p>
            <p className="d-flex align-items-center">
              <b>
                <i className="fa-solid  fa-location-dot me-3"></i>
              </b>
              {address}
            </p>
            <p>
              <b>
                <i className="fa-solid fa-phone me-3"></i>
              </b>{" "}
              <a href={`tel:${phone}`}>{phone}</a>
            </p>
            <p>
              <b>
                {" "}
                <i className="fa-solid fa-map-location-dot me-3"></i>
              </b>{" "}
              <a href={link} target="_blank" rel="noopener noreferrer">
                Find Direction
              </a>
            </p>
          </div>
        </div>
      );

      newInfoWindow.setContent(renderToString(content));
      newInfoWindow.setPosition(position);
      newInfoWindow.setOptions({
        pixelOffset: new window.google.maps.Size(0, -30),
      });
      newInfoWindow.open(newMap);
    });

    const newOriginMarker = new window.google.maps.Marker({ map: newMap });
    newOriginMarker.setVisible(false);
    setOriginMarker(newOriginMarker);

    const newAutocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("pac-input")
    );
    newAutocomplete.setFields(["formatted_address", "geometry", "name"]);
    setAutocomplete(newAutocomplete);

    newAutocomplete.addListener("place_changed", async () => {
      newOriginMarker.setVisible(false);
      const originLocation = newMap.getCenter();
      const place = newAutocomplete.getPlace();

      if (!place.geometry) {
        return;
      }

      newMap.setCenter(place.geometry.location);
      newMap.setZoom(12);

      newOriginMarker.setPosition(place.geometry.location);
      newOriginMarker.setVisible(true);

      const rankedStores = await calculateDistances(
        newMap.data,
        place.geometry.location
      );
      showStoresList(newMap.data, rankedStores);

      return;
    });
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBBnChZoptOSJ4YsBRkUKpZ3bcGb5JHcZk&libraries=places&callback=initMap&solution_channel=GMP_codelabs_simplestorelocator_v1_a";
    script.async = true;
    script.onload = initializeMap;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const calculateDistances = async (data, origin) => {
    const stores = [];
    const destinations = [];

    data.forEach((store) => {
      const storeNum = store.getProperty("storeid");
      const storeLoc = store.getGeometry().get();

      stores.push(storeNum);
      destinations.push(storeLoc);
    });

    const service = new window.google.maps.DistanceMatrixService();
    const getDistanceMatrix = (service, parameters) =>
      new Promise((resolve, reject) => {
        service.getDistanceMatrix(parameters, (response, status) => {
          if (status !== window.google.maps.DistanceMatrixStatus.OK) {
            reject(response);
          } else {
            const distances = [];
            const results = response.rows[0].elements;
            for (let j = 0; j < results.length; j++) {
              const element = results[j];
              const distanceText = element.distance.text;
              const distanceVal = element.distance.value;
              const distanceObject = {
                storeid: stores[j],
                distanceText: distanceText,
                distanceVal: distanceVal,
              };
              distances.push(distanceObject);
            }

            resolve(distances);
          }
        });
      });

    const distancesList = await getDistanceMatrix(service, {
      origins: [origin],
      destinations: destinations,
      travelMode: "DRIVING",
      unitSystem: window.google.maps.UnitSystem.METRIC,
    });

    distancesList.sort((first, second) => {
      return first.distanceVal - second.distanceVal;
    });

    return distancesList;
  };

  const showStoresList = (rankedStores) => {
    setStoreList(rankedStores);
  };

  return (
    <>
      <Navbar />
      <div id="map" style={{ height: "100vh", width: "100%" }}></div>
      <div id="pac-card">
        <div id="title">Find the nearest store</div>
        <div id="pac-container">
          <input
            id="pac-input"
            type="text"
            placeholder="Enter City"
            style={{ color: "black" }}
          />
        </div>
      </div>
      <div id="store-list">
        {/* <ul className="store-list">
    {storeList.map((store, index) => (
      <li key={index}>
        <strong>{index + 1}. Store ID:</strong> {store.storeid}<br />
        <strong>Distance:</strong> {store.distanceText}<br /><br />
      </li>
    ))}
  </ul> */}
      </div>
      <Footer />
    </>
  );
};

export default StoreScreen;
