import React, { useEffect } from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Meta from "../Components/Meta";
import { getAllXcapeAccessories } from "../Actions/bikeActions";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

const XcapeScreen = () => {
  SwiperCore.use([Navigation, Pagination]);
  const currencyFormat = (num) => {
    return "₹" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  const dispatch = useDispatch();
  const allXcapeAccessories = useSelector((state) => state.allXcapeAccessories);
  const { accessories } = allXcapeAccessories;
  useEffect(() => {
    dispatch(getAllXcapeAccessories());
  }, []);
  return (
    <>
      <Meta
        title={
          "Electric Cycle Accessories, Buy Accessories for Electric Bicycle & E Bike Online"
        }
        description={
          "Shop the best quality accessories for your electric bicycle include helmets, air pump, locks etc at best prices."
        }
        cannonicalTag={"https://www.emotorad.com/accessories/xcape"}
      />
      <Navbar breadcrumb={true} />
      <div className="xcape-page">
        <div className="accessory-main-div">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/MOBILE+BANNER+1.png"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/xcape+banner+2.png"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/xcape+banner+2.png"
              alt="Banner1"
              className="d-block w-100"
            />
          </picture>
          <picture className="main-banner-img mt-4 mt-lg-5">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/MOBILE+BANNER+2.png"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/xcaoe+banner+1.png"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/xcaoe+banner+1.png"
              alt="Banner1"
              className="d-block w-100"
            />
          </picture>
        </div>
        <div className="helmet-div mt-4 mt-lg-0">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/MOBILE+HELMET+.png"
            />
            <source
              media="(min-width:651px)"
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/DESKKTOP+HELMET.png"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/DESKKTOP+HELMET.png"
              alt="Banner1"
              className="d-block w-100"
            />
          </picture>
          <div className="ps-lg-3 ps-2 pe-lg-3 d-none d-lg-block mt-4 mt-lg-5">
            <Swiper
              spaceBetween={15}
              slidesPerView={1.1}
              initialSlide={2}
              centeredSlides={false}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  centeredSlides: false,
                },
                1724: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  centeredSlides: false,
                  loop: false,
                },
              }}
              slide
            >
              {accessories &&
                accessories.map(
                  (product, i) =>
                    product.subCategory === "helmet" &&
                    product.urlName !== "force-xtech" && (
                      <SwiperSlide key={i} className="p-1">
                        <div
                          className="border position-relative rounded-3 p-2"
                          style={{
                            boxShadow:
                              "0px 0px 5px #0060D6, -0px -0px 5px #0060D6",
                            overflow: "hidden",
                          }}
                        >
                          <div className="position-absolute outofstock-alert">
                            {product.inStock === 0 && (
                              <span
                                className="text-end p-1 outofstock-tag"
                                style={{
                                  maxWidth: "fit-content",
                                  backgroundColor: "#ed2939",
                                  color: "#ffffff",
                                  fontFamily: "Metropolis-Black",
                                }}
                              >
                                OUT OF STOCK
                              </span>
                            )}
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="w-50">
                              <Link
                                to={`/accessories/xcape/${product.urlName}`}
                                style={{ color: "inherit" }}
                                className="text-decoration-none"
                              >
                                <div>
                                  <img
                                    src={
                                      product.name === "Beacon"
                                        ? product.cartImages[2]
                                        : product.cartImages[0]
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </Link>
                            </div>

                            <div className="px-4">
                              <Link
                                to={`/accessories/xcape/${product.urlName}`}
                                style={{ color: "inherit" }}
                                className="text-decoration-none"
                              >
                                <div className="mt-4 d-flex justify-content-between">
                                  <h3
                                    className="w-50"
                                    style={{
                                      fontSize: "1.5rem",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {product.name}
                                  </h3>

                                  <div>
                                    <Icon
                                      icon="ic:round-star-rate"
                                      style={{ color: "#ffce31" }}
                                      width={"20"}
                                    />
                                    <Icon
                                      icon="ic:round-star-rate"
                                      style={{ color: "#ffce31" }}
                                      width={"20"}
                                    />
                                    <Icon
                                      icon="ic:round-star-rate"
                                      style={{ color: "#ffce31" }}
                                      width={"20"}
                                    />
                                    <Icon
                                      icon="ic:round-star-rate"
                                      style={{ color: "#ffce31" }}
                                      width={"20"}
                                    />
                                    <Icon
                                      icon="ic:round-star-half"
                                      style={{ color: "#ffce31" }}
                                      width={"20"}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-center my-2 w-100"
                                  style={{
                                    gap: "2.3em",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {accessories &&
                                    product.attributes.map((item, index) => (
                                      <div
                                        className=""
                                        key={index}
                                        style={{ gap: "1em" }}
                                      >
                                        <div className="d-flex justify-content-center mb-2">
                                          <Icon
                                            icon={item.icon}
                                            style={{ color: "#004dac" }}
                                            width={"30"}
                                          />
                                        </div>
                                        <div className="text-center">
                                          <h6
                                            className="mb-0 mt-1 text-center"
                                            style={{
                                              fontFamily: "Metropolis-medium",
                                            }}
                                          >
                                            {item.spec.split(" ")[0]}
                                          </h6>
                                          <p style={{ fontSize: "0.8em" }}>
                                            {item.spec
                                              .split(" ")
                                              .slice(1)
                                              .join(" ")}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                                <div
                                  className="d-flex justify-content-start align-items-end"
                                  style={{ gap: "0.5em" }}
                                >
                                  <p
                                    style={{ fontSize: "1.2em" }}
                                    className="mb-2"
                                  >
                                    MRP{" "}
                                    <span
                                      className="bold"
                                      style={{
                                        textDecoration: "line-through",
                                        fontSize: "1.12em",
                                      }}
                                    >
                                      {currencyFormat(product.slashPrice)}
                                    </span>{" "}
                                    <b className="text-em">
                                      &nbsp;
                                      {currencyFormat(product.accPrice)}
                                    </b>
                                  </p>
                                  <div
                                    className="mb-2 d-flex align-items-center"
                                    style={{ color: "green" }}
                                  >
                                    <small className="mb-1 fw-bold">
                                      {Math.trunc(
                                        ((product.slashPrice -
                                          product.accPrice) /
                                          product.slashPrice) *
                                          100
                                      )}
                                      % OFF
                                    </small>
                                  </div>
                                </div>
                              </Link>

                              <div className="d-flex mb-3">
                                <div className="w-50 pe-2 testride-btn-allbike">
                                  <Link
                                    to={`/accessories/xcape/${product.urlName}`}
                                    className={`btn w-100 border-0 em-btn rounded-3 text-uppercase fw-bold text-decoration-none ${
                                      product.inStock === 0 ? "disabled" : ""
                                    }`}
                                    style={{
                                      fontSize: "0.8em",
                                    }}
                                    disabled={product.inStock === 0}
                                  >
                                    Buy Now
                                  </Link>
                                </div>
                                <div className="w-50 ps-2">
                                  <Link
                                    to={`/accessories/xcape/${product.urlName}`}
                                    style={{
                                      fontSize: "0.8em",
                                    }}
                                    className="btn border-0 w-100 know-btn rounded-3 text-uppercase fw-bold"
                                  >
                                    Know More
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                )}
            </Swiper>
          </div>
          <div className="ps-lg-3 ps-2 pe-lg-3 d-lg-none mt-4 mt-lg-5">
            <Swiper
              navigation={{ clickable: true }}
              spaceBetween={15}
              slidesPerView={1.1}
              initialSlide={2}
              centeredSlides={false}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 3.5,
                  spaceBetween: 20,
                  centeredSlides: true,
                },
                1724: {
                  slidesPerView: 4.3,
                  spaceBetween: 20,
                  centeredSlides: true,
                  loop: true,
                  initialSlide: 2,
                },
              }}
              slide
            >
              {accessories &&
                accessories.map(
                  (product, i) =>
                    product.subCategory === "helmet" &&
                    product.urlName !== "force-xtech" && (
                      <SwiperSlide key={i} className="p-1">
                        <div
                          className="border position-relative rounded-3 p-2"
                          style={{
                            boxShadow:
                              "0px 0px 5px #0060D6, -0px -0px 5px #0060D6",
                            overflow: "hidden",
                          }}
                        >
                          <div className="position-relative">
                            <h5 className="text-center fw-bold text-uppercase">
                              {product.subCategory}
                            </h5>
                            <div className="position-absolute outofstock-alert">
                              {product.inStock === 0 && (
                                <span
                                  className="text-end p-1 outofstock-tag"
                                  style={{
                                    maxWidth: "fit-content",
                                    backgroundColor: "#ed2939",
                                    color: "#ffffff",
                                    fontFamily: "Metropolis-Black",
                                  }}
                                >
                                  OUT OF STOCK
                                </span>
                              )}
                            </div>
                          </div>

                          <Link
                            to={`/accessories/xcape/${product.urlName}`}
                            style={{ color: "inherit" }}
                            className="text-decoration-none"
                          >
                            <div>
                              <img
                                src={
                                  product.name === "Beacon"
                                    ? product.cartImages[2]
                                    : product.cartImages[0]
                                }
                                alt=""
                                className="img-fluid "
                              />
                            </div>
                          </Link>
                          <div className="ps-2  pe-2">
                            <Link
                              to={`/accessories/xcape/${product.urlName}`}
                              style={{ color: "inherit" }}
                              className="text-decoration-none"
                            >
                              <div className="mt-4 d-flex justify-content-between">
                                <h3
                                  className="w-50"
                                  style={{
                                    fontSize: "1.5rem",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontWeight: "600",
                                  }}
                                >
                                  {product.name}
                                </h3>
                                <div>
                                  <Icon
                                    icon="ic:round-star-rate"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                  <Icon
                                    icon="ic:round-star-rate"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                  <Icon
                                    icon="ic:round-star-rate"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                  <Icon
                                    icon="ic:round-star-rate"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                  <Icon
                                    icon="ic:round-star-half"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center my-2 justify-content-between"
                                style={{ gap: "1em" }}
                              >
                                {accessories &&
                                  product.attributes.map((item, index) => (
                                    <div
                                      className=""
                                      key={index}
                                      style={{ gap: "1em" }}
                                    >
                                      <div className="d-flex justify-content-center mb-2">
                                        <Icon
                                          icon={item.icon}
                                          style={{ color: "#004dac" }}
                                          width={"24"}
                                        />
                                      </div>
                                      <div className="text-center">
                                        <h6
                                          className="mb-0 mt-1 text-center"
                                          style={{
                                            fontFamily: "Metropolis-medium",
                                          }}
                                        >
                                          {item.spec.split(" ")[0]}
                                        </h6>
                                        <p style={{ fontSize: "0.8em" }}>
                                          {item.spec
                                            .split(" ")
                                            .slice(1)
                                            .join(" ")}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              <div
                                className="d-flex justify-content-start align-items-end"
                                style={{ gap: "0.5em" }}
                              >
                                <p
                                  style={{ fontSize: "1.3em" }}
                                  className="mb-2"
                                >
                                  MRP{" "}
                                  <span
                                    className="bold"
                                    style={{
                                      textDecoration: "line-through",
                                      fontSize: "1.12em",
                                    }}
                                  >
                                    {currencyFormat(product.slashPrice)}
                                  </span>{" "}
                                  <b className="text-em">
                                    &nbsp;
                                    {currencyFormat(product.accPrice)}
                                  </b>
                                </p>
                                <div
                                  className="mb-2 d-flex align-items-center"
                                  style={{ color: "green" }}
                                >
                                  <small className="mb-1 fw-bold">
                                    {Math.trunc(
                                      ((product.slashPrice - product.accPrice) /
                                        product.slashPrice) *
                                        100
                                    )}
                                    % OFF
                                  </small>
                                </div>
                              </div>
                            </Link>

                            <div className="d-flex mb-3">
                              <div className="w-50 pe-2 testride-btn-allbike">
                                <Link
                                  to={`/accessories/xcape/${product.urlName}`}
                                  className={`btn w-100 border-0 em-btn rounded-3 text-uppercase fw-bold text-decoration-none ${
                                    product.inStock === 0 ? "disabled" : ""
                                  }`}
                                  style={{
                                    fontSize: "0.8em",
                                  }}
                                  disabled={product.inStock === 0}
                                >
                                  Buy Now
                                </Link>
                              </div>
                              <div className="w-50 ps-2">
                                <Link
                                  to={`/accessories/xcape/${product.urlName}`}
                                  style={{
                                    fontSize: "0.8em",
                                  }}
                                  className="btn border-0 w-100 know-btn rounded-3 text-uppercase fw-bold"
                                >
                                  Know More
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                )}
            </Swiper>
          </div>
        </div>
        <div className="lock-div mt-4 mt-lg-5">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/MOBILE+LOCK.png"
            />
            <source
              media="(min-width:651px)"
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/DESKTOP+LOCK.png"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/DESKTOP+LOCK.png"
              alt="Banner1"
              className="d-block w-100"
            />
          </picture>
          <div className="ps-lg-3 ps-2 pe-lg-3 mt-4 mt-lg-5">
            <Swiper
              navigation={{ clickable: true }}
              spaceBetween={15}
              slidesPerView={1.1}
              initialSlide={2}
              centeredSlides={false}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 3.5,
                  spaceBetween: 20,
                  centeredSlides: true,
                },
                1724: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                  centeredSlides: true,
                  loop: true,
                  initialSlide: 2,
                },
              }}
              slide
            >
              {accessories &&
                accessories.map(
                  (product, i) =>
                    product.subCategory === "lock" && (
                      <SwiperSlide key={i} className="p-1">
                        <div
                          className="border position-relative rounded-3 p-2"
                          style={{
                            boxShadow:
                              "0px 0px 5px #0060D6, -0px -0px 5px #0060D6",
                            overflow: "hidden",
                          }}
                        >
                          <div className="position-relative">
                            <h5 className="text-center fw-bold text-uppercase">
                              {product.subCategory}
                            </h5>
                            <div className="position-absolute outofstock-alert">
                              {product.inStock === 0 && (
                                <span
                                  className="text-end p-1 outofstock-tag"
                                  style={{
                                    maxWidth: "fit-content",
                                    backgroundColor: "#ed2939",
                                    color: "#ffffff",
                                    fontFamily: "Metropolis-Black",
                                  }}
                                >
                                  OUT OF STOCK
                                </span>
                              )}
                            </div>
                          </div>

                          <Link
                            to={`/accessories/xcape/${product.urlName}`}
                            style={{ color: "inherit" }}
                            className="text-decoration-none"
                          >
                            <div>
                              <img
                                src={product.cartImages[0]}
                                alt=""
                                className="img-fluid "
                              />
                            </div>
                          </Link>
                          <div className="ps-2  pe-2">
                            <Link
                              to={`/accessories/xcape/${product.urlName}`}
                              style={{ color: "inherit" }}
                              className="text-decoration-none"
                            >
                              <div className="mt-4 d-flex justify-content-between">
                                <h3
                                  className="w-50"
                                  style={{
                                    fontSize: "1.5rem",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontWeight: "600",
                                  }}
                                >
                                  {product.name}
                                </h3>
                                <div>
                                  <Icon
                                    icon="ic:round-star-rate"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                  <Icon
                                    icon="ic:round-star-rate"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                  <Icon
                                    icon="ic:round-star-rate"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                  <Icon
                                    icon="ic:round-star-rate"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                  <Icon
                                    icon="ic:round-star-half"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center my-2 justify-content-between"
                                style={{ gap: "1em" }}
                              >
                                {accessories &&
                                  product.attributes.map((item, index) => (
                                    <div
                                      className=""
                                      key={index}
                                      style={{ gap: "1em" }}
                                    >
                                      <div className="d-flex justify-content-center mb-2">
                                        <Icon
                                          icon={item.icon}
                                          style={{ color: "#004dac" }}
                                          width={"24"}
                                        />
                                      </div>
                                      <div className="text-center">
                                        <h6
                                          className="mb-0 mt-1 text-center"
                                          style={{
                                            fontFamily: "Metropolis-medium",
                                          }}
                                        >
                                          {item.spec.split(" ")[0]}
                                        </h6>
                                        <p style={{ fontSize: "0.8em" }}>
                                          {item.spec
                                            .split(" ")
                                            .slice(1)
                                            .join(" ")}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              <div
                                className="d-flex justify-content-start align-items-end"
                                style={{ gap: "0.5em" }}
                              >
                                <p
                                  style={{ fontSize: "1.3em" }}
                                  className="mb-2"
                                >
                                  MRP{" "}
                                  <span
                                    className="bold"
                                    style={{
                                      textDecoration: "line-through",
                                      fontSize: "1.12em",
                                    }}
                                  >
                                    {currencyFormat(product.slashPrice)}
                                  </span>{" "}
                                  <b className="text-em">
                                    &nbsp;
                                    {currencyFormat(product.accPrice)}
                                  </b>
                                </p>
                                <div
                                  className="mb-2 d-flex align-items-center"
                                  style={{ color: "green" }}
                                >
                                  <small className="mb-1 fw-bold">
                                    {Math.trunc(
                                      ((product.slashPrice - product.accPrice) /
                                        product.slashPrice) *
                                        100
                                    )}
                                    % OFF
                                  </small>
                                </div>
                              </div>
                            </Link>

                            <div className="d-flex mb-3">
                              <div className="w-50 pe-2 testride-btn-allbike">
                                <Link
                                  to={`/accessories/xcape/${product.urlName}`}
                                  className={`btn w-100 border-0 em-btn rounded-3 text-uppercase fw-bold text-decoration-none ${
                                    product.inStock === 0 ? "disabled" : ""
                                  }`}
                                  style={{
                                    fontSize: "0.8em",
                                  }}
                                  disabled={product.inStock === 0}
                                >
                                  Buy Now
                                </Link>
                              </div>
                              <div className="w-50 ps-2">
                                <Link
                                  to={`/accessories/xcape/${product.urlName}`}
                                  style={{
                                    fontSize: "0.8em",
                                  }}
                                  className="btn border-0 w-100 know-btn rounded-3 text-uppercase fw-bold"
                                >
                                  Know More
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                )}
            </Swiper>
          </div>
        </div>
        <div className="pump-div mt-4 mt-lg-5">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/MOBILE+PUMP.png"
            />
            <source
              media="(min-width:651px)"
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/DESKTOP+PUMP.png"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/xcape/DESKTOP+PUMP.png"
              alt="Banner1"
              className="d-block w-100"
            />
          </picture>
          <div className="ps-lg-3 ps-2 pe-lg-3 my-4 my-lg-5">
            <Swiper
              navigation={{ clickable: true }}
              spaceBetween={15}
              slidesPerView={1.1}
              initialSlide={2}
              centeredSlides={false}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 3.5,
                  spaceBetween: 20,
                  centeredSlides: true,
                },
                1724: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                  centeredSlides: true,
                  loop: true,
                  initialSlide: 2,
                },
              }}
              slide
            >
              {accessories &&
                accessories.map(
                  (product, i) =>
                    product.subCategory === "pump" && (
                      <SwiperSlide key={i} className="p-1">
                        <div
                          className="border position-relative rounded-3 p-2"
                          style={{
                            boxShadow:
                              "0px 0px 5px #0060D6, -0px -0px 5px #0060D6",
                            overflow: "hidden",
                          }}
                        >
                          <div className="position-relative">
                            <h5 className="text-center fw-bold text-uppercase">
                              {product.subCategory}
                            </h5>
                            <div className="position-absolute outofstock-alert">
                              {product.inStock === 0 && (
                                <span
                                  className="text-end p-1 outofstock-tag"
                                  style={{
                                    maxWidth: "fit-content",
                                    backgroundColor: "#ed2939",
                                    color: "#ffffff",
                                    fontFamily: "Metropolis-Black",
                                  }}
                                >
                                  OUT OF STOCK
                                </span>
                              )}
                            </div>
                          </div>

                          <Link
                            to={`/accessories/xcape/${product.urlName}`}
                            style={{ color: "inherit" }}
                            className="text-decoration-none"
                          >
                            <div>
                              <img
                                src={product.cartImages[0]}
                                alt=""
                                className="img-fluid "
                              />
                            </div>
                          </Link>
                          <div className="ps-2  pe-2">
                            <Link
                              to={`/accessories/xcape/${product.urlName}`}
                              style={{ color: "inherit" }}
                              className="text-decoration-none"
                            >
                              <div className="mt-4 d-flex justify-content-between">
                                <h3
                                  className="w-50"
                                  style={{
                                    fontSize: "1.5rem",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontWeight: "600",
                                  }}
                                >
                                  {product.name}
                                </h3>
                                <div>
                                  <Icon
                                    icon="ic:round-star-rate"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                  <Icon
                                    icon="ic:round-star-rate"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                  <Icon
                                    icon="ic:round-star-rate"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                  <Icon
                                    icon="ic:round-star-rate"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                  <Icon
                                    icon="ic:round-star-half"
                                    style={{ color: "#ffce31" }}
                                    width={"20"}
                                  />
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center my-2 justify-content-between"
                                style={{ gap: "1em" }}
                              >
                                {accessories &&
                                  product.attributes.map((item, index) => (
                                    <div
                                      className=""
                                      key={index}
                                      style={{ gap: "1em" }}
                                    >
                                      <div className="d-flex justify-content-center mb-2">
                                        <Icon
                                          icon={item.icon}
                                          style={{ color: "#004dac" }}
                                          width={"24"}
                                        />
                                      </div>
                                      <div className="text-center">
                                        <h6
                                          className="mb-0 mt-1 text-center"
                                          style={{
                                            fontFamily: "Metropolis-medium",
                                          }}
                                        >
                                          {item.spec.split(" ")[0]}
                                        </h6>
                                        <p style={{ fontSize: "0.8em" }}>
                                          {item.spec
                                            .split(" ")
                                            .slice(1)
                                            .join(" ")}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              <div
                                className="d-flex justify-content-start align-items-end"
                                style={{ gap: "0.5em" }}
                              >
                                <p
                                  style={{ fontSize: "1.3em" }}
                                  className="mb-2"
                                >
                                  MRP{" "}
                                  <span
                                    className="bold"
                                    style={{
                                      textDecoration: "line-through",
                                      fontSize: "1.12em",
                                    }}
                                  >
                                    {currencyFormat(product.slashPrice)}
                                  </span>{" "}
                                  <b className="text-em">
                                    &nbsp;
                                    {currencyFormat(product.accPrice)}
                                  </b>
                                </p>
                                <div
                                  className="mb-2 d-flex align-items-center"
                                  style={{ color: "green" }}
                                >
                                  <small className="mb-1 fw-bold">
                                    {Math.trunc(
                                      ((product.slashPrice - product.accPrice) /
                                        product.slashPrice) *
                                        100
                                    )}
                                    % OFF
                                  </small>
                                </div>
                              </div>
                            </Link>

                            <div className="d-flex mb-3">
                              <div className="w-50 pe-2 testride-btn-allbike">
                                <Link
                                  to={`/accessories/xcape/${product.urlName}`}
                                  className={`btn w-100 border-0 em-btn rounded-3 text-uppercase fw-bold text-decoration-none ${
                                    product.inStock === 0 ? "disabled" : ""
                                  }`}
                                  style={{
                                    fontSize: "0.8em",
                                  }}
                                  disabled={product.inStock === 0}
                                >
                                  Buy Now
                                </Link>
                              </div>
                              <div className="w-50 ps-2">
                                <Link
                                  to={`/accessories/xcape/${product.urlName}`}
                                  style={{
                                    fontSize: "0.8em",
                                  }}
                                  className="btn border-0 w-100 know-btn rounded-3 text-uppercase fw-bold"
                                >
                                  Know More
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                )}
            </Swiper>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default XcapeScreen;
