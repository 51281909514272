export const FAQs = [
  {
    question: "What are electric cycles?",
    answer:
      "You can call it the magic carpet of bicycles. An electric cycle, or e-cycle, is your regular bicycle, but with an energy boost unlike another– it has an electric motor that makes pedalling a breeze and lets you roll without breaking a sweat, creating a win-win harmony for you and the environment.",
  },
  {
    question: "How do electric cycles work?",
    answer:
      "Let's breakdown the magic, shall we? Electric cycles work like a two-in-one system– rechargeable batteries power up the electric motor. So you can use it as a regular cycle, pedalling with pure manual exert, or accelerate on pure electric power at the twist of a hand, or even better choose a hybrid riding option where the amount of your manual exertion is complemented by exponential electric boost.",
  },
  {
    question: "What is the range of an electric cycle on a single charge?",
    answer:
      "The range of an e-cycle is a bit like a choose-your-own-adventure story – it depends on factors like battery capacity, terrain, your weight, and the vibe of your ride. Generally, you're looking at a range of 40 to 80 km on a single charge. ",
  },
  {
    question: "Do I need a licence to ride an electric cycle in India?",
    answer:
      "Licence to thrill? Not in the electric world. Currently, riding an electric cycle in India is like having a golden ticket – no need for a licence. Just hop on and enjoy the ride, licence-free.",
  },
  {
    question:
      "Are there any government incentives for purchasing an electric cycle?",
    answer:
      "The government is all in for the electric revolution. In some Indian states, they're rolling out the red carpet with incentives – think subsidies, tax benefits, and all the perks to encourage you to join the electric cycle party. It's like a VIP pass to a greener, cooler ride.",
  },
  {
    question:
      "How long does it take to charge an electric cycle battery fully?",
    answer:
      "Your electric cycles come with removable batteries that are as easily rechargeable as your smart phone. So you can literally charge it anywhere in the timespan of a quick movie as it takes around 4-5 hours on average for a fully charged electric cycle battery.",
  },
  {
    question: "Can I pedal the electric cycle like a regular bicycle?",
    answer:
      "Pedal power to the rescue! Our electric cycles can be pedalled like your classic traditional cycles, or can be cruised in a hybrid way with electric assistance added to your pedal power. So you always have the freedom to switch between superhero electric-assist and old-school pedal action.",
  },
  {
    question: "What are the maintenance requirements for an electric cycle?",
    answer:
      "Maintenance, or as we like to call it, bike spa day is a breeze – routine checks on the battery, motor, and brakes, plus the usual tyre TLC and chain pampering for your electric steed are more than enough to keep your cycle greased.",
  },
  {
    question: "Are electric cycles suitable for all terrains?",
    answer:
      "Off-roading or city cruising, they're up for the challenge! Many electric cycles handle urban streets, hills, and off-road trails like pros – versatile and ready for any adventure you throw their way.",
  },
  {
    question: "Is there a weight limit for riders on electric cycles?",
    answer:
      "Most electric cycles have weight limits, so check the specs – typically ranging from 100 to 150 kgs.",
  },
  {
    question: "Do electric cycles have gears?",
    answer:
      "When you can't change the road, change the mode! Many electric cycles come with gears, letting you play director with your ride. Adjust the assistance level and control the speed – it's like having your own action movie on two wheels.",
  },
  {
    question: "Are there different modes of assistance on electric cycles?",
    answer:
      "Yes, we have modes for every mood! Electric cycles have pedal-assist for those zen rides and throttle mode for when you want to feel the wind in your hair without the leg workout. Choices, choices, and more choices is all you get!",
  },
  {
    question: "What safety features do electric cycles typically have?",
    answer:
      "Safety first, adventure second! LED lights, reflective bling, and integrated brakes are the superhero ensemble. But don't forget your helmet – it's the cape of the electric cycle world!",
  },
  {
    question:
      "Are there any restrictions on where I can ride an electric cycle?",
    answer:
      "Rules of the road run sovereign! Electric cycles play by the same traffic rules as their pedal-powered pals. Check local policies for any extra regulations and ride on!",
  },
  {
    question:
      "What is the lifespan of the battery of an electric cycle, and is it replaceable?",
    answer:
      "Battery life – the marathon runner of the electric world! It lasts for several hundred charge cycles. And guess what? Most electric cycles let you swap out the battery once the life runs out so that your adventures never end!",
  },
  {
    question:
      "Are there any specific charging stations for electric cycles in India?",
    answer:
      "Infrastructure installments are a hassle not needed with electric cycles. Plug and play anywhere! No fancy charging stations needed for it has a battery that you can remove and charge at your regular power outlet like a mobile phone. Convenience level: maximum.",
  },
  {
    question: "Do electric cycles require insurance?",
    answer:
      "Insurance is never a question of need. Everything’s as per you. Off the rack, your electric cycle isn’t on the insurance hook. You can choose to get a third party insurance done at your own volition.",
  },
  {
    question: "Are there any specific traffic rules for electric cycle riders?",
    answer:
      "Traffic rules are the silent script of the road! Electric cycle riders follow the same rules as regular cyclists and other vehicles on the road. Just remember, knowing the local traffic regulations keeps the trip running smoothly.",
  },
];
