import axios from "axios";
import {
  GET_STORES_FAIL,
  GET_STORES_REQUEST,
  GET_STORES_SUCCESS,
} from "../Constants/storeConstants.js";

export const getAllStores = () => async (dispatch, getState) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({ type: GET_STORES_REQUEST });
    const { data } = await axios.get("/api/store", config);
    dispatch({ type: GET_STORES_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: GET_STORES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
