import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({
  title,
  description,
  cannonicalTag,
  keywords,
  product,
  noindex,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keywords} />
      {noindex && <meta name="robots" content="noindex" />}
      <meta property="og:title" content={product?.name} />
      <meta property="og:description" content={product?.description} />
      <meta
        property="og:url"
        content={product && `https://emotorad.com/bikes/${product?.urlName}`}
      />
      <meta property="product:condition" content="new"></meta>
      <meta property="og:image" content={product?.cartImages[0]} />
      <meta property="product:brand" content="EMotorad" />
      <meta
        property="product:availability"
        content={product && product?.inStock > 0 ? "in stock" : "out of stock"}
      />
      <meta property="product:price:amount" content={product?.price} />
      <meta property="product:price:currency" content="INR" />
      <meta property="product:retailer_item_id" content={product?._id} />
      <meta property="google_product_category" content={"ebike"} />
      <link rel="canonical" href={cannonicalTag} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title:
    "Electric Cycle, E Cycle Online, Buy the Best Electric Bicycles & E-Bikes | EMotorad",
  description:
    "EMotorad offers a diverse range of premium electric bicycles and e-bikes. Shop now for the best deals on e-cycle & e-bike.",
  keywords: "",
};

export default Meta;
