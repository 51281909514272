import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Meta from "../Components/Meta";

const ServiceQueryScreen = () => {
  return (
    <>
      <Meta
        title={
          "EMotorad Customer Service | Electric Cycle Assistance & Support"
        }
        description={
          "Get quick assistance with EMotorad’s customer service. Reach out for help with product issues, service queries, and general questions about our electric cycles. Contact us via call, email, or WhatsApp."
        }
      />
      <Navbar />
      <div
        className="px-3 px-lg-5"
        style={{
          backgroundColor: "#f4f4f4",
          paddingTop: "3em",
          paddingBottom: "3em",
        }}
      >
        <div className="my-lg-5">
          <h1
            className="d-none d-lg-block mb-5"
            style={{ fontFamily: "Metropolis-bold", fontSize: "4rem" }}
          >
            Resolve Your Queries
          </h1>
          <h1
            className="mb-4 d-lg-none"
            style={{ fontFamily: "Metropolis-bold", fontSize: "2.6rem" }}
          >
            Resolve Your Queries
          </h1>
          <h3>
            We’re here to make things easier for you.<br></br>Got a question or
            need assistance? We’re here to help!
          </h3>
          <div
            className="d-flex service-blocks mt-5 pt-5"
            style={{ gap: "2em" }}
          >
            <div className="service-block w-100">
              <a
                href="tel:8956248161"
                className="text-decoration-none"
                style={{ color: "inherit" }}
              >
                <div
                  className="p-lg-5 p-4 service-block-top"
                  style={{ backgroundColor: "white" }}
                >
                  <h3
                    className="mb-lg-4 mb-3"
                    style={{ fontFamily: "Metropolis-medium" }}
                  >
                    Service-Related Issues
                  </h3>
                  <p className="mb-0 service-block-text">
                    Facing a problem with your product or service? Our dedicated
                    service team is ready to assist you.
                  </p>
                </div>
                <div className="service-block-btn p-4 px-lg-5">
                  <p className="mb-0" style={{ fontSize: "1.1em" }}>
                    Call Us
                  </p>
                </div>
              </a>
            </div>
            <div className="service-block w-100">
              <a
                href="tel:8686050590"
                className="text-decoration-none"
                style={{ color: "inherit" }}
              >
                <div
                  className="p-lg-5 p-4 service-block-top"
                  style={{ backgroundColor: "white" }}
                >
                  <h3
                    className="mb-4"
                    style={{ fontFamily: "Metropolis-medium" }}
                  >
                    General Queries
                  </h3>
                  <p className="mb-0 service-block-text">
                    Need assistance or have something on your mind?<br></br>
                    Reach out to us.
                  </p>
                </div>
                <div className="service-block-btn p-4 px-lg-5">
                  <p className="mb-0" style={{ fontSize: "1.1em" }}>
                    Call Us
                  </p>
                </div>
              </a>
            </div>
            <div className="service-block w-100">
              <a
                href="mailto:service@emotorad.com"
                className="text-decoration-none"
                style={{ color: "inherit" }}
              >
                <div
                  className="p-lg-5 p-4 service-block-top"
                  style={{ backgroundColor: "white" }}
                >
                  <h3
                    className="mb-4"
                    style={{ fontFamily: "Metropolis-medium" }}
                  >
                    Service-Related Issues
                  </h3>
                  <p className="mb-0 service-block-text">
                    For any unresolved concerns, feel free to drop us a message.
                  </p>
                </div>
                <div className="service-block-btn p-4 px-lg-5">
                  <p className="mb-0" style={{ fontSize: "1.1em" }}>
                    Chat with us
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ServiceQueryScreen;
