// VideoModal.js
import React from 'react';
import ReactPlayer from "react-player";
import Modal from "react-modal";

function VideoModal({ isOpen, closeModal, videoUrl }) {
  return (
    <div>
      <div className="position-relative w-100" style={{ margin: "auto" }}>
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          className="modal-video"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              display: "flex",
              zIndex: "100",
              alignItems: "center",
              justifyContent: "center",
            },
            content: {
              position: "static",
              margin: "auto",
              border: "none",
              padding: "0px",
              outline: "none",
              overflow: "hidden",
            },
          }}
          contentLabel="Video Modal"
        >
          <ReactPlayer
            url={videoUrl}
            controls={true}
            playing={true}
            width="100%"
            height="100%"
          />
        </Modal>
      </div>
    </div>
  );
}

export default VideoModal;
