import React from "react";
import "../CSS/tooltip.css";

const Tooltip = ({ bike }) => {
  switch (bike) {
    case "x1":
      return (
        <div className="position-relative">
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/tooltip/x1+blue.png"
            className="bike-img img-fluid d-block mx-auto"
            alt=""
          />
          {/* battery */}
          <div className="position-absolute d-lg-flex  d-block x1-spec x1-spec-1  align-items-center">
            <div className=" bike-spec-tooltip d-lg-flex d-block mx-auto align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item d-none d-lg-block">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/desktop/Asset+6.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* suspensions */}
          <div className="position-absolute d-lg-flex  d-block x1-spec x1-spec-2  align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item d-none d-lg-block">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/desktop/Asset+10.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* diskbrake */}
          <div className="position-absolute d-flex x1-spec x1-spec-3 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/desktop/Asset+12.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* motor */}
          <div className="position-absolute d-flex x1-spec x1-spec-4 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/desktop/Asset+4.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* display */}
          <div className="position-absolute d-flex x1-spec x1-spec-5 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/desktop/Asset+14.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* frame */}
          <div className="position-absolute d-flex x1-spec x1-spec-6 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/X1/desktop/Asset+8.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
        </div>
      );

      break;
    case "t-rex-air":
      return (
        <div className="position-relative">
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex-air/tooltip/T-rex%2B+Air+Red.png"
            className="img-fluid bike-img d-block mx-auto"
            alt=""
            width={"80%"}
          />
          {/* battery */}
          <div className="position-absolute d-flex x1-spec t-rex-spec-1 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-rex-air/Desktop/Artboard+3.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* suspension brake */}
          <div className="position-absolute d-flex x1-spec t-rex-spec-2 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-rex-air/Desktop/Artboard+17.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* diskbrake */}
          <div className="position-absolute d-flex x1-spec t-rex-spec-3 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-REX%2B+old/Desktop/Asset+98.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* motor */}
          <div className="position-absolute d-flex x1-spec t-rex-spec-4 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-rex-air/Desktop/Artboard+4.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* derailers */}
          {/* <div className="position-absolute d-flex x1-spec t-rex-spec-5 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-REX%2B+old/Desktop/Asset+100.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div> */}
          {/* display */}
          <div className="position-absolute d-flex x1-spec t-rex-spec-5 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-rex-air/Desktop/Artboard+14.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* frame */}
          <div className="position-absolute d-flex x1-spec t-rex-spec-6 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-rex-air/Desktop/Artboard+5.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
        </div>
      );
      break;
    case "neo":
      return (
        <div className="position-relative">
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/cartBot.png"
            className="img-fluid bike-img d-block mx-auto"
            alt=""
            width={"80%"}
          />
          {/* battery */}
          <div className="position-absolute d-flex x1-spec neo-spec-1 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/tooltip/neoBattery.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* suspension brake */}
          <div className="position-absolute d-flex x1-spec t-rex-spec-2 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/tooltip/neoSuspension.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* diskbrake */}
          <div className="position-absolute d-flex x1-spec neo-spec-3 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/tooltip/neoBrakes.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* motor */}
          <div className="position-absolute d-flex x1-spec neo-spec-4 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/tooltip/neoMotor.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* derailers */}
          {/* <div className="position-absolute d-flex x1-spec t-rex-spec-5 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/specs/T-REX%2B+old/Desktop/Asset+100.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div> */}
          {/* display */}
          <div className="position-absolute d-flex x1-spec neo-spec-5 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/tooltip/neoDisplay.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
          {/* frame */}
          <div className="position-absolute d-flex x1-spec neo-spec-6 align-items-center">
            <div className=" bike-spec-tooltip d-flex align-items-center justify-content-center rounded-circle"></div>
            <div className="bike-spec-tooltip-item">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/tooltip/neoFrame.png"
                className="img-fluid  "
                width={300}
                alt=""
              />
            </div>
          </div>
        </div>
      );
      break;
    default:
      break;
  }
};

export default Tooltip;
