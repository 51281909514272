import React from "react";

function QuizResult({ result, retry, setWin }) {
  if (result.correct >= 3) {
    setWin("true");
  } else {
    setWin("false");
  }
  return (
    <div className="result-screen">
      <h2>
        <b>Result: {result.percentage}% </b>
      </h2>
      {result.correct >= 3 ? (
        <h3
          style={{ color: "#004dac", fontWeight: "600", textAlign: "center" }}
        >
          Congratulations! You have won yourself an exclusive EMotorad voucher
          :D
        </h3>
      ) : (
        <h3
          style={{ color: "#ed2939", fontWeight: "600", textAlign: "center" }}
        >
          Better luck next time! You can still take a test ride though :D
        </h3>
      )}
      <p>
        Selected {result.correct} correct options out of {result.total}{" "}
        questions.
      </p>
      <button className="quiz-button-result" onClick={retry}>
        PLAY AGAIN
      </button>
    </div>
  );
}

export default QuizResult;
