import React, { useEffect, useRef, useState } from "react";
import logo from "../images/mainlogo.png";
import "../CSS/checkIn.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import ModalMobile from "../Components/ModalMobile";

function CheckInScreen() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [bike, setBike] = useState("");
  const [size, setSize] = useState("S");
  const [status, setStatus] = useState("");
  const [checkboxValue, setCheckboxValue] = useState(false);
  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setCheckboxValue(true); // You can set this to any value you want
    } else {
      setCheckboxValue("");
    }
  };

  const navigate = useNavigate();
  const handleConfirm = async (e) => {
    try {
      e.preventDefault();
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `/api/form/checkIn`,
        {
          registrationNumber,
          name,
          email,
          phoneNumber,
          size,
          bike,
          tc: checkboxValue,
        },
        config
      );
      if (data) {
        if (data.message) {
          alert("Already Checked In");
        } else {
          navigate("/checked-in", {
            state: { bibNumber: data.data.bibNumber },
          });
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(
        `/api/form/checkIn?search=${searchValue}`,
        config
      );
      if (data.status === "not found") {
        setStatus("ENTER A VALID ID/NUMBER!!!");
        setName("");
        setEmail("");
        setRegistrationNumber("");
        setPhoneNumber("");
        setBike("");
      } else {
        setStatus("");
        setName("");
        setEmail("");
        setRegistrationNumber("");
        setPhoneNumber("");
        setBike("");
      }
      setBike(data.bike);
      setName(data.name);
      setPhoneNumber(data.phoneNumber);
      setEmail(data.email);
      setRegistrationNumber(data.registrationNumber);
    } catch (error) {
      console.log(error.message);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      setShowReadMore(ref.current.scrollHeight !== ref.current.clientHeight);
    }
  }, []);
  return (
    <div className="landingpagemovementsbody">
      {/* navabar */}
      <div
        className="navbar-banner p-2 position-sticky navigation w-100"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              src={logo}
              className="img-fluid"
              style={{ width: "140px" }}
              alt=""
            />
          </div>
          <div class="col-md-12 navbar-banner-mainheading text-center">
            E-CYCLOTHON <span className="navbar-submainheading">2024</span>
          </div>
        </div>
      </div>
      <div className="p-4">
        <h4
          className="mt-3 mb-3"
          style={{ textAlign: "center", color: "#004dac" }}
        >
          <b>WEB CHECK-IN</b>
        </h4>
        {/* search */}
        <div className="search-panel mb-3">
          <form
            className="form-inline my-2 my-lg-0 d-flex align-items-center flex-column"
            onSubmit={handleSubmit}
          >
            <input
              className="form-control mr-sm-2 mb-2"
              type="search"
              required
              placeholder="Registration ID/Phone No."
              aria-label="Search"
              value={searchValue.toUpperCase()}
              onChange={(e) => setSearchValue(e.target.value)}
              style={{ borderRadius: 0 }}
            />
            <button
              className="btn btn-outline-success my-2 my-sm-0"
              type="submit"
              style={{ borderRadius: 0 }}
            >
              Search
            </button>
          </form>
          {status ? <></> : <br></br>}
          {status && (
            <form className="text-center">
              <br></br>
              <label
                className="form-label fw-bold text-center mb-3"
                style={{ color: "red" }}
              >
                {status}
              </label>
              {status && (
                <div>
                  <a
                    className="em-btn text-decoration-none"
                    href="/movements-of-fun"
                  >
                    Click here for registration
                  </a>
                  {/* <ModalMobile className="btn btn-primary" /> */}
                </div>
              )}
              <br></br>
              {/* <label
              className="form-label text-center"
              style={{ color: "#004dac" }}
            >
              Click here for registration!!!
            </label> */}
            </form>
          )}

          <form>
            <label
              className="form-label fw-bold text-center d-flex justify-content-center"
              style={{
                fontFamily: "Metropolis-medium",
                fontSize: "1.2rem",
                color: "#004dac",
              }}
            >
              BIKE SELECTED
            </label>
            <input
              type="text"
              value={bike}
              readOnly
              className="check-in-form-input w-100 text-center"
            />{" "}
          </form>
        </div>
        {/* display details */}
        <div className="display-panel">
          <form className="d-flex flex-column" onSubmit={handleConfirm}>
            <label className="form-label">Registration Number</label>
            <input
              type="text"
              value={registrationNumber}
              readOnly
              className="check-in-form-input"
            />{" "}
            <label className="form-label">Name</label>
            <input
              type="text"
              readOnly
              value={name}
              className="check-in-form-input"
            />{" "}
            <label className="form-label">Phone Number</label>
            <input
              type="tel"
              readOnly
              className="check-in-form-input"
              value={phoneNumber}
              onChange={(e) => {
                const phoneNumber = e.target.value.slice(0, 12);
                if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                  e.target.setCustomValidity("");
                } else {
                  e.target.setCustomValidity(
                    "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                  );
                }
              }}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                )
              }
            />
            <label className="form-label">Email ID</label>
            <input
              type="email"
              readOnly
              value={email}
              className="check-in-form-input"
            />{" "}
            <label className="form-label">Select your t-shirt size</label>
            <select
              onChange={(e) => setSize(e.target.value)}
              className="check-in-form-input"
            >
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
              <option value="XXL">XXL</option>
            </select>
            <div class="form-check mt-3 mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                required
                onChange={handleCheckboxChange}
              />
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style={{ fontSize: "0.9em", color: "grey" }}
              >
                <p
                  className="m-0"
                  style={
                    isOpen
                      ? null
                      : {
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          display: "-webkit-box",
                        }
                  }
                  ref={ref}
                >
                  {" "}
                  <p className="mb-2">
                    By participating in the EMotorad E-Cyclothon event, you
                    acknowledge and agree that your participation is entirely at
                    your own risk. EMotorad, its affiliates, directors,
                    employees, volunteers, and agents (collectively, "EMotorad")
                    will not be held responsible or liable for any injuries,
                    accidents, losses, or damages that may occur before, during,
                    or after the event. You are solely responsible for ensuring
                    your own safety and well-being throughout the event.
                  </p>
                  <p className="mb-2">
                    Participants must take all necessary precautions and consult
                    with a medical professional to confirm their fitness to
                    participate in the E-Cyclothon. You agree to assume full
                    responsibility for any risks associated with your
                    participation, including but not limited to falls, contact
                    with other
                  </p>
                  <p className="mb-2">
                    This disclaimer is intended to be as broad and inclusive as
                    permitted by the jurisdiction's laws in which the event is
                    conducted. If any portion of this disclaimer is held
                    invalid, the remaining provisions shall continue in full
                    force and effect. Participating in the EMotorad E-Cyclothon
                    acknowledges that you have read, understood, and agreed to
                    this disclaimer.
                  </p>
                </p>{" "}
                {showReadMore && (
                  <span
                    onClick={() => setIsOpen(!isOpen)}
                    className="fw-bold"
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    {isOpen ? "read less..." : "read more..."}
                  </span>
                )}
              </label>
            </div>
            <button
              type="submit"
              className="mt-3 mb-5 btn btn-lg movements-register-button-main-banner"
            >
              Check In
            </button>
          </form>
        </div>
        {/* <button
          className={`floating-cta-event w-100 text-decoration-none d-lg-none text-center${
            isVisible ? "visible" : ""
          }`}
          onClick={scrollToRegister}
        >
          REGISTER
        </button> */}
      </div>
    </div>
  );
}

export default CheckInScreen;
