import {
  CONTACT_US_FAIL,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  CONTACT_US_RESET,
  EMI_FAIL,
  EMI_REQUEST,
  EMI_SUCCESS,
  EMI_RESET,
  INSURANCE_FAIL,
  INSURANCE_REQUEST,
  INSURANCE_SUCCESS,
  PARTNER_WITH_US_FAIL,
  PARTNER_WITH_US_REQUEST,
  PARTNER_WITH_US_SUCCESS,
  WARRANTY_FAIL,
  WARRANTY_REQUEST,
  WARRANTY_SUCCESS,
  PARTNER_WITH_US_RESET,
  WARRANTY_RESET,
  INSURANCE_RESET,
  SEARCHCHECKIN_REQUEST,
  SEARCHCHECKIN_SUCCESS,
  SEARCHCHECKIN_FAIL,
  SEARCHCHECKIN_RESET,
} from "../Constants/formConstants";

export const contactUsReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_US_REQUEST:
      return { loading: true };
    case CONTACT_US_SUCCESS:
      return { loading: false, contact: action.payload };
    case CONTACT_US_FAIL:
      return { loading: false, error: action.payload };
    case CONTACT_US_RESET:
      return {};
    default:
      return state;
  }
};

export const EMIReducer = (state = {}, action) => {
  switch (action.type) {
    case EMI_REQUEST:
      return { loading: true };
    case EMI_SUCCESS:
      return { loading: false, emiStatus: action.payload };
    case EMI_FAIL:
      return { loading: false, error: action.payload };
    case EMI_RESET:
      return {};
    default:
      return state;
  }
};

export const partnerWithUsReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_WITH_US_REQUEST:
      return { loading: true };
    case PARTNER_WITH_US_SUCCESS:
      return { loading: false, partnerStatus: action.payload };
    case PARTNER_WITH_US_FAIL:
      return { loading: false, error: action.payload };
    case PARTNER_WITH_US_RESET:
      return {};
    default:
      return state;
  }
};

export const warrantyReducer = (state = {}, action) => {
  switch (action.type) {
    case WARRANTY_REQUEST:
      return { loading: true };
    case WARRANTY_SUCCESS:
      return { loading: false, warrantyStatus: action.payload };
    case WARRANTY_FAIL:
      return { loading: false, error: action.payload };
    case WARRANTY_RESET:
      return {};
    default:
      return state;
  }
};

export const insuranceReducer = (state = {}, action) => {
  switch (action.type) {
    case INSURANCE_REQUEST:
      return { loading: true };
    case INSURANCE_SUCCESS:
      return { loading: false, insuranceStatus: action.payload };
    case INSURANCE_FAIL:
      return { loading: false, error: action.payload };
    case INSURANCE_RESET:
      return {};
    default:
      return state;
  }
};
