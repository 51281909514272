import React, { useEffect, useRef, useState } from "react";
import "../CSS/spinWheel.css";
import Navbar from "../Components/Navbar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

const SpinWheel = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [rotation, setRotation] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [prizeNodes, setPrizeNodes] = useState([]);
  const [hasSpun, setHasSpun] = useState(false);

  const spinnerRef = useRef(null);
  const tickerRef = useRef(null);
  const reaperRef = useRef(null);

  const [dealerName, setDealerName] = useState("");
  const [dealerData, setDealerData] = useState("");
  const [randomGuess, setRandomGuess] = useState("");
  const [isConfetti, setIsConfetti] = useState(false);
  const [output, setOutput] = useState("");
  const [expired, setExpired] = useState(false);

  const [unitOptions, setUnitOptions] = useState([]);

  useEffect(() => {
    const fetchDealerNamne = async () => {
      try {
        const response = await axios.get(`/api/form/warranty/${id}`);
        if (!response.data.warranty.jackpot.expired) {
          setDealerName(
            response.data.warranty.dealerName
              ? response.data.warranty.dealerName
              : "Dealer"
          );
        } else {
          setExpired(true);
          setDealerName("");
        }
      } catch (error) {
        setDealerName("");
      }
    };
    fetchDealerNamne();
  }, [id]);

  useEffect(() => {
    if (dealerName) {
      const fetchDealer = async () => {
        if (dealerName === "Dealer") {
          setDealerData("Dealer");
          setRandomGuess("accessories");
        } else {
          try {
            const config = {
              headers: {
                "Content-Type": "application/json",
              },
            };
            const globalRewardResponse = await axios.get(
              "/api/dealer/global/global-reward",
              config
            );
            const availableRewards = Object.entries(
              globalRewardResponse.data.units
            )
              .filter(([key, value]) => value > 0)
              .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
              }, {});
            const encodedDealerName = encodeURIComponent(dealerName);
            const response = await axios.get(
              `/api/dealer/${encodedDealerName}`,
              config
            );
            setDealerData(response.data.dealer);
            const { units } = response.data.dealer;
            const nonZeroKeys = Object.keys(units).filter(
              (key) => units[key] > 0
            );
            const validKeys = nonZeroKeys.filter(
              (key) => availableRewards[key] > 0
            );
            setUnitOptions(validKeys);
            const options =
              validKeys.length > 0 ? [...validKeys] : ["accessories"];
            const randomIndex = Math.floor(Math.random() * options.length);
            let optionValue = options[randomIndex];
            if (response.data.dealer.delivered) {
              setRandomGuess(optionValue);
            } else {
              optionValue = options[0];
              setRandomGuess(optionValue);
            }
          } catch (err) {
            setDealerData("Dealer");
            setRandomGuess("accessories");
          }
        }
      };
      fetchDealer();
    }
  }, [dealerName]);

  const prizes = [
    {
      text: "Smart TV",
      color: "#004dac",
      unitName: "tv",
    },
    {
      text: "Sound Bar",
      color: "#f15523",
      unitName: "soundBar",
    },
    {
      text: "OTG",
      color: "#faa31f",
      unitName: "otg",
    },
    {
      text: "Play Station 5",
      color: "#8cba50",
      unitName: "ps5",
    },
    {
      text: "Kent Mixer",
      color: "#3ec2d4",
      unitName: "mixer",
    },
    {
      text: "iPhone",
      color: "#936eb0",
      unitName: "iphone",
    },
    {
      text: "Portable Speaker",
      color: "#fed60f",
      unitName: "speaker",
    },
    {
      text: "30% off on accessories",
      color: "#ee1c51",
      unitName: "accessories",
    },
  ];

  const prizeSlice = 360 / prizes.length;
  const prizeOffset = Math.floor(180 / prizes.length);

  useEffect(() => {
    if (dealerData) {
      setupWheel();
    }
  }, [dealerData]);

  const setupWheel = () => {
    createConicGradient();
    createPrizeNodes();
  };

  const createPrizeNodes = () => {
    const nodes = prizes.map(({ text, color, reaction }, i) => {
      const rotation = prizeSlice * i * -1 - prizeOffset;
      return (
        <li
          key={i}
          className="prize text-light fw-bold"
          data-reaction={reaction}
          style={{ "--rotate": `${rotation}deg` }}
        >
          <span className="text">{text}</span>
        </li>
      );
    });
    setPrizeNodes(nodes);
  };

  const createConicGradient = () => {
    const gradient = `conic-gradient(
      from -90deg,
      ${prizes
        .map(
          ({ color }, i) =>
            `${color} 0 ${(100 / prizes.length) * (prizes.length - i)}%`
        )
        .reverse()}
    )`;
    spinnerRef.current.style.background = gradient;
  };

  const spinWheel = async () => {
    if (isSpinning) return;

    try {
      setIsSpinning(true);
      setHasSpun(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const prizeIndex = prizes.findIndex(
        (prize) => prize.unitName === randomGuess
      );

      if (prizeIndex === -1) {
        throw new Error("Invalid prize");
      }

      setOutput(prizes[prizeIndex].text);

      const prizeAngle = prizeIndex * prizeSlice;
      const randomSpin = Math.floor(Math.random() * 5000) + 5000;
      const finalRotation = rotation + randomSpin;
      const finalAngle = finalRotation % 360;
      const additionalAngle = 2;
      const offset = prizeAngle - finalAngle + additionalAngle;
      const adjustedRotation = finalRotation + offset;

      setRotation(adjustedRotation);

      if (spinnerRef.current) {
        spinnerRef.current.style.transition =
          "transform 8s cubic-bezier(0.1, 1, 0.3, 1)";
        spinnerRef.current.style.transform = `rotate(${adjustedRotation}deg)`;
      }

      setTimeout(() => {
        setIsSpinning(false);
        setIsConfetti(true);
        const updateBumperDealer = async () => {
          if (unitOptions.includes(randomGuess)) {
            try {
              await axios.patch(
                "/api/dealer",
                {
                  dealerName,
                  unitName: randomGuess,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              await axios.patch("/api/dealer/global/global-reward", {
                unitType: randomGuess,
                dealerName: dealerName,
              });
            } catch (err) {
              console.error("Error updating dealer data:", err);
            }
          }
        };

        const updateWarranty = async () => {
          try {
            const response = await axios.put(
              `/api/form/warranty/${id}`,
              { prize: randomGuess },
              config
            );
          } catch (error) {
            console.error("Error updating warranty data:", error);
          }
        };

        updateWarranty();
        updateBumperDealer();
      }, 8000);
    } catch (error) {
      console.error("Error spinning the wheel:", error);
      setIsSpinning(false);
    }
  };

  return (
    <>
      <Navbar />
      {dealerData && (
        <div className="spinwheel-container position-relative">
          <div className="position-relative">
            <picture className="main-banner-img">
              <source
                media="(max-width:650px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/Wheel+Bg+mobile-100.jpg"
              />
              <source
                media="(min-width:651px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/Artboard+5-100.jpg"
              />
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/Artboard+5-100.jpg"
                alt="Banner1"
                className="w-100"
              />
            </picture>
            <div className="spinwheel">
              <div className="d-lg-none">
                <h1
                  className="text-light text-center"
                  style={{ fontFamily: "Metropolis-bold", fontSize: "2.5em" }}
                >
                  <em>Spin</em>
                </h1>
                <h5
                  className="text-light text-center mb-5"
                  style={{ fontFamily: "" }}
                >
                  And get a chance to win big!
                </h5>
              </div>

              <div className={`deal-wheel ${isSpinning ? "is-spinning" : ""}`}>
                <ul
                  className="spinner"
                  ref={spinnerRef}
                  style={{ transform: `rotate(${rotation}deg)` }}
                >
                  {prizeNodes}
                </ul>
                <figure className="cap">
                  <div className="grim-reaper" ref={reaperRef}></div>
                </figure>
                <div className="ticker" ref={tickerRef}></div>
                <button
                  className="btn-spin"
                  style={{ fontFamily: "Metropolis-medium" }}
                  onClick={spinWheel}
                  disabled={isSpinning || hasSpun}
                >
                  Spin Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isConfetti && (
        <div
          className="confetti-overlay px-3"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            zIndex: "10",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1
            className="text-center mb-5 congratulations-text"
            style={{
              fontFamily: "Glamatrix",
              color: "white",
              zIndex: "11",
            }}
          >
            Congratulations
          </h1>
          <h6 className="text-center text-light mb-3 mt-lg-3 unlocked-text">
            You've unlocked your mega prize
          </h6>
          <h1
            className="text-light mb-5 reward-text text-center"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            <em>{output}</em>
          </h1>
          <small className="text-center text-light mt-5">
            *Contact your dealer to claim your prize. <br></br>T&C Apply.
          </small>
        </div>
      )}
      {expired && (
        <div
          style={{
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1
            className="text-danger px-3 px-lg-0 text-center"
            style={{
              fontFamily: "Metropolis-bold",
              zIndex: "101",
            }}
          >
            Oops! The meter’s already maxed out.
          </h1>
        </div>
      )}
    </>
  );
};

export default SpinWheel;
