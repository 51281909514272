import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProductByName } from "../Actions/productActions";
import { addToCart } from "../Actions/cartActions";
import Navbar from "../Components/Navbar";
import { Icon } from "@iconify/react/dist/iconify.js";
import { productHighlights } from "../Components/productDetailsObject";
import Meta from "../Components/Meta";
import Footer from "../Components/Footer";

const AccessoryScreen = () => {
  SwiperCore.use([Navigation, Pagination, Scrollbar]);

  const sliderRef = useRef();
  const [Collapse, setCollapse] = useState(false);
  const [variant, setVariant] = useState(0);
  const [model, setModel] = useState(0);
  const [imageIndex, setImageIndex] = useState(1);
  const [isDimensionOpen, setIsDimensionOpen] = useState(true);
  const [isDescOpen, setIsDescOpen] = useState(false);
  const [isShippingOpen, setIsShippingOpen] = useState(false);
  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const [isDoubtsOne, setIsDoubtsOne] = useState(false);
  const [isDoubtsTwo, setIsDoubtsTwo] = useState(false);
  const [isDoubtsThree, setIsDoubtsThree] = useState(false);
  const [isDoubtsFour, setIsDoubtsFour] = useState(false);
  const [isDoubtsFive, setIsDoubtsFive] = useState(false);
  const [product1, setProduct1] = useState("");
  const [product2, setProduct2] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productName } = useParams();

  const productByName = useSelector((state) => state.productByName);
  const { product: productInfo } = productByName;

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const toggleDimensionCollapse = () => {
    setIsDimensionOpen(!isDimensionOpen);
  };
  const toggleDescCollapse = () => {
    setIsDescOpen(!isDescOpen);
  };
  const toggleShippingCollapse = () => {
    setIsShippingOpen(!isShippingOpen);
  };
  const toggleFaqCollapse = () => {
    setIsFaqOpen(!isFaqOpen);
  };
  const toggleDoubtsOneCollapse = () => {
    setIsDoubtsOne(!isDoubtsOne);
  };
  const toggleDoubtsTwoCollapse = () => {
    setIsDoubtsTwo(!isDoubtsTwo);
  };
  const toggleDoubtsThreeCollapse = () => {
    setIsDoubtsThree(!isDoubtsThree);
  };
  const toggleDoubtsFourCollapse = () => {
    setIsDoubtsFour(!isDoubtsFour);
  };
  const toggleDoubtsFiveCollapse = () => {
    setIsDoubtsFive(!isDoubtsFive);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (productName) {
        await dispatch(getProductByName(productName));
        setImageIndex(1);
        if (productInfo) {
          if (productInfo.models.length > params.get("color")) {
            setModel(Number(params.get("color")));
          }
        }
      }
    };
    fetchData();
  }, [dispatch, productName]);

  useEffect(() => {
    const handleViewItem = () => {
      if (productInfo && productInfo.name) {
        window.gtag("event", "view_item", {
          currency: "INR",
          value: productInfo.accPrice,
          items: [
            {
              item_id: productInfo._id,
              item_name: productInfo.name,
              item_brand: "EMotorad",
              item_category:
                productInfo.category === "accessorie"
                  ? "EM Accessories"
                  : "Xcape Accessories",
              item_category2: productInfo.subCategory,
              item_list_name: productInfo.subCategory,
              item_variant: `${productInfo.models[model].colorName} ${
                productInfo.variants.length
                  ? `|${productInfo.variants[variant]?.variantName}`
                  : ""
              }`,
              price: productInfo.accPrice,
              quantity: 1,
            },
          ],
        });
      }
    };
    const createProductSchema = () => {
      if (productInfo && productInfo.name) {
        const productSchema = {
          "@context": "http://schema.org",
          "@type": "Product",
          name: productInfo.name,
          url: `https://www.emotorad.com/${
            productInfo.category === "accessorie"
              ? "accessories"
              : "accessories/xcape"
          }/${productName}`,
          image: productInfo.cartImages[0],
          description: productInfo.description,
          brand: {
            "@type": "Brand",
            name: "EMotorad",
          },
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: productInfo.reviews.length
              ? parseFloat(productInfo.reviews[0])
              : 4.6,
            reviewCount: productInfo.reviews.length
              ? parseInt(productInfo.reviews[1])
              : 149,
          },
          offers: {
            "@type": "Offer",
            priceCurrency: "INR",
            price: productInfo.accPrice,
            itemCondition: "NewCondition",
            availability: productInfo.inStock ? "InStock" : "Out of Stock",
          },
        };
        const script = document.createElement("script");
        script.type = "application/ld+json";
        script.innerHTML = JSON.stringify(productSchema);

        document.head.appendChild(script);
      }
    };
    createProductSchema();
    handleViewItem();
  }, [productInfo, productName]);

  const currencyFormat = (num) => {
    return "₹" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const addCart = () => {
    const handleAddToCart = () => {
      if (productInfo) {
        window.gtag("event", "add_to_cart", {
          currency: "INR",
          value: productInfo.accPrice,
          items: [
            {
              item_id: productInfo._id,
              item_name: productInfo.name,
              item_brand: "EMotorad",
              item_category:
                productInfo.category === "accessorie"
                  ? "EM Accessories"
                  : "Xcape Accessories",
              item_category2: productInfo.subCategory,
              item_variant: `${productInfo.models[model].colorName} ${
                productInfo.variants.length
                  ? `|${productInfo.variants[variant]?.variantName}`
                  : ""
              }`,
              price: productInfo.accPrice,
              quantity: 1,
            },
          ],
        });
      }
    };
    handleAddToCart();
    dispatch(addToCart(productInfo._id, model, 1, 1, variant));
    navigate("/cart");
  };

  const productHighlightsData = productHighlights.find(
    (b) => b.name === productName
  );

  const bikeSpecs = [
    {
      productName: "Beacon",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/beacon/Blue/BEACON+BLUE+cart.png",
      price: currencyFormat(25999),
      material: "EPS foam body and a PC hard shell exterior",
      ventilation: "20 vents",
      visibility: "LED front and taillight",
      fit: "Premium adjustable dial",
      weight: "330g",
      type: "helmet",
    },
    {
      productName: "Aerosheild",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/aeroshield/red/AEROSHIELD+RED+cart.png",
      price: currencyFormat(25999),
      material: "EPS foam body and a PC hard shell exterior",
      ventilation: "19 vents",
      visibility: "Integrated taillight",
      fit: "Advanced adjustable dial",
      weight: "260g",
      type: "helmet",
    },
    {
      productName: "Blazebeam",
      type: "light",
    },
    {
      productName: "miniair",
      type: "light",
    },
  ];

  const tableSpecsData = bikeSpecs.find((b) => b.name === productName);

  useEffect(() => {
    if (productInfo) {
      if (productInfo.urlName === "beacon") {
        setProduct1("Beacon");
        setProduct2("Aerosheild");
      }
    }
  }, [productInfo, productName]);

  return (
    <>
      <Navbar breadcrumb={true} />
      {productInfo && (
        <>
          <Meta
            title={`Buy ${productInfo.name} ${productInfo.subCategory} at best price | EMotorad`}
            description={`${productInfo.description}`}
            cannonicalTag={`https://www.emotorad.com/accessories/${productInfo.urlName}`}
          />
          <div className="container-fluid position-relative">
            <div className="row position-relative">
              <div className="col-lg-8 p-0 ">
                {/* catalogue images */}

                <div className="d-flex position-relative align-items-center bike-product-images justify-content-center">
                  <div
                    className="position-absolute slider-count"
                    style={{
                      fontFamily: "Metropolis-bold",
                    }}
                  >
                    <h6 className="">
                      ({imageIndex}/{productInfo.productImages[model].length})
                    </h6>
                  </div>
                  <Swiper
                    navigation={{ clickable: true }}
                    ref={sliderRef}
                    spaceBetween={15}
                    slidesPerView={1}
                    loop={true}
                    breakpoints={{
                      1024: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                    }}
                    slide
                    onSlideChange={(swiper) => {
                      const newIndex =
                        swiper.activeIndex %
                        productInfo.productImages[model].length;
                      if (newIndex === 0) {
                        setImageIndex(productInfo.productImages[model].length);
                      } else {
                        setImageIndex(newIndex);
                      }
                    }}
                    onSwiper={(swiper) => {
                      const newIndex =
                        swiper.activeIndex %
                        productInfo.productImages[model].length;
                      setImageIndex(newIndex);
                    }}
                  >
                    {productInfo.productImages[model].map((item) => {
                      return (
                        <SwiperSlide className="p-1 w-100">
                          <img
                            src={item}
                            alt=""
                            className="img-fluid d-block mx-auto"
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>

                {/* ...........mobile details............ */}
                <div className="d-lg-none">
                  <div className="p-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h1
                          className="mb-1"
                          style={{
                            fontFamily: "Metropolis-bold",
                            fontSize: "1.7em",
                          }}
                        >
                          {productInfo.name}
                        </h1>
                        <div className="d-flex align-content-center">
                          <Icon
                            icon="ic:round-star-rate"
                            style={{ color: "#ff9000" }}
                            width={"15"}
                          />
                          <Icon
                            icon="ic:round-star-rate"
                            style={{ color: "#ff9000" }}
                            width={"15"}
                          />
                          <Icon
                            icon="ic:round-star-rate"
                            style={{ color: "#ff9000" }}
                            width={"15"}
                          />
                          <Icon
                            icon="ic:round-star-rate"
                            style={{ color: "#ff9000" }}
                            width={"15"}
                          />
                          <Icon
                            icon="ic:round-star-half"
                            style={{ color: "#ff9000" }}
                            width={"15"}
                          />
                          <span
                            className="ms-2"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            {productHighlightsData.reviews.product[1]}
                          </span>
                        </div>
                      </div>
                      <div>
                        <h4
                          className="text-end fw-bold"
                          style={{
                            fontFamily: "Metropolis-medium",
                          }}
                        >
                          {" "}
                          {productInfo.models[model].price
                            ? currencyFormat(productInfo.models[model].price)
                            : currencyFormat(productInfo.accPrice)}
                        </h4>
                        <h2
                          className="text-end "
                          style={{
                            textDecoration: "line-through",
                            fontFamily: "Metropolis-medium",
                          }}
                        >
                          {currencyFormat(
                            productInfo.models[model].slashPrice
                              ? productInfo.models[model].slashPrice
                              : productInfo.slashPrice
                          )}
                        </h2>
                      </div>
                    </div>
                    <hr className="mt-2" />
                    {productInfo.variants.length ? (
                      <>
                        <div className="mt-3 mb-4">
                          <p
                            style={{ fontFamily: "Metropolis-bold" }}
                            className="mb-1"
                          >
                            Variants
                          </p>
                          <div
                            className="d-flex flex-wrap"
                            style={{ gap: "1em" }}
                          >
                            {productInfo.variants.map((vari, i) => (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setVariant(i);
                                  setModel(0);
                                  sliderRef.current.swiper.slideTo(1);
                                }}
                                className={`border  d-flex align-items-center justify-content-center p-2 px-3 border-dark text-center rounded-pill ${
                                  variant === i && "active-color"
                                }`}
                              >
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Metropolis-bold",
                                  }}
                                >
                                  {vari.variantName}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                        <hr className="mt-2" />
                      </>
                    ) : (
                      ""
                    )}
                    {productInfo.variants.length ? (
                      <div className="mt-3 mb-4">
                        <p
                          style={{ fontFamily: "Metropolis-bold" }}
                          className="mb-1"
                        >
                          Colours
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "1em" }}
                        >
                          {productInfo.variants[variant].models.map(
                            (color, i) => (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setModel(i);
                                  sliderRef.current.swiper.slideTo(1);
                                }}
                                className={`border  d-flex align-items-center p-2 px-3 border-dark rounded-pill ${
                                  model === i && "active-color"
                                }`}
                              >
                                <div
                                  className="me-2 rounded-circle"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    background: `${productInfo.models[color].colorCode}`,
                                  }}
                                ></div>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Metropolis-bold",
                                  }}
                                >
                                  {productInfo.models[color].colorName}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="mt-3 mb-4">
                        <p
                          style={{ fontFamily: "Metropolis-bold" }}
                          className="mb-1"
                        >
                          Colours
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "1em" }}
                        >
                          {productInfo.models.map((color, i) => (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setModel(i);
                                sliderRef.current.swiper.slideTo(1);
                              }}
                              className={`border  d-flex align-items-center p-2 border-dark rounded-pill ${
                                model === i && "active-color"
                              }`}
                            >
                              <div
                                className="me-2 rounded-circle"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  background: `${color.colorCode}`,
                                }}
                              ></div>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Metropolis-bold",
                                }}
                              >
                                {color.colorName}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <hr className="mt-2" />
                    <div className="mt-3">
                      <Link
                        to={"/find-e-bicycle-store"}
                        className="btn w-100 p-lg-2 pt-3 pb-3  text-em rounded-5"
                        style={{
                          fontSize: "14px",
                          border: "1px solid #004DAC",
                          fontFamily: "Metropolis-bold",
                        }}
                      >
                        BUY NOW AT YOUR NEAREST DEALERSHIP
                      </Link>
                    </div>
                    {/* )} */}
                  </div>
                  <div className="line-break"></div>
                  <div className="p-3">
                    <div className="row pt-1 pb-1  pb-0 justify-content-around">
                      <div className="col-4 text-center p-0">
                        <Icon
                          icon={productHighlightsData.productSpecs[0].iconName}
                          width={25}
                          style={{ color: "#004dac" }}
                        />
                        <h6 className="mb-0 mt-1 text-center">
                          {
                            productHighlightsData.productSpecs[0].spec.split(
                              " "
                            )[0]
                          }
                        </h6>
                        <p style={{ fontSize: "0.8em" }}>
                          {productHighlightsData.productSpecs[0].spec
                            .split(" ")
                            .slice(1)
                            .join(" ")}
                        </p>
                      </div>
                      <div className="col-4 text-center p-0">
                        <Icon
                          icon={productHighlightsData.productSpecs[1].iconName}
                          width={25}
                          style={{ color: "#004dac" }}
                        />
                        <h6 className="mb-0 mt-1 text-center">
                          {
                            productHighlightsData.productSpecs[1].spec.split(
                              " "
                            )[0]
                          }
                        </h6>
                        <p style={{ fontSize: "0.8em" }}>
                          {productHighlightsData.productSpecs[1].spec
                            .split(" ")
                            .slice(1)
                            .join(" ")}
                        </p>
                      </div>
                      <div className="col-4 text-center p-0">
                        <Icon
                          icon={productHighlightsData.productSpecs[2].iconName}
                          width={25}
                          style={{ color: "#004dac" }}
                        />
                        <h6 className="mb-0 mt-1 text-center">
                          {
                            productHighlightsData.productSpecs[2].spec.split(
                              " "
                            )[0]
                          }
                        </h6>
                        <p style={{ fontSize: "0.8em" }}>
                          {productHighlightsData.productSpecs[2].spec
                            .split(" ")
                            .slice(1)
                            .join(" ")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="line-break"></div>

                <div className="lifeStyle-breaker">
                  <img
                    className="w-100"
                    src={productHighlightsData.lifestyleImages[model][0]}
                    alt=""
                  />
                </div>

                <div className="line-break"></div>

                {/* USP Section */}
                <div className="mt-4 ps-lg-5 pe-lg-5 px-3">
                  <h1
                    className="mb-4 structure-main-heading text-em heading-homepage"
                    style={{ fontFamily: "Metropolis-black" }}
                  >
                    What makes {productInfo.name} special?
                  </h1>
                  <p>{productInfo.description}</p>
                  <div className="col-12 col-md-6 my-5 px-3">
                    {productInfo.specs.map((item, i) => (
                      <div key={i} className="row ">
                        <div className="col-md-6 col-5">
                          <p className="m-0 specs-feature">{item.name}</p>
                        </div>
                        <div className="col-md-6 col-7">
                          <p className="m-0 specs-feature">{item.value}</p>
                        </div>
                        <hr className="p-0" style={{ marginTop: "8px" }} />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="line-break"></div>

                <div className="lifeStyle-breaker">
                  <img
                    className="w-100"
                    src={productHighlightsData.lifestyleImages[model][1]}
                    alt=""
                  />
                </div>

                <div className="line-break"></div>

                <div className="specs-list p-3 ps-lg-4 pe-lg-4 mt-3">
                  <div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample4"
                      aria-expanded={isShippingOpen ? "true" : "false"}
                      aria-controls="collapseExample"
                      onClick={toggleShippingCollapse}
                    >
                      <h1
                        className="structure-main-heading text-em heading-homepage"
                        style={{ fontFamily: "Metropolis-black" }}
                      >
                        Shipping Terms
                      </h1>
                      <span className="iconify">
                        {isShippingOpen ? (
                          <i className="fa-solid fa-minus"></i>
                        ) : (
                          <i className="fa-solid fa-plus"></i>
                        )}
                      </span>
                    </div>

                    <div className="collapse mt-3" id="collapseExample4">
                      <p className="ps-4">
                        <b>Shipping Terms:</b> Available shipping options will
                        be displayed during the checkout process. Any timeframe
                        provided by EMotorad for when the Product(s) may ship or
                        be delivered is a good faith estimate. While we do our
                        best to meet that timeframe, it is not a guarantee.
                        Actual delivery of your order can be impacted by many
                        events for which EMotorad cannot be held liable. If you
                        no longer have use for an item due to a late delivery,
                        please contact our Customer Service Department
                        immediately.
                      </p>
                      <p className="ps-4">
                        <b>Inspection on Receipt:</b> Customers are advised to
                        immediately inspect the packaging for any signs of
                        damage upon receiving the goods. If any obvious damage
                        is observed, customers have the option to reject the
                        delivery and should contact EMotorad promptly.
                      </p>
                      <p className="ps-4">
                        <b>Reporting Shipping Damage:</b> Claims for shipping
                        damage must be made within 24 hours of receiving the
                        product. EMotorad will not accept claims for shipping
                        damage reported later than this timeframe.
                      </p>
                      <p className="ps-4">
                        <b>Rejecting Damaged Goods:</b> If customers notice
                        obvious damage to the product packaging, they have the
                        right to reject the delivery. Customers should contact
                        EMotorad immediately to initiate the claims process.
                      </p>
                      <p className="ps-4">
                        <b>Warranty Exclusions:</b> Shipping damage caused by
                        neglect, misuse, abuse, or accidents is not covered
                        under the warranty. Normal wear and tear, incorrect
                        installation, alterations, and tampering damage are also
                        excluded from warranty coverage.
                      </p>
                      <p className="ps-4">
                        <b>Geographical Limitations:</b> The warranty is valid
                        only within the geographical boundaries of the Indian
                        subcontinent.
                      </p>
                      <p className="ps-4">
                        <b>Change of Warranty Policy:</b> EMotorad reserves the
                        right to change the warranty policy at its sole
                        discretion without any prior notice.
                      </p>
                      <p className="ps-4">
                        <b>Contact Information:</b> For offline and online
                        purchases, customers should reach out to the EMotorad
                        service team regarding any shipping damage claims or
                        warranty-related queries. Please note that these terms
                        are subject to change by EMotorad, and customers are
                        advised to refer to the latest terms and conditions
                        provided by the company.
                      </p>
                    </div>
                  </div>

                  <hr className="p-0" style={{ marginTop: "16px" }} />

                  <div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample5"
                      aria-expanded={isFaqOpen ? "true" : "false"}
                      aria-controls="collapseExample"
                      onClick={toggleFaqCollapse}
                    >
                      <h1
                        className="structure-main-heading text-em heading-homepage"
                        style={{ fontFamily: "Metropolis-black" }}
                      >
                        FAQs
                      </h1>
                      <span className="iconify">
                        {isFaqOpen ? (
                          <i className="fa-solid fa-minus"></i>
                        ) : (
                          <i className="fa-solid fa-plus"></i>
                        )}
                      </span>
                    </div>

                    <div className="collapse mt-3" id="collapseExample5">
                      {productHighlightsData.faq.map((faq, index) => (
                        <div key={index}>
                          <input
                            type="checkbox"
                            id={`question${index + 1}`}
                            name="q"
                            className="questions"
                          ></input>
                          <label
                            htmlFor={`question${index + 1}`}
                            className="question"
                          >
                            {index + 1}. {faq.question}
                          </label>
                          <div
                            className="answers"
                            dangerouslySetInnerHTML={{ __html: faq.answer }}
                          ></div>
                          <hr className="p-0" style={{ marginTop: "16px" }} />
                        </div>
                      ))}
                    </div>
                  </div>
                  {!isFaqOpen && (
                    <hr className="p-0" style={{ marginTop: "16px" }} />
                  )}
                </div>

                <div className="line-break"></div>

                <div className="reviews-div  p-3 ps-lg-4">
                  <div className=" mb-lg-4 reviews-sub-div">
                    <div className="">
                      <h1
                        className="mb-4 structure-main-heading text-em heading-homepage"
                        style={{ fontFamily: "Metropolis-black" }}
                      >
                        Some Rider Love!
                      </h1>
                      <p className="product-text mb-1 mb-lg-3">
                        More than 90% of our customers who have bought EMotorad
                        products have loved them. Do take a look at what some of
                        them have to say:
                      </p>
                      <div
                        className="mb-2 mb-lg-5"
                        style={{ textAlign: "center" }}
                      >
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            fontWeight: "600",
                            fontSize: "1.3em",
                          }}
                        >
                          <span style={{ fontSize: "2em" }}>
                            {productHighlightsData.reviews.product[0]}
                          </span>
                          /5
                        </p>
                        <Icon icon="emojione:star" />
                        <Icon icon="emojione:star" />
                        <Icon icon="emojione:star" />
                        <Icon icon="emojione:star" />
                        <Icon icon="emojione:star" />
                        <p className="reviews-text">1965 reviews</p>
                      </div>
                      <div className="row reviews-logo-sec">
                        <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                          <img
                            className="reviews-logo"
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/amazon-logo_2.png"
                            alt="Amazon Logo"
                          />
                          <div className="d-flex flex-column align-items-center reviews-logo-div">
                            <div className="d-flex">
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                            </div>
                            <p
                              style={{ margin: 0, padding: 0 }}
                              className="review-logo-text"
                            >
                              1640 reviews
                            </p>
                          </div>
                        </div>
                        <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                          <img
                            className="reviews-logo"
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/flipkart-logo1.png"
                            alt="Amazon Logo"
                          />
                          <div className="d-flex flex-column align-items-center reviews-logo-div">
                            <div className="d-flex">
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                            </div>
                            <p
                              style={{ margin: 0, padding: 0 }}
                              className="review-logo-text"
                            >
                              193 reviews
                            </p>
                          </div>
                        </div>
                        <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                          <img
                            className="reviews-logo"
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/Google__G__logo.png"
                            alt="Amazon Logo"
                          />
                          <div className="d-flex flex-column align-items-center reviews-logo-div">
                            <div className="d-flex">
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                            </div>
                            <p
                              style={{ margin: 0, padding: 0 }}
                              className="review-logo-text"
                            >
                              132 reviews
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-lg-none">
                    <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                      <img
                        className="reviews-logo"
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/amazon-logo_2.png"
                        alt="Amazon Logo"
                      />
                      <div className="d-flex flex-column align-items-center reviews-logo-div">
                        <div className="d-flex">
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                        </div>
                        <p
                          style={{ margin: 0, padding: 0 }}
                          className="review-logo-text"
                        >
                          1640 reviews
                        </p>
                      </div>
                    </div>
                    <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                      <img
                        className="reviews-logo"
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/flipkart-logo1.png"
                        alt="Amazon Logo"
                      />
                      <div className="d-flex flex-column align-items-center reviews-logo-div">
                        <div className="d-flex">
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                        </div>
                        <p
                          style={{ margin: 0, padding: 0 }}
                          className="review-logo-text"
                        >
                          193 reviews
                        </p>
                      </div>
                    </div>
                    <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                      <img
                        className="reviews-logo"
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/Google__G__logo.png"
                        alt="Amazon Logo"
                      />
                      <div className="d-flex flex-column align-items-center reviews-logo-div">
                        <div className="d-flex">
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                        </div>
                        <p
                          style={{ margin: 0, padding: 0 }}
                          className="review-logo-text"
                        >
                          132 reviews
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line-break"></div>
              </div>
              <div
                className="background-em d-lg-none position-sticky p-2"
                style={{
                  bottom: "0%",
                  border: "0px",
                  width: "100%",
                  zIndex: "2",
                }}
              >
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    gap: "1.5em",
                    bottom: "0%",
                    border: "0px",
                    width: "100%",
                    zIndex: "2",
                  }}
                >
                  <div>
                    {productInfo.slashPrice > 0 && (
                      <h4
                        className="text-light text-center mb-0"
                        style={{ fontFamily: "Metropolis-bold" }}
                      >
                        {Math.trunc(
                          ((productInfo.slashPrice - productInfo.accPrice) /
                            productInfo.slashPrice) *
                            100
                        )}
                        {"%"} OFF
                      </h4>
                    )}{" "}
                    <div className="bg-light rounded-5  text-center text-em ">
                      <h4 style={{ fontFamily: "Metropolis-bold" }}>SALE</h4>
                    </div>
                    <small
                      style={{
                        fontSize: "0.8em",
                        fontFamily: "Metropolis-medium",
                      }}
                      className=" d-block text-center text-light "
                    >
                      Limited Period Offer
                    </small>
                  </div>
                  <div className=" col-6 ">
                    {productInfo.inStock ? (
                      productInfo.models[
                        productInfo.variants.length
                          ? productInfo.variants[variant].models[model]
                          : model
                      ].inStock ? (
                        <button
                          className="btn p-2 fw-bold w-100 btn-light d-block ms-auto text-em border-0 "
                          style={{
                            fontFamily: "Metropolis-bold",
                            borderRadius: "25px",
                          }}
                          onClick={() =>
                            addCart(productInfo._id, model, 1, 1, variant)
                          }
                        >
                          BUY NOW
                        </button>
                      ) : (
                        <a
                          className="btn p-2 fw-bold disabled w-100 btn-light d-block ms-auto text-em border-0 text-deoration-none"
                          style={{
                            fontFamily: "Metropolis-bold",
                            borderRadius: "25px",
                          }}
                          href=""
                        >
                          OUT OF STOCK
                        </a>
                      )
                    ) : (
                      // bikeInfo.name === "T-Rex Air" ? (
                      //   <a
                      //     className="btn p-2 fw-bold w-100 btn-light d-block ms-auto text-em border-0 text-deoration-none"
                      //     style={{
                      //       fontFamily: "Metropolis-bold",
                      //       borderRadius: "25px",
                      //     }}
                      //     href="https://rzp.io/l/xV2C7BfKcK"
                      //   >
                      //     PRE-BOOK @ ₹ 999
                      //   </a>
                      // ) :
                      <a
                        className="btn p-2 fw-bold disabled w-100 btn-light d-block ms-auto text-em border-0 text-deoration-none"
                        style={{
                          fontFamily: "Metropolis-bold",
                          borderRadius: "25px",
                        }}
                        href=""
                      >
                        OUT OF STOCK
                      </a>
                    )}
                  </div>
                </div>
                {/* {!bikeInfo.inStock && (
                  <p className="text-light m-0 mt-2 text-center">
                    It will be dispatched soon
                  </p>
                )} */}
              </div>

              {/* .........................Fixed Desktop bike details................................ */}
              <div
                className="col-lg-4 d-none d-lg-block p-0 position-sticky"
                style={{ height: "85vh", overflowY: "auto", top: "14%" }}
              >
                <div className="p-4 pe-5" style={{ minHeight: "fit-content" }}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h1
                        className="mb-1"
                        style={{
                          fontFamily: "Metropolis-bold",
                          fontSize: "2em",
                        }}
                      >
                        {productInfo.name}
                      </h1>
                      <div className="d-flex align-content-center">
                        <Icon
                          icon="ic:round-star-rate"
                          style={{ color: "#ff9000" }}
                          width={"20"}
                        />
                        <Icon
                          icon="ic:round-star-rate"
                          style={{ color: "#ff9000" }}
                          width={"20"}
                        />
                        <Icon
                          icon="ic:round-star-rate"
                          style={{ color: "#ff9000" }}
                          width={"20"}
                        />
                        <Icon
                          icon="ic:round-star-rate"
                          style={{ color: "#ff9000" }}
                          width={"20"}
                        />
                        <Icon
                          icon="ic:round-star-half"
                          style={{ color: "#ff9000" }}
                          width={"20"}
                        />
                        <span
                          className="ms-2"
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          {/* {bikeHighlightsData.reviews.product[1]} Reviews */}
                        </span>
                      </div>
                    </div>
                    <div>
                      <h4
                        className="text-end fw-bold"
                        style={{
                          fontFamily: "Metropolis-medium",
                        }}
                      >
                        {" "}
                        {productInfo.models[
                          productInfo.variants.length
                            ? productInfo.variants[variant].models[model]
                            : model
                        ].price
                          ? currencyFormat(
                              productInfo.models[
                                productInfo.variants.length
                                  ? productInfo.variants[variant].models[model]
                                  : model
                              ].price
                            )
                          : currencyFormat(productInfo.accPrice)}
                      </h4>
                      <h3
                        className="text-end "
                        style={{
                          textDecoration: "line-through",
                          fontFamily: "Metropolis-medium",
                        }}
                      >
                        {currencyFormat(
                          productInfo.models[
                            productInfo.variants.length
                              ? productInfo.variants[variant].models[model]
                              : model
                          ].slashPrice
                            ? productInfo.models[
                                productInfo.variants.length
                                  ? productInfo.variants[variant].models[model]
                                  : model
                              ].slashPrice
                            : productInfo.slashPrice
                        )}
                      </h3>
                    </div>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex my-3 justify-content-around">
                    <div className=" text-center">
                      <Icon
                        icon={productHighlightsData.productSpecs[0].iconName}
                        width={25}
                        style={{ color: "#004dac" }}
                      />
                      <h6 className="mb-0 mt-1 text-center">
                        {
                          productHighlightsData.productSpecs[0].spec.split(
                            " "
                          )[0]
                        }
                      </h6>
                      <p className="mb-0">
                        {productHighlightsData.productSpecs[0].spec
                          .split(" ")
                          .slice(1)
                          .join(" ")}
                      </p>
                    </div>
                    <div className=" text-center">
                      <Icon
                        icon={productHighlightsData.productSpecs[1].iconName}
                        width={25}
                        style={{ color: "#004dac" }}
                      />
                      <h6 className="mb-0 mt-1 text-center">
                        {
                          productHighlightsData.productSpecs[1].spec.split(
                            " "
                          )[0]
                        }
                      </h6>
                      <p className="mb-0">
                        {productHighlightsData.productSpecs[1].spec
                          .split(" ")
                          .slice(1)
                          .join(" ")}
                      </p>
                    </div>
                    <div className=" text-center">
                      <Icon
                        icon={productHighlightsData.productSpecs[2].iconName}
                        width={25}
                        style={{ color: "#004dac" }}
                      />
                      <h6 className="mb-0 mt-1 text-center">
                        {
                          productHighlightsData.productSpecs[2].spec.split(
                            " "
                          )[0]
                        }
                      </h6>
                      <p className="mb-0">
                        {productHighlightsData.productSpecs[2].spec
                          .split(" ")
                          .slice(1)
                          .join(" ")}
                      </p>
                    </div>
                  </div>
                  <hr className="mt-2" />
                  {productInfo.variants.length ? (
                    <>
                      <div className="mt-3 mb-4">
                        <p style={{ fontFamily: "Metropolis-bold" }}>
                          VARIANTS
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "1em" }}
                        >
                          {productInfo.variants.map((vari, i) => (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setVariant(i);
                                setModel(0);
                                sliderRef.current.swiper.slideTo(1);
                              }}
                              className={`border  d-flex align-items-center p-2 px-3 border-dark justify-content-center rounded-pill ${
                                variant === i && "active-color"
                              }`}
                            >
                              <span
                                className=""
                                style={{
                                  fontSize: "15px",
                                  fontFamily: "Metropolis-bold",
                                }}
                              >
                                {vari.variantName}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr />
                    </>
                  ) : (
                    ""
                  )}
                  {productInfo.variants.length ? (
                    <div className="mt-3 mb-4">
                      <p style={{ fontFamily: "Metropolis-bold" }}>COLOURS</p>
                      <div className="d-flex flex-wrap" style={{ gap: "1em" }}>
                        {productInfo.variants[variant].models.map(
                          (color, i) => (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setModel(i);
                                sliderRef.current.swiper.slideTo(1);
                              }}
                              className={`border  d-flex align-items-center p-2 px-3 border-dark rounded-pill ${
                                model === i && "active-color"
                              }`}
                            >
                              <div
                                className="me-2 rounded-circle"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  background: `${productInfo.models[color].colorCode}`,
                                }}
                              ></div>
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontFamily: "Metropolis-bold",
                                }}
                              >
                                {productInfo.models[color].colorName}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="mt-3 mb-4">
                      <p style={{ fontFamily: "Metropolis-bold" }}>COLOURS</p>
                      <div className="d-flex flex-wrap" style={{ gap: "1em" }}>
                        {productInfo.models.map((color, i) => (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModel(i);
                              sliderRef.current.swiper.slideTo(1);
                            }}
                            className={`border  d-flex align-items-center p-2 border-dark rounded-pill ${
                              model === i && "active-color"
                            }`}
                          >
                            <div
                              className="me-2 rounded-circle"
                              style={{
                                width: "20px",
                                height: "20px",
                                background: `${color.colorCode}`,
                              }}
                            ></div>
                            <span
                              style={{
                                fontSize: "15px",
                                fontFamily: "Metropolis-bold",
                              }}
                            >
                              {color.colorName}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="mt-4">
                    <Link
                      to={"/find-e-bicycle-store"}
                      className="btn w-100 p-3 text-em rounded-5"
                      style={{
                        fontSize: "13px",
                        border: "1px solid #004DAC",
                        fontFamily: "Metropolis-bold",
                      }}
                    >
                      BUY NOW AT YOUR NEAREST DEALERSHIP
                    </Link>
                    {/* <div className="d-flex mt-3 " style={{ gap: "2em" }}>
                      <div className="w-100">
                        <a
                          href={productHighlightsData.links.amazon}
                          target="_blank"
                          className="btn d-flex justify-content-center align-items-center w-100 p-3 text-light rounded-5"
                          style={{
                            border: "1px solid #004DAC",
                            fontSize: "15px",
                            gap: "1em",
                            background: "#004dac",
                            fontFamily: "Metropolis-bold",
                          }}
                          rel="noreferrer"
                        >
                          BUY ON
                          <img
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/bi_amazon.png"
                            className="img-fluid "
                            style={{ width: "20px" }}
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="w-100">
                        <a
                          href={productHighlightsData.links.flipkart}
                          target="_blank"
                          className="btn d-flex justify-content-center align-items-center w-100 p-3 text-light rounded-5"
                          style={{
                            border: "1px solid #004DAC",
                            fontSize: "15px",
                            gap: "1em",
                            background: "#004dac",
                            fontFamily: "Metropolis-bold",
                          }}
                          rel="noreferrer"
                        >
                          BUY ON
                          <img
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/simple-icons_flipkart.png"
                            className="img-fluid "
                            style={{ width: "20px" }}
                            alt=""
                          />
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div
                  className="background-em d-flex position-sticky align-items-center justify-content-center p-3"
                  style={{ gap: "3em", bottom: "0%", height: "auto" }}
                >
                  <div>
                    {productInfo.slashPrice > 0 && (
                      <h4
                        className="text-light text-center mb-0"
                        style={{ fontFamily: "Metropolis-bold" }}
                      >
                        {Math.trunc(
                          (((productInfo.models[
                            productInfo.variants.length
                              ? productInfo.variants[variant].models[model]
                              : model
                          ].slashPrice
                            ? productInfo.models[
                                productInfo.variants.length
                                  ? productInfo.variants[variant].models[model]
                                  : model
                              ].slashPrice
                            : productInfo.slashPrice) -
                            (productInfo.models[
                              productInfo.variants.length
                                ? productInfo.variants[variant].models[model]
                                : model
                            ].price
                              ? productInfo.models[
                                  productInfo.variants.length
                                    ? productInfo.variants[variant].models[
                                        model
                                      ]
                                    : model
                                ].price
                              : productInfo.accPrice)) /
                            (productInfo.models[
                              productInfo.variants.length
                                ? productInfo.variants[variant].models[model]
                                : model
                            ].slashPrice
                              ? productInfo.models[
                                  productInfo.variants.length
                                    ? productInfo.variants[variant].models[
                                        model
                                      ]
                                    : model
                                ].slashPrice
                              : productInfo.slashPrice)) *
                            100
                        )}
                        {"%"} OFF
                      </h4>
                    )}{" "}
                    <div className="bg-light rounded-5  text-center text-em ">
                      <h4 style={{ fontFamily: "Metropolis-bold" }}>SALE</h4>
                    </div>
                    <small
                      style={{
                        fontSize: "0.8em",
                        fontFamily: "Metropolis-medium",
                      }}
                      className=" d-block text-center text-light "
                    >
                      Limited Period Offer
                    </small>
                  </div>
                  <div className="">
                    {productInfo.inStock ? (
                      productInfo.models[
                        productInfo.variants.length
                          ? productInfo.variants[variant].models[model]
                          : model
                      ].inStock ? (
                        <button
                          className="btn btn-light w-100 text-em border-0 rounded-5 pt-3 pb-3 p-3 ps-5 pe-5"
                          style={{
                            padding: "1em 4em",
                            fontFamily: "Metropolis-bold",
                          }}
                          onClick={() =>
                            addCart(productInfo._id, model, 1, 1, variant)
                          }
                        >
                          BUY NOW
                        </button>
                      ) : (
                        <div
                          className="btn btn-light w-100 text-em disabled border-0 rounded-5 pt-3 pb-3 p-3 text-decoration-none"
                          style={{
                            fontFamily: "Metropolis-bold",
                          }}
                        >
                          OUT OF STOCK
                        </div>
                      )
                    ) : (
                      <div
                        className="btn btn-light w-100 text-em disabled border-0 rounded-5 pt-3 pb-3 p-3 text-decoration-none"
                        style={{
                          fontFamily: "Metropolis-bold",
                        }}
                        href=""
                      >
                        OUT OF STOCK
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default AccessoryScreen;
