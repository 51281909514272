import React, { useEffect } from "react";
import ordersuccess from "../images/order-confirm.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CART_RESET } from "../Constants/cartConstants";
import {
  ORDER_CONFIRM_RESET,
  ORDER_CONFIRM_SUCCESS,
} from "../Constants/orderConstants";
import { Helmet } from "react-helmet";
import axios from "axios";

const OrderConfirmScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const orderConfirm = useSelector((state) => state.orderConfirm);
  const { orderStatus } = orderConfirm;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get("status");
    const amount = params.get("amount");
    const txnid = params.get("txnid");

    if (txnid) {
      const fetchOrder = async () => {
        try {
          const { data } = await axios.post(`/api/order/orders/${txnid}`);
          const cartItems = data.items;

          if (!cartItems || cartItems.length === 0) {
            console.warn("No items found in the order.");
            return;
          }

          // Fire the gtag purchase event
          window.gtag("event", "purchase", {
            currency: "INR",
            transaction_id: data._id,
            value: cartItems.reduce(
              (total, cartItem) =>
                total + (cartItem.accPrice || 0) * (cartItem.qty || 0),
              0
            ),
            items: cartItems.map((cartItem) => ({
              item_id: cartItem.product,
              item_name: cartItem.name,
              item_brand: "EMotorad",
              item_category:
                cartItem.category === "bike"
                  ? "E-Bikes"
                  : cartItem.category === "accessorie"
                  ? "EM Accessories"
                  : "Xcape Accessories",
              item_category2: cartItem.subCategory || "Unknown",
              item_list_name: cartItem.subCategory || "Unknown",
              item_variant: `${
                cartItem.models?.[cartItem.model]?.colorName || "Unknown"
              } ${
                cartItem.variants?.[cartItem.variant]?.variantName
                  ? `|${cartItem.variants[cartItem.variant]?.variantName}`
                  : ""
              }`,
              price: cartItem.accPrice || 0,
              quantity: cartItem.qty || 0,
            })),
          });
        } catch (error) {
          console.error("Failed to fetch order details:", error);
        }
      };

      fetchOrder();
    }

    if (status === "success") {
      dispatch({
        type: ORDER_CONFIRM_SUCCESS,
        payload: { amount, txnid },
      });
      localStorage.removeItem("cartItems");
      dispatch({
        type: CART_RESET,
      });
    }

    return () => {
      dispatch({
        type: ORDER_CONFIRM_RESET,
      });
    };
  }, [dispatch, location]);

  return (
    <>
      <Helmet>
        <title>EMotorad | Order Confirm</title>
        <script>
          {window.gtag("event", "conversion", {
            send_to: "AW-10790037225/9y-7COPowZYYEOnNi5ko",
            value: orderStatus?.amount,
            currency: "INR",
            transaction_id: orderStatus?._id,
          })}
        </script>
      </Helmet>

      <div style={{ height: "90vh" }} className="container">
        <div className="row h-100 align-items-center position-relative">
          <div className="col-12 col-lg-6 offset-lg-3">
            <img
              src={ordersuccess}
              className="img-fluid d-block w-75 mx-auto"
              alt=""
            />
            <h6
              className="text-center"
              style={{
                lineHeight: "1.5em",
                fontSize: "1.1em",
                fontWeight: "700",
              }}
            >
              We’re happy to let you know that we’ve received your order.
            </h6>

            <p
              className="mt-lg-4 mt-4 text-center"
              style={{
                lineHeight: "1.5em",
                fontSize: "0.9em",
                fontWeight: "200",
              }}
            >
              You will be receiving a confirmation email with the order details.
            </p>
          </div>

          <Link className="d-block mx-auto text-center" to="/">
            Go back
          </Link>
        </div>
      </div>
    </>
  );
};

export default OrderConfirmScreen;
