import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CONFIRM_CART_ITEMS,
  CART_RESET,
  CHECK_PROMOCODE_REQUEST,
  CHECK_PROMOCODE_SUCCESS,
  CHECK_PROMOCODE_FAIL,
} from "../Constants/cartConstants";

export const cartReducer = (
  state = { cartItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload;
      const existItem = state.cartItems.find(
        (x) =>
          x.productModel === item.productModel &&
          x.colorName === item.colorName &&
          x.variantType === item.variantType
      );

      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.productModel === existItem.productModel &&
            x.colorName === existItem.colorName &&
            x.variantType === item.variantType
              ? { ...x, qty: x.qty + 1 }
              : x
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, { ...item }],
        };
      }

    // case CART_ADD_ITEM:
    // 	const item = action.payload
    // 	const existItem = state.cartItems.find(
    // 		(x) => x.productModel === item.productModel,
    // 	)

    // 	if (existItem) {
    // 		return {
    // 			...state,
    // 			cartItems: state.cartItems.map((x) =>
    // 			x.productModel === existItem.productModel ? { ...x, qty: x.qty + 1 } : x
    // 		  ),
    // 		};
    // 	} else {
    // 		return {
    // 			...state,
    // 			cartItems: [...state.cartItems, { ...item }],
    // 		  }
    // 	}

    case CART_REMOVE_ITEM:
      const remove = action.payload;
      let removeItem;
      if (remove.name === "EMX+") {
        removeItem = state.cartItems.find(
          (x) => x.colorName === remove.colorName
        );
        if (removeItem) {
          const updatedCartItems = state.cartItems.map((x) =>
            x.colorName === remove.colorName
              ? { ...x, qty: x.qty > 1 ? x.qty - 1 : 0 }
              : x
          );

          const filteredCartItems = updatedCartItems.filter((x) => x.qty > 0);

          return {
            ...state,
            cartItems: filteredCartItems,
          };
        } else {
          return state;
        }
      } else {
        removeItem = state.cartItems.find(
          (x) =>
            x.productModel === remove.productModel &&
            x.variantType === remove.variantType
        );
        if (removeItem) {
          const updatedCartItems = state.cartItems.map((x) =>
            x.productModel === remove.productModel &&
            x.variantType === remove.variantType
              ? { ...x, qty: x.qty > 1 ? x.qty - 1 : 0 }
              : x
          );

          const filteredCartItems = updatedCartItems.filter((x) => x.qty > 0);

          return {
            ...state,
            cartItems: filteredCartItems,
          };
        } else {
          return state;
        }
      }
    //   if (removeItem) {
    //     const updatedCartItems = state.cartItems.map((x) =>
    //       x.productModel === remove.productModel
    //         ? { ...x, qty: x.qty > 1 ? x.qty - 1 : 0 }
    //         : x
    //     );

    //     const filteredCartItems = updatedCartItems.filter((x) => x.qty > 0);

    //     return {
    //       ...state,
    //       cartItems: filteredCartItems,
    //     };
    //   } else {
    //     return state;
    //   }

    case CART_RESET:
      return {
        ...state,
        cartItems: [],
      };

    // You might want to handle other cases or add a default return statement here.
    // If there is nothing to return, you can use 'return state;' outside the switch statement.

    // const updatedCartItems = state.cartItems.map((item) =>
    //   item.productModel === resetProduct.productModel ? { ...item, qty:0 } : item
    // );
    // const newArray = updatedCartItems.filter(item => item.productModel !== resetProduct.productModel);
    // return {
    //   ...state,
    //   cartItems: newArray,
    // };
    default:
      return state;
  }
};

export const confirmCartItemsReducer = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_CART_ITEMS:
      return {
        loading: false,
        confirmedItems: action.payload,
      };

    default:
      return state;
  }
};

export const checkPromocodeReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECK_PROMOCODE_REQUEST:
      return { loading: true };
    case CHECK_PROMOCODE_SUCCESS:
      return {
        loading: false,
        promocodes: action.payload,
      };
    case CHECK_PROMOCODE_FAIL:
      return {
        loading: false,
        promocodes: action.payload,
      };
    default:
      return state;
  }
};
