import React, { useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import Meta from "../Components/Meta";
import TestRide from "../Components/TestRide";
const BlogScreen = () => {
  const { slug } = useParams();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const categoryMapping = {
    latestUpdates: "Latest Updates",
    productScoop: "Product Scoop",
    dailyDash: "Daily Dash",
    tipsTricks: "Tips & Tricks",
    vibezEvents: "Vibez & Events",
    pedalTales: "Pedal Tales",
  };
  const [blogPost, setBlogPost] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!slug) return;
      const hygraph = new GraphQLClient(
        "https://api-ap-south-1.hygraph.com/v2/clcrn35rt0ct401ur6hv90b6q/master"
      );
      const Query1 = gql`
        query GetBlogPost($slug: String!) {
          posts(where: { slug: $slug }) {
            author {
              name
            }
            coverImage {
              url
            }
            date
            slug
            seo {
              title
              description
              keywords
            }
            blogCategory
            tags
            excerpt
            title
            content {
              html
              markdown
              raw
              text
            }
          }
        }
      `;
      try {
        const { posts } = await hygraph.request(Query1, { slug });
        setBlogPost(posts[0]);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };

    fetchData();
  }, [slug]);

  console.log(blogPosts[0]);

  useEffect(() => {
    const fetchData = async () => {
      if (!slug) return;
      const hygraph = new GraphQLClient(
        "https://api-ap-south-1.hygraph.com/v2/clcrn35rt0ct401ur6hv90b6q/master"
      );
      const Query2 = gql`
        {
          posts(orderBy: date_DESC) {
            title
            slug
            coverImage {
              url
            }
            date
            blogCategory
            excerpt
          }
        }
      `;
      try {
        const { posts } = await hygraph.request(Query2, { slug });
        setBlogPosts(posts);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };

    fetchData();
  }, [slug]);

  const renderBlogContent = () => {
    return parse(blogPost.content.html, {
      replace: (domNode) => {
        if (domNode.name && domNode.name === "img") {
          return <img {...domNode.attribs} className="img-fluid" />;
        }
        return undefined;
      },
    });
  };

  return (
    <>
      <>
        {blogPost && (
          <Meta
            title={blogPost.seo && blogPost.seo.title}
            description={blogPost.seo && blogPost.seo.description}
            cannonicalTag={`https://www.emotorad.com/blogs/${slug}`}
            keywords={blogPost.seo && blogPost.seo.keywords.join(", ")}
          />
        )}
        <Navbar breadcrumb={true} />
        <div className="container mt-lg-5 mt-4">
          {blogPost && blogPost.content && blogPosts && (
            <>
              <div>
                <div className="py-4 py-lg-5">
                  <div
                    className="d-flex flex-column flex-lg-row nowrap pb-lg-5"
                    style={{ gap: "2em" }}
                  >
                    <div className="col-12 col-md-6">
                      <h1
                        className="mb-4 blog-heading"
                        style={{ fontFamily: "Metropolis-bold" }}
                      >
                        {blogPost.title}
                      </h1>

                      <div className="mb-3">
                        <p className="text-muted m-0">{blogPost.excerpt}</p>
                      </div>
                      <div className="d-flex mb-4" style={{ gap: "2em" }}>
                        {blogPost.blogCategory && (
                          <p
                            style={{ fontSize: "0.9em" }}
                            className="text-em m-0 blog-category fw-bold blog-category"
                          >
                            {categoryMapping[blogPost.blogCategory]}
                          </p>
                        )}
                        <p
                          style={{ fontSize: "0.9em" }}
                          className="text-muted m-0"
                        >
                          <span className="pe-2 text-muted">
                            {months[new Date(blogPost.date).getMonth()]} {""}
                            {new Date(blogPost.date).getDate()},{" "}
                            {new Date(blogPost.date).getFullYear()}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <img
                        src={blogPost?.coverImage?.url}
                        className="img-fluid"
                        alt="Blog Cover"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <div className="px-0 px-lg-5">
                    <div id="content" className="blog-content px-lg-5">
                      {renderBlogContent()}
                    </div>
                  </div>
                </div>

                <div></div>
              </div>
            </>
          )}
        </div>
        <div className="pt-5">
          {" "}
          {blogPosts[0] && (
            <div className="background-em py-2 py-lg-5">
              <div className="container py-5">
                <h5 className="mb-5 fw-bold text-center text-light">
                  Other Related Articles that might interest you
                </h5>
                <div
                  className="d-flex flex-column flex-lg-row"
                  style={{ gap: "1.2em" }}
                >
                  {blogPosts.slice(0, 4).map((post, i) => (
                    <div key={i} className="col-md-3 col-12 mb-4 blog-div">
                      <Link
                        to={`/blogs/${post.slug}`}
                        className="text-decoration-none text-dark"
                      >
                        <div
                          className=" rounded-3  h-100"
                          style={{ boxShadow: "1px 1px 5px lightgrey" }}
                        >
                          <div>
                            <img
                              src={post?.coverImage?.url}
                              alt=""
                              className="img-fluid rounded-2"
                            />
                          </div>
                          <div className="p-3">
                            <div className="d-flex justify-content-between mb-3">
                              {post.blogCategory && (
                                <p
                                  style={{ fontSize: "0.9em" }}
                                  className="text-em m-0 blog-category fw-bold text-light"
                                >
                                  {categoryMapping[post.blogCategory]}
                                </p>
                              )}
                              <p
                                style={{ fontSize: "0.9em" }}
                                className="text-muted m-0 text-light"
                              >
                                <span className="pe-2 text-light">
                                  {months[new Date(post.date).getMonth()]} {""}
                                  {new Date(post.date).getDate()},{" "}
                                  {new Date(post.date).getFullYear()}
                                </span>
                              </p>
                            </div>

                            <div className="">
                              <h5
                                className="mb-4 blog-heading text-light"
                                style={{
                                  fontFamily: "Metropolis-medium",
                                  display: "-webkit-box",
                                  maxWidth: "100%",
                                  fontSize: "1.15em",
                                  WebkitLineClamp: "3",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {post.title}
                              </h5>
                              <p
                                className=" mt-2 text-light"
                                style={{
                                  display: "-webkit-box",
                                  fontSize: "0.9em",
                                  maxWidth: "100%",
                                  WebkitLineClamp: "3",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                }}
                              >
                                {post.excerpt}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        <Footer />
      </>
    </>
  );
};

export default BlogScreen;
