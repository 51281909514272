import React, { useEffect, useRef, useState } from "react";

function Question({ question, totalQuestions, currentQuestion, setAnswer }) {
  const [selectedOption, setSelectedOption] = useState("not answered");
  const timer = useRef(null);
  const progressBar = useRef(null);
  const [timerFull, setTimerFull] = useState(false);
  async function goToNextQuestion() {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    await setAnswer(selectedOption);
    setSelectedOption("not answered");
  }

  useEffect(() => {
    setSelectedOption("not answered");
    progressBar.current.classList.remove("active");
    setTimeout(() => {
      progressBar.current.classList.add("active");
    }, 0);

    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setTimerFull(true);
    }, 10 * 1000);

    return () => clearTimeout(timer.current);
  }, [question]);

  useEffect(() => {
    if (timerFull) {
      clearTimeout(timer.current);
      progressBar.current.classList.remove("active");
      setTimeout(() => {
        progressBar.current.classList.add("active");
      }, 0);
      timer.current = setTimeout(() => {
        setTimerFull(true);
      }, 10 * 1000);
      setAnswer(selectedOption);
      setTimerFull(false);
    }
  }, [timerFull]);
  return (
    <div className="question">
      <div className="progress-line" ref={progressBar}></div>
      <div className="question-count">
        <b>{currentQuestion}</b>&nbsp;of&nbsp;
        <b>{totalQuestions}</b>
      </div>
      <div className="main">
        <div className="title">
          <span>Question:</span>
          <p>{question.title}</p>
        </div>
        <div className="options">
          {question.options.map((option, index) => {
            return (
              <div
                className={index == selectedOption ? "option-active" : "option"}
                key={index}
                onClick={() => setSelectedOption(index)}
              >
                {option}
              </div>
            );
          })}
        </div>
      </div>
      <div className="control">
        <button className="quiz-button" onClick={goToNextQuestion}>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default Question;
